import { Map } from 'immutable';

import { EllipsisVariationDiamond } from 'components/EllipsisVariationDiamond';
import { VariationColor } from 'components/VariationColor';
import styles from 'stylesheets/components/Variation.module.css';
import { colorVariation, Variation } from 'utils/flagUtils';

type VariationIconStackProps = {
  maxIconsToDisplay: number;
  variations: Map<number, Variation>;
};

const VariationIconStack = ({ maxIconsToDisplay, variations }: VariationIconStackProps) => {
  const variationsToShow = variations.slice(0, maxIconsToDisplay);
  const displayOuterStroke = variations.count() > 1;
  const variationIcons = variationsToShow
    .map((v: Variation, key: number) => (
      <VariationColor
        key={v._key}
        className={styles.colorDiamond}
        displayOuterStroke={displayOuterStroke}
        fill={colorVariation(key)}
      />
    ))
    .toList();
  return (
    <>
      {variationIcons}
      {variations.count() > maxIconsToDisplay && <EllipsisVariationDiamond />}
    </>
  );
};

export { VariationIconStack };
