import { MouseEvent } from 'react';
import { couldBeNumber } from '@gonfalon/types';
import classNames from 'clsx';
import { Tooltip } from 'launchpad';

import { getAlternateType } from 'utils/typeUtils';

import 'stylesheets/components/SelectAttribute.css';

export type SelectAttributeValueTypeProps<T = unknown> = {
  isInMenu: boolean;
  disabled?: boolean;
  expectedType?: string;
  label: string;
  onTypeChange: (value: T, alternateType?: 'string' | 'number' | 'boolean') => void;
  value: T;
};

const shortTypes: Record<string, string> = {
  string: 'str',
  number: 'num',
  boolean: 'bool',
};

export function SelectAttributeValueType<T extends string | number | boolean = string>(
  props: SelectAttributeValueTypeProps<T>,
) {
  const { expectedType, isInMenu, label, value } = props;
  const type = typeof value;
  const isTooLargeForJavaScript = couldBeNumber(value) && (value as string).length > 16;
  const alternateType = getAlternateType(value);
  const shortType = shortTypes[type];
  const tooltip = `Click to change from a ${type} to a ${alternateType}`;
  const hasLeadingWhiteSpace = /^\s/.test(value as string);
  const hasTrailingWhiteSpace = /\s$/.test(value as string);

  const handleMouseDown = (event: MouseEvent) => {
    const { onTypeChange, disabled } = props;

    // use mouseDown and stopPropagation to prevent select from focusing and showing autocomplete results
    event.stopPropagation();
    !disabled && onTypeChange(value, alternateType);
  };

  return (
    <>
      {hasLeadingWhiteSpace && !isInMenu && (
        <Tooltip placement="bottom" content="This value contains leading whitespace">
          <span className="SelectAttribute-whitespace" />
        </Tooltip>
      )}
      <span className={classNames('Select-option-label', `SelectAttributeValue-label--${type}`)}>{label.trim()}</span>
      {hasTrailingWhiteSpace && !isInMenu && (
        <Tooltip placement="bottom" content="This value contains trailing whitespace">
          <span className="SelectAttribute-whitespace" />
        </Tooltip>
      )}
      {isTooLargeForJavaScript && !isInMenu && (
        <Tooltip
          placement="bottom"
          content="Numbers with more than 16 digits are supported as strings to avoid rounding errors"
        >
          <span
            role="presentation"
            className={classNames(
              'Chip',
              'Chip--default',
              'Chip--subtle',
              'SelectAttributeValue--isTooLargeForJavaScript',
              `SelectAttributeValue-type--${type}`,
            )}
          >
            {shortType}
          </span>
        </Tooltip>
      )}
      {!expectedType && !!alternateType && !isTooLargeForJavaScript && !isInMenu && (
        <Tooltip placement="bottom" content={tooltip}>
          <span
            role="presentation"
            className={classNames(
              'Chip',
              'Chip--default',
              'Chip--subtle',
              'SelectAttributeValue-type',
              `SelectAttributeValue-type--${type}`,
            )}
            onMouseDown={handleMouseDown}
          >
            {shortType}
          </span>
        </Tooltip>
      )}
      {!expectedType && !!alternateType && !isTooLargeForJavaScript && isInMenu && (
        <span
          className={classNames(
            'Chip',
            'Chip--default',
            'Chip--subtle',
            'SelectAttributeValue-typeLabel',
            `SelectAttributeValue-type--${type}`,
          )}
        >
          {shortType}
        </span>
      )}
    </>
  );
}
