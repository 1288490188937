import { Icon, IconName } from '@launchpad-ui/icons';
import clsx from 'clsx';
import { Label, Tooltip } from 'launchpad';

import styles from './KindButtonGroup.module.css';

export function KindButton<Kind extends string>({
  ariaLabel,
  children,
  icon,
  kind,
  tooltip,
  selectedKind,
  setKind,
  disabled,
}: {
  ariaLabel?: string;
  children: React.ReactNode;
  icon?: IconName;
  kind: Kind;
  tooltip: string;
  selectedKind: Kind;
  setKind: (kind: Kind) => void;
  disabled?: boolean;
}) {
  const id = `kindRadioButton-${kind}`;
  return (
    <div
      className={clsx(styles.kindButton, {
        [styles.kindButtonChecked]: kind === selectedKind,
        [styles.kindButtonDisabled]: disabled,
      })}
    >
      <Tooltip content={tooltip}>
        <Label htmlFor={id}>
          {icon && <Icon size="medium" name={icon} />}
          {children}
          <input
            id={id}
            type="radio"
            aria-label={ariaLabel}
            name="kind"
            value={kind}
            checked={kind === selectedKind}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setKind(e.target.value as Kind)}
            disabled={disabled}
          />
        </Label>
      </Tooltip>
    </div>
  );
}
