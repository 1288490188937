import { Time } from '@gonfalon/datetime';
import { DateFormat } from '@gonfalon/format';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';
import { Tooltip } from 'launchpad';

import styles from './UserExpirationTooltip.module.css';

type UserExpirationTooltipProps = {
  expirationDate: number;
  isOpen?: boolean;
  tooltipText?: string;
};

export const UserExpirationTooltip = ({ expirationDate, isOpen = false, tooltipText }: UserExpirationTooltipProps) => {
  const icon = <Icon name="calendar" size="small" />;

  return isOpen ? (
    <span className={styles.tooltip} data-test-id="user-expiration-tooltip">
      {icon}
    </span>
  ) : (
    <Tooltip
      targetClassName={cx('Popover-target', styles.tooltip)}
      content={
        <div data-test-id="user-expiration-tooltip">
          {tooltipText ? tooltipText : 'Scheduled for removal on '}
          <Time dateFormat={DateFormat.MM_DD_YYYY_H_MM_A_Z} datetime={expirationDate} notooltip />
        </div>
      }
    >
      {icon}
    </Tooltip>
  );
};
