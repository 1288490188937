import { VariationColor } from 'components/VariationColor';
import styles from 'stylesheets/components/Variation.module.css';
import { GreyColors } from 'types/colorTypes';

const EllipsisVariationDiamond = () => (
  <VariationColor className={styles.colorDiamond} fill={GreyColors.GREY_LIGHT} displayOuterStroke>
    ...
  </VariationColor>
);

export { EllipsisVariationDiamond };
