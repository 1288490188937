/* eslint-disable no-param-reassign */
import { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';
import { enableProgressiveRollout } from '@gonfalon/dogfood-flags';
import { StringUnion } from '@gonfalon/types';
import nullthrows from 'nullthrows';
import { v4 } from 'uuid';

import { createReducer } from 'components/release-pipeline/v2/common/createReducer';

import {
  durationQuantityLimits,
  ProgressiveRolloutConfiguration,
  ProgressiveRolloutConfigurationStep,
} from '../schemas';

export function FlagTargetingPendingProgressiveRolloutsProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(flagTargetingPendingProgressiveRolloutsReducer, {
    pendingProgressiveRollout: undefined,
  });

  if (!enableProgressiveRollout()) {
    return children;
  }

  return (
    <FlagTargetingPendingProgressiveRolloutsStateContext.Provider value={state}>
      <FlagTargetingPendingProgressiveRolloutsDispatchContext.Provider value={dispatch}>
        {children}
      </FlagTargetingPendingProgressiveRolloutsDispatchContext.Provider>
    </FlagTargetingPendingProgressiveRolloutsStateContext.Provider>
  );
}

const FlagTargetingPendingProgressiveRolloutsStateContext = createContext<
  FlagTargetingPendingProgressiveRolloutsState | undefined
>(undefined);
const FlagTargetingPendingProgressiveRolloutsDispatchContext = createContext<
  Dispatch<FlagTargetingPendingProgressiveRolloutsAction> | undefined
>(undefined);

export function useFlagTargetingPendingProgressiveRolloutsState<T>(
  selector: (state: FlagTargetingPendingProgressiveRolloutsState) => T,
) {
  const contextValue = useContext(FlagTargetingPendingProgressiveRolloutsStateContext);

  if (!enableProgressiveRollout()) {
    return selector({ pendingProgressiveRollout: undefined });
  }

  return selector(
    nullthrows(
      contextValue,
      `${useFlagTargetingPendingProgressiveRolloutsState.name} called outside of ${FlagTargetingPendingProgressiveRolloutsProvider.name}`,
    ),
  );
}

export function useFlagTargetingPendingProgressiveRolloutsDispatch() {
  const contextValue = useContext(FlagTargetingPendingProgressiveRolloutsDispatchContext);

  if (!enableProgressiveRollout()) {
    return (() => {}) as NonNullable<typeof contextValue>;
  }

  return nullthrows(
    contextValue,
    `${useFlagTargetingPendingProgressiveRolloutsDispatch.name} called outside of ${FlagTargetingPendingProgressiveRolloutsProvider.name}`,
  );
}

type ProgressiveRolloutConfigurationKey = StringUnion<'fallthrough'>;

export type PendingProgressiveRollout = {
  configurationKey: ProgressiveRolloutConfigurationKey;
  configuration: ProgressiveRolloutConfiguration;
};

export type FlagTargetingPendingProgressiveRolloutsState = {
  pendingProgressiveRollout: PendingProgressiveRollout | undefined;
};

export type FlagTargetingPendingProgressiveRolloutsAction =
  | {
      type: 'add-progressive-rollout';
      payload: {
        configurationKey: ProgressiveRolloutConfigurationKey;
        initialState?: ProgressiveRolloutConfiguration;
      };
    }
  | {
      type: 'add-step';
      payload: { configurationKey: ProgressiveRolloutConfigurationKey; insertAfterStepWithKey: string };
    }
  | {
      type: 'set-step-rollout-weight';
      payload: {
        configurationKey: ProgressiveRolloutConfigurationKey;
        stepKey: string;
        rolloutWeight: ProgressiveRolloutConfigurationStep['rolloutWeight'];
      };
    }
  | {
      type: 'set-step-duration-quantity';
      payload: {
        configurationKey: ProgressiveRolloutConfigurationKey;
        stepKey: string;
        quantity: ProgressiveRolloutConfigurationStep['duration']['quantity'];
      };
    }
  | {
      type: 'set-step-duration-unit';
      payload: {
        configurationKey: ProgressiveRolloutConfigurationKey;
        stepKey: string;
        unit: ProgressiveRolloutConfigurationStep['duration']['unit'];
      };
    }
  | {
      type: 'set-context-kind';
      payload: {
        configurationKey: ProgressiveRolloutConfigurationKey;
        contextKind: ProgressiveRolloutConfiguration['contextKind'];
      };
    }
  | { type: 'delete-step'; payload: { configurationKey: ProgressiveRolloutConfigurationKey; stepKey: string } }
  | {
      type: 'discard-progressive-rollout';
    }
  | {
      type: 'discard-progressive-rollout-with-configuration-key';
      payload: { configurationKey: ProgressiveRolloutConfigurationKey };
    };

export function createDefaultProgressiveRollout(): ProgressiveRolloutConfiguration {
  return {
    contextKind: 'user',
    steps: [
      {
        key: v4(),
        rolloutWeight: 1000,
        duration: {
          quantity: 4,
          unit: 'hour',
        },
      },
      {
        key: v4(),
        rolloutWeight: 5000,
        duration: {
          quantity: 4,
          unit: 'hour',
        },
      },
      {
        key: v4(),
        rolloutWeight: 10000,
        duration: {
          quantity: 4,
          unit: 'hour',
        },
      },
      {
        key: v4(),
        rolloutWeight: 25000,
        duration: {
          quantity: 4,
          unit: 'hour',
        },
      },
      {
        key: v4(),
        rolloutWeight: 50000,
        duration: {
          quantity: 4,
          unit: 'hour',
        },
      },
    ],
  };
}

export const flagTargetingPendingProgressiveRolloutsReducer = createReducer<
  FlagTargetingPendingProgressiveRolloutsState,
  FlagTargetingPendingProgressiveRolloutsAction
>({
  'add-progressive-rollout': (state, { configurationKey, initialState }) => {
    if (state.pendingProgressiveRollout) {
      throw new Error('Cannot add progressive rollout. Pending progressive rollout already exists.');
    }

    state.pendingProgressiveRollout = {
      configurationKey,
      configuration: initialState ?? createDefaultProgressiveRollout(),
    };
  },
  'add-step': (state, { configurationKey, insertAfterStepWithKey }) => {
    const previousStepIndex = selectProgressiveRolloutStepIndex(configurationKey, insertAfterStepWithKey)(state);
    const pr = selectProgressiveRollout(configurationKey)(state);
    pr.steps.splice(
      previousStepIndex + 1,
      0,
      createNewProgressiveRolloutStep(selectProgressiveRollout(configurationKey)(state).steps, insertAfterStepWithKey),
    );
  },
  'set-step-rollout-weight': (state, { configurationKey, stepKey, rolloutWeight }) => {
    const step = selectProgressiveRolloutStep(configurationKey, stepKey)(state);
    step.rolloutWeight = rolloutWeight;
  },
  'set-step-duration-quantity': (state, { configurationKey, stepKey, quantity }) => {
    const step = selectProgressiveRolloutStep(configurationKey, stepKey)(state);
    step.duration.quantity = quantity;
  },
  'set-step-duration-unit': (state, { configurationKey, stepKey, unit }) => {
    const step = selectProgressiveRolloutStep(configurationKey, stepKey)(state);
    step.duration.unit = unit;
    const quantityLimitsForUnit = nullthrows(
      durationQuantityLimits[unit],
      `invalid progressive rollout step duration quantity unit "${unit}"`,
    );
    step.duration.quantity = Math.min(step.duration.quantity, quantityLimitsForUnit.max);
  },
  'delete-step': (state, { configurationKey, stepKey }) => {
    const pr = selectProgressiveRollout(configurationKey)(state);
    const stepIndex = selectProgressiveRolloutStepIndex(configurationKey, stepKey)(state);
    pr.steps.splice(stepIndex, 1);
  },
  'set-context-kind': (state, { configurationKey, contextKind }) => {
    const pr = selectProgressiveRollout(configurationKey)(state);
    pr.contextKind = contextKind;
  },
  'discard-progressive-rollout': (state) => {
    state.pendingProgressiveRollout = undefined;
  },
  'discard-progressive-rollout-with-configuration-key': (state, { configurationKey }) => {
    if (state.pendingProgressiveRollout?.configurationKey === configurationKey) {
      state.pendingProgressiveRollout = undefined;
    }
  },
});

export function createNewProgressiveRolloutStep(
  steps: ProgressiveRolloutConfiguration['steps'],
  insertAfterStepWithKey: string,
): ProgressiveRolloutConfigurationStep {
  const previousStepIndex = steps.findIndex((s) => s.key === insertAfterStepWithKey);
  const previousStep = steps[previousStepIndex];
  const nextStep = previousStepIndex === steps.length - 1 ? undefined : steps[previousStepIndex + 1];

  return {
    key: v4(),
    // use a rollout weight halfway between the previous step and the next step, or 100000 if there is no next step
    rolloutWeight:
      previousStep.rolloutWeight + Math.round(((nextStep?.rolloutWeight ?? 100000) - previousStep.rolloutWeight) / 2),
    // use the previous step's duration, for simplicity
    duration: {
      quantity: previousStep.duration.quantity,
      unit: previousStep.duration.unit,
    },
  };
}

const hasStepKey = (stepKey: string) => (step: ProgressiveRolloutConfigurationStep) => step.key === stepKey;

export function selectProgressiveRolloutStep(
  configurationKey: ProgressiveRolloutConfigurationKey,
  stepKey: string,
  options: { optional: true },
): (state: FlagTargetingPendingProgressiveRolloutsState) => ProgressiveRolloutConfigurationStep | undefined;
export function selectProgressiveRolloutStep(
  configurationKey: ProgressiveRolloutConfigurationKey,
  stepKey: string,
  options?: { optional: false },
): (state: FlagTargetingPendingProgressiveRolloutsState) => ProgressiveRolloutConfigurationStep;
export function selectProgressiveRolloutStep(
  configurationKey: ProgressiveRolloutConfigurationKey,
  stepKey: string,
  { optional }: { optional: boolean } = { optional: false },
) {
  return (state: FlagTargetingPendingProgressiveRolloutsState) =>
    optional
      ? selectProgressiveRollout(configurationKey, { optional: true })(state)?.steps.find(hasStepKey(stepKey))
      : nullthrows(
          selectProgressiveRollout(configurationKey)(state).steps.find(hasStepKey(stepKey)),
          `progressive rollout step with key "${stepKey}" not found`,
        );
}

export function selectProgressiveRolloutStepIndex(
  configurationKey: ProgressiveRolloutConfigurationKey,
  stepKey: string,
) {
  return (state: FlagTargetingPendingProgressiveRolloutsState) =>
    selectProgressiveRollout(configurationKey)(state).steps.findIndex((step) => step.key === stepKey);
}

export function selectProgressiveRollout(
  configurationKey: ProgressiveRolloutConfigurationKey,
  options: { optional: true },
): (state: FlagTargetingPendingProgressiveRolloutsState) => ProgressiveRolloutConfiguration | undefined;
export function selectProgressiveRollout(
  configurationKey: ProgressiveRolloutConfigurationKey,
  options?: { optional: false },
): (state: FlagTargetingPendingProgressiveRolloutsState) => ProgressiveRolloutConfiguration;
export function selectProgressiveRollout(
  configurationKey: ProgressiveRolloutConfigurationKey,
  { optional }: { optional?: boolean } = { optional: false },
) {
  return (state: FlagTargetingPendingProgressiveRolloutsState) => {
    if (
      (!state.pendingProgressiveRollout || state.pendingProgressiveRollout.configurationKey !== configurationKey) &&
      !optional
    ) {
      throw new Error(`Pending progressive rollout with configuration key "${configurationKey}" not found.`);
    }

    return optional
      ? state.pendingProgressiveRollout?.configuration
      : nullthrows(
          state.pendingProgressiveRollout?.configuration,
          `Pending progressive rollout with configuration key "${configurationKey}" not found.`,
        );
  };
}

export function selectProgressiveRolloutStepDurations(configurationKey: ProgressiveRolloutConfigurationKey) {
  return (state: FlagTargetingPendingProgressiveRolloutsState) => {
    const pr = selectProgressiveRollout(configurationKey)(state);
    return pr.steps.map((step) => step.duration);
  };
}
