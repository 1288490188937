import { isEmpty } from '@gonfalon/es6-utils';
import { ProgressBar } from '@launchpad-ui/components';
import { UseQueryResult } from '@tanstack/react-query';
import { Alert } from 'launchpad';

import { PendingChangesForm } from 'components/PendingChanges/PendingChangesForm';
import { ResourceInfo } from 'components/PendingChanges/ResourceInfo';
import { LaunchDarklyIntegrationsManifest } from 'types/generated/integrationSchema';
import { AccessDecision } from 'utils/accessUtils';
import { Flag } from 'utils/flagUtils';
import { FieldPath, FormState } from 'utils/formUtils';
import { SemanticInstruction } from 'utils/instructions/shared/types';
import {
  getReadableMigrationErrors,
  getRuleNameForMigrationError,
  MigrationErrorResponse,
  PendingChangesFormRecord,
  PendingChangesFormValueType,
} from 'utils/pendingChangesUtils';
import { ResourceKind, SaveOptions } from 'utils/saveButtonUtils';

import PendingChangesConfirmationModalAlerts from './PendingChangesConfirmationModalAlerts';
import { usePendingChangesContext } from './PendingChangesContext';

import './styles.css';
import styles from './PendingChangesConfirmationModal.module.css';

export type PendingChangesConfirmationModalProps = {
  saveOption: SaveOptions;
  resourceKind?: ResourceKind;
  resourceName: string;
  pendingChangesFormState: FormState<PendingChangesFormRecord>;
  hideApprovalOption?: boolean;
  hideScheduleOption?: boolean;
  approvalServiceKind?: string;
  approvalServiceManifest?: LaunchDarklyIntegrationsManifest;
  suggestedApprovalMemberIds?: string[];
  hasExpiringUserTargetChanges?: boolean;
  environmentValidation: { label: string; placeholder: string; onValidate(val: string): boolean };
  showExperimentsAlert?: boolean;
  showDeprecatedAlert?: boolean;
  ExperimentsAlertComponent?: React.ReactNode;
  showMultipleApprovalRequestsCreatedAlert?: boolean;
  showFlagTargetingOffAlert?: boolean;
  flagKey?: string;
  instructionList?: SemanticInstruction[];
  isUserPendingChanges?: boolean;
  migrationSafetyIssues?: UseQueryResult<MigrationErrorResponse[]>;
  updateScheduledChangesAccessDecision?: AccessDecision;
  updatedFlag?: Flag;
  renderInstructionList(): JSX.Element;
  onEditPendingChangesForm: (field: string, value: PendingChangesFormValueType) => void;
  onBlur(field: FieldPath): void;
  onBack?(): void;
  onCancel(): void;
  onSubmit(pendingChangesForm: PendingChangesFormRecord): void;
};

export function PendingChangesConfirmationModal({
  saveOption,
  resourceKind,
  resourceName,
  pendingChangesFormState,
  hideApprovalOption = false,
  hideScheduleOption = false,
  approvalServiceKind,
  approvalServiceManifest,
  environmentValidation,
  suggestedApprovalMemberIds,
  showExperimentsAlert = false,
  showDeprecatedAlert = false,
  ExperimentsAlertComponent,
  showMultipleApprovalRequestsCreatedAlert = false,
  showFlagTargetingOffAlert = false,
  flagKey,
  instructionList,
  isUserPendingChanges,
  renderInstructionList,
  migrationSafetyIssues,
  onEditPendingChangesForm,
  onBlur,
  onBack,
  onCancel,
  onSubmit,
  updatedFlag,
}: PendingChangesConfirmationModalProps) {
  const { flag, environment } = usePendingChangesContext();
  const migrationSafetyIssuesData = migrationSafetyIssues?.data;
  const showMigrationGuardrailsAlert = migrationSafetyIssuesData && migrationSafetyIssuesData.length > 0;
  const renderMigrationSafetyIssues = () => {
    if (migrationSafetyIssues?.isFetching) {
      return <ProgressBar aria-label="Loading…" isIndeterminate className={styles.progress} />;
    } else {
      if (isEmpty(migrationSafetyIssuesData)) {
        return null;
      }
      return (
        <>
          {migrationSafetyIssuesData &&
            migrationSafetyIssuesData.map((issue, i) => {
              const causingRuleName = getRuleNameForMigrationError(
                issue.causingRuleId,
                environment.key,
                flag,
                updatedFlag,
                instructionList,
              );
              const affectedRuleNames: string[] = [];
              issue.affectedRuleIds.forEach((ruleId) => {
                const affectedRuleName = getRuleNameForMigrationError(
                  ruleId,
                  environment.key,
                  flag,
                  updatedFlag,
                  instructionList,
                );
                affectedRuleNames.push(affectedRuleName);
              });
              return (
                <li key={`${issue.issue}-${i}`}>
                  <section>
                    <Alert header="This change may cause data integrity issues" kind="warning">
                      {getReadableMigrationErrors(issue, causingRuleName, affectedRuleNames, i)}
                    </Alert>
                  </section>
                </li>
              );
            })}
        </>
      );
    }
  };
  return (
    <>
      <section>
        <ResourceInfo resourceName={resourceName} resourceKind={resourceKind} />
      </section>
      {(showExperimentsAlert ||
        showMultipleApprovalRequestsCreatedAlert ||
        showFlagTargetingOffAlert ||
        showDeprecatedAlert ||
        showMigrationGuardrailsAlert) && (
        <PendingChangesConfirmationModalAlerts
          ExperimentsAlertComponent={ExperimentsAlertComponent}
          MigrationsGuardrailsSafetyIssuesComponent={renderMigrationSafetyIssues()}
          showExperimentsAlert={showExperimentsAlert}
          showMultipleApprovalRequestsCreatedAlert={showMultipleApprovalRequestsCreatedAlert}
          showFlagTargetingOffAlert={showFlagTargetingOffAlert}
          showDeprecatedAlert={showDeprecatedAlert}
          showMigrationGuardrailsAlert={migrationSafetyIssuesData && migrationSafetyIssuesData.length > 0}
        />
      )}
      <section className="u-mb-l">
        <div className={styles.headerContainer}>
          <h3 className="u-fw-bold">Changes</h3>
        </div>
        {renderInstructionList()}
      </section>
      <PendingChangesForm
        flagKey={flagKey}
        hideApprovalOption={hideApprovalOption}
        hideScheduleOption={hideScheduleOption}
        saveOption={saveOption}
        pendingChangesFormState={pendingChangesFormState}
        resourceValidation={environmentValidation}
        suggestedApprovalMemberIds={suggestedApprovalMemberIds || []}
        approvalServiceKind={approvalServiceKind}
        approvalServiceManifest={approvalServiceManifest}
        isUserPendingChanges={isUserPendingChanges}
        onEditPendingChangesForm={onEditPendingChangesForm}
        onBlur={onBlur}
        onBack={onBack}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </>
  );
}
