import React from 'react';
import { useController, UseFormReturn, useWatch } from 'react-hook-form';
import { FieldGroup, Label, TextArea, TextField } from '@launchpad-ui/components';

import SelectMemberContainer from 'components/SelectMemberContainer';
import SelectTags from 'components/SelectTags';
import { TagKind } from 'components/tagFilter/types';
import { Member } from 'utils/accountUtils';

import { MetricForm } from '../common';
import { RequiredLabel } from '../components/RequiredLabel';

import { MetricKeyNameFields } from './MetricKeyNameFields';

import styles from './GuidedMetricCreationModal.module.css';

interface MetricMetadataProps {
  context: UseFormReturn<MetricForm>;
  isEdit?: boolean;
}

export const MetricMetadata: React.FC<MetricMetadataProps> = ({ context, isEdit = false }) => {
  const {
    control,
    register,
    formState: { errors },
  } = context;

  const { field: memberField } = useController({
    name: 'maintainerId',
    control,
    rules: { required: 'Maintainer is required' },
  });

  const description = useWatch({ control, name: 'description', exact: true });

  const { ref: _memberRef, onChange: onMemberChange, ...memberFieldProps } = memberField;

  return (
    <>
      <MetricKeyNameFields context={context} isEdit={isEdit} />
      <span className={styles.dualInputContainer}>
        <FieldGroup errorMessage={errors.tags?.message}>
          <Label htmlFor="tags">Tags</Label>
          <div data-test-id="create-metric-tags">
            <SelectTags<MetricForm>
              id="tags"
              useHookForm
              kind={TagKind.METRIC}
              control={control}
              name="tags"
              ariaLabel="Metric tags"
              placeholder="Add tags"
              className={styles.tagsInput}
              styles={{
                valueContainer: { paddingTop: 'var(--lp-size-2)', paddingBottom: 'var(--lp-size-2)' },
                control: { width: 'var(--lp-size-256)' },
              }}
            />
          </div>
        </FieldGroup>
        <FieldGroup errorMessage={errors.maintainerId?.message}>
          <RequiredLabel label="Maintainer" htmlFor="maintainerId" />
          <SelectMemberContainer
            {...memberFieldProps}
            onChange={(member: Member) => onMemberChange({ target: { value: member?._id } })}
            placeholder="Select maintainer"
            valueContainerStyles={{
              paddingTop: 0,
              paddingBottom: 0,
              width: 'var(--lp-size-224)',
            }}
          />
        </FieldGroup>
      </span>
      <FieldGroup errorMessage={errors.description?.message}>
        <TextField className={styles.descriptionInput}>
          <Label htmlFor="description">Description</Label>
          <TextArea
            id="description"
            value={description}
            {...register('description', { value: context.getValues('description') })}
            placeholder="Add a description"
          />
        </TextField>
      </FieldGroup>
    </>
  );
};
