import { isDynamicDefaultTargetingContextKindEnabled } from '@gonfalon/dogfood-flags';
import { useProjectKey } from '@gonfalon/router';
import { Label } from '@launchpad-ui/components';
import cx from 'clsx';
import { List } from 'immutable';

import { getDefaultTargetingContextKind } from 'components/Contexts/utils/contextKindUtils';
import SelectContextKind from 'components/contextTargeting/SelectContextKind';
import { useFlagContext } from 'components/FlagContext';
import HelpTooltip from 'components/HelpTooltip';
import Restrict from 'components/Restrict';
import SelectAttributeContainer from 'components/selectAttributes/SelectAttributeContainer';
import TargetingValidationHint from 'components/TargetingValidationHint';
import { AlignValue, Cell, CellLayoutValue, Grid } from 'components/ui/grid';
import { VariationColor } from 'components/VariationColor';
import { useContextKinds } from 'hooks/useContextKinds';
import { USER_CONTEXT_KIND } from 'utils/constants';
import { colorVariation, Rollout, Variation } from 'utils/flagUtils';
import { RolloutValidation } from 'utils/validation/targeting';

import styles from './RolloutBucketBy.module.css';

export type RolloutBucketByProps = {
  rollout: Rollout | null;
  validation: RolloutValidation;
  canModify: boolean;
  onChangeBucket(bucket?: string, contextKind?: string): void;
};

export function RolloutBucketBy({ rollout, validation, canModify, onChangeBucket }: RolloutBucketByProps) {
  const { flag } = useFlagContext();

  // For default rule serving percentage rollouts it's not the first variation defined on the flag, it's the first variation listed in the rollout.
  // https://app.shortcut.com/launchdarkly/story/248572/escalation-flag-values-incorrectly-returned-for-experiment-flags-for-non-matching-context-kind
  const firstRolloutVariationObj = rollout?.variations.find((v) => v.weight > 0);

  const projectKey = useProjectKey();
  const { contextKinds } = useContextKinds({ projKey: projectKey });
  const bucketBy = rollout?.bucketBy || 'key';
  const bucketByValidation = validation.get('bucketBy');
  const hasBucketByValidationProblem = !!(bucketByValidation && !bucketByValidation.isEmpty());
  const defaultContextKind = isDynamicDefaultTargetingContextKindEnabled()
    ? getDefaultTargetingContextKind(contextKinds).key
    : USER_CONTEXT_KIND;
  const contextKind =
    rollout?.contextKind || (flag.isMigrationFlag() && flag.getMigrationContextKind()) || defaultContextKind;
  const isSixStageMigration = flag.isMigrationFlag() && flag.getStageCount() === 6;
  const contextKindDisabledTooltip = isSixStageMigration
    ? "When moving data, rollouts must reference the migration's context kind"
    : 'This field cannot be modified';
  const attributeDisabledTooltip = isSixStageMigration ? (
    <span className={styles.disabledTooltip}>
      When moving data, a rollout can only assign by <code>key</code>
    </span>
  ) : (
    'This field cannot be modified'
  );

  const onContextKindChange = (value: string) => {
    onChangeBucket('key', value); // Default back to key when context kind changes
  };

  const handleAttributeChange = (value: string) => {
    onChangeBucket(value, contextKind);
  };

  return (
    <Grid align={AlignValue.BOTTOM}>
      <Cell layout={CellLayoutValue.AUTO}>
        {hasBucketByValidationProblem ? (
          <TargetingValidationHint validation={validation} />
        ) : (
          <Label className={styles.ByLabel}>By</Label>
        )}
      </Cell>
      <Cell>
        <Restrict
          isRestricted={!canModify || isSixStageMigration}
          tooltip={contextKindDisabledTooltip}
          tooltipOptions={{
            rootElementStyle: { display: 'block' },
            placement: 'top',
          }}
          willDisable
        >
          <SelectContextKind
            className="SelectContextKind"
            label="Context kind"
            value={contextKind}
            onChange={onContextKindChange}
          />
        </Restrict>
      </Cell>

      <Cell>
        <Restrict
          isRestricted={!canModify || isSixStageMigration}
          tooltip={attributeDisabledTooltip}
          tooltipOptions={{
            rootElementStyle: { display: 'block' },
            placement: 'top',
          }}
          willDisable
        >
          <SelectAttributeContainer
            className={cx('SelectAttribute', styles.selectAttributeContainer)}
            isClearable={false}
            value={bucketBy}
            contextKind={contextKind}
            onChange={handleAttributeChange}
          />
        </Restrict>
      </Cell>
      {!isSixStageMigration && (
        <Cell layout={CellLayoutValue.AUTO}>
          <HelpTooltip placement="right" size="medium">
            <div>
              <span>
                If the default rule is evaluated for context kinds other than the one selected, those contexts will be
                served the first variation
              </span>
              {firstRolloutVariationObj !== undefined && (
                <FirstVariationRollout index={firstRolloutVariationObj.variation} variations={flag.variations} />
              )}
            </div>
          </HelpTooltip>
        </Cell>
      )}
    </Grid>
  );
}

function FirstVariationRollout({ index, variations }: { index: number; variations: List<Variation> }) {
  const variation = variations.get(index);

  if (variation === undefined) {
    return null;
  }

  return (
    <span className="u-ml-s">
      - <VariationColor size="medium" fill={colorVariation(index)} className="u-mr-s" />
      <strong>{variation.name}</strong>
    </span>
  );
}

/* eslint-disable import/no-default-export */
export default function RolloutBucketByContainer({
  className,
  rollout,
  validation,
  canModify,
  onChangeBucket,
}: Omit<RolloutBucketByProps, 'enableContextAwareAttributeSelect'> & { className?: string }) {
  return (
    <div className={cx(styles.RolloutBucketBy, [styles.ContextAware], className)}>
      <RolloutBucketBy
        rollout={rollout}
        validation={validation}
        canModify={canModify}
        onChangeBucket={onChangeBucket}
      />
    </div>
  );
}
