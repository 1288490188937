import { Time } from '@gonfalon/datetime';
import { DateFormat } from '@gonfalon/format';
import { differenceInMinutes, parseISO } from 'date-fns';

type FullDateProps = {
  datetime: Date | number | string | undefined | null;
};

//this function return the date formatted as MM DD, YYYY
export function FullDate({ datetime }: FullDateProps) {
  const displayContent = (formatted: string) => `${formatted}`;

  let date: number | Date;
  if (datetime === undefined || datetime === null) {
    return null;
  }
  if (typeof datetime === 'string') {
    date = parseISO(datetime);
  } else {
    date = datetime;
  }

  // Because this function rounds up, we need a 30 min adjustment so that it never
  // renders "24 hours ago"
  if (differenceInMinutes(Date.now(), date) < 24 * 60 - 30) {
    return (
      <Time fromNow datetime={datetime}>
        {displayContent}
      </Time>
    );
  }

  return (
    <Time dateFormat={DateFormat.MMM_D_YYYY} datetime={datetime}>
      {displayContent}
    </Time>
  );
}
