import UpsellImageConvertToSegment from 'components/upsells/upsellImages/UpsellImageConvertToSegment';
import { UpsellModal } from 'components/upsells/UpsellModal';
import { getDocumentationUrl } from 'utils/urlUtils';

const convertingRuleDocLink = getDocumentationUrl('home/flags/targeting-rules#convert-rules-into-segments');

type UpsellFeatureModalConvertToSegmentProps = {
  isOpen: boolean;
  onCancel(): void;
};

const UpsellFeatureModalConvertToSegment = ({ isOpen, onCancel }: UpsellFeatureModalConvertToSegmentProps) => (
  <UpsellModal
    featureKind="convertToSegment"
    isEnterpriseOnlyFeature={false}
    isOpen={isOpen}
    onCancel={onCancel}
    title="Convert to rules when things get complex"
    featureImage={<UpsellImageConvertToSegment />}
    componentForAnalyticTracking="Convert to Segment"
    featureBullets={[
      'Convert individual targets to a segment with one click',
      'Convert targets that make up a rule to a segment-based rule',
    ]}
    learnMoreUrl={convertingRuleDocLink}
  />
);

/* eslint-disable import/no-default-export */
export default UpsellFeatureModalConvertToSegment;
