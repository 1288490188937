import { KeyboardEventHandler, useState } from 'react';
import { capitalize } from '@gonfalon/strings';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'launchpad';

import DocumentationLink from 'components/DocumentationLink';
import { DateTimePickerWithPopover } from 'components/ui/dates';
import { USER_CONTEXT_KIND } from 'utils/constants';
import { getDocumentationUrl } from 'utils/urlUtils';
import { User } from 'utils/userUtils';

import styles from './ScheduleRemovalModal.module.css';

const removalDatesDocsLink = '/home/flags/individual-targeting#schedule-removal-dates-for-individual-targeting';

export type ScheduleRemovalModalProps = {
  contextKind?: string;
  contextKey?: string;
  context?: User;
  originalExpirationDate?: number | null;
  updatedExpirationDate?: number | null;
  onSave: ({
    originalExpirationDate,
    updatedExpirationDate,
  }: {
    originalExpirationDate: number | null;
    updatedExpirationDate: number | null;
  }) => void;
  onCancel(): void;
  onKeyDownRemoveButton: KeyboardEventHandler<HTMLButtonElement>;
};

/* eslint-disable import/no-default-export */
export default function ScheduleRemovalModal(props: ScheduleRemovalModalProps) {
  const [expirationDate, setExpirationDate] = useState(props.updatedExpirationDate || null);
  const user = props.context;
  const contextKindDisplay = capitalize(props.contextKind || '');
  const subText = props.contextKind === USER_CONTEXT_KIND && user ? user.attributes.getDisplayName() : props.contextKey;

  function onSave() {
    props.onSave({
      originalExpirationDate: props.originalExpirationDate ?? null,
      updatedExpirationDate: expirationDate,
    });
    props.onCancel();
  }

  function onDelete() {
    props.onSave({ originalExpirationDate: props.originalExpirationDate ?? null, updatedExpirationDate: null });
    props.onCancel();
  }

  const isEdit = !!props.updatedExpirationDate;

  const title = isEdit ? 'Update scheduled removal' : 'Schedule removal';
  const description = isEdit ? (
    'Change or remove the scheduled time for removal'
  ) : (
    <>
      Choose a time to remove this context from targeting.{' '}
      <DocumentationLink
        component="ScheduleRemovalModal"
        href={getDocumentationUrl(removalDatesDocsLink)}
        text="Learn more"
      />
    </>
  );

  const primaryButton = isEdit ? (
    <Button kind="primary" disabled={props.updatedExpirationDate === expirationDate} onClick={onSave}>
      Update
    </Button>
  ) : (
    <Button kind="primary" onClick={onSave} disabled={!expirationDate}>
      Save
    </Button>
  );

  const secondaryButton = isEdit ? (
    <Button onClick={onDelete} onKeyDown={props.onKeyDownRemoveButton}>
      Remove
    </Button>
  ) : (
    <Button onClick={props.onCancel}>Cancel</Button>
  );

  return (
    <Modal className={styles.modal} onCancel={props.onCancel}>
      <ModalHeader title={title} description={description} />
      <ModalBody className={styles.body}>
        <div className={styles.contextInfo}>
          <span className={styles.displayName}>{contextKindDisplay}</span>
          <p>{subText}</p>
        </div>
        <DateTimePickerWithPopover
          disablePast
          useLongDate
          onChange={(newRemovalDate) => setExpirationDate(newRemovalDate)}
          value={expirationDate ? new Date(expirationDate) : undefined}
        />
      </ModalBody>
      <ModalFooter primaryButton={primaryButton} secondaryButton={secondaryButton} />
    </Modal>
  );
}
