import { getDocumentationUrl } from 'utils/urlUtils';

export enum ErrorMessages {
  UNEXPECTED = 'Uh oh! We ran into an unexpected error',
  CONTEXT_NOT_FOUND = 'Sorry, we can’t find what you’re looking for.',
}

export enum TitleMessages {
  ATTRIBUTES = 'Attributes',
  FROM_SOURCE = 'From source:',
  SEGMENTS = 'Segments',
  INSTANCES = 'Instances',
  FLAG_VARIATIONS = 'Expected flag variations',
  CONTEXT_LIST = 'Find contexts that have been identified or evaluated for feature flags within the last 30 days for this environment.',
  CONTEXT_KIND_MGMT = 'Find contexts that have been identified or evaluated for feature flags within the last 30 days for this project.',
}

export enum ToolTipMessages {
  INSTANCES = 'The number of unique context instances in which this context appeared',
  SEGMENTS = 'Segments that include this context',
  IN_USE = 'Indicates whether an SDK has sent a kind to LaunchDarkly',
  HIDDEN = 'Hidden context kinds will not be shown in the Contexts tab or be available for targeting',
  ARCHIVED = 'Archived context kinds are not shown in the Contexts list or on the Kinds tab and are not available for targeting',
  AVAILABLE_IN_EXPERIMENTS = 'Contexts are available for targeting in experiments',
}

export enum InfoMessages {
  SINGLE_INSTANCE = 'This is a single instance.',
  NO_SEGMENT_MEMBERSHIP = 'This context is not part of any segments.',
}

type evaluationReasonMessageType = {
  targetMatchSection: string;
  ruleMatchSection: string;
  prerequisiteFailedSection?: string;
  prerequisiteFailedMessage?: string;
};

type evaluationReasonMessageReturnType = {
  [key: string]: {
    section: string;
    message: string;
  };
};

const evaluationReasonsDocLink = getDocumentationUrl('sdk/concepts/evaluation-reasons');

export const evaluationReasonMessageMap = ({
  targetMatchSection,
  ruleMatchSection,
  prerequisiteFailedSection,
  prerequisiteFailedMessage,
}: evaluationReasonMessageType): evaluationReasonMessageReturnType => ({
  OFF: {
    section: 'targetingoff',
    message: 'will be served because flag targeting is off',
  },
  FALLTHROUGH: {
    section: 'defaultrule',
    message: 'will be served because the context is not targeted individually or by a rule',
  },
  TARGET_MATCH: {
    section: `targetindividualusers-${targetMatchSection}`,
    message: 'will be served because the context is individually targeted',
  },
  RULE_MATCH: {
    section: ruleMatchSection,
    message: `will be served because the context is targeted by rule ${ruleMatchSection}`,
  },
  PREREQUISITE_FAILED: {
    section: `prerequisites-${prerequisiteFailedSection}`,
    message: `will be served because the prerequisite flag ${prerequisiteFailedMessage} returned the off variation`,
  },
  DEFAULT: {
    section: evaluationReasonsDocLink,
    message: 'evaluation reasons can be found on this link.',
  },
});

const errorConditionsDocLink = getDocumentationUrl('sdk/concepts/evaluation-reasons#error-conditions');

export const evaluationReasonErrorMessageMap: evaluationReasonMessageReturnType = {
  CLIENT_NOT_READY: {
    section: errorConditionsDocLink,
    message:
      'The default value will be served because the client is not able to establish a connection to LaunchDarkly',
  },
  FLAG_NOT_FOUND: {
    section: errorConditionsDocLink,
    message: 'The default variation will be served because the flag key did not match any known flag',
  },
  USER_NOT_SPECIFIED: {
    section: errorConditionsDocLink,
    message: 'The default variation will be served because the flag key did not match any known flag',
  },
  MALFORMED_FLAG: {
    section: errorConditionsDocLink,
    message: 'The default variation will be served because there was an internal inconsistency in the flag data',
  },
  WRONG_TYPE: {
    section: errorConditionsDocLink,
    message:
      'The default variation will be served because the application code requested the flag value with a different data type than it actually is',
  },
  EXCEPTION: {
    section: errorConditionsDocLink,
    message: 'The default variation will be served because an unexpected error stopped flag evaluation',
  },
  DEFAULT: {
    section: errorConditionsDocLink,
    message: 'An error occured',
  },
};
