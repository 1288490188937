import { useState } from 'react';
import { Control, Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useMatch, useParams } from 'react-router-dom';
import { userId } from '@gonfalon/constants';
import { useAccountContext } from '@gonfalon/context';
import { Metric } from '@gonfalon/metrics';
import { checkAccess } from '@gonfalon/permissions';
import {
  Button,
  Dialog,
  Form,
  Heading,
  ListBox,
  ListBoxItem,
  Modal,
  ModalOverlay,
  Popover,
  ProgressBar,
  Select,
  SelectValue,
} from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';

import { LearnMoreButton } from 'components/experimentation/common/components/LearnMoreButton/index';
import NavigationPromptModal from 'components/NavigationPromptModal';
import NavigationPrompt from 'routers/NavigationPrompt';
import { trackExperimentEvent } from 'utils/analyticsUtils';
import MetricKind, { getMetricKind, getMetricKindDisplay } from 'utils/MetricKind';
import { getDocumentationUrl } from 'utils/urlUtils';

import { MetricEventUrl, MetricForm, toMetricEventUrl } from '../common';
import { RequiredLabel } from '../components/RequiredLabel';
import { useCreateMetric } from '../hooks/useCreateMetric';

import { MetricKindDetails } from './MetricKindDetails';
import { MetricMetadata } from './MetricMetadata';

import styles from './GuidedMetricCreationModal.module.css';

// need to include every field or `isDirty` may be a false positive
const defaultFormValues: MetricForm = {
  analysisType: 'mean',
  kind: MetricKind.CUSTOM,
  isNumeric: false,
  urls: [{ kind: 'canonical', value: '' }],
  randomizationUnits: [],
  maintainerId: '',
  name: '',
  key: '',
  description: '',
  eventKey: '',
  tags: undefined,
  successCriteria: 'HigherThanBaseline',
  unitAggregationType: undefined,
  percentileValue: undefined,
  eventDefault: { disabled: false, value: 0 },
};

export function GuidedMetricCreationModal({
  onCancel,
  onSuccess,
  defaultValues,
}: {
  onCancel: () => void;
  onSuccess?: (metric: Metric) => void;
  defaultValues?: Partial<MetricForm>;
}) {
  const { profile } = useAccountContext();
  const hasAccess = checkAccess(profile, profile._access, 'createMetric').isAllowed;

  const [showPrompt, setShowPrompt] = useState(false);
  const { mutate: createMetric, isPending: isSaving } = useCreateMetric();
  const form = useForm<MetricForm>({
    defaultValues: {
      ...defaultFormValues,
      maintainerId: userId(),
      ...defaultValues,
    },
    mode: 'onBlur',
  });

  const { isDirty, isValid } = form.formState;
  const params = useParams();
  const matchBuilder = useMatch({ path: '/:projKey/:envKey/experiments/create' });
  const matchMetrics = useMatch({ path: '/:projKey/metrics' });

  const control = form.control;
  const metricKind = useWatch({ control, name: 'kind', exact: true });
  if (!hasAccess) {
    // TODO: some kind of toast message?
    onCancel();
    return;
  }

  const getLocation = () => {
    if (params.experimentKey) {
      return 'design';
    }
    if (matchBuilder) {
      return 'builder';
    }
    if (matchMetrics) {
      return 'metricsList';
    }
    return 'unknown';
  };

  const onValid = (metric: MetricForm) => {
    createMetric(toMetricPost(metric), {
      onSuccess: (m) => {
        if (onSuccess) {
          return onSuccess(m);
        }
        onCancel();
      },
    });
    trackExperimentEvent('Metric Created', {
      location: getLocation(),
    });
  };

  const onCancelWithPrompt = () => {
    if (isDirty) {
      setShowPrompt(true);
    } else {
      onCancel();
    }
  };

  return (
    <ModalOverlay isOpen onOpenChange={(isOpen: boolean) => !isOpen && onCancelWithPrompt()}>
      <Modal isOpen className={styles.noPadding}>
        <Dialog className={styles.noPadding}>
          <NavigationPrompt shouldBlock={isDirty && !isSaving} />
          {showPrompt ? (
            <NavigationPromptModal
              onCancelNavigation={() => setShowPrompt(false)}
              onConfirmNavigation={onCancel}
              body="If you close this form, you will lose any unsaved changes."
              confirmText="Discard changes"
            />
          ) : null}
          <div slot="header" className={styles.header}>
            <Heading slot="title" className={styles.formTitle}>
              Create metric
            </Heading>
          </div>
          <div slot="body" className={styles.formBody}>
            <FormProvider {...form}>
              <Form className={styles.createMetricForm} data-test-id="metric-form">
                <MetricKindPicker control={control} metricKind={metricKind} />
                <MetricKindDetails context={form} metricKind={metricKind} />
                <MetricMetadata context={form} />
              </Form>
            </FormProvider>
          </div>
          <div slot="footer" className={styles.footer}>
            <LearnMoreButton
              link={getDocumentationUrl('home/observability/metrics')}
              className={styles.learnMoreButton}
            />
            <div className={styles.footerActions}>
              <Button onPress={onCancelWithPrompt}>Cancel</Button>
              <Button
                variant="primary"
                onPress={async () => form.handleSubmit(onValid)()}
                isDisabled={!isValid || isSaving}
                data-test-id="metric-create-button"
              >
                Create metric
                {isSaving && <ProgressBar aria-label="Loading…" isIndeterminate size="small" />}
              </Button>
            </div>
          </div>
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
}

interface MetricKindPickerProps {
  control: Control<MetricForm>;
  metricKind: Metric['kind'];
}

const MetricKindPicker: React.FC<MetricKindPickerProps> = ({ control, metricKind }) => (
  <Controller
    control={control}
    name="kind"
    render={({ field }) => (
      <Select onSelectionChange={field.onChange} selectedKey={field.value} className={styles.shortInput}>
        <RequiredLabel label="Event kind" htmlFor="kind" />
        <Button>
          <Icon name={getMetricKindDisplay(metricKind).displayIcon} size="small" />
          <SelectValue />
          <Icon name="chevron-down" size="small" />
        </Button>
        <Popover>
          <ListBox>
            <ListBoxItem id={MetricKind.CUSTOM}>Custom</ListBoxItem>
            <ListBoxItem id={MetricKind.PAGEVIEW}>Page viewed</ListBoxItem>
            <ListBoxItem id={MetricKind.CLICK}>Clicked or tapped</ListBoxItem>
          </ListBox>
        </Popover>
      </Select>
    )}
  />
);

type MetricPost = Omit<MetricForm, 'tags' | 'randomizationUnits' | 'urls' | 'kind'> & {
  kind: 'click' | 'pageview' | 'custom';
  tags?: string[];
  randomizationUnits?: string[];
  urls?: MetricEventUrl[];
};

function toMetricPost(form: MetricForm): MetricPost {
  const { kind, urls, randomizationUnits, tags, measureType, ...rest } = form;
  const metric = {
    ...rest,
    isActive: true,
    kind: getMetricKind(kind) as 'custom' | 'click' | 'pageview',
    randomizationUnits: randomizationUnits?.map(({ value }) => value),
    tags: tags?.map(({ value }) => value),
    urls: kind === MetricKind.CLICK || kind === MetricKind.PAGEVIEW ? urls?.map(toMetricEventUrl) : undefined,
  };

  if (kind === MetricKind.CUSTOM) {
    metric.isNumeric = !!metric.isNumeric;
  }

  return metric;
}
