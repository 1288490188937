import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Metric } from '@gonfalon/metrics';

import { MetricForm } from '../common';

import { ClickMetricDetails } from './ClickMetricDetails';
import { CustomMetricKindDetails } from './CustomMetricKindDetails';
import { PageviewMetricDetails } from './PageviewMetricDetails';

type MetricKindDetailsProps = {
  context: UseFormReturn<MetricForm>;
  metricKind: Metric['kind'];
};

export const MetricKindDetails: React.FC<MetricKindDetailsProps> = ({ context, metricKind }) => {
  if (metricKind === 'custom') {
    return <CustomMetricKindDetails context={context} />;
  } else if (metricKind === 'click') {
    return <ClickMetricDetails context={context} />;
  } else if (metricKind === 'pageview') {
    return <PageviewMetricDetails context={context} />;
  }
  return null;
};
