import React, { Key } from 'react';
import { UseFormReturn, UseFormSetValue, useWatch } from 'react-hook-form';
import { Metric } from '@gonfalon/metrics';
import { Button, FieldGroup, ListBox, ListBoxItem, Popover, Select, SelectValue } from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';

import {
  AnalysisTypeDisplayOptions,
  getAnalysisTypeOption,
  MetricForm,
  SuccessCriteriaDisplayOptions,
  VALID_ANALYSIS_PERCENTILES,
} from '../common';
import { RandomizationUnitsSection } from '../components/RandomizationUnitsSection';
import { RequiredLabel } from '../components/RequiredLabel';

import { RandomizationUnitOption } from './formUtils';

import styles from './MetricDefinition.module.css';

interface MetricDefinitionProps {
  context: UseFormReturn<MetricForm>;
}
export const MetricDefinition: React.FC<MetricDefinitionProps> = ({ context }) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = context;

  const selectedMeasureType = useWatch({ control, name: 'measureType', exact: true });
  const percentile = useWatch({ control, name: 'percentileValue', exact: true });
  const analysisType = useWatch({ control, name: 'analysisType', exact: true });
  const successCriteria = useWatch({
    control,
    name: 'successCriteria',
    exact: true,
  });
  const unitAggregationType = useWatch({ control, name: 'unitAggregationType', exact: true });
  const metricKind = useWatch({ control, name: 'kind', exact: true });
  const randomizationUnits = useWatch({ control, name: 'randomizationUnits', exact: true });

  if (!selectedMeasureType) {
    return null;
  }

  const metricDefinitionError = [
    errors.analysisType?.message,
    errors.randomizationUnits?.message,
    errors.successCriteria?.message,
  ]
    .filter(Boolean)
    .join(', ');

  const randomizationUnitsOnSelect = (selection: string[], options: RandomizationUnitOption[]) => {
    const selectedOptions = options.filter((option) => selection.includes(option.value));
    setValue('randomizationUnits', selectedOptions, { shouldDirty: true });
  };

  return (
    <FieldGroup errorMessage={metricDefinitionError}>
      <RequiredLabel label="Metric definition" htmlFor="metricDefinition" />
      <span className={styles.metricDefinition} id="metricDefinition">
        {selectedMeasureType !== 'occurrence' && (
          <AnalysisTypePicker
            currentAnalysisType={analysisType}
            currentPercentileValue={percentile}
            setValue={setValue}
          />
        )}
        <RandomizationUnitsSection
          measureType={selectedMeasureType}
          metricKind={metricKind}
          unitAggregationType={unitAggregationType}
          onSelect={randomizationUnitsOnSelect}
          initialValues={randomizationUnits.map((ru) => ru.value)}
        />
        {metricKind === 'custom' ? (
          <SuccessCriteriaPicker currentSuccessCriteria={successCriteria} setValue={setValue} />
        ) : (
          'higher is better'
        )}{' '}
      </span>
    </FieldGroup>
  );
};

interface AnalysisTypePickerProps {
  currentAnalysisType: Metric['analysisType'] | undefined;
  currentPercentileValue: number | undefined;
  setValue: UseFormSetValue<MetricForm>;
}
const AnalysisTypePicker: React.FC<AnalysisTypePickerProps> = (props: AnalysisTypePickerProps) => {
  const selectedKey = getAnalysisTypeOption(props.currentAnalysisType, props.currentPercentileValue);
  function onAnalysisTypeChange(key: Key) {
    if (Number.isInteger(key) && VALID_ANALYSIS_PERCENTILES.includes(Number(key))) {
      props.setValue('analysisType', 'percentile', { shouldDirty: true });
      // We need to update eventDefault to be disabled here - it is not supported for percentile analysis
      props.setValue('eventDefault', { disabled: true }, { shouldDirty: true });
      props.setValue('percentileValue', Number(key), { shouldDirty: true });
    } else {
      props.setValue('analysisType', 'mean', { shouldDirty: true });
      props.setValue('percentileValue', undefined, { shouldDirty: true });
    }
  }
  return (
    <Select
      onSelectionChange={onAnalysisTypeChange}
      selectedKey={selectedKey}
      aria-label="Choose a method for unit to sample aggregation"
      className={styles.analysisTypeSelect}
    >
      <Button>
        <SelectValue />
        <Icon name="chevron-down" size="small" />
      </Button>
      <Popover className={styles.popover}>
        <ListBox>
          {AnalysisTypeDisplayOptions.map((option) => (
            <ListBoxItem key={option.value} id={option.value}>
              {option.displayName}
            </ListBoxItem>
          ))}
        </ListBox>
      </Popover>
    </Select>
  );
};

interface SuccessCriteriaPickerProps {
  currentSuccessCriteria: string | undefined;
  setValue: UseFormSetValue<MetricForm>;
}
const SuccessCriteriaPicker: React.FC<SuccessCriteriaPickerProps> = (props: SuccessCriteriaPickerProps) => {
  function onSuccessCriteriaChange(key: Key) {
    if (key === 'HigherThanBaseline' || key === 'LowerThanBaseline') {
      props.setValue('successCriteria', key, { shouldDirty: true });
    }
  }
  return (
    <Select
      onSelectionChange={onSuccessCriteriaChange}
      selectedKey={props.currentSuccessCriteria ?? 'HigherThanBaseline'}
      aria-label="Choose a success criteria"
    >
      <Button data-test-id="success-criteria-button">
        <SelectValue />
        <Icon name="chevron-down" size="small" />
      </Button>
      <Popover className={styles.popover}>
        <ListBox>
          {SuccessCriteriaDisplayOptions.map((option) => (
            <ListBoxItem key={option.value} id={option.value}>
              {option.displayName}
            </ListBoxItem>
          ))}
        </ListBox>
      </Popover>
    </Select>
  );
};
