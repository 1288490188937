import produce, { Draft } from 'immer';

export function createReducer<
  State,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Action extends { type: string; payload?: { [key: string]: any } },
>(actionHandlers: {
  [Type in Action['type']]: Extract<Action, { type: Type }>['payload'] extends undefined
    ? (state: Draft<State>) => void
    : (state: Draft<State>, payload: Extract<Action, { type: Type }>['payload']) => void;
}) {
  return (state: State, action: Action) =>
    produce(state, (draft) => {
      actionHandlers[action.type as Action['type']](draft, action.payload);
    });
}
