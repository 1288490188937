import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { FieldGroup, Input, Text, TextField } from '@launchpad-ui/components';

import { MetricForm } from '../common';
import { CardSelectGroup } from '../components/CardSelectGroup';
import { MetricUrls } from '../components/MetricUrls';
import { RequiredLabel } from '../components/RequiredLabel';

import { clickMeasureTypeSelectOptions } from './formUtils';
import { MetricDefinition } from './MetricDefinition';

import styles from './GuidedMetricCreationModal.module.css';
interface ClickMetricDetailsProps {
  context: UseFormReturn<MetricForm>;
}

export const ClickMetricDetails: React.FC<ClickMetricDetailsProps> = ({ context }) => {
  const {
    control,
    register,
    formState: { errors },
    setValue,
  } = context;

  const selector = useWatch({ control, name: 'selector', exact: true });
  const handleMeasureOptionSelect = (value: string) => {
    if (!['count', 'occurrence'].includes(value)) {
      return;
    }
    if (value === 'count') {
      setValue('measureType', 'count');
      setValue('unitAggregationType', 'sum', { shouldDirty: true });
    } else if (value === 'occurrence') {
      setValue('measureType', 'occurrence');
      setValue('eventDefault', { disabled: false, value: 0 }, { shouldDirty: true });
      setValue('unitAggregationType', 'average', { shouldDirty: true });
      setValue('analysisType', 'mean', { shouldDirty: true });
      setValue('percentileValue', undefined);
    }
  };

  const measureType = useWatch({ control, name: 'measureType', exact: true });
  return (
    <>
      <FieldGroup errorMessage={errors.selector?.message} className={styles.labelInputWrapper}>
        <TextField className={styles.textInput}>
          <RequiredLabel label="Click target(s)" htmlFor="selector" />
          <Input
            {...register('selector', { required: 'Click target(s) are required' })}
            placeholder="Enter one or more CSS selectors"
            value={selector}
          />
        </TextField>
        <Text slot="subtitle" className={styles.kindHint}>
          Enter one or more comma-separated CSS selectors to record clicks
        </Text>
      </FieldGroup>
      <MetricUrls context={context} />
      <CardSelectGroup
        context={context}
        name="measureType"
        label="What do you want to measure?"
        validationErrorMessage="Selection is required"
        options={clickMeasureTypeSelectOptions}
        ariaLabel="What do you want to measure?"
        onSelect={handleMeasureOptionSelect}
        errorMessage={errors?.measureType?.message}
        defaultValue={measureType}
      />
      {measureType && <MetricDefinition context={context} />}
    </>
  );
};
