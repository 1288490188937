import { useEffect, useRef } from 'react';
import type { Matcher } from 'react-day-picker';
import { DateRangePicker, Time } from '@gonfalon/datetime';
import { DateFormat } from '@gonfalon/format';
import { Button, ButtonGroup, FilterButton, Popover } from '@launchpad-ui/core';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';
import { differenceInHours, isSameDay } from 'date-fns';

import { TimeRange } from '../TimeRange';

import './styles.css';

export type DateRangeFilterProps = {
  dates: Array<Date | number | undefined>;
  isOpen?: boolean;
  onInteraction?(nextIsOpen: boolean): void;
  onChange(dates: Date[]): void;
  onConfirm(): void;
  onCancel?(): void;
  enableCustomRange?: boolean;
  className?: string;
  createShortcuts?(): Array<{
    label: string;
    dateRange: Array<Date | undefined>;
  }>;
  renderSelectionFn?(options: {
    isHour?: boolean;
    isSingleDay: boolean;
    startDate?: Date | number;
    endDate?: Date | number;
  }): React.ReactNode;
  triggerTestId: string;
  disabledDays?: Matcher | Matcher[];
  footerBanner?: React.ReactNode;
  optionalDateSelection?: boolean;
  label?: string | React.ReactNode;
  showIconButton?: boolean;
};

export const DateRangeFilter = (props: DateRangeFilterProps) => {
  const {
    dates,
    isOpen,
    onConfirm,
    onCancel,
    onInteraction,
    enableCustomRange = true,
    createShortcuts,
    className,
    renderSelectionFn,
    triggerTestId,
    disabledDays,
    footerBanner,
    optionalDateSelection,
    label,
    showIconButton,
  } = props;

  const popoverClasses = cx('Filter-target DateRangeFilter', className);
  const [startDate, endDate] = dates;
  const isClear = !startDate && !endDate;
  let noDatesText = optionalDateSelection ? 'Most recent' : 'Select';
  if (optionalDateSelection && createShortcuts) {
    const shortcut = createShortcuts().find((s) => !s.dateRange[0] && !s.dateRange[1]);
    if (shortcut) {
      noDatesText = shortcut.label;
    }
  }
  const isSingleDay = !!startDate && !!endDate && isSameDay(startDate, endDate);
  const canConfirm = optionalDateSelection
    ? !!((!startDate && !endDate) || (startDate && endDate))
    : !!(startDate && endDate);
  const hourDiff = startDate && endDate && differenceInHours(endDate, startDate);
  const isHour = hourDiff === 1;
  const triggerElement = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    triggerElement.current?.setAttribute('aria-expanded', isOpen?.toString() || '');
  }, [isOpen]);

  const renderSelection = () => {
    if (renderSelectionFn) {
      const selection = renderSelectionFn({ isHour, isSingleDay, startDate, endDate });
      if (selection !== null) {
        return selection;
      }
    }

    if (isHour) {
      return (
        <span>
          {startDate && <Time dateFormat={DateFormat.H_MM_A} datetime={startDate} notooltip />} &ndash;{' '}
          {startDate && endDate ? <Time dateFormat={DateFormat.H_MM_A} datetime={endDate} notooltip /> : 'To'}
        </span>
      );
    } else if (isSingleDay) {
      return <Time dateFormat={DateFormat.MMM_D_YYY} datetime={startDate} notooltip />;
    } else if (startDate && endDate) {
      return <TimeRange startDate={startDate} endDate={endDate} />;
    }
    return (
      <span>{startDate && <Time dateFormat={DateFormat.MMM_D_YYY} datetime={startDate} notooltip />} &ndash; To</span>
    );
  };

  const handleDateRangeChange = (dateRange: Date[]) => {
    props.onChange(dateRange);
  };

  const handleClose = () => {
    onCancel && onCancel();
    handleFocus();
  };

  const handleConfirm = () => {
    onConfirm();
    handleFocus();
  };

  const handleFocus = () => {
    setTimeout(() => {
      triggerElement.current?.focus();
    }, 50); // setTimeout is needed to keep focus on button after closing popover
  };

  return (
    <Popover
      popoverClassName={className}
      isOpen={isOpen}
      placement={enableCustomRange ? 'bottom-start' : 'bottom'}
      restrictHeight={false}
      restrictWidth={false}
      onInteraction={onInteraction}
      targetClassName={popoverClasses}
      onClose={handleFocus}
    >
      {showIconButton ? (
        <Button renderIconFirst icon={<Icon name="calendar" />} ref={triggerElement} loadingText="Saving">
          <>
            {isClear ? noDatesText : <span>{renderSelection()}</span>}{' '}
            <Icon name={isOpen ? 'chevron-up' : 'chevron-down'} size="small" />
          </>
        </Button>
      ) : (
        <FilterButton
          className="DateRangeFilter-Button"
          ref={triggerElement}
          name={label ? label : 'Dates'}
          data-test-id={triggerTestId}
        >
          {isClear ? noDatesText : <span>{renderSelection()}</span>}
        </FilterButton>
      )}
      <div>
        <DateRangePicker
          createShortcuts={createShortcuts}
          dates={dates.map((d) => (d ? new Date(d) : undefined))}
          onChange={handleDateRangeChange}
          enableCustomRange={enableCustomRange}
          disabledDays={disabledDays}
        />

        {enableCustomRange && (
          <div className="DateRangeFilter-footer">
            {footerBanner && footerBanner}
            <ButtonGroup className="u-pullright u-flex">
              <Button size="small" onClick={handleClose}>
                Cancel
              </Button>
              <Button size="small" kind="primary" disabled={!canConfirm} onClick={handleConfirm}>
                Apply
              </Button>
            </ButtonGroup>
          </div>
        )}
      </div>
    </Popover>
  );
};
