import { createRef, Dispatch, useEffect, useState } from 'react';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { enableSemanticPatchInstructionsForSegments } from '@gonfalon/dogfood-flags';
import { useParam, useProjectKey } from '@gonfalon/router';
import { SegmentPendingChangesActionsType } from '@gonfalon/segments';
import { Icon } from '@launchpad-ui/icons';
import { Button } from 'launchpad';
import { isNil } from 'lodash';

import { changeContextTargetingExpiration } from 'actions/expiringContextTargets';
import {
  contextHasName,
  createUserFromContextItem,
  getContextDisplayName,
  makeKindKeyStringFromProps,
} from 'components/Contexts/utils/contextTargetingUtils';
import UserAvatar from 'components/UserAvatar';
import { UserExpirationTooltip } from 'components/UserExpirationTooltip';
import { useDispatch } from 'hooks/useDispatch';
import { useSelector } from 'hooks/useSelector';
import { contextByKindKeySelector } from 'reducers/contexts';
import {
  contextExpirationUpdatesSelectorByContextAndFlagKey,
  contextExpirationUpdatesSelectorByContextAndSegmentKey,
  expiringTargetsForSegmentByContextKeySelector,
  scheduledRemovedContextTargetsByKeySelector,
} from 'reducers/expiringContextTargets';
import { AccessDecision } from 'utils/accessUtils';
import { track } from 'utils/analyticsUtils';
import {
  ContextTargetingExpirationInstructionKind,
  ExpiringContextTarget,
  getContextTargetingExpirationInfo,
  getContextTargetingExpirationInstructionKind,
} from 'utils/expiringContextTargetsUtils';

import { ContextTargetContent } from './ContextTargetContent';

type ContextTargetProps = {
  contextKey: string;
  variationId?: string;
  onDeleteTarget: (contextKey: string) => void;
  isScrolling?: boolean;
  updateExpiringTargetsAccess?: AccessDecision;
  onSetOpenContextPopoverKey: (key: string) => void;
  accessCheck?: AccessDecision;
  openContextPopoverKey?: string;
  contextKind: string;
  onLoadTarget?: (contextKey: string) => void;
  segmentKey?: string;
  segmentPendingChangesDispatch?: Dispatch<SegmentPendingChangesActionsType>;
};

/* eslint-disable import/no-default-export */
export default function ContextTarget(props: ContextTargetProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [showScheduleRemovalModal, setShowScheduleRemovalModal] = useState(false);
  const dispatch = useDispatch();
  const projKey = useProjectKey();
  const envKey = useSelectedEnvironmentKey();
  const flagKey = useParam('flagKey', { optional: true });
  const { contextKind, contextKey, variationId, segmentKey, segmentPendingChangesDispatch } = props;

  const contextTargetScheduledForRemoval = useSelector((state) =>
    segmentKey
      ? expiringTargetsForSegmentByContextKeySelector(state, { segmentKey, contextKind, contextKey })
      : scheduledRemovedContextTargetsByKeySelector(state, {
          projKey,
          envKey,
          flagKey,
          contextKey: props.contextKey,
          contextKind,
        }),
  ) as ExpiringContextTarget | undefined;
  const contextExpirationUpdate = useSelector((state) =>
    segmentKey
      ? contextExpirationUpdatesSelectorByContextAndSegmentKey(state, { contextKind, contextKey, segmentKey })
      : contextExpirationUpdatesSelectorByContextAndFlagKey(state, { contextKind, contextKey, flagKey }),
  );
  const expirationInfo = getContextTargetingExpirationInfo(contextTargetScheduledForRemoval, contextExpirationUpdate);
  const contextItem = useSelector((state) => {
    const kindKey = makeKindKeyStringFromProps(props.contextKind, props.contextKey);
    return contextByKindKeySelector(state, { kindKey });
  });

  const childRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (!contextItem && props.onLoadTarget) {
      props.onLoadTarget(props.contextKey);
    }

    if (props.openContextPopoverKey === props.contextKey) {
      setShowScheduleRemovalModal(true);
    }
  }, []);

  function handlePopoverInteraction() {
    if (props.openContextPopoverKey) {
      props.onSetOpenContextPopoverKey('');
    }
    setIsOpen(!isOpen);
  }

  function handleChangeContextTargetingExpiration({
    originalExpirationDate,
    updatedExpirationDate,
  }: {
    originalExpirationDate?: number | null;
    updatedExpirationDate?: number | null;
  }) {
    const version = contextTargetScheduledForRemoval?._version;
    if (segmentKey && segmentPendingChangesDispatch !== undefined && enableSemanticPatchInstructionsForSegments()) {
      const instructionKind = getContextTargetingExpirationInstructionKind(
        originalExpirationDate,
        updatedExpirationDate,
      );

      if (instructionKind === ContextTargetingExpirationInstructionKind.ADD && !isNil(updatedExpirationDate)) {
        segmentPendingChangesDispatch({
          type: 'ADD_EXPIRING_TARGET',
          contextKind,
          value: contextKey,
          removalDate: updatedExpirationDate,
        });
      } else if (
        instructionKind === ContextTargetingExpirationInstructionKind.UPDATE &&
        !isNil(updatedExpirationDate)
      ) {
        segmentPendingChangesDispatch({
          type: 'UPDATE_EXPIRING_TARGET',
          contextKind,
          value: contextKey,
          removalDate: updatedExpirationDate,
        });
      } else if (instructionKind === ContextTargetingExpirationInstructionKind.REMOVE) {
        segmentPendingChangesDispatch({
          type: 'REMOVE_EXPIRING_TARGET',
          contextKind,
          value: contextKey,
        });
      } else if (instructionKind === null) {
        segmentPendingChangesDispatch({
          type: 'UNDO_EXPIRING_TARGET',
          contextKind,
          value: contextKey,
        });
      }
    }
    dispatch(
      changeContextTargetingExpiration({
        contextKind,
        contextKey,
        flagKey,
        originalExpirationDate,
        updatedExpirationDate,
        variationId,
        version,
        segmentKey,
      }),
    );
  }

  function handleDelete() {
    const hasExpirationOnDifferentVariation =
      !!expirationInfo.updatedExpirationVariationId &&
      props.variationId !== expirationInfo.updatedExpirationVariationId;

    if (!hasExpirationOnDifferentVariation) {
      handleChangeContextTargetingExpiration({
        originalExpirationDate: expirationInfo.originalExpirationDate,
        updatedExpirationDate: null,
      });
    }

    props.onDeleteTarget(props.contextKey);
    track('Remove Individual Context Target Clicked', '', { path: ':projKey/:envKey/features/:flagKey' });
  }

  const contextAttributes = contextItem?.context;
  const icon = <UserAvatar email={contextAttributes?.email} url={contextAttributes?.avatar} />;
  const info =
    contextAttributes && contextHasName(contextAttributes) ? (
      <span className="fs-exclude">{getContextDisplayName(contextAttributes)}</span>
    ) : (
      <span>{props.contextKey}</span>
    );

  const deleteAction = (
    <Button className="UserTarget-deleteButton" aria-label="Remove target" onClick={handleDelete}>
      <Icon name="cancel" className="Icon" size="medium" />
    </Button>
  );

  const scheduleHover = () => {
    if (expirationInfo.updatedExpirationDate !== null) {
      if (props.variationId === expirationInfo.updatedExpirationVariationId) {
        return <UserExpirationTooltip expirationDate={expirationInfo.updatedExpirationDate} isOpen={isOpen} />;
      }
    }
  };

  const user = contextItem ? createUserFromContextItem(contextItem, projKey, envKey) : undefined;

  const baseProps = {
    icon,
    info,
    isUnknown: !user,
    accessCheck: props.accessCheck,
    updateExpiringTargetsAccess: props.updateExpiringTargetsAccess,
    deleteAction,
    onDelete: handleDelete,
    contextKey: props.contextKey,
    user,
    contextKind: props.contextKind,
  };

  const expiringValuesProps = {
    variationId,
    contextTargetScheduledForRemoval,
    originalExpirationDate: expirationInfo.originalExpirationDate,
    updatedExpirationDate: expirationInfo.updatedExpirationDate,
    updatedExpirationVariationId: expirationInfo.updatedExpirationVariationId,
    scheduleHover,
    setRef: childRef,
    isOpen,
    onPopoverInteraction: handlePopoverInteraction,
    onChangeTargetingExpiration: handleChangeContextTargetingExpiration,
    showScheduleRemoval: showScheduleRemovalModal,
  };

  const contentProps = { ...baseProps, ...expiringValuesProps };
  return <ContextTargetContent {...contentProps} />;
}
