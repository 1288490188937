// transformed by jscodeshift
import { ClipboardEventHandler, useState } from 'react';
import { CustomCreatable, OptionProps, StylesObject } from '@gonfalon/launchpad-experimental';
import { pluralize } from '@gonfalon/strings';
import { Set } from 'immutable';

import { LDContext } from 'components/Contexts/types/LDContext';
import { contextHasName, getContextDisplayName } from 'components/Contexts/utils/contextTargetingUtils';
import Restrict from 'components/Restrict';

import AddAndScheduleRemovalButton from './ExpiringContextTargets/AddAndScheduleRemovalButton';
import SelectContextOption from './SelectContextOption';

const invalidUnicodeCharactersRegex = /\u202A\u202C/g;

const ContextOption = ({
  context,
  renderAddAndScheduleButtons,
}: OptionProps & { renderAddAndScheduleButtons?: React.ReactElement }) => (
  <div className="u-flex u-flex-row u-flex-middle fs-exclude SelectContext--ContextOption">
    <div className="u-ml-m">
      {context && contextHasName(context) && <div>{getContextDisplayName(context)}</div>}
      <div className="u-ff-mono u-fs-sm u-very-subtle">{String(context?.key)}</div>
    </div>
    <div className="u-pullright">{renderAddAndScheduleButtons}</div>
  </div>
);

const noResultsText = (
  isLoading: boolean,
  alreadyTargetedKeys: Set<string>,
  inputValue: string,
  options: { length: number },
) => {
  if (isLoading) {
    return 'Loading targets';
  } else if (alreadyTargetedKeys.has(inputValue)) {
    return 'Already targeted';
  } else if (options.length) {
    return 'Type to search for more targets';
  } else {
    return 'No targets found';
  }
};

const getOptions = (
  inputValue: string,
  searchCharacterMin: number,
  options: OptionProps[],
  alreadyTargetedKeys: Set<string>,
) => {
  // if we haven't met the minimum char limit, return a single option with feedback for the user
  if (inputValue && inputValue.length < searchCharacterMin) {
    return [{ label: 'Continue typing to search', isDisabled: true }];
  }
  return options.filter((option: OptionProps) =>
    option?.context?.key ? !alreadyTargetedKeys.has(String(option?.context?.key)) : false,
  );
};

export type SelectContextProps = {
  options: Array<{ context: LDContext; value: string }>;
  alreadyTargetedKeys: Set<string>;
  hasSelectedTargets: boolean;
  isLoading?: boolean;
  onChange(option: { value?: string } | OptionProps[] | null): void;
  onPaste: ClipboardEventHandler<HTMLDivElement>;
  onInputChange(value: string): void;
  onClose(): void;
  contextKind: string;
  onBlur?(): void;
  searchCharacterMin: number;
  onOpenContextPopover(value?: string): void;
  hasExpiringContextTargetAccess?: boolean;
  value?: Array<{ value: string; key: number }>;
  className?: string;
  enableExpiringContextTargets?: boolean;
  isDisabled: boolean;
  disabledReason?: string;
};

export const SelectContext = ({
  value,
  alreadyTargetedKeys,
  hasSelectedTargets,
  isLoading = false,
  options,
  onPaste,
  onChange,
  searchCharacterMin,
  onInputChange,
  onClose,
  onBlur,
  className,
  contextKind,
  enableExpiringContextTargets = true,
  onOpenContextPopover,
  hasExpiringContextTargetAccess,
  isDisabled,
  disabledReason,
}: SelectContextProps) => {
  const [inputValue, setInputValue] = useState('');
  const showExpiringContextTargets = enableExpiringContextTargets && hasExpiringContextTargetAccess;
  const bottomRadiusRemoved = '0.3rem 0.3rem 0 0';
  const defaultStyles: Partial<StylesObject> = {
    container: { flexGrow: 1 },
    control: {
      borderRadius: hasSelectedTargets ? bottomRadiusRemoved : '0.1875rem',
      minHeight: '2rem', // account for 2px of border top + bottom, since the border is outside of this element
    },
  };
  const defaultGetOptionLabel = (option: OptionProps) => {
    if (option.isDisabled) {
      return option.label ? option.label.toString() : '';
    }
    if ('label' in option) {
      // create option
      return showExpiringContextTargets ? (
        <AddAndScheduleRemovalButton
          onClick={() => onOpenContextPopover(option.user ? option.user.attributes.key : inputValue)}
        />
      ) : undefined;
    }
    return (
      <ContextOption
        context={option.context}
        renderAddAndScheduleButtons={
          showExpiringContextTargets ? (
            <AddAndScheduleRemovalButton
              className="SelectContext--button"
              onClick={() => onOpenContextPopover(option.user ? option.user.attributes.key : option.value)}
            />
          ) : (
            <></>
          )
        }
      />
    );
  };

  const handleInputChange = (input: string) => {
    setInputValue(input);
    if (input.length !== 1) {
      onInputChange(input);
    }
    return input;
  };

  return (
    <div onPaste={onPaste} className={className}>
      <Restrict
        isRestricted={isDisabled}
        tooltip={disabledReason}
        tooltipOptions={{
          rootElementStyle: { display: 'block' },
          placement: 'top',
        }}
      >
        <CustomCreatable
          ariaLabel="select target"
          className="SelectContext fs-exclude"
          customComponents={{ Option: SelectContextOption }}
          placeholder={`Search to find or add ${pluralize(contextKind, 2)}`}
          value={value ? value : ''}
          tabSelectsValue={false}
          isDisabled={isDisabled}
          options={(!isLoading && getOptions(inputValue, searchCharacterMin, options, alreadyTargetedKeys)) || []}
          noOptionsMessage={() => noResultsText(isLoading, alreadyTargetedKeys, inputValue, options)}
          isValidNewOption={(input: string) =>
            input.length > 0
              ? !alreadyTargetedKeys.has(input.replaceAll(invalidUnicodeCharactersRegex, ''))
              : input.length !== 0
          }
          onMenuClose={onClose}
          onFocus={() => onInputChange('')}
          onInputChange={handleInputChange}
          onChange={onChange}
          onBlur={onBlur}
          filterOption={() => true}
          formatCreateLabel={(label: string) => `Add ${contextKind} "${label}"`}
          formatOptionLabel={defaultGetOptionLabel}
          styles={defaultStyles}
        />
      </Restrict>
    </div>
  );
};

/* eslint-disable import/no-default-export */
export default SelectContext;
