import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateMetricGroupPayload } from 'components/experimentation/common/api/api.types';
import { createMetricGroup } from 'components/experimentation/common/api/metric';
import { requestErrorNotification } from 'components/experimentation/common/utils/errors';

import { METRIC_GROUPS_QUERY_KEY } from './useMetricGroup';

type Props = {
  projectKey: string;
};

function createMetricGroupQuery({ projectKey }: Props) {
  return {
    mutationFn: async (body: CreateMetricGroupPayload) => createMetricGroup(projectKey, body),
    mutationKey: [METRIC_GROUPS_QUERY_KEY, 'CREATE', projectKey],
  };
}

/**
 * Hook creates a metric group
 *
 * @param param.projectKey - the key of the project you want the metric group to belong to
 * @param param.body - payload of the request
 */
export function useCreateMetricGroup({ projectKey }: Props) {
  const queryClient = useQueryClient();

  return useMutation({
    ...createMetricGroupQuery({ projectKey }),
    onError: requestErrorNotification('Error creating metric group'),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [METRIC_GROUPS_QUERY_KEY] });
    },
  });
}
