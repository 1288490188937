import { RefObject } from 'react';
import { Icon } from '@launchpad-ui/icons';
import { Button } from 'launchpad';

import styles from './ScheduleRemovalButton.module.css';

export const SCHEDULE_REMOVAL = 'Schedule removal';

type ScheduleRemovalProps = {
  hasRemovalScheduled?: boolean;
  onClick(): void;
  disabled?: boolean;
  buttonRef?: RefObject<HTMLButtonElement>;
};

/* eslint-disable import/no-default-export */
export default function ScheduleRemovalButton(props: ScheduleRemovalProps) {
  return (
    <Button
      className={styles.ScheduleRemoval}
      aria-label={SCHEDULE_REMOVAL}
      title={SCHEDULE_REMOVAL}
      disabled={props.disabled}
      onClick={props.onClick}
      ref={props.buttonRef}
    >
      <Icon
        name="calendar-cancel"
        className={props.hasRemovalScheduled ? styles.IconWithSchedule : styles.Icon}
        size="medium"
      />
    </Button>
  );
}
