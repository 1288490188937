import { MutableRefObject, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';
import { List } from 'immutable';

import { useWorkflowBuilderViewOptions } from 'components/CustomWorkflows/WorkflowBuilder/WorkflowBuilderState/utils';
import { EllipsisButtonWithDropdown } from 'components/ui/buttons';
import { EllipsisButtonType } from 'components/ui/buttons/types';
import UpsellFeatureModalBulkUserManagement from 'components/upsells/upsellFeatureModals/UpsellFeatureModalBulkFeatures';
import UpsellFeatureModalConvertToSegment from 'components/upsells/upsellFeatureModals/UpsellFeatureModalConvertToSegment';
import { useEffectOnClose } from 'hooks/useEffectOnClose';
import { AccessDecision, combineAccessDecisions, createAccessDecision } from 'utils/accessUtils';
import { getTrackingLocation } from 'utils/analyticsUtils';
import { SegmentVariationTypes } from 'utils/expiringContextTargetsUtils';
import { trackFlagTargetingEvent } from 'utils/flagUtils';

import styles from './ContextTargetingActionMenu.module.css';

export type ContextTargetingActionMenuProps = {
  clearIndex?: number;
  createSegmentAccessCheck?: AccessDecision;
  handleBulkEditClick(): void;
  handleConvertToSegmentClick(): void;
  handleExportAsCSVClick(): void;
  isBulkUserManagementEnabled: boolean;
  isEmpty: boolean;
  isTargetingToSegmentEnabled: boolean;
  menuTriggerRef?: MutableRefObject<HTMLButtonElement | undefined>;
  onChange(targets: List<string>): void;
  onClearAllTargets?(): void;
  onClearSegmentTarget?: (
    variation: SegmentVariationTypes,
    update: (list: List<string>) => void,
    contextKind?: string,
  ) => void;
  segmentKey?: string | undefined;
  segmentVariation?: SegmentVariationTypes;
  showConvertToSegment?: boolean;
  updateTargetAccessCheck: AccessDecision;
  variationName?: string;
};

type UpsellModalLabel = 'Bulk edit' | 'Export as CSV' | 'Convert to segment';

/* eslint-disable import/no-default-export */
export default function ContextTargetingActionMenu({
  createSegmentAccessCheck,
  handleBulkEditClick,
  handleConvertToSegmentClick,
  handleExportAsCSVClick,
  isBulkUserManagementEnabled,
  isEmpty,
  isTargetingToSegmentEnabled,
  menuTriggerRef,
  onChange,
  onClearAllTargets,
  onClearSegmentTarget,
  segmentKey,
  segmentVariation,
  showConvertToSegment,
  updateTargetAccessCheck,
  variationName,
}: ContextTargetingActionMenuProps) {
  const location = useLocation();
  const trackingLocation = getTrackingLocation(location.pathname);
  const [isUpsellModalOpen, setIsUpsellModalOpen] = useState(false);
  const [upsellModalToShow, setUpsellModalToShow] = useState<UpsellModalLabel | ''>('');
  const { hideTargetsBulkActions } = useWorkflowBuilderViewOptions();
  const localMenuTriggerRef = useRef<HTMLButtonElement>();
  useEffectOnClose(() => localMenuTriggerRef.current?.focus(), isUpsellModalOpen);

  const handleUpsellModalCancel = () => {
    setIsUpsellModalOpen(false);
  };

  const renderUpsellModal = () => {
    if (upsellModalToShow === 'Bulk edit') {
      return (
        <UpsellFeatureModalBulkUserManagement
          isOpen={isUpsellModalOpen}
          onCancel={handleUpsellModalCancel}
          desiredFeature="edit"
        />
      );
    }
    if (upsellModalToShow === 'Export as CSV') {
      return (
        <UpsellFeatureModalBulkUserManagement
          isOpen={isUpsellModalOpen}
          onCancel={handleUpsellModalCancel}
          desiredFeature="export"
        />
      );
    }
    if (upsellModalToShow === 'Convert to segment') {
      return <UpsellFeatureModalConvertToSegment isOpen={isUpsellModalOpen} onCancel={handleUpsellModalCancel} />;
    }
  };

  const trackClick = (action: string) => {
    trackFlagTargetingEvent('Targeting Rule OverFlow Button Clicked', {
      location: trackingLocation,
      type: 'individual',
      action,
    });
  };

  const textWithLockIcon = (text: string) => (
    <div className={cx(styles.contextTargetingActionMenuItem, styles.locked)}>
      <Icon name="lock" className={styles.iconLock} data-test-id="locked-icon" size="small" variant="subtle" />
      {text}
    </div>
  );

  const upgradePrompt = (label: UpsellModalLabel) => ({
    key: label,
    label: textWithLockIcon(label),
    onClick: () => {
      trackClick(`${label} (upsell)`);
      setUpsellModalToShow(label);
      setIsUpsellModalOpen(true);
    },
    tooltip: 'Upgrade your plan to use this feature. Click to learn more.',
    tooltipPlacement: 'left' as const,
  });

  const bulkEdit = () => {
    const label = 'Bulk edit';
    if (!isBulkUserManagementEnabled) {
      return upgradePrompt(label);
    }
    return {
      key: 'edit',
      label,
      onClick: () => {
        trackClick('edit');
        handleBulkEditClick();
      },
      disabled: !updateTargetAccessCheck.isAllowed,
      tooltip: updateTargetAccessCheck.getActionReason(),
    };
  };

  const exportAsCsv = () => {
    const label = 'Export as CSV';
    if (!isBulkUserManagementEnabled) {
      return upgradePrompt(label);
    }
    return {
      key: 'export',
      label,
      onClick: () => {
        trackClick('export');
        handleExportAsCSVClick();
      },
    };
  };

  const convertToSegment = () => {
    const convertToSegmentAccess = combineAccessDecisions(
      updateTargetAccessCheck,
      createSegmentAccessCheck,
      createAccessDecision({
        isAllowed: !isEmpty,
        customMessage: 'Please select a minimum of one target to convert to segment',
      }),
    );
    const isDisabled = !convertToSegmentAccess.isAllowed;

    const upgradeRequired = !isTargetingToSegmentEnabled && showConvertToSegment;
    const label = 'Convert to segment';
    if (upgradeRequired) {
      return upgradePrompt(label);
    }
    return {
      key: 'segment',
      label,
      onClick: () => {
        trackClick('convert to segment');
        if (!isDisabled) {
          handleConvertToSegmentClick();
        }
      },
      disabled: isDisabled,
      tooltip: isDisabled ? convertToSegmentAccess.getActionReason() : undefined,
      tooltipPlacement: 'left',
    };
  };

  const clearTargets = () => {
    const clearTargetsAccess = combineAccessDecisions(
      updateTargetAccessCheck,
      createAccessDecision({
        isAllowed: !isEmpty,
        customMessage: 'Please select a minimum of one target to clear targets',
      }),
    );
    const isDisabled = !clearTargetsAccess.isAllowed;

    return {
      key: 'delete',
      label: 'Clear',
      disabled: isDisabled,
      onClick: () => {
        trackClick('delete');
        if (onClearAllTargets) {
          return onClearAllTargets();
        }
        if (segmentKey) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          segmentVariation !== undefined && onChange !== undefined && onClearSegmentTarget !== undefined
            ? onClearSegmentTarget(segmentVariation, onChange)
            : null;
        }
      },
      tooltip: isDisabled ? clearTargetsAccess.getActionReason() : undefined,
      tooltipPlacement: 'left',
    };
  };

  const handleRenderMenuItems = () => {
    if (hideTargetsBulkActions) {
      return [clearTargets()];
    }
    return showConvertToSegment
      ? [bulkEdit(), exportAsCsv(), convertToSegment(), clearTargets()]
      : [bulkEdit(), exportAsCsv(), clearTargets()];
  };

  return (
    <>
      {isUpsellModalOpen && renderUpsellModal()}
      <EllipsisButtonWithDropdown
        className="ContextTargeting--ellipsisButton"
        type={EllipsisButtonType.VERTICAL}
        onClickEllipsisButton={() => {
          trackFlagTargetingEvent('Targeting Rule Overflow Button Viewed', {
            location: trackingLocation,
            type: 'individual',
          });
        }}
        items={handleRenderMenuItems()}
        aria-label={`${variationName || segmentVariation} target options`}
        testId="individual-target-action-menu"
        ref={(menuTriggerElement) => {
          if (menuTriggerElement) {
            localMenuTriggerRef.current = menuTriggerElement;
            if (menuTriggerRef) {
              // eslint-disable-next-line no-param-reassign
              menuTriggerRef.current = menuTriggerElement;
            }
          }
        }}
      />
    </>
  );
}
