import { createTrackerForCategory } from '@gonfalon/analytics';

import { ReasonToUseLaunchDarkly, SkipSetupEventInfo, Step } from './types';

export const trackGetStartedEvent = (event: string, attributes?: SkipSetupEventInfo) =>
  createTrackerForCategory('Get Started')(event, attributes);

export const trackSkipSetupButtonClicked = (step: Step, type: 'link' | 'button') =>
  trackGetStartedEvent('Skip Setup Button Clicked', { step, type });

export const trackQuickstartGuideButtonClicked = () => trackGetStartedEvent('Quickstart Guide Button Clicked');

export const trackSetupInCLIButtonClicked = () => trackGetStartedEvent('Setup in CLI Button Clicked');

export const trackCLINotifyMeButtonClicked = (userEmail: string) =>
  trackGetStartedEvent('CLI Notify Me Button Clicked', { userEmail });

export const trackInviteTeamMembersButtonClicked = () => trackGetStartedEvent('Invite Team Members Button Clicked');

export const trackSandboxButtonClicked = () => trackGetStartedEvent('Sandbox Button Clicked');

export const trackContinueButtonClicked = (step: Step) => trackGetStartedEvent('Continue Button Clicked', { step });

export const trackQuickstartFlagUpdated = () => trackGetStartedEvent('Quickstart Flag Updated');

export const trackSDKSelected = (sdk: string) => trackGetStartedEvent('SDK Selected', { sdk });

export const trackSDKSelectionEdited = (sdk: string) => trackGetStartedEvent('SDK Selection Edited', { sdk });

export const trackInstallSDKDocumentationLinkClicked = (sdk: string) =>
  trackGetStartedEvent('Install SDK Documentation Link Clicked', { sdk });

export const trackQuickstartFlagToggled = (on: boolean) => trackGetStartedEvent('Quickstart Flag Toggled', { on });

export const trackStartFlaggingButtonClicked = ({ location }: { location: string }) =>
  trackGetStartedEvent('Start Flagging Button Clicked', { location });

export const trackFlagEventsReceivedRendering = ({ step, sdk }: { step: Step; sdk: string }) =>
  trackGetStartedEvent('Flag Event Received', { step, sdk });

export const trackBackButtonClicked = ({ component }: { component: string }) =>
  trackGetStartedEvent('Back Button Clicked', { component });

export const trackGoToMembersButtonClicked = () => trackGetStartedEvent('Go To Members Page Button Clicked');

export const trackReceivedEventForFlag = (sdk: string) => trackGetStartedEvent('Received Event for Flag', { sdk });

export const trackSeeGithub = (sdk: string) => trackGetStartedEvent('See Github', { sdk });

export const trackWelcomeSurveyEvent = createTrackerForCategory('Welcome Survey');

export const trackWelcomeSurvey = (reasonToUseLaunchDarkly: ReasonToUseLaunchDarkly) =>
  trackWelcomeSurveyEvent('Welcome Survey Submitted', {
    reasonToUseLaunchDarkly,
  });

export const trackWelcomeSurveyPageLoaded = () => trackWelcomeSurveyEvent('Welcome Survey Page Loaded');

export const trackSignUpEvent = createTrackerForCategory('Sign Up Flow');
export const trackStartFreeTrialButtonClicked = () => trackSignUpEvent('Start Free Trial Button Clicked');

export const trackOnboardingEvent = createTrackerForCategory('Onboarding');
export const trackOnboardingTargetAnythingClicked = () => trackOnboardingEvent('Onboarding Target Anything Clicked');
export const trackOnboardingInviteMembersClicked = () => trackOnboardingEvent('Onboarding Invite Members Clicked');
export const trackOnboardingIntegrateWithSlackClicked = () =>
  trackOnboardingEvent('Onboarding Integrate With Slack Clicked');
