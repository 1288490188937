type StateChart<State extends string, Action extends string> = {
  [state in State]: {
    transitions: {
      [action in Action]?: Partial<State>;
    };
  };
};

export function createStateChart<State extends string, Action extends string>(chart: StateChart<State, Action>) {
  return function reducer(state: State, action: Action) {
    const transitions = chart[state]?.transitions;
    const nextState = transitions[action] || state;
    return nextState;
  };
}
