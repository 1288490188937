import { useState } from 'react';
import { toContextKinds } from '@gonfalon/navigator';
import { pluralize } from '@gonfalon/strings';
import { Link } from '@launchpad-ui/components';
import classNames from 'clsx';
import { List } from 'immutable';
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'launchpad';

import { ContextKind } from 'components/Contexts/types';
import { useFlagContext } from 'components/FlagContext';
import { FlagContextErrorWrapper } from 'components/FlagContextErrorWrapper';
import { WithConfirmation } from 'components/ui/confirmation';
import { Target } from 'utils/flagUtils';
import { SegmentTarget } from 'utils/segmentUtils';

import { ContextKindsModalList } from './ContextKindsModalList';

import styles from './ManageContextKindsModal.module.css';

export type ManageContextKindsModalProps = {
  allContextKinds: ContextKind[];
  contextsWithTargets: string[];
  visibleContextKinds: string[];
  targetsForVariation: List<Target> | List<SegmentTarget>;
  label: string | JSX.Element;
  projKey: string;
  envKey: string;
  flagKey?: string;
  onClearTargets: (contextsWithClearedTargets: string[]) => void;
  onUpdateVisibleContextKinds(selectedContexts: string[]): void;
  onCancel(): void;
};

export function ManageContextKindsModal({
  allContextKinds,
  contextsWithTargets,
  visibleContextKinds,
  targetsForVariation,
  label,
  projKey,
  envKey,
  flagKey,
  onClearTargets,
  onUpdateVisibleContextKinds,
  onCancel,
}: ManageContextKindsModalProps) {
  const [selectedContexts, setSelectedContexts] = useState(visibleContextKinds);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const { flagConfiguration } = useFlagContext();
  const flagEvaluatedContextKindKeys = (flagKey && flagConfiguration?.evaluation?.contextKinds) || List<string>();
  const displayableContextKinds = allContextKinds.filter((ck: ContextKind) => !ck.hideInTargeting);
  const [filteredContextKinds, setFilteredContextKinds] = useState(displayableContextKinds);
  const evaluatedContextKinds = displayableContextKinds.filter((ck) => flagEvaluatedContextKindKeys.contains(ck.key));
  const unevaluatedContextKinds = displayableContextKinds.filter(
    (ck) => !flagEvaluatedContextKindKeys.contains(ck.key),
  );

  const handleUpdateSelectedContexts = (selectedContext: string) => {
    const updatedSelectedContexts = selectedContexts.filter((context) => context !== selectedContext);
    if (updatedSelectedContexts.length === selectedContexts.length) {
      updatedSelectedContexts.push(selectedContext);
    }
    setSelectedContexts(updatedSelectedContexts);
  };
  const contextsWithClearedTargets = contextsWithTargets.filter((context) => !selectedContexts.includes(context));

  const handleSave = () => {
    onClearTargets(contextsWithClearedTargets);
    onUpdateVisibleContextKinds(selectedContexts);
    onCancel();
  };

  const labelIsString = typeof label === 'string';
  const modalHeaderTitle = (
    <label htmlFor="filter">{labelIsString ? `Select context kinds for ${label}` : 'Select context kinds'}</label>
  );
  const modalDescription = <>For {labelIsString ? <span>{label}</span> : <span>variation {label}</span>}</>;

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredContextKinds(
      displayableContextKinds.filter((item) => item.name.toLowerCase().includes(event.target.value.toLowerCase())),
    );
  };

  return (
    <Modal className={classNames({ [styles.hideModal]: isConfirmationVisible })} onCancel={onCancel}>
      <ModalHeader className={styles.modalHeader} title={modalHeaderTitle} />
      <div className={styles.modalHeaderDescription}>
        {!labelIsString && modalDescription}
        <input
          className={styles.contextKindsFilterInput}
          type="text"
          id="filter"
          onChange={handleFilter}
          placeholder="Filter context kinds"
        />

        {contextsWithTargets.length > 0 && (
          <Alert className={styles.alert} kind="warning" size="medium" isInline>
            <span className={styles.alertContent}>
              Removing populated context kinds will also clear the targets for that kind
            </span>
          </Alert>
        )}
      </div>
      <ModalBody>
        <ContextKindsModalLists
          allDisplayableContextKinds={filteredContextKinds}
          evaluatedContextKinds={evaluatedContextKinds}
          unevaluatedContextKinds={unevaluatedContextKinds}
          targetsForVariation={targetsForVariation}
          selectedContexts={selectedContexts}
          visibleContextKinds={visibleContextKinds}
          onUpdateSelectedContexts={handleUpdateSelectedContexts}
        />
        <Link className={styles.managementLink} href={toContextKinds({ projectKey: projKey, environmentKey: envKey })}>
          Manage context kinds
        </Link>
      </ModalBody>
      <ModalFooter
        primaryButton={
          contextsWithClearedTargets.length ? (
            <WithConfirmation
              confirmation={{
                kind: 'destructive',
                title: `Remove context ${pluralize('kind', contextsWithClearedTargets.length)}`,
                description: modalDescription,
                body: () => (
                  <>
                    <ul>
                      {targetsForVariation.map((target) =>
                        contextsWithClearedTargets.includes(target.contextKind) ? (
                          <li className={styles.contextsWithTargetsListItem} key={target.contextKind}>
                            Context kind <strong>{target.contextKind}</strong> has <strong>{target.values.size}</strong>{' '}
                            {pluralize('target', target.values.size)}.
                          </li>
                        ) : null,
                      )}
                    </ul>

                    <div className="u-mt-m">
                      Removing populated context kinds will also clear targets for those kinds.
                    </div>
                  </>
                ),
                confirmLabel: 'Remove',
              }}
              onConfirm={handleSave}
              onCancel={() => setIsConfirmationVisible(!isConfirmationVisible)}
              // onAction prop is being used but ts does not seem to recognize it
              // eslint-disable-next-line react/no-unused-prop-types
              render={({ onAction }: { onAction(): void }) => (
                <Button
                  kind="primary"
                  data-test-id="save-context-kinds-targeting"
                  disabled={false}
                  onClick={() => {
                    onAction();
                    setIsConfirmationVisible(!isConfirmationVisible);
                  }}
                >
                  Save
                </Button>
              )}
            />
          ) : (
            <Button kind="primary" data-test-id="save-context-kinds-targeting" disabled={false} onClick={handleSave}>
              Save
            </Button>
          )
        }
        secondaryButton={<Button onClick={onCancel}>Cancel</Button>}
      />
    </Modal>
  );
}

export type ContextKindsModalListsProps = {
  allDisplayableContextKinds: ContextKind[];
  evaluatedContextKinds: ContextKind[];
  unevaluatedContextKinds: ContextKind[];
  visibleContextKinds: string[];
  targetsForVariation: List<Target> | List<SegmentTarget>;
  selectedContexts: string[];
  onUpdateSelectedContexts(selectedContext: string): void;
};

export function ContextKindsModalLists({
  allDisplayableContextKinds,
  evaluatedContextKinds,
  unevaluatedContextKinds,
  targetsForVariation,
  selectedContexts,
  visibleContextKinds,
  onUpdateSelectedContexts,
}: ContextKindsModalListsProps) {
  if (evaluatedContextKinds.length > 0) {
    return (
      <>
        <section className={styles.listSection}>
          <h4>Recently seen</h4>
          <ContextKindsModalList
            allContextKinds={evaluatedContextKinds}
            targetsForVariation={targetsForVariation}
            selectedContexts={selectedContexts}
            visibleContextKinds={visibleContextKinds}
            onUpdateSelectedContexts={onUpdateSelectedContexts}
          />
        </section>
        <section className={styles.listSection}>
          <h4>Other available</h4>
          <ContextKindsModalList
            allContextKinds={unevaluatedContextKinds}
            targetsForVariation={targetsForVariation}
            selectedContexts={selectedContexts}
            visibleContextKinds={visibleContextKinds}
            onUpdateSelectedContexts={onUpdateSelectedContexts}
          />
        </section>
      </>
    );
  }
  return (
    <ContextKindsModalList
      allContextKinds={allDisplayableContextKinds}
      targetsForVariation={targetsForVariation}
      selectedContexts={selectedContexts}
      visibleContextKinds={visibleContextKinds}
      onUpdateSelectedContexts={onUpdateSelectedContexts}
    />
  );
}

/* eslint-disable import/no-default-export */
export default function ManageContextKindsModalWrapper({
  allContextKinds,
  contextsWithTargets,
  visibleContextKinds,
  targetsForVariation,
  label,
  projKey,
  envKey,
  flagKey,
  onClearTargets,
  onUpdateVisibleContextKinds,
  onCancel,
}: ManageContextKindsModalProps) {
  const ContextKindsModal = (
    <ManageContextKindsModal
      onClearTargets={onClearTargets}
      onCancel={onCancel}
      onUpdateVisibleContextKinds={onUpdateVisibleContextKinds}
      allContextKinds={allContextKinds}
      visibleContextKinds={visibleContextKinds}
      contextsWithTargets={contextsWithTargets}
      targetsForVariation={targetsForVariation}
      label={label}
      projKey={projKey}
      envKey={envKey}
      flagKey={flagKey}
    />
  );
  return flagKey ? (
    <FlagContextErrorWrapper envKey={envKey} flagKey={flagKey} expandEvaluation>
      {ContextKindsModal}
    </FlagContextErrorWrapper>
  ) : (
    ContextKindsModal
  );
}
