import { useFormContext, useWatch } from 'react-hook-form';
import { useDebouncedString } from '@gonfalon/async';
import { useEventActivity } from '@gonfalon/rest-api';
import { useProjectKey } from '@gonfalon/router';
import { Alert } from '@launchpad-ui/components';

import { MetricForm } from '../../common';

import { EventLastSeenTable } from './EventLastSeenTable';

export function EventActivitySummary() {
  const projectKey = useProjectKey();
  const { control } = useFormContext<MetricForm>();
  const eventKeyFormValue = useWatch({ control, name: 'eventKey', defaultValue: '' });
  const eventKey = useDebouncedString(eventKeyFormValue ?? '', 1000);
  const {
    data: eventActivity,
    isPlaceholderData,
    isPending,
    isError,
  } = useEventActivity(
    { projectKey, eventKey },
    { enabled: !!eventKey, placeholderData: (previousData) => previousData, staleTime: 0 },
  );

  // during initial fetch, or if we haven't fetched yet, don't show anything
  if (isPending) {
    return null;
  }

  if (isError) {
    return <Alert status="error">Failed to load event activity</Alert>;
  }

  // render the previous results "greyed out" while waiting for new results
  // starting from the event key changing to fetch completion, for a smoother experience
  const isLoading = isPlaceholderData || eventKeyFormValue !== eventKey;
  return (
    <div className={isLoading ? 'u-a-50' : ''}>
      <EventLastSeenTable items={eventActivity.items ?? []} />
    </div>
  );
}
