import { useState } from 'react';
import { Alert } from 'launchpad';

export type MultipleApprovalRequestsCreatedAlertProps = {
  numApprovalRequests: number;
  bodyText: string;
};

export const MultipleApprovalRequestsCreatedAlert = ({
  numApprovalRequests,
  bodyText,
}: MultipleApprovalRequestsCreatedAlertProps) => {
  const [hide, setHide] = useState(false);
  return !hide ? (
    <Alert
      kind="info"
      size="small"
      className="MultipleApprovalRequestsCreatedAlert"
      onDismiss={() => setHide(true)}
      dismissible
      header={`This will create ${numApprovalRequests} approval requests.`}
    >
      {bodyText}
    </Alert>
  ) : null;
};
