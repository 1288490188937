import { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { userId } from '@gonfalon/constants';
import { funnelGroupMetricLimit, standardMetricGroupMetricLimit } from '@gonfalon/dogfood-flags';
import { useProjectKey } from '@gonfalon/router';
import { convertToKey, hookFormKeyInvalid } from '@gonfalon/strings';
import clsx from 'clsx';
import {
  Button,
  CopyToClipboard,
  Form,
  FormField,
  InlineEdit,
  ModalBody,
  ModalHeader,
  TextArea,
  TextField,
  Tooltip,
} from 'launchpad';

import DocumentationLink from 'components/DocumentationLink';
import { MetricGroup } from 'components/experimentation/common/types';
import { MAX_METRIC_GROUP_KEY_LENGTH } from 'components/metrics/common';
import { FormFieldWithTooltip } from 'components/metrics/components/FormFieldWithTooltip';
import { KindButton, KindButtonGroup, KindButtonGroupContent } from 'components/metrics/components/KindButtonGroup';
import { useCreateMetricGroup } from 'components/metrics/hooks/useCreateMetricGroup';
import { ModalWithCancelPrompt } from 'components/ModalWithCancelPrompt';
import RequiredFieldsNotice from 'components/RequiredFieldsNotice';
import SelectMemberContainer from 'components/SelectMemberContainer';
import { Member } from 'utils/accountUtils';
import { trackExperimentEvent } from 'utils/analyticsUtils';
import { getDocumentationUrl } from 'utils/urlUtils';

import { CreateMetricGroupSortableContainer } from './CreateMetricGroupSortableContainer';

import styles from './CreateMetricGroupModal.module.css';

const defaultFunnelSteps = [
  { metric: undefined, name: 'Step 1' },
  { metric: undefined, name: 'Step 2' },
];

const keyFieldHint = (
  <>
    We use the key to give you friendly URLs. Keys should be at most {MAX_METRIC_GROUP_KEY_LENGTH} characters and must
    only contain letters, numbers, <code>.</code>, <code>_</code> or <code>-</code>.
  </>
);

export type CreateMetricGroupFormData = {
  name: string;
  key: string;
  description: string;
  maintainer: string;
  kind: 'standard' | 'funnel';
  steps: Array<{ name: string; metric?: { key: string; randomizationUnits?: string[] } }>;
};

type CreateMetricGroupModalProps = {
  onCancel: () => void;
  onSuccess?: (metricGroup: MetricGroup) => void;
  supportedRandomizationUnits?: string[];
  location?: 'builder' | 'design' | 'metricGroupsList';
  metricGroupType?: 'funnel' | 'standard';
};

export function CreateMetricGroupModal({
  onCancel,
  onSuccess,
  supportedRandomizationUnits,
  location = 'metricGroupsList',
  metricGroupType,
}: CreateMetricGroupModalProps) {
  const defaultValues: CreateMetricGroupFormData = {
    name: '',
    key: '',
    description: '',
    maintainer: userId() || '',
    kind: metricGroupType ?? 'funnel',
    steps: defaultFunnelSteps,
  };

  const [showPrompt, setShowPrompt] = useState(false);
  const projKey = useProjectKey();
  const createMetricsGroupLink = getDocumentationUrl('home/observability/metric-groups');

  const form = useForm<CreateMetricGroupFormData>({ defaultValues });

  const { register, handleSubmit, control, setValue, watch, getFieldState, clearErrors } = form;
  const { errors, isDirty, dirtyFields } = form.formState;

  const watchKey = watch('key');
  const kind = watch('kind');
  const steps = watch('steps');

  const keyState = getFieldState('key');

  const mutation = useCreateMetricGroup({ projectKey: projKey });
  const onSubmit = handleSubmit((data) => {
    mutation.mutate(
      {
        key: data.key,
        name: data.name,
        kind: data.kind,
        description: data.description,
        metrics: data.steps.map((step) => ({
          nameInGroup: data.kind === 'funnel' ? step.name : '', // TODO should this be omitted instead?
          key: step.metric?.key ?? '',
        })),
        maintainerId: data.maintainer,
        tags: [],
      },
      { onSuccess },
    );
    trackExperimentEvent('Metric Group Created', {
      location,
      metricGroupLength: data.steps?.length,
      metricGroupType: data.kind,
    });
  });

  const getTooltip = (currentButton: 'funnel' | 'standard') => {
    if (location !== 'builder') {
      return '';
    } else {
      if (metricGroupType === 'standard') {
        return currentButton === 'standard'
          ? 'Add metrics in any order to standardize your measurements'
          : 'You cannot create a funnel group in the middle of creating a feature change experiment';
      } else {
        return currentButton === 'funnel'
          ? 'Match the step order to the order of the actions your users take in a multi-step flow'
          : 'You cannot create a standard metric group in the middle of creating a funnel experiment';
      }
    }
  };

  const onCancelWithPrompt = () => {
    if (isDirty) {
      setShowPrompt(true);
    } else {
      onCancel();
    }
  };

  const nameFieldProps = register('name', { required: 'Metric group name is required' });

  return (
    <ModalWithCancelPrompt
      size="large"
      onCancel={onCancelWithPrompt}
      shouldBlock={isDirty && !mutation.isPending}
      showPrompt={showPrompt}
      onCancelPrompt={() => setShowPrompt(false)}
      onConfirmPrompt={onCancel}
    >
      <ModalHeader
        title="Create metric group"
        description={
          <>
            <span className={styles.modalDescription}>
              A metric group is a re-usable set of metrics to use for measurement.{' '}
              <DocumentationLink href={createMetricsGroupLink} component="CreateMetricGroupModal" text="Learn more" />
            </span>
            <span>
              <RequiredFieldsNotice />
            </span>
          </>
        }
        className={styles.modalHeader}
      />
      <ModalBody className={styles.modalBody}>
        <FormProvider {...form}>
          <Form onSubmit={onSubmit}>
            <FormField
              name="name"
              htmlFor="name"
              label="Name"
              errorMessage={errors.name?.message}
              isRequired
              className={styles.formField}
            >
              <TextField
                {...nameFieldProps}
                onChange={async (e) => {
                  if (keyState.isDirty) {
                    if (e.target.value.length > 0) {
                      clearErrors('name');
                    }
                    return;
                  }
                  setValue('key', convertToKey(e.target.value.substring(0, MAX_METRIC_GROUP_KEY_LENGTH + 1)));
                  clearErrors('key');
                  return nameFieldProps.onChange(e);
                }}
                placeholder="Name your metric group"
              />
            </FormField>

            <FormFieldWithTooltip
              name="key"
              htmlFor="key"
              label="Key"
              tooltip={keyFieldHint}
              errorMessage={errors.key?.message}
              isRequired
              className={styles.formField}
            >
              <InlineEdit
                defaultValue={watchKey}
                onConfirm={(string) =>
                  setValue('key', convertToKey(string.substring(0, MAX_METRIC_GROUP_KEY_LENGTH + 1)))
                }
                renderInput={
                  <TextField
                    id="key"
                    {...register('key', {
                      required: 'metric group key is required',
                      pattern: hookFormKeyInvalid,
                      maxLength: {
                        value: MAX_METRIC_GROUP_KEY_LENGTH,
                        message: `Key must be fewer than ${MAX_METRIC_GROUP_KEY_LENGTH} characters`,
                      },
                    })}
                    placeholder="Enter metric group key"
                  />
                }
              >
                {watchKey ? (
                  <CopyToClipboard text={watchKey}>{watchKey}</CopyToClipboard>
                ) : (
                  'Metric group key will be auto generated here'
                )}
              </InlineEdit>
            </FormFieldWithTooltip>

            <FormField
              name="description"
              htmlFor="description"
              label="Description"
              errorMessage={errors.description?.message}
              isRequired={false}
              className={styles.formField}
            >
              <>
                <TextArea {...register('description')} placeholder="Add a description to this metric group" />
              </>
            </FormField>

            <FormField
              name="maintainer"
              htmlFor="maintainer"
              label="Maintainer"
              errorMessage={errors.maintainer?.message}
              isRequired={false}
              className={clsx(styles.maintainer, styles.formField)}
            >
              <Controller
                name="maintainer"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, name } }) => (
                  <SelectMemberContainer
                    id="maintainerId"
                    name={name}
                    value={value}
                    onChange={(member: Member | null | undefined) => onChange(member ? member._id : null)}
                    placeholder="Select the maintainer for this metric group"
                    ariaLabel="Select the maintainer for this metric group"
                  />
                )}
              />
            </FormField>
            <>
              <KindButtonGroup title="Metric group">
                <KindButton
                  kind="standard"
                  selectedKind={kind}
                  setKind={(k) => setValue('kind', k)}
                  ariaLabel="Make this a standard metric group"
                  tooltip={getTooltip('standard')}
                  disabled={metricGroupType === 'funnel'}
                >
                  Standard
                </KindButton>
                <KindButton
                  kind="funnel"
                  icon="filter-list"
                  selectedKind={kind}
                  setKind={(k) => setValue('kind', k)}
                  ariaLabel="Make this a funnel metric group"
                  tooltip={getTooltip('funnel')}
                  disabled={metricGroupType === 'standard'}
                >
                  Funnel
                </KindButton>
              </KindButtonGroup>
              <KindButtonGroupContent>
                <div className={styles.kindContent}>
                  <div className={styles.kindDescription}>
                    {kind === 'funnel' ? <FunnelGroupDescription /> : <MetricGroupDescription />}
                  </div>
                  <p className={styles.metricCount}>
                    <strong>{steps.length}</strong> metrics added
                  </p>
                </div>
                <CreateMetricGroupSortableContainer supportedRandomizationUnits={supportedRandomizationUnits} />
              </KindButtonGroupContent>
            </>
            <div className={styles.buttons}>
              <Button type="button" kind="default" onClick={onCancelWithPrompt} disabled={mutation.isPending}>
                Cancel
              </Button>
              <Tooltip>
                <Button
                  type="submit"
                  kind="primary"
                  isLoading={mutation.isPending}
                  disabled={
                    Object.keys(errors).length > 0 || Object.keys(dirtyFields).length === 0 || kind === 'funnel'
                      ? steps.length > funnelGroupMetricLimit()
                      : steps.length > standardMetricGroupMetricLimit()
                  }
                >
                  Create metric group
                </Button>
                {kind === 'funnel' && steps.length > funnelGroupMetricLimit() ? (
                  <span>The limit of {funnelGroupMetricLimit()} metrics per funnel group has been reached</span>
                ) : null}
                {kind === 'standard' && steps.length > standardMetricGroupMetricLimit() ? (
                  <span>The limit of {standardMetricGroupMetricLimit()} metrics per metric group has been reached</span>
                ) : null}
              </Tooltip>
            </div>
          </Form>
        </FormProvider>
      </ModalBody>
    </ModalWithCancelPrompt>
  );
}

function MetricGroupDescription() {
  return <p className="u-mb-l">Re-usable metric groups with two or more metrics.</p>;
}

function FunnelGroupDescription() {
  return (
    <p className="u-mb-l">
      Match the step order within a funnel group to the order of the actions your users take within your funnel.
    </p>
  );
}
