import { useReducer } from 'react';
import { useMediaQuery, useMediaQueryListener } from '@gonfalon/dom';
import { useHotkey } from '@gonfalon/react';

import { createStateChart } from './createStateChart';
import { KeyboardShortcut } from './keyboardShortcuts';

const reducer = createStateChart({
  open: {
    transitions: {
      toggle: 'prefer-closed',
      minimized: 'closed',
    },
  },
  closed: {
    transitions: {
      toggle: 'prefer-open',
      maximized: 'open',
    },
  },
  'prefer-open': {
    transitions: {
      toggle: 'prefer-closed',
      minimized: 'prefer-small-open',
    },
  },
  'prefer-closed': {
    transitions: {
      toggle: 'prefer-open',
    },
  },
  'prefer-small-open': {
    transitions: {
      toggle: 'prefer-small-closed',
      maximized: 'prefer-open',
    },
  },
  'prefer-small-closed': {
    transitions: {
      toggle: 'prefer-small-open',
      maximized: 'prefer-closed',
    },
  },
});

export function useSidebarState({
  breakpoint,
  toggleShortcut,
}: {
  breakpoint: number;
  toggleShortcut: KeyboardShortcut;
}) {
  const mediaQuery = `(max-width: ${breakpoint}px)`;
  const isSmall = useMediaQuery(mediaQuery);

  const [visibility, send] = useReducer(reducer, isSmall ? 'closed' : 'open');
  const isVisible = visibility === 'open' || visibility === 'prefer-small-open' || visibility === 'prefer-open';

  useMediaQueryListener(mediaQuery, (matches) => send(matches ? 'minimized' : 'maximized'));

  useHotkey(toggleShortcut, () => send('toggle'));

  function toggle() {
    send('toggle');
  }

  return { isVisible, toggle } as const;
}
