// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState } from 'react';
import type { Key, Selection } from 'react-aria-components';
import { GridList, GridListItem } from 'react-aria-components';

export type CardSelectValue = {
  value: string;
  name: string;
  description?: string;
  subtitle?: string;
  tags?: string[];
  isDisabled?: boolean;
};

type CardSelectProps = {
  items: CardSelectValue[];
  ariaLabel: string;
  defaultValue?: string;
  disallowEmptySelection?: boolean;
  onSelect?: (value: string) => void;
};

import { Tag, TagGroup, TagList } from '@launchpad-ui/components';

import styles from './CardSelect.module.css';

export function CardSelect({ items, ariaLabel, defaultValue, disallowEmptySelection, onSelect }: CardSelectProps) {
  const [selectedKeys, setSelectedKeys] = useState<Set<Key>>(new Set(defaultValue ? [defaultValue] : []));
  const handleSelect = (s: Selection) => {
    if (s === 'all') {
      return;
    }
    setSelectedKeys(s);
    const element = [...s][0];
    onSelect && onSelect(element.toString() ?? '');
  };
  return (
    <GridList
      aria-label={ariaLabel}
      className={styles.selectContainer}
      selectionMode="single"
      onSelectionChange={handleSelect}
      selectedKeys={selectedKeys}
      disallowEmptySelection={disallowEmptySelection}
      layout="grid"
    >
      {items.map((item) => (
        <GridListItem
          data-selected={selectedKeys.has(item.value)}
          aria-selected={selectedKeys.has(item.value)}
          id={item.value}
          textValue={item.name}
          key={`${item.name}`}
          className={styles.optionCard}
          isDisabled={item.isDisabled}
        >
          <p className={styles.optionTitle}>{item.name}</p>
          <p>{item.description}</p>
          <p className={styles.optionSubtitle}>{item.subtitle}</p>
          {item.tags && item.tags.length > 0 && (
            <TagGroup className={styles.tags} aria-label={`Card select tags for ${ariaLabel}`}>
              <TagList>
                {item.tags.map((tag) => (
                  <Tag size="small" key="GridListItem-{tag}">
                    {tag}
                  </Tag>
                ))}
              </TagList>
            </TagGroup>
          )}
        </GridListItem>
      ))}
    </GridList>
  );
}
