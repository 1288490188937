import {
  FlagsPayload,
  MethodologyPayload,
  START_ITERATION,
  StartIteration,
  STOP_ITERATION,
  StopIteration,
  TreatmentsPayload,
  UPDATE_DESCRIPTION,
  UPDATE_METHODOLOGY,
  UPDATE_NAME,
} from '../../../../api/api.types';
import { ExperimentStatus, RUNNING } from '../../../../types';
import {
  ExperimentUpdateBody,
  UPDATE_ATTRIBUTES,
  UPDATE_EDIT_REASON,
  UPDATE_HYPOTHESIS,
  UPDATE_METRICS,
  UPDATE_RANDOMIZATION_UNIT,
  UPDATE_RULE_ID,
  UPDATE_TRAFFIC_RESHUFFLE,
  UPDATE_TREATMENTS,
  UpdateField,
} from '../../types';

import { Dispatcher, UPDATE_INSTRUCTION } from './types';

/**
 * Util function dispatches an action to update the experiment's iteration status to running.
 *
 * @param dispatch - the dispatch function
 *
 */
function startExperiment(changeJustification: string, dispatch: Dispatcher) {
  dispatch({
    type: UPDATE_INSTRUCTION,
    payload: {
      kind: START_ITERATION,
      changeJustification,
    },
  });
}

/**
 *  Util function dispatches an action to update the experiment's iteration status to stopped.
 *
 * @param winningTreatmentId - the winning treatment id
 * @param winningReason - the reason for stopping the experiment and why the passed treatment is the winner
 * @param dispatch - the dispatch function
 */
function stopExperiment(winningTreatmentId: string, winningReason: string, dispatch: Dispatcher) {
  dispatch({
    type: UPDATE_INSTRUCTION,
    payload: {
      kind: STOP_ITERATION,
      winningTreatmentId,
      winningReason,
    },
  });
}

/**
 * Function dispatches an action to update an experiment's status.
 *
 * @param param.status - the current status of the experiment
 * @param param.reason - the reason for stopping the experiment
 * @param param.treatmentId - the winning treatment id
 * @param param.dispatch - the dispatch function
 */
export function updateExperimentStatus({
  status,
  reason,
  treatmentId,
  dispatch,
}: {
  status: ExperimentStatus;
  reason?: string;
  treatmentId?: string;
  dispatch: Dispatcher;
}) {
  if (status === RUNNING) {
    if (!reason) {
      throw new Error('reason is required to stop an iteration');
    }

    return stopExperiment(treatmentId ?? '', reason, dispatch);
  }

  return startExperiment(reason ?? '', dispatch);
}

/**
 * Function dispatches an action to update an experiment field.
 *
 * @param key - the key of the field to update
 * @param value - the value of the field to update
 * @param dispatch - the dispatch function
 */
export function updateField(key: UpdateField, value: unknown, dispatch: Dispatcher) {
  if (key === START_ITERATION) {
    const { changeJustification } = value as StartIteration;
    return startExperiment(changeJustification, dispatch);
  }

  if (key === STOP_ITERATION) {
    const { winningReason, winningTreatmentId } = value as StopIteration;

    return stopExperiment(winningTreatmentId, winningReason, dispatch);
  }

  if (key === UPDATE_NAME) {
    const name = value as string;

    return dispatch({
      type: UPDATE_INSTRUCTION,
      payload: {
        kind: UPDATE_NAME,
        value: name,
      },
    });
  }

  if (key === UPDATE_DESCRIPTION) {
    const description = value as string;

    return dispatch({
      type: UPDATE_INSTRUCTION,
      payload: {
        kind: UPDATE_DESCRIPTION,
        value: description,
      },
    });
  }

  if (key === UPDATE_HYPOTHESIS) {
    const hypothesis = value as string;

    return dispatch({
      type: UPDATE_HYPOTHESIS,
      payload: {
        hypothesis,
      },
    });
  }

  if (key === UPDATE_METRICS) {
    return dispatch({
      type: UPDATE_METRICS,
      payload: value as ExperimentUpdateBody,
    });
  }

  if (key === UPDATE_ATTRIBUTES) {
    const attributes = value as string[];
    return dispatch({
      type: UPDATE_ATTRIBUTES,
      payload: { attributes },
    });
  }

  if (key === UPDATE_TREATMENTS) {
    const treatments = value as TreatmentsPayload;
    return dispatch({
      type: UPDATE_TREATMENTS,
      payload: { treatments },
    });
  }

  if (key === UPDATE_TRAFFIC_RESHUFFLE) {
    const canReshuffleTraffic = value as boolean;
    return dispatch({
      type: UPDATE_TRAFFIC_RESHUFFLE,
      payload: { canReshuffleTraffic },
    });
  }

  if (key === UPDATE_RULE_ID) {
    const flags = value as FlagsPayload;
    return dispatch({
      type: UPDATE_RULE_ID,
      payload: { flags },
    });
  }

  if (key === UPDATE_EDIT_REASON) {
    const winningReason = value as string;
    return dispatch({
      type: UPDATE_EDIT_REASON,
      payload: { winningReason },
    });
  }

  if (key === UPDATE_RANDOMIZATION_UNIT) {
    const randomizationUnit = value as string;
    return dispatch({
      type: UPDATE_RANDOMIZATION_UNIT,
      payload: { randomizationUnit },
    });
  }

  if (key === UPDATE_METHODOLOGY) {
    const methodology = (value as MethodologyPayload).methodology;
    const analysisConfig = (value as MethodologyPayload).analysisConfig;
    return dispatch({
      type: UPDATE_INSTRUCTION,
      payload: {
        kind: UPDATE_METHODOLOGY,
        methodology,
        analysisConfig,
      },
    });
  }
}
