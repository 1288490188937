import { pluralize } from '@gonfalon/strings';
import { List } from 'immutable';

import { Target } from 'utils/flagUtils';

import styles from './styles/ConvertToSegmentSummary.module.css';

export type ConvertToSegmentSummaryProps = {
  variationLabel?: string | JSX.Element;
  allTargets?: List<Target>;
};
const ConvertToSegmentSummary = ({ variationLabel, allTargets }: ConvertToSegmentSummaryProps) => (
  <ul className={styles.convertToSegmentSummary}>
    {allTargets?.map((target) => (
      <li key={target.contextKind} className={styles.ruleSummary}>
        Remove {target.values.size} {target.contextKind} {pluralize('target', target.values.size)} from{' '}
        <span>{variationLabel}</span>
      </li>
    ))}
    <li className={styles.ruleSummary}>
      Create a new rule targeting this segment to <span>{variationLabel}</span>
    </li>
  </ul>
);

/* eslint-disable import/no-default-export */
export default ConvertToSegmentSummary;
