import type { CollapsibleTriggerProps } from 'launchpad';
import { Button } from 'launchpad';

export const AlertCollapsibleTrigger = (props: CollapsibleTriggerProps) => {
  const { isOpen, toggleOpen, icon, triggerProps } = props;

  return (
    <div className="u-mt-s">
      <Button
        icon={icon}
        renderIconFirst
        kind="minimal"
        onClick={toggleOpen}
        data-test-id="collapsible-trigger"
        {...triggerProps}
      >
        Show {isOpen ? 'less' : 'more'}
      </Button>
    </div>
  );
};
