import { useMemo } from 'react';
import { useMatches } from 'react-router-dom';
import { z } from 'zod';

const actionSchema = z.object({
  Action: z.function().returns(z.any()),
});

/**
 * Render an action for the current route.
 *
 * A route defines its action via its `handle`.
 */
export function Action() {
  const matches = useMatches();

  const RouteAction = useMemo(() => {
    // Extract view modes from the deepest route first, since
    // view modes may be siblings in the route tree, and we
    // want to ensure active links are highlighted correctly.
    const reversedMatches = [...matches].reverse();

    for (const match of reversedMatches) {
      const parse = actionSchema.safeParse(match.handle);
      if (parse.success) {
        return parse.data.Action;
      }
    }
    return undefined;
  }, [matches]);

  if (RouteAction === undefined) {
    return null;
  }

  return (
    <div>
      <RouteAction />
    </div>
  );
}
