import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useDispatch, useSelector } from 'react-redux';
import { useProjectKey } from '@gonfalon/router';

import { fetchContextKindsIfNeeded as fetchContextKindsAction } from 'actions/contextKinds';
// import { fetchContextsList } from 'actions/contexts';
import { contextKindsRequestSelector, contextKindsSelector } from 'reducers/contextKinds';
// import { contextsSelector } from 'reducers/contexts';
import { ready } from 'utils/reduxUtils';

import ContextTargeting, { ContextTargetingProps } from './ContextTargeting';

export const useRedux = (projKey: string) => {
  const dispatch = useDispatch();
  const fetchContextKinds = () => dispatch(fetchContextKindsAction(projKey));
  const contextKinds = useSelector(contextKindsSelector);
  const contextKindsRequest = useSelector(contextKindsRequestSelector);

  return {
    fetchContextKinds,
    contextKinds,
    isContextKindsReady: ready(contextKindsRequest),
  };
};

export type ContextTargetingContainerProps = Omit<ContextTargetingProps, 'contextKinds'>;

/* eslint-disable import/no-default-export */
export default function ContextTargetingContainer({ ...finalProps }: ContextTargetingContainerProps) {
  const projKey = useProjectKey();
  const { fetchContextKinds, contextKinds, isContextKindsReady } = useRedux(projKey);

  useEffect(() => {
    fetchContextKinds();
  }, []);

  return <ContextTargeting contextKinds={contextKinds} isContextKindsReady={isContextKindsReady} {...finalProps} />;
}
