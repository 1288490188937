import { createContext, useContext } from 'react';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { useParam, useProjectKey } from '@gonfalon/router';

import { useArchiveExperiment } from '../../hooks/useArchiveExperiment';
import { useExperiment } from '../../hooks/useExperiment';
import { useFlag } from '../../hooks/useFlag';
import { useRestoreExperiment } from '../../hooks/useRestoreExperiment';
import {
  attachTreatmentColors,
  getFlagConfigVersion,
  getFlagKeysForIteration,
  getVisibleIteration,
} from '../../utils/experiment';

import { useUpdateExperiment } from './hooks/useUpdateExperiment/index';
import { Context } from './types';

export const ExperimentIterationContext = createContext<Context | undefined>(undefined);

export const ExperimentProvider = ({ children }: { children: React.ReactNode }) => {
  const envKey = useSelectedEnvironmentKey();
  const projKey = useProjectKey();
  const experimentKey = useParam('experimentKey');

  const {
    data: experiment,
    refetch: refetchExperiment,
    status: experimentStatus,
    fetchStatus: experimentFetchStatus,
    error: experimentError,
  } = useExperiment({
    environmentKey: envKey,
    projectKey: projKey,
    experimentKey,
    expand: ['treatments', 'secondaryMetrics', 'metrics', 'draftIteration', 'previousIterations', 'analysisConfig'],
  });

  const archiveMutation = useArchiveExperiment({ projKey, envKey, experimentKey });
  const restoreMutation = useRestoreExperiment({ projKey, envKey, experimentKey });

  const [flagKey] = experiment ? getFlagKeysForIteration(getVisibleIteration(experiment)) : [];
  const flagConfigVersion = experiment ? getFlagConfigVersion(getVisibleIteration(experiment), flagKey) : undefined;
  const { data: flag, error: flagError } = useFlag({
    environmentKey: envKey,
    projectKey: projKey,
    flagKey,
    flagConfigVersion,
  });

  const { updateExperimentStatus, updateField, saveExperimentChanges, reset } = useUpdateExperiment();

  return (
    <ExperimentIterationContext.Provider
      value={{
        experiment: attachTreatmentColors(experiment, flag),
        flag,
        status: experimentStatus,
        fetchStatus: experimentFetchStatus,
        error: experimentError || flagError,
        archive: archiveMutation.mutate,
        restore: restoreMutation.mutate,
        updateField,
        resetUpdateState: reset,
        refetchExperiment,
        saveUpdatedChanges: async () => {
          if (!experiment) {
            return;
          }

          const iteration = getVisibleIteration(experiment);
          // update flag variation names if needed [see SC-161941]
          const treatments = iteration.treatments.map((treatment) => ({
            ...treatment,
            name:
              flag?.variations.find(
                ({ _id }) => _id === (treatment?.parameters && treatment.parameters[0]?.variationId),
              )?.name ?? treatment.name,
          }));
          if (treatments.some((treatment, idx) => treatment.name !== iteration.treatments[idx].name)) {
            iteration.treatments = treatments;
          }

          await saveExperimentChanges(iteration);

          return refetchExperiment();
        },
        updateIterationStatus: async (treatmentId?: string, reason?: string) => {
          if (!experiment) {
            return;
          }

          const { currentIteration } = experiment;
          updateExperimentStatus(currentIteration.status, treatmentId, reason);

          await saveExperimentChanges(getVisibleIteration(experiment));
          return refetchExperiment();
        },
      }}
    >
      {children}
    </ExperimentIterationContext.Provider>
  );
};

export function useExperimentProvider(props?: { optional: false }): Context;
export function useExperimentProvider(props: { optional: true }): Context | undefined;
export function useExperimentProvider(props?: { optional: boolean }) {
  const context = useContext(ExperimentIterationContext);

  if (!context) {
    if (props?.optional) {
      return undefined;
    }
    throw new Error('useExperimentProvider must be used within a ExperimentProvider');
  }

  return context;
}
