import { type SegmentSemanticInstruction, UpdateRuleDescriptionInstruction } from '@gonfalon/openapi';

export function makeUpdatedInstructions(
  instructionKind: SegmentSemanticInstruction['kind'],
  originalInstructions: SegmentSemanticInstruction[],
  updatedInstruction?: SegmentSemanticInstruction,
): SegmentSemanticInstruction[] {
  if (!updatedInstruction) {
    return originalInstructions;
  }

  let updatedInstructions = originalInstructions;
  const insertionIdx = originalInstructions.findIndex((instruction) => {
    if (['addExpiringTarget', 'updateExpiringTarget', 'removeExpiringTarget'].includes(instruction.kind)) {
      return (
        'contextKind' in updatedInstruction &&
        'contextKind' in instruction &&
        updatedInstruction.contextKind === instruction.contextKind &&
        'value' in updatedInstruction &&
        'value' in instruction &&
        updatedInstruction.value === instruction.value
      );
    }

    if (updatedInstruction.kind === 'updateRuleDescription') {
      return (instruction as UpdateRuleDescriptionInstruction).ruleId === updatedInstruction.ruleId;
    }

    // allow for multiple instructions of the same kind as long as they're modifying different rules
    if ('ruleId' in instruction && 'ruleId' in updatedInstruction) {
      return instruction.kind === instructionKind && instruction.ruleId === updatedInstruction.ruleId;
    }

    return instruction.kind === instructionKind;
  });

  if ('values' in updatedInstruction && updatedInstruction.values.length === 0) {
    // remove instruction if there are no values to update
    updatedInstructions.splice(insertionIdx, 1);
  } else {
    updatedInstructions =
      insertionIdx !== -1
        ? originalInstructions.toSpliced(insertionIdx, 1, updatedInstruction)
        : originalInstructions.concat([updatedInstruction]);
  }

  if (instructionKind === 'updateClause' && updatedInstruction.kind === 'updateClause') {
    // for a given rule, an updateClause instruction supersedes addValuesToClause and removeValuesFromClause instructions
    // remove the addValuesToClause and removeValuesFromClause for that rule and retain all other instructions
    updatedInstructions = updatedInstructions.filter((instruction) => {
      if (instruction.kind === 'addValuesToClause' || instruction.kind === 'removeValuesFromClause') {
        return instruction.ruleId !== updatedInstruction.ruleId;
      } else {
        return true;
      }
    });
  }

  return updatedInstructions;
}
