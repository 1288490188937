import { connect } from 'react-redux';
import { isBulkUserManagementEnabled, isTargetingToSegmentEnabled } from '@gonfalon/dogfood-flags';

import ContextTargetingActionMenu from './ContextTargetingActionMenu';

const mapStateToProps = () => ({
  isBulkUserManagementEnabled: isBulkUserManagementEnabled(),
  isTargetingToSegmentEnabled: isTargetingToSegmentEnabled(),
});

/* eslint-disable import/no-default-export */
export default connect(mapStateToProps)(ContextTargetingActionMenu);
