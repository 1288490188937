import classNames from 'clsx';

import { useScrollToEvaluationReason } from 'hooks/useScrollToEvaluationReason';

import 'stylesheets/components/Rule.css';

export type RuleProps = {
  className?: string;
  conditions: JSX.Element;
  targeting?: JSX.Element;
};

export function Rule({ conditions, targeting, className }: RuleProps) {
  useScrollToEvaluationReason();
  return (
    <div className={classNames('Rule', className)}>
      <div className="Rule-conditions">{conditions}</div>
      <div className="Rule-rollout">{targeting}</div>
    </div>
  );
}
