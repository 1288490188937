import UpsellImageBulkFeatures from 'components/upsells/upsellImages/UpsellImageBulkFeatures';
import { UpsellModal } from 'components/upsells/UpsellModal';
import { getDocumentationUrl } from 'utils/urlUtils';

const bulkEditingUsersDocLink = getDocumentationUrl('home/flags/bulk-targeting');
const exportingUsersDocLink = getDocumentationUrl('home/flags/bulk-targeting#export-a-list-of-contexts-to-csv');

type UpsellFeatureModalBulkUserManagementProps = {
  desiredFeature: 'edit' | 'export';
  isOpen: boolean;
  onCancel(): void;
};

const UpsellFeatureModalBulkUserManagement = ({
  desiredFeature,
  isOpen,
  onCancel,
}: UpsellFeatureModalBulkUserManagementProps) => {
  const learnMoreUrl = desiredFeature === 'edit' ? bulkEditingUsersDocLink : exportingUsersDocLink;
  return (
    <UpsellModal
      featureKind="Bulk user management"
      isEnterpriseOnlyFeature
      isOpen={isOpen}
      onCancel={onCancel}
      title="Do more with bulk features"
      featureImage={<UpsellImageBulkFeatures />}
      componentForAnalyticTracking="Bulk Users"
      featureBullets={['Easily add, remove, or replace individual targets', 'Download a CSV of individual targets']}
      learnMoreUrl={learnMoreUrl}
    />
  );
};

/* eslint-disable import/no-default-export */
export default UpsellFeatureModalBulkUserManagement;
