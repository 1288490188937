import { Alert } from 'launchpad';

import { CollapsibleInstructionList } from 'components/InstructionList/CollapsibleInstructionList';
import { Flag } from 'utils/flagUtils';
import {
  FlagConflictKind,
  InstructionIndexToConflictsInfo,
  SemanticInstruction,
} from 'utils/instructions/shared/types';

import { filterInstructionsForConflict } from './instructionListUtils';

export type InstructionListConflictsProps = {
  instructions: SemanticInstruction[];
  flag: Flag;
  projKey: string;
  envKey: string;
  instructionIndexToConflictsInfo: InstructionIndexToConflictsInfo;
  expandTargetList?: boolean;
  onExit?: () => void;
};

export const InstructionListConflicts = ({
  instructions,
  flag,
  projKey,
  envKey,
  instructionIndexToConflictsInfo,
  expandTargetList,
  onExit,
}: InstructionListConflictsProps) => {
  const conflictKindToHeaderText = {
    [FlagConflictKind.PENDING_CHANGES_WILL_FAIL]: 'These instructions will cause a pending change to fail.',
    [FlagConflictKind.PROPOSED_SCHEDULED_CHANGES_WILL_FAIL]:
      'These instructions will fail due to a conflict with a pending change.',
    [FlagConflictKind.PROPOSED_APPROVED_CHANGES_WILL_FAIL]:
      'These instructions will fail and prevent the change request from being applied',
  };

  return (
    <Alert kind="warning" className="Alert">
      {Object.keys(conflictKindToHeaderText).map((conflictKind, i) => {
        const filteredInstructions = filterInstructionsForConflict({
          instructions,
          instructionIndexToConflictsInfo,
          conflictKind: conflictKind as FlagConflictKind,
        });
        const hasConflictsForKind = filteredInstructions.some((ins) => ins !== undefined);
        return hasConflictsForKind ? (
          <div key={i}>
            <h4 className="u-mb-s u-fw-regular">{conflictKindToHeaderText[conflictKind as FlagConflictKind]}</h4>
            <CollapsibleInstructionList
              instructions={filteredInstructions}
              flag={flag}
              projKey={projKey}
              envKey={envKey}
              expandTargetList={expandTargetList}
              shouldShowConflictLinks={conflictKind !== FlagConflictKind.PROPOSED_APPROVED_CHANGES_WILL_FAIL}
              onExit={onExit}
            />
          </div>
        ) : null;
      })}
    </Alert>
  );
};
