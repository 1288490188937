import { Fragment } from 'react';
import { Metric } from '@gonfalon/metrics';

import { MeasureType } from '../common';
import { RandomizationUnitOption } from '../CreateMetricModal/formUtils';

import { CodeSpan } from './CodeSpan';
import { MetricRandomizationUnitsV2 } from './MetricRandomizationUnitsV2';

interface RandomizationUnitsSectionProps {
  measureType: MeasureType;
  metricKind: Metric['kind'];
  unitAggregationType: Metric['unitAggregationType'];
  onSelect?: (selection: string[], options: RandomizationUnitOption[]) => void;
  initialValues?: string[];
  readonly?: boolean;
  readonlyValues?: string[];
}

export const RandomizationUnitsSection: React.FC<RandomizationUnitsSectionProps> = ({
  measureType,
  metricKind,
  unitAggregationType,
  readonlyValues,
  onSelect,
  initialValues,
  readonly = false,
}: RandomizationUnitsSectionProps) => {
  const randomizationUnits = readonly ? (
    <>
      {readonlyValues?.map((rov, index) => (
        <Fragment key={rov}>
          <CodeSpan textValue={rov} />
          {index === readonlyValues.length - 1 ? '' : ','}
        </Fragment>
      ))}
    </>
  ) : (
    <MetricRandomizationUnitsV2 initialValues={initialValues} onSelect={onSelect} />
  );

  return (
    <>
      {getFirstCopySection(measureType, metricKind)}
      {randomizationUnits}
      {getSecondCopySection(measureType, metricKind, unitAggregationType)}
    </>
  );
};

const getFirstCopySection = (selectedMeasureType: MeasureType, metricKind: Metric['kind']) => {
  if (selectedMeasureType === 'occurrence') {
    return <span>Percentage of</span>;
  }
  switch (metricKind) {
    case 'custom':
      if (selectedMeasureType === 'value') {
        return (
          <span>
            <CodeSpan textValue="value" /> of the per
          </span>
        );
      } else if (selectedMeasureType === 'count') {
        return (
          <span>
            of event <CodeSpan textValue="count" /> per
          </span>
        );
      }
      break;
    case 'click':
      return <span>clicks per</span>;
    case 'pageview':
      return <span>page views per</span>;
    default:
      return null;
  }
};

const getSecondCopySection = (
  selectedMeasureType: MeasureType,
  metricKind: Metric['kind'],
  unitAggregationType: Metric['unitAggregationType'],
) => {
  if (selectedMeasureType === 'count') {
    return <span>, where </span>;
  }
  switch (metricKind) {
    case 'custom':
      if (selectedMeasureType === 'value') {
        return (
          <span>
            {' '}
            event <CodeSpan textValue={unitAggregationType?.toString() ?? ''} />, where
          </span>
        );
      } else if (selectedMeasureType === 'occurrence') {
        return <span> units that sent the event, where </span>;
      }
      break;
    case 'click':
      return <span> units that clicked, where </span>;
    case 'pageview':
      return <span> units that saw the page, where </span>;
    default:
      break;
  }
};
