import { RefObject, useState } from 'react';
import { RefCallBack } from 'react-hook-form';
import { formatJSON } from '@gonfalon/strings';

import { CodeEditor } from 'components/CodeEditor';
import { CodeEditorProps } from 'components/ui/codeEditor/CodeEditor';

export type CodeTextEditorProps = Omit<CodeEditorProps, 'value' | 'onChange'> & {
  value: string;
  onChange: (value: string) => void;
  innerRef?: RefObject<$TSFixMe> | RefCallBack;
  mode?: 'json' | 'markdown';
};

export const CodeTextEditor = (props: CodeTextEditorProps) => {
  const { value, onChange, innerRef, mode = 'json', ...rest } = props;
  const [formattedValue, setFormattedValue] = useState(formatJSON(value));

  const handleChange = (v: string) => {
    setFormattedValue(v);
    onChange(v);
  };

  const keyMap: CodeMirror.KeyMap = {
    Tab: false,
  };

  return (
    <CodeEditor {...rest} mode={mode} ref={innerRef} value={formattedValue} onChange={handleChange} keyMap={keyMap} />
  );
};
