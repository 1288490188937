import { memo, ReactElement, useEffect, useReducer } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useSelector } from 'react-redux';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { enableFollowFlagsByTeam, isApprovalByTeamEnabled, memberFilterPageSize } from '@gonfalon/dogfood-flags';
import { noop } from '@gonfalon/es6-utils';
import { OptionProps } from '@gonfalon/launchpad-experimental';
import { useFlag, useSegment } from '@gonfalon/rest-api';
import { useParam, useProjectKey } from '@gonfalon/router';
import { List, OrderedMap, Set } from 'immutable';
import nullthrows from 'nullthrows';

import { GlobalState } from 'reducers';
import { profileSelector } from 'reducers/profile';
import { getMember, getMembers } from 'sources/AccountAPI';
import { getTeam, getTeams } from 'sources/TeamsAPI';
import { makeResourceSpec } from 'utils/accessUtils';
import { Member } from 'utils/accountUtils';
import { ApprovalRequestResourceKind } from 'utils/approvalsUtils';
import { createMemberFilters } from 'utils/memberUtils';
import { createTeamsFiltersFromQuery, Team } from 'utils/teamsUtils';

import SelectReviewers from './SelectReviewers';

export type MemberOptionType = OptionProps & {
  email: string;
  name?: string;
  isAdminOrOwner?: boolean;
  isCurrentMember?: boolean;
};

function isReviewerType(t: unknown): t is Member {
  return t instanceof Member || t instanceof Team;
}
export function isTeam(t: unknown): t is Team {
  return t instanceof Team;
}

const actions = {
  loadSelectedMembers: () => ({ type: 'loadSelectedMembers' }) as const,
  loadSelectedTeams: () => ({ type: 'loadSelectedTeams' }) as const,
  saveSelectedMembers: (members: Member[]) => ({ type: 'saveSelectedMembers', members }) as const,
  saveSelectedTeams: (teams: Team[]) => ({ type: 'saveSelectedTeams', teams }) as const,
  search: (searchValue: string) => ({ type: 'search', searchValue }) as const,
  fetchReviewers: () => ({ type: 'fetchReviewers' }) as const,
  fetchMembers: () => ({ type: 'fetchMembers' }) as const,
  fetchTeams: () => ({ type: 'fetchTeams' }) as const,
  fetchReviewersDone: (reviewers: OrderedMap<string, Member>) => ({ type: 'fetchReviewersDone', reviewers }) as const,
  fetchMembersDone: (members: OrderedMap<string, Member>) => ({ type: 'fetchMembersDone', members }) as const,
  fetchTeamsDone: (teams: OrderedMap<string, Team>) => ({ type: 'fetchTeamsDone', teams }) as const,
  fetchReviewersFailed: () => ({ type: 'fetchReviewersFailed' }) as const,
  fetchMembersFailed: () => ({ type: 'fetchMembersFailed' }) as const,
  fetchTeamsFailed: () => ({ type: 'fetchTeamsFailed' }) as const,
};

function reducer(
  state: {
    isSearchingReviewers: boolean;
    isSearchingMembers: boolean;
    isSearchingTeams: boolean;
    searchValue: string;
    reviewers: OrderedMap<string, Member>;
    members: OrderedMap<string, Member>;
    teams: OrderedMap<string, Team>;
    selectedMembers: Map<string, Member>;
    selectedTeams: Map<string, Team>;
  },
  action: ReturnType<(typeof actions)[keyof typeof actions]>,
) {
  switch (action.type) {
    case 'search':
      return {
        isSearchingReviewers: false,
        isSearchingMembers: false,
        isSearchingTeams: false,
        searchValue: action.searchValue,
        reviewers: state.reviewers,
        members: state.members,
        teams: state.teams,
        selectedMembers: state.selectedMembers,
        selectedTeams: state.selectedTeams,
      };
    case 'fetchReviewers':
      return {
        isSearchingMembers: state.isSearchingMembers,
        isSearchingReviewers: true,
        isSearchingTeams: state.isSearchingTeams,
        searchValue: state.searchValue,
        reviewers: state.reviewers,
        members: state.members,
        teams: state.teams,
        selectedMembers: state.selectedMembers,
        selectedTeams: state.selectedTeams,
      };
    case 'fetchMembers':
      return {
        isSearchingMembers: true,
        isSearchingReviewers: state.isSearchingReviewers,
        isSearchingTeams: state.isSearchingTeams,
        searchValue: state.searchValue,
        reviewers: state.reviewers,
        members: state.members,
        teams: state.teams,
        selectedMembers: state.selectedMembers,
        selectedTeams: state.selectedTeams,
      };
    case 'fetchTeams':
      return {
        isSearchingMembers: state.isSearchingMembers,
        isSearchingReviewers: state.isSearchingReviewers,
        isSearchingTeams: true,
        searchValue: state.searchValue,
        reviewers: state.reviewers,
        members: state.members,
        teams: state.teams,
        selectedMembers: state.selectedMembers,
        selectedTeams: state.selectedTeams,
      };
    case 'fetchReviewersDone':
      return {
        isSearchingMembers: state.isSearchingMembers,
        isSearchingReviewers: false,
        isSearchingTeams: state.isSearchingTeams,
        searchValue: state.searchValue,
        reviewers: action.reviewers,
        members: state.members,
        teams: state.teams,
        selectedMembers: state.selectedMembers,
        selectedTeams: state.selectedTeams,
      };
    case 'fetchMembersDone':
      return {
        isSearchingMembers: false,
        isSearchingReviewers: state.isSearchingReviewers,
        isSearchingTeams: state.isSearchingTeams,
        searchValue: state.searchValue,
        reviewers: state.reviewers,
        members: action.members,
        teams: state.teams,
        selectedMembers: state.selectedMembers,
        selectedTeams: state.selectedTeams,
      };
    case 'fetchTeamsDone':
      return {
        isSearchingMembers: state.isSearchingMembers,
        isSearchingReviewers: state.isSearchingReviewers,
        isSearchingTeams: false,
        searchValue: state.searchValue,
        reviewers: state.reviewers,
        members: state.members,
        teams: action.teams,
        selectedMembers: state.selectedMembers,
        selectedTeams: state.selectedTeams,
      };
    case 'fetchReviewersFailed':
      return {
        isSearchingMembers: state.isSearchingMembers,
        isSearchingReviewers: false,
        isSearchingTeams: state.isSearchingTeams,
        searchValue: state.searchValue,
        reviewers: state.reviewers,
        members: state.members,
        teams: state.teams,
        selectedMembers: state.selectedMembers,
        selectedTeams: state.selectedTeams,
      };
    case 'fetchMembersFailed':
      return {
        isSearchingMembers: false,
        isSearchingReviewers: state.isSearchingReviewers,
        isSearchingTeams: state.isSearchingTeams,
        searchValue: state.searchValue,
        reviewers: state.reviewers,
        members: state.members,
        teams: state.teams,
        selectedMembers: state.selectedMembers,
        selectedTeams: state.selectedTeams,
      };
    case 'fetchTeamsFailed':
      return {
        isSearchingMembers: state.isSearchingMembers,
        isSearchingReviewers: state.isSearchingReviewers,
        isSearchingTeams: false,
        searchValue: state.searchValue,
        reviewers: state.reviewers,
        members: state.members,
        teams: state.teams,
        selectedMembers: state.selectedMembers,
        selectedTeams: state.selectedTeams,
      };
    case 'saveSelectedMembers':
      const updatedCache = state.selectedMembers;

      for (const member of action.members) {
        updatedCache.set(member._id, member);
      }

      return {
        isSearchingMembers: state.isSearchingMembers,
        isSearchingReviewers: state.isSearchingReviewers,
        isSearchingTeams: state.isSearchingTeams,
        searchValue: state.searchValue,
        reviewers: state.reviewers,
        members: state.members,
        teams: state.teams,
        selectedMembers: updatedCache,
        selectedTeams: state.selectedTeams,
      };
    case 'saveSelectedTeams':
      const updatedTeamCache = state.selectedTeams;

      for (const team of action.teams) {
        updatedTeamCache.set(team.key, team);
      }

      return {
        isSearchingMembers: state.isSearchingMembers,
        isSearchingReviewers: state.isSearchingReviewers,
        isSearchingTeams: state.isSearchingTeams,
        searchValue: state.searchValue,
        reviewers: state.reviewers,
        members: state.members,
        teams: state.teams,
        selectedMembers: state.selectedMembers,
        selectedTeams: updatedTeamCache,
      };
    default:
      return state;
  }
}

type SelectReviewerContainerProps = {
  className?: string;
  name?: string;
  id?: string;
  placeholder?: string;
  resourceKind?: ApprovalRequestResourceKind;
  resourceKey?: string;
  value?: Array<Member | Team> | string | null;
  onChange(member?: Member | null | Member[], team?: Team | null | Team[]): void;
  filterPendingInvites?: boolean;
  isClearable?: boolean;
  valueContainerStyles?: object;
  onBlur?(): void;
  closeMenuOnSelect?: boolean;
  filterOutCurrentMember?: boolean;
  filterMembers?: (m: Member) => boolean;
  // filter members from the list of members available to select by their ID
  filterByMemberId?: Set<string>;
  suggestedMemberIds?: string[];
  isInPortal?: boolean;
  disabled?: boolean;
  profile?: Member;
  isTeamsMemberContainer?: boolean;
  customMultiValueRemoveTooltip?: string;
  customNoOptionsMessage?: ReactElement | string;
  ariaLabel?: string;
  showGroupOptions?: boolean;
  useAccessCheck?: boolean;
  canClearOriginalValues?: boolean;
  isMulti?: boolean;
  defaultMenuIsOpen?: boolean;
  existingReviewers?: List<string>;
};

export const useRedux = () => {
  const profile = useSelector((state: GlobalState) => profileSelector(state));
  return {
    profile: profile.get('entity'),
  };
};

const useAccessCheckForResource = ({
  useAccessCheck,
  resourceKind,
  resourceKey,
}: {
  useAccessCheck: boolean;
  resourceKind?: ApprovalRequestResourceKind;
  resourceKey?: string;
}) => {
  const projKey = useProjectKey();
  const envKey = useSelectedEnvironmentKey();
  const flagKey = useParam('flagKey', { optional: true });

  let accessCheck: string | undefined;
  let flagKeyToUse = flagKey;

  const resourceIsFlag = resourceKind === 'Flag' || resourceKind === 'FlagConfiguration';
  if (resourceIsFlag && !flagKey && resourceKey) {
    flagKeyToUse = resourceKey;
  }

  const resourceIsSegment = resourceKind === 'Segment';

  const { data: flag } = useFlag(
    { projectKey: projKey, flagKey: flagKeyToUse ?? '' },
    { enabled: useAccessCheck && resourceIsFlag },
  );

  const { data: segment } = useSegment(
    { projectKey: projKey, environmentKey: envKey, segmentKey: resourceKey ?? '' },
    { enabled: useAccessCheck && resourceIsSegment },
  );

  if (useAccessCheck) {
    if (resourceIsFlag) {
      const resourceSpec = makeResourceSpec(projKey, envKey, 'flag', nullthrows(flagKeyToUse), flag?.tags ?? []);
      accessCheck = `reviewApprovalRequest:${encodeURIComponent(resourceSpec)}`;
    }
    if (resourceIsSegment) {
      const resourceSpec = makeResourceSpec(projKey, envKey, 'segment', nullthrows(resourceKey), segment?.tags ?? []);
      accessCheck = `reviewApprovalRequest:${encodeURIComponent(resourceSpec)}`;
    }
  }

  return accessCheck;
};

/* eslint-disable import/no-default-export */
// eslint-disable-next-line prefer-arrow-callback
export default memo(function SelectReviewersContainer({
  value,
  disabled,
  closeMenuOnSelect,
  onChange,
  isInPortal = true,
  useAccessCheck = true,
  canClearOriginalValues = true,
  isMulti = true,
  defaultMenuIsOpen = false,
  ...props
}: SelectReviewerContainerProps) {
  const { profile } = useRedux();
  const accessCheck = useAccessCheckForResource({
    useAccessCheck,
    resourceKind: props.resourceKind,
    resourceKey: props.resourceKey,
  });

  const [state, dispatch] = useReducer(reducer, {
    isSearchingMembers: false,
    isSearchingReviewers: false,
    isSearchingTeams: false,
    searchValue: '',
    reviewers: OrderedMap<string, Member>(),
    members: OrderedMap<string, Member>(),
    teams: OrderedMap<string, Team>(),
    selectedMembers: new Map<string, Member>(),
    selectedTeams: new Map<string, Team>(),
  });

  useEffect(() => {
    const controller = new AbortController();

    dispatch(actions.search(state.searchValue));

    // fetch reviewers
    dispatch(actions.fetchReviewers());
    getMembers(
      createMemberFilters({ query: state.searchValue, limit: Math.ceil(memberFilterPageSize() / 2), accessCheck }),
      {
        signal: controller.signal,
      },
    )
      .then((data) => {
        const members = data
          .get('entities')
          .get('members')
          .map((member) => member.set('canReview', true));
        !controller.signal.aborted && dispatch(actions.fetchReviewersDone(members));
      })
      .catch(() => {
        dispatch(actions.fetchReviewersFailed());
      });

    // fetch members
    dispatch(actions.fetchMembers());
    getMembers(createMemberFilters({ query: state.searchValue, limit: Math.ceil(memberFilterPageSize() / 2) }), {
      signal: controller.signal,
    })
      .then(
        (data) => !controller.signal.aborted && dispatch(actions.fetchMembersDone(data.get('entities').get('members'))),
        noop,
      )
      .catch(() => {
        dispatch(actions.fetchMembersFailed());
      });

    // fetch teams
    if (isApprovalByTeamEnabled() || enableFollowFlagsByTeam()) {
      dispatch(actions.fetchTeams());
      getTeams(createTeamsFiltersFromQuery({ query: state.searchValue }), ['members'])
        .then(
          (data) => !controller.signal.aborted && dispatch(actions.fetchTeamsDone(data.get('entities').get('teams'))),
          noop,
        )
        .catch(() => {
          dispatch(actions.fetchTeamsFailed());
        });
    }

    return () => controller.abort();
  }, [state.searchValue]);

  useEffect(() => {
    const controller = new AbortController();

    if (!value) {
      return;
    }

    const ids = Array.isArray(value)
      ? value.map((memberOrTeam) => {
          if (isTeam(memberOrTeam)) {
            return memberOrTeam.key;
          } else {
            return memberOrTeam._id;
          }
        })
      : [value];

    for (const id of ids) {
      if (state.selectedMembers.has(id) || state.selectedTeams.has(id)) {
        continue;
      }

      getMember(id, { signal: controller.signal }).then(
        (data) => !controller.signal.aborted && dispatch(actions.saveSelectedMembers([data])),
        noop,
      );

      getTeam(id).then((data) => !controller.signal.aborted && dispatch(actions.saveSelectedTeams([data])), noop);
    }

    return () => controller.abort();
  }, [value]);

  let finalValue: Member | Team | Array<Member | Team> | null = null;

  if (typeof value === 'string') {
    finalValue =
      state.members.get(value) ||
      state.selectedMembers.get(value) ||
      state.teams.get(value) ||
      state.selectedTeams.get(value) ||
      null; // null is for react-select…
  }

  if (Array.isArray(value) && value.every(isReviewerType)) {
    finalValue = value;
  }

  function handleChange(nextValue: string | string[] | null) {
    if (nextValue === null) {
      handleChange(null);
      return;
    }

    const nextValueIds = Array.isArray(nextValue) ? nextValue : [nextValue];
    const members: Member[] = [];
    const teams: Team[] = [];

    for (const id of nextValueIds) {
      const member =
        state.reviewers.get(id) ||
        state.members.get(id) ||
        state.selectedMembers.get(id) ||
        (profile._id === id && profile);
      const team = state.teams.get(id) || state.selectedTeams.get(id);
      if (member) {
        members.push(member);
      } else if (team) {
        teams.push(team);
      }
    }

    // cache the selected member(s) if we already have them
    dispatch(actions.saveSelectedMembers(members));
    dispatch(actions.saveSelectedTeams(teams));
    // update the parent (which will update the URL, etc…)
    onChange(Array.isArray(nextValue) ? members : members[0], Array.isArray(nextValue) ? teams : teams[0]);
  }

  return (
    <SelectReviewers
      {...props}
      onChange={handleChange}
      onInputChange={(searchValue) => dispatch(actions.search(searchValue))}
      isLoading={state.isSearchingMembers || state.isSearchingReviewers || state.isSearchingTeams}
      disabled={disabled}
      profile={profile}
      reviewers={state.reviewers}
      teams={state.teams}
      members={state.members}
      value={finalValue}
      closeMenuOnSelect={closeMenuOnSelect}
      isInPortal={isInPortal}
      accessCheck={accessCheck}
      canClearOriginalValues={canClearOriginalValues}
      isMulti={isMulti}
      defaultMenuIsOpen={defaultMenuIsOpen}
    />
  );
});
