import { useProjectKey } from '@gonfalon/router';
import { Alert } from '@launchpad-ui/core';

import { useProjectExperimentationSettings } from 'components/experimentation/common/hooks/useProjectExperimentationSettings';
import { RandomizationUnit } from 'components/experimentation/common/types';

import { MultiSelectWithSearch } from '../../MultiSelectWithSearch';
import { RandomizationUnitOption } from '../CreateMetricModal/formUtils';

interface MetricRandomizationUnitsV2Props {
  onSelect?: (selection: string[], options: RandomizationUnitOption[]) => void;
  initialValues?: string[];
}

export function MetricRandomizationUnitsV2({ onSelect, initialValues }: MetricRandomizationUnitsV2Props) {
  const projKey = useProjectKey();

  const { data: settings, isPending, isError } = useProjectExperimentationSettings({ projKey });
  if (isError) {
    return <Alert kind="error">Failed to load randomization units</Alert>;
  }

  if (isPending) {
    return <MultiSelectWithSearch ariaLabel="Randomization Unit Multi Select" options={[]} isDisabled />;
  }

  const options: RandomizationUnitOption[] = settings.randomizationUnits.map(toOption);
  const defaultValues =
    initialValues && initialValues.length > 0
      ? initialValues
      : [options.find((o) => o.default)?.value ?? options[0].value];
  return (
    <div data-test-id="metric-randomization-units">
      <MultiSelectWithSearch
        onSelect={onSelect && ((s: string[]) => onSelect(s, options))}
        ariaLabel="Randomization Unit Multi Select"
        options={options}
        defaultValues={defaultValues}
        placeholder="Search for units"
        disallowEmptySelection
        shortenLabels
      />
    </div>
  );
}

function toOption(r: RandomizationUnit): RandomizationUnitOption {
  return {
    label: r._displayName ?? r.randomizationUnit,
    value: r.randomizationUnit,
    default: r.default,
  };
}
