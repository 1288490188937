import { ClipboardEvent, useRef, useState } from 'react';
import {
  booleanOptions,
  clauseOpOptions as defaultClauseOpOptions,
  getAttributeFromClause,
  getClauseExpectedValueType,
  getClauseOpOptionsForClause,
  isAppSupportedAttribute,
  isAppSupportedOp,
  isDateOp,
  isMatchesOp,
  isSegmentOp,
} from '@gonfalon/clauses';
import {
  isDynamicDefaultTargetingContextKindEnabled,
  isMobileAppTargetingEnabled,
  isReusableSegmentsEnabled,
} from '@gonfalon/dogfood-flags';
import { useIsInNewIA } from '@gonfalon/ia-migration';
import { CustomSelect, OptionProps } from '@gonfalon/launchpad-experimental';
import { trackSegmentEvent } from '@gonfalon/segments';
import { coerceToType } from '@gonfalon/types';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List } from 'immutable';
import { ButtonGroup, IconButton, Label, TextField } from 'launchpad';
import { v4 } from 'uuid';

import { DatePickerClause } from 'components/DatePickerClause';
import SelectSegmentContainer from 'components/segments/SelectSegmentContainer';
import SelectAttributeContainer from 'components/selectAttributes/SelectAttributeContainer';
import { SelectAttributeValues } from 'components/selectAttributes/SelectAttributeValues';
import TargetingValidationAlert from 'components/TargetingValidationAlert';
import { Cell, CellLayoutValue, Grid } from 'components/ui/grid';
import { useContextKinds } from 'hooks/useContextKinds';
import { useMigrationContext } from 'hooks/useMigrationContext';
import { useCurrentEnvironmentKey, useCurrentProjectKey } from 'reducers/projects';
import { createAccessDecision } from 'utils/accessUtils';
import {
  Clause as ClauseRecord,
  createApplicationContextClause,
  createClause,
  createDeviceContextClause,
  createSegmentRuleClause,
  shouldAutoCompleteAttributeValue,
} from 'utils/clauseUtils';
import {
  CONTEXT_KIND_ATTRIBUTE,
  NOT_SEGMENT_MATCH_ATTRIBUTE,
  SEGMENT_MATCH_ATTRIBUTE,
  USER_CONTEXT_KIND,
} from 'utils/constants';
import { trackFlagTargetingEvent } from 'utils/flagUtils';
import { isBulkInput, readPastedInput, tokenizeInput } from 'utils/inputUtils';
import { couldAllBeBooleans, couldAllBeNumbers } from 'utils/typeUtils';
import { ClauseValidation } from 'utils/validation/clauses';

import { AddTargetingMenu } from './AddTargetingMenu/AddTargetingMenu';
import { ApplicationSupportChip } from './applications/ApplicationSupportChip';
import { SelectApplicationClause } from './applications/SelectApplicationClause';
import { getDefaultTargetingContextKind } from './Contexts/utils/contextKindUtils';
import SelectContextKind from './contextTargeting/SelectContextKind';
import { ExternalSegmentClauseAlert } from './segments/ExternalSegmentClauseAlert/ExternalSegmentClauseAlert';
import { SelectSegmentV2 } from './segments/SelectSegmentV2/SelectSegmentV2';
import Restrict from './Restrict';

import 'stylesheets/components/Clause.css';
import styles from './Clause.module.css';

type OperatorOption = {
  value: string;
  label: string;
  negate: boolean;
  op: string;
};

export const clauseOp = (c: ClauseRecord) => (c.negate ? `not-${c.op}` : c.op);

type ClauseProps = {
  className?: string;
  clause: ClauseRecord;
  prefix: 'if' | 'and';
  validation?: ClauseValidation;
  canModify: boolean;
  canDelete: boolean;
  onChange(c: ClauseRecord): void;
  onDelete(): void;
  onAdd?: (c?: ClauseRecord) => void;
  showAddButton?: boolean;
  clauseIndex: number;
  isInPortal?: boolean;
  isFlagTargeting?: boolean;
  isMobileRule?: boolean;
  hasMobileFlagConfig?: boolean;
  ruleKind?: string;
  allClauses?: List<ClauseRecord | null>;
  shouldFetchContextKinds?: boolean;
};

export function Clause({
  className,
  clause,
  prefix,
  validation,
  canModify,
  canDelete,
  onChange,
  onDelete,
  onAdd,
  showAddButton = true,
  clauseIndex,
  isMobileRule,
  ruleKind,
  isInPortal = false,
  isFlagTargeting,
  hasMobileFlagConfig,
  allClauses,
  shouldFetchContextKinds = false,
  ...props
}: ClauseProps) {
  const isInNewIA = useIsInNewIA();
  const migrationContext = useMigrationContext({ optional: true });
  const isSixStageMigration = migrationContext.isMigrationFlag && migrationContext.stageCount === 6;
  const [regexInputFocused, setRegexInputFocused] = useState(false);
  const isNewClause = !clause._id;

  const regexInputRef = useRef<HTMLInputElement>(null);
  const hasValidationProblem = validation && (isNewClause ? validation.isInvalid() : !validation.isEmpty());

  const projectKey = useCurrentProjectKey();
  const environmentKey = useCurrentEnvironmentKey();
  const enableMobileTargeting = isMobileAppTargetingEnabled();

  const { unhiddenContextKinds } = useContextKinds({ projKey: projectKey });
  const clauseOpOptions = enableMobileTargeting ? getClauseOpOptionsForClause(clause.toJS()) : defaultClauseOpOptions;
  const enableSegmentTargeting = isReusableSegmentsEnabled();

  const classes = cx(
    'Clause',
    {
      'Clause--invalid': hasValidationProblem,
    },
    className,
  );

  const clauseAttribute = getAttributeFromClause(clause.toJS());
  const isSegmentMatchClauseAttribute =
    clauseAttribute === SEGMENT_MATCH_ATTRIBUTE || clauseAttribute === NOT_SEGMENT_MATCH_ATTRIBUTE;

  const onContextKindChange = (value: string) => {
    let updatedClause: ClauseRecord;
    const isSegmentMatchAttribute = value === SEGMENT_MATCH_ATTRIBUTE || value === NOT_SEGMENT_MATCH_ATTRIBUTE;
    if (value === CONTEXT_KIND_ATTRIBUTE || isSegmentMatchAttribute) {
      updatedClause = clause.updateContextKind('').updateAttribute(value);
    } else if (clauseAttribute === CONTEXT_KIND_ATTRIBUTE || isSegmentMatchClauseAttribute) {
      updatedClause = clause.updateContextKind(value).updateAttribute('');
    } else {
      updatedClause = clause.updateContextKind(value);
    }
    onChange(updatedClause);
  };

  const defaultTargetingContextKind = isDynamicDefaultTargetingContextKindEnabled()
    ? getDefaultTargetingContextKind(unhiddenContextKinds).key
    : USER_CONTEXT_KIND;

  const getDefaultContextKind = (clauseRec: ClauseRecord) => {
    if (isSixStageMigration) {
      return migrationContext.contextKind;
    }

    return clauseRec.contextKind ? clauseRec.contextKind : defaultTargetingContextKind;
  };

  let contextKindSelectValue = getDefaultContextKind(clause);

  if (
    clauseAttribute === CONTEXT_KIND_ATTRIBUTE ||
    clauseAttribute === SEGMENT_MATCH_ATTRIBUTE ||
    clauseAttribute === NOT_SEGMENT_MATCH_ATTRIBUTE
  ) {
    contextKindSelectValue = clauseAttribute;
  }

  const updateContextKind = createAccessDecision({
    isAllowed: !isSixStageMigration,
    customMessage: "When moving data, clauses must reference the migration's context kind",
  });
  const readOnly = !updateContextKind.get('isAllowed');

  const hideAttributeSelect = clauseAttribute === CONTEXT_KIND_ATTRIBUTE || clause.op === 'segmentMatch';
  const operatorFieldId = v4();

  const canRenderUpdatedSegmentClauseUI = isSegmentMatchClauseAttribute;

  const segmentOperatorOptions: OperatorOption[] = [
    {
      value: 'segmentMatch',
      label: 'is in',
      negate: false,
      op: 'in',
    },
    {
      value: 'not-segmentMatch',
      label: 'is not in',
      negate: true,
      op: 'in',
    },
  ];

  const renderOption = (option: OperatorOption) => {
    if (isAppSupportedOp(option.op)) {
      return (
        <>
          is <ApplicationSupportChip supported={!option.negate} /> for
        </>
      );
    }

    return option.label;
  };

  const renderContextSelector = () => {
    if (canRenderUpdatedSegmentClauseUI) {
      return (
        <Cell layout={CellLayoutValue.AUTO}>
          <h4 className={cx('Clause-prefix', styles.contextKindLabel)}>Context</h4>
        </Cell>
      );
    } else if (enableMobileTargeting && clause.isMobileClause() && (isMobileRule || !isNewClause)) {
      return (
        <Cell layout={CellLayoutValue.AUTO}>
          <h4 className={cx('Clause-prefix', styles.contextKindLabel)}>{clause.getFormarmatedContextKind()}</h4>
        </Cell>
      );
    } else if (readOnly) {
      return (
        <Cell className={styles.contextKind}>
          <Restrict
            isRestricted={readOnly}
            tooltip={updateContextKind.getModifyFieldReason()}
            tooltipOptions={{
              rootElementStyle: { display: 'block' },
              placement: 'top',
            }}
            willDisable
          >
            <SelectContextKind
              label="Context kind"
              value={contextKindSelectValue}
              providedContextKinds={unhiddenContextKinds}
              shouldFetchContextKinds={shouldFetchContextKinds}
              includeContextKindOption
              includeSegmentMatchOptions={false}
              onChange={onContextKindChange}
            />
          </Restrict>
        </Cell>
      );
    } else {
      return (
        <Cell className={styles.contextKind}>
          <SelectContextKind
            label="Context kind"
            value={contextKindSelectValue}
            providedContextKinds={unhiddenContextKinds}
            shouldFetchContextKinds={shouldFetchContextKinds}
            includeContextKindOption
            includeSegmentMatchOptions={false}
            onChange={onContextKindChange}
            disabled={!canModify}
          />
        </Cell>
      );
    }
  };

  const addNewClause = (c?: ClauseRecord) => {
    onAdd?.(c);
    trackFlagTargetingEvent('Add Clause Clicked', { clauseKind: c ? c.getClauseKind() : 'custom', ruleKind });
  };

  const renderAddButton = () => {
    if (!onAdd) {
      return null;
    }
    return (
      <AddTargetingMenu
        isIconButton
        ariaLabel="Add clause"
        buttonKind={isInNewIA ? 'default' : 'primary'}
        addIcon={<Icon name="add" size="medium" />}
        addButtonClassName={cx(styles.addButton)}
        label="clause"
        menuItems={[
          {
            label: 'Segment',
            onClick: () => {
              addNewClause(createSegmentRuleClause());
            },
            icon: <Icon name="chart-venn" size="small" />,
            hidden: !enableSegmentTargeting,
            disabled: isSixStageMigration,
            tooltip: isSixStageMigration ? 'When moving data, cohorts cannot reference segments' : undefined,
            tooltipPlacement: 'top' as const,
          },
          {
            label: 'Application',
            onClick: () => {
              addNewClause(createApplicationContextClause());
            },
            icon: <Icon name="application" size="small" />,
            hidden: !hasMobileFlagConfig,
          },
          {
            label: 'Device',
            onClick: () => {
              addNewClause(createDeviceContextClause());
            },
            icon: <Icon name="device-mobile" size="small" />,
            hidden: !hasMobileFlagConfig,
          },
          {
            label: 'Custom',
            onClick: () => {
              addNewClause(createClause());
            },
            icon: <Icon name="gear" size="small" />,
          },
        ]}
      />
    );
  };

  return (
    <div {...props} className={classes}>
      <Grid className={styles.content}>
        <Cell layout={CellLayoutValue.AUTO}>
          <span className={`Clause-prefix Clause-prefix-${prefix}`}>{prefix}</span>
        </Cell>

        {renderContextSelector()}

        {!hideAttributeSelect && (
          <Cell className={cx('Clause-attribute', styles.attribute)}>
            <SelectAttributeContainer
              className="SelectAttributeContainer u-flex-auto"
              isClearable={false}
              backspaceRemoves={false}
              deleteRemoves={false}
              disabled={!canModify}
              value={getAttributeFromClause(clause.toJS())}
              contextKind={clause.contextKind}
              onChange={handleAttributeChange}
              placeholder="Select an attribute"
              ariaLabel="Select attribute"
              isInPortal={isInPortal}
            />
          </Cell>
        )}

        {canRenderUpdatedSegmentClauseUI && (
          <Cell tight className={styles.op}>
            <Label htmlFor={operatorFieldId}>Operator</Label>
            <CustomSelect
              id={operatorFieldId}
              className="u-flex-auto"
              isClearable={false}
              disabled={!canModify}
              getOptionLabel={(option: OperatorOption) => option.label}
              placeholder="Select an operator"
              value={segmentOperatorOptions.filter((option) => option.value === clauseOp(clause))}
              options={segmentOperatorOptions}
              onChange={handleSegmentOperatorChange}
              ariaLabel="Select operator"
            />
          </Cell>
        )}

        {!canRenderUpdatedSegmentClauseUI && (
          <>
            {clause.op !== 'segmentMatch' && clause.attribute !== 'anonymous' && (
              <Cell tight className={styles.op}>
                <Label htmlFor={operatorFieldId}>Operator</Label>
                <CustomSelect
                  id={operatorFieldId}
                  className="u-flex-auto"
                  isClearable={false}
                  disabled={!canModify}
                  formatOptionLabel={(option: OperatorOption) => renderOption(option)}
                  getOptionLabel={(option: OperatorOption) => option.label}
                  placeholder="Select an operator"
                  value={clauseOpOptions.filter((option) => option.value === clauseOp(clause))}
                  options={clauseOpOptions}
                  onChange={handleOperatorChange}
                  ariaLabel="Select operator"
                />
              </Cell>
            )}
          </>
        )}

        <Cell tight className={cx('fs-exclude', styles.values)}>
          {renderValueField()}
        </Cell>

        {canModify && showAddButton && (
          <Cell className="Clause-actions" layout={CellLayoutValue.AUTO}>
            <ButtonGroup className="Clause-action-group">
              {canDelete && (
                <IconButton
                  aria-label={`delete clause ${clauseIndex + 1}`}
                  className="Clause-delete"
                  kind="destructive"
                  icon={<Icon name="minus" size="medium" />}
                  onClick={() => {
                    onDelete();
                    trackFlagTargetingEvent('Delete Clause', { clauseKind: clause.getClauseKind(), ruleKind });
                  }}
                />
              )}
              {renderAddButton()}
            </ButtonGroup>
          </Cell>
        )}
      </Grid>
      {hasValidationProblem && validation && (
        <div className={styles.targetingAlert}>
          <TargetingValidationAlert validation={validation} />
        </div>
      )}

      {isSegmentOp(clause.op) && <ExternalSegmentClauseAlert clause={clause} />}
    </div>
  );

  function renderValueField() {
    const valueDisabled = !canModify || !clause.attribute;
    const expectedType = getExpectedType();
    const fieldId = v4();

    if (isDateOp(clause.op)) {
      return (
        <>
          <Label htmlFor={fieldId}>Value</Label>
          <DatePickerClause id={fieldId} clause={clause} canModify={canModify} onChange={onChange} />
        </>
      );
    } else if (isAppSupportedAttribute(clause.attribute)) {
      return <SelectApplicationClause onChange={onChange} clause={clause} canModify={canModify} />;
    } else if (clause.attribute === 'anonymous') {
      return (
        <>
          <Label htmlFor={fieldId}>Value</Label>
          <CustomSelect
            id={fieldId}
            disabled={valueDisabled}
            isClearable={false}
            backspaceRemoves={false}
            options={booleanOptions}
            value={booleanOptions.filter(({ value }) => value === clause.values.first())}
            onChange={({ value }: OptionProps) => {
              onChange(clause.set('values', List.of(value)));
            }}
            aria-label="Select value"
          />
        </>
      );
    } else if (isMatchesOp(clause.op)) {
      const placeholderText = 'Eg. ^foo$';
      const generateInputWidth = (value: string) => {
        if (value) {
          return (clause.values.first() as string).length + 1;
        } else {
          return placeholderText.length + 1;
        }
      };
      return (
        <>
          <Label htmlFor={fieldId}>Value</Label>
          <div
            tabIndex={-1}
            role="button"
            className={cx('RegexInputWrapper', { RegexInputWrapperFocused: regexInputFocused })}
            onClick={() => regexInputRef?.current?.focus()}
            onKeyDown={() => regexInputRef?.current?.focus()}
          >
            <span className="RegexSlash">/</span>
            <TextField
              className="Regex-Input"
              ref={regexInputRef}
              disabled={valueDisabled}
              id={fieldId}
              value={(clause?.values?.first() as string) || ''}
              onChange={(event) => {
                if (event.target.value === '') {
                  onChange(clause.set('values', List()));
                } else {
                  onChange(clause.set('values', List.of(event.target.value)));
                }
              }}
              placeholder={placeholderText}
              overrideWidth={`${generateInputWidth(clause.values.first() as string)}ch`}
              onFocus={() => setRegexInputFocused(true)}
              onBlur={() => setRegexInputFocused(false)}
            />
            <span className="RegexSlash">/</span>
          </div>
        </>
      );
    } else if (isSegmentOp(clause.op)) {
      return (
        <>
          <Label htmlFor={fieldId}>{canRenderUpdatedSegmentClauseUI ? 'Segments' : 'Values'}</Label>
          {canRenderUpdatedSegmentClauseUI ? (
            <SelectSegmentV2
              disabled={valueDisabled}
              id={fieldId}
              segmentKeys={clause.values as List<string>}
              onChange={(vs: OptionProps | OptionProps[] | null | string[]) =>
                onChange(clause.set('values', fromJS(vs)))
              }
            />
          ) : (
            <SelectSegmentContainer
              disabled={valueDisabled}
              id={fieldId}
              segmentKeys={clause.values as List<string>}
              onChange={(vs: OptionProps | OptionProps[] | null | string[]) =>
                onChange(clause.set('values', fromJS(vs)))
              }
            />
          )}
        </>
      );
    } else {
      return (
        <>
          <SelectAttributeValues
            key={`${clause.attribute}-${clause.negate ? 'not-' : ''}${clause.op}`}
            isClearable={false}
            autoload={!!clause.attribute && shouldAutoCompleteAttributeValue(clause)}
            autocomplete={!!clause.attribute && shouldAutoCompleteAttributeValue(clause)}
            disabled={valueDisabled}
            attribute={clause.attribute}
            value={clause.values.map((a) => ({ value: a, label: String(a) })).toJS()}
            values={clause.values.toJS()}
            expectedType={expectedType}
            onChange={(values: List<string>) => onChange(clause.set('values', values.toOrderedSet().toList()))} //toOrderedSet removes duplicates
            onPaste={handlePaste}
            placeholder="Enter some values"
            className="u-flex-auto"
            ariaLabel="Select attribute values"
            projectKey={projectKey}
            environmentKey={environmentKey}
            contextKind={clause.contextKind || defaultTargetingContextKind}
            projectContextKinds={unhiddenContextKinds}
          />
        </>
      );
    }
  }

  function handleAttributeChange(attr: string) {
    const contextKind = getDefaultContextKind(clause);
    if (isFlagTargeting) {
      trackFlagTargetingEvent('Targeting Rule Attribute Selected', {
        contextKind,
        ruleKind,
        clauseKind: clause.getClauseKind(),
        attributeName: attr,
      });
    } else {
      trackSegmentEvent('Targeting Rule Attribute Selected', {
        contextKind,
        ruleKind,
        clauseKind: clause.getClauseKind(),
        attributeName: attr,
      });
    }

    let updatedClause = clause.updateAttribute(attr);

    if (clause.contextKind === '') {
      updatedClause = updatedClause.updateContextKind(contextKind);
    }

    onChange(updatedClause);
  }

  function handleOperatorChange(value: OperatorOption) {
    if (isFlagTargeting) {
      trackFlagTargetingEvent('Targeting Rule Operator Selected', {
        contextKind: clause.contextKind,
        ruleKind,
        clauseKind: clause.getClauseKind(),
        operatorName: clause.op,
      });
    } else {
      trackSegmentEvent('Targeting Rule Operator Selected', {
        contextKind: clause.contextKind,
        ruleKind,
        clauseKind: clause.getClauseKind(),
        operatorName: clause.op,
      });
    }
    if (value.op === clause.op && value.negate === clause.negate) {
      return;
    }

    onChange(clause.updateOperator(value.op, value.negate));
  }

  function handlePaste(event: ClipboardEvent<HTMLDivElement>) {
    const input = readPastedInput(event);

    if (isBulkInput(input)) {
      event.preventDefault();
      onChange(
        clause.update('values', (vs) => {
          const tokenized = tokenizeInput(input);
          let type: 'number' | 'string' | 'boolean' | undefined = getExpectedType();
          if (!type) {
            if (couldAllBeNumbers(tokenized)) {
              type = 'number';
            } else if (couldAllBeBooleans(tokenized)) {
              type = 'boolean';
            }
          }

          return vs.concat(tokenized.map((v) => (type ? coerceToType(v, type) : v)));
        }),
      );
    }
  }

  function getExpectedType() {
    return getClauseExpectedValueType(clause.toJS());
  }

  function handleSegmentOperatorChange({ value }: OperatorOption) {
    const updatedClause: ClauseRecord = clause.updateContextKind('').updateAttribute(value);
    onChange(updatedClause);
  }
}
