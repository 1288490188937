import { getDogfoodClient } from '@gonfalon/dogfood-client';
import { enableGuidedMetricCreation, trackMetricCreationDuration } from '@gonfalon/dogfood-flags';
import { Metric } from '@gonfalon/metrics';

import { NewMetricForm } from '../common';

import { GuidedMetricCreationModal } from './GuidedMetricCreationModal';
import { LegacyCreateMetricModal } from './LegacyCreateMetricModal';

export function CreateMetricModal({
  onCancel,
  onSuccess,
  defaultValues,
}: {
  onCancel: () => void;
  onSuccess?: (metric: Metric) => void;
  defaultValues?: Partial<NewMetricForm>;
}) {
  const openAtStamp = Date.now();
  const onSuccessWrapped = (metric: Metric) => {
    if (trackMetricCreationDuration()) {
      const successAtStamp = Date.now();
      const modalUseDuration = (successAtStamp - openAtStamp) / 1000;
      getDogfoodClient().track('Metric Creation Modal Duration', undefined, modalUseDuration);
    }
    onSuccess && onSuccess(metric);
  };
  return enableGuidedMetricCreation() ? (
    <GuidedMetricCreationModal onCancel={onCancel} onSuccess={onSuccessWrapped} defaultValues={defaultValues} />
  ) : (
    <LegacyCreateMetricModal onCancel={onCancel} onSuccess={onSuccessWrapped} defaultValues={defaultValues} />
  );
}
