import { enableGuidedMetricCreation } from '@gonfalon/dogfood-flags';
import { IconName } from '@launchpad-ui/icons';

export enum MetricKind {
  CLICK = 'click',
  PAGEVIEW = 'pageview',
  CUSTOM = 'custom',
  NUMERIC = 'numeric',
  BINARY = 'binary',
  COUNT = 'count',
}

export enum MetricGroupKind {
  FUNNEL_GROUP = 'funnel',
  STANDARD_GROUP = 'standard',
}

export const MetricKindDisplayNames = {
  [MetricKind.CLICK]: 'Click',
  [MetricKind.PAGEVIEW]: 'Page view',
  [MetricKind.NUMERIC]: 'Numeric',
  [MetricKind.BINARY]: 'Binary',
  [MetricKind.CUSTOM]: 'Custom',
  [MetricGroupKind.FUNNEL_GROUP]: 'Funnel group',
  [MetricGroupKind.STANDARD_GROUP]: 'Standard group',
};

export const MetricKindDisplays: {
  [key: string]: { sidebarName: string; displayName: string; displayIcon: IconName; metricsListName: string };
} = {
  [MetricKind.CLICK]: {
    sidebarName: 'Click',
    displayName: 'Click',
    displayIcon: 'click',
    metricsListName: 'Click',
  },
  [MetricKind.PAGEVIEW]: {
    sidebarName: 'Page view',
    displayName: 'Page view',
    displayIcon: 'device-desktop',
    metricsListName: 'Page view',
  },
  [MetricKind.CUSTOM]: {
    sidebarName: 'Custom',
    displayName: 'Custom',
    displayIcon: 'code-circle-outline',
    metricsListName: 'Custom',
  },
  [MetricKind.COUNT]: {
    sidebarName: 'Binary',
    displayName: 'Custom: conversion',
    displayIcon: 'code-circle-outline',
    metricsListName: 'Binary',
  },
  [MetricKind.BINARY]: {
    sidebarName: 'Binary',
    displayName: 'Custom: conversion',
    displayIcon: 'code-circle-outline',
    metricsListName: 'Binary',
  },
  [MetricKind.NUMERIC]: {
    sidebarName: 'Numeric',
    displayName: 'Custom: numeric',
    displayIcon: 'code-circle-outline',
    metricsListName: 'Numeric',
  },
  [MetricGroupKind.STANDARD_GROUP]: {
    sidebarName: 'Standard Group',
    displayName: 'Standard Group',
    displayIcon: 'code-circle-outline',
    metricsListName: 'Standard Group',
  },
  [MetricGroupKind.FUNNEL_GROUP]: {
    sidebarName: 'Funnel Group',
    displayName: 'Funnel Group',
    displayIcon: 'code-circle-outline',
    metricsListName: 'Funnel Group',
  },
} as const;

// These are the new display names, for use in the guided metric creation flow.
// TODO: clean up this file after launch (https://app.shortcut.com/launchdarkly/story/252425/clean-up-metric-type-names)
export const GuidedMetricKindDisplays: {
  [key: string]: { sidebarName: string; displayName: string; displayIcon: IconName };
} = {
  [MetricKind.CLICK]: {
    sidebarName: 'clicked or tapped',
    displayName: 'click',
    displayIcon: 'click',
  },
  [MetricKind.PAGEVIEW]: {
    sidebarName: 'page viewed',
    displayName: 'page view',
    displayIcon: 'device-desktop',
  },
  [MetricKind.CUSTOM]: {
    sidebarName: 'custom: conversion',
    displayName: 'custom: conversion',
    displayIcon: 'code-circle-outline',
  },
  [MetricKind.COUNT]: {
    sidebarName: 'conversion: count',
    displayName: 'conversion: count',
    displayIcon: 'code-circle-outline',
  },
  [MetricKind.BINARY]: {
    sidebarName: 'conversion: binary',
    displayName: 'conversion: binary',
    displayIcon: 'code-circle-outline',
  },
  [MetricKind.NUMERIC]: {
    sidebarName: 'numeric',
    displayName: 'numeric',
    displayIcon: 'code-circle-outline',
  },
  [MetricGroupKind.STANDARD_GROUP]: {
    sidebarName: 'standard group',
    displayName: 'standard group',
    displayIcon: 'code-circle-outline',
  },
  [MetricGroupKind.FUNNEL_GROUP]: {
    sidebarName: 'funnel group',
    displayName: 'funnel group',
    displayIcon: 'code-circle-outline',
  },
} as const;

export const getMetricKindDisplay = (
  metricKind: string,
  unitAggregationType?: 'sum' | 'average',
  isNumeric?: boolean,
  isEventKind: boolean = true,
) => {
  if (enableGuidedMetricCreation()) {
    const kind = isEventKind ? getMetricKind(metricKind, unitAggregationType, isNumeric) : metricKind;
    return GuidedMetricKindDisplays[kind];
  } else {
    const kind = isEventKind ? getMetricKind(metricKind) : metricKind;
    return kind === MetricKind.CUSTOM
      ? isNumeric
        ? MetricKindDisplays[MetricKind.NUMERIC]
        : MetricKindDisplays[MetricKind.BINARY]
      : MetricKindDisplays[kind];
  }
};

export const getMetricKind = (kindString: string, unitAggregationType?: 'sum' | 'average', isNumeric?: boolean) => {
  if (unitAggregationType || isNumeric) {
    if (kindString === 'click') {
      return MetricKind.CLICK;
    } else if (kindString === 'pageview') {
      return MetricKind.PAGEVIEW;
    } else if (kindString === 'custom') {
      if (isNumeric) {
        return MetricKind.NUMERIC;
      } else if (unitAggregationType === 'sum') {
        return MetricKind.COUNT;
      } else if (unitAggregationType === 'average') {
        return MetricKind.BINARY;
      }
    }
  } else {
    switch (kindString) {
      case 'funnel':
        return MetricGroupKind.FUNNEL_GROUP;
      case 'standard':
        return MetricGroupKind.STANDARD_GROUP;
      case 'click':
        return MetricKind.CLICK;
      case 'pageview':
        return MetricKind.PAGEVIEW;
      case 'custom':
        return MetricKind.CUSTOM;
      case 'numeric':
        return MetricKind.NUMERIC;
      case 'binary':
        return MetricKind.BINARY;
      default:
        return MetricKind.CUSTOM;
    }
  }
  return MetricKind.CUSTOM;
};

/* eslint-disable import/no-default-export */
export default MetricKind;
