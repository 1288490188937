import { forwardRef, RefObject, useEffect, useRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useDispatch, useSelector } from 'react-redux';
import { components, GroupHeadingProps, MenuPosition } from 'react-select';
import { CustomSelect, GroupedOption, OptionProps, StylesObject } from '@gonfalon/launchpad-experimental';
import { useParam } from '@gonfalon/router';
import { List } from 'immutable';
import { Label, Tooltip } from 'launchpad';
import { v4 } from 'uuid';

import { fetchContextKindsForProject as fetchContextKindsForProjectAction } from 'actions/contextKinds';
import { ContextKind } from 'components/Contexts/types';
import { makeSelectContextKindOptions } from 'components/Contexts/utils/contextTargetingUtils';
import { useFlagContext } from 'components/FlagContext';
import { FlagContextErrorWrapper } from 'components/FlagContextErrorWrapper';
import { contextKindsRequestSelector, unhiddenContextKindsSelector } from 'reducers/contextKinds';
import { currentProjectKeySelector } from 'reducers/projects';
import { CONTEXT_KIND_ATTRIBUTE } from 'utils/constants';
import { ready } from 'utils/reduxUtils';

import styles from './SelectContextKind.module.css';

export type SelectContextKindProps = {
  className?: string;
  id?: string;
  value?: string;
  projKey?: string;
  flagKey?: string;
  name?: string;
  disabled?: boolean;
  providedContextKinds?: ContextKind[];
  shouldFetchContextKinds?: boolean;
  includeContextKindOption?: boolean;
  includeSegmentMatchOptions?: boolean;
  isMulti?: boolean;
  label?: string | JSX.Element;
  menuPosition?: MenuPosition;
  onChange?(contextKind: string): void;
  onChangeMulti?(options: OptionProps[]): void;
  onBlur?(event: React.FocusEvent<HTMLInputElement>): void;
  innerRef?: React.Ref<HTMLSelectElement>;
};

export const useRedux = (requestedProjKey?: string) => {
  const currentProjKey = useSelector(currentProjectKeySelector);
  const projKey = requestedProjKey ? requestedProjKey : currentProjKey;
  const contextKinds = useSelector(unhiddenContextKindsSelector);
  const contextKindsRequest = useSelector(contextKindsRequestSelector);

  const dispatch = useDispatch();
  const fetchContextKinds = () => dispatch(fetchContextKindsForProjectAction(projKey));

  const isReady = ready(contextKindsRequest);

  return {
    isReady,
    contextKinds,
    fetchContextKinds,
  };
};

const customSelectStyleOverrides: StylesObject = {
  input: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  group: {
    paddingBottom: '0',
    paddingTop: '0',
  },
  groupHeading: {
    backgroundColor: 'var(--lp-color-bg-field-aside)',
    color: 'var(--lp-color-text-ui-secondary)',
    fontSize: 'var(--lp-font-size-200)',
    letterSpacing: '1px',
    textTransform: 'none',
    paddingTop: 'var(--lp-spacing-300)',
    paddingBottom: 'var(--lp-spacing-300)',
    borderTop: '1px solid var(--lp-color-border-ui-primary)',
  },
};

const GroupOptionHeading = (props: GroupHeadingProps<GroupedOption>) => {
  if (!props.data.label) {
    return <components.GroupHeading style={{ display: 'none' }} {...props} />;
  } else {
    return <components.GroupHeading {...props} />;
  }
};

const isMultiOptionsList = (res: OptionProps | OptionProps[], isMulti: boolean): res is OptionProps[] => isMulti;

const SelectContextKind = ({
  className,
  id = v4(),
  value: selectedContextKindValue,
  name,
  projKey,
  flagKey,
  disabled,
  providedContextKinds,
  shouldFetchContextKinds = true,
  includeContextKindOption = false,
  includeSegmentMatchOptions = false,
  isMulti,
  menuPosition,
  label,
  onChange,
  onChangeMulti,
  onBlur,
  innerRef,
}: SelectContextKindProps) => {
  const { isReady, contextKinds, fetchContextKinds } = useRedux(projKey);
  const didFirstRender = useRef(false);

  useEffect(() => {
    if (shouldFetchContextKinds && !isReady) {
      fetchContextKinds();
    }
    didFirstRender.current = true;
  }, []);

  useEffect(() => {
    if (shouldFetchContextKinds && didFirstRender.current) {
      fetchContextKinds();
    }
  }, [projKey]);

  const { flagConfiguration } = useFlagContext();
  const contextKindsToDisplay = shouldFetchContextKinds ? contextKinds : (providedContextKinds ?? []);
  const flagEvaluatedContextKindKeys = (flagKey && flagConfiguration?.evaluation?.contextKinds) || List<string>();

  const optionsResult = makeSelectContextKindOptions({
    contextKinds: contextKindsToDisplay,
    evaluatedContextKindKeys: flagEvaluatedContextKindKeys,
    includeContextKindOption,
    includeSegmentMatchOptions,
  });

  const flattedOptions = optionsResult.groupedOptions
    ? optionsResult.options.reduce(
        (accum, currGroupedOption) => [...accum, ...currGroupedOption.options],
        [] as OptionProps[],
      )
    : optionsResult.options;
  let selectedOption;

  if (selectedContextKindValue) {
    selectedOption = flattedOptions.find((o) => o.value === selectedContextKindValue) || {
      label: selectedContextKindValue,
      value: selectedContextKindValue,
    };
  }
  return (
    <>
      {label ? <Label htmlFor={id}>{label}</Label> : null}
      <CustomSelect
        className={className}
        id={id}
        name={name}
        innerRef={innerRef as RefObject<HTMLSelectElement>}
        value={selectedOption}
        options={optionsResult.options}
        onChange={(option: OptionProps | OptionProps[]) => {
          isMultiOptionsList(option, !!isMulti) ? onChangeMulti?.(option) : onChange?.(option.value);
        }}
        onBlur={onBlur}
        placeholder="Select a context kind"
        ariaLabel="Select context kind"
        styles={customSelectStyleOverrides}
        noOptionsMessage={() => (!isReady ? 'Loading values' : 'No values found')}
        isLoading={!isReady}
        disabled={disabled}
        formatOptionLabel={formatOptionLabel}
        customComponents={{ GroupHeading: GroupOptionHeading }}
        isMulti={isMulti}
        menuPosition={menuPosition}
      />
    </>
  );
};

const formatOptionLabel = (option: OptionProps) => {
  const optionLabel = <div className="SelectContextKind-option">{option.label}</div>;

  if (option.value !== CONTEXT_KIND_ATTRIBUTE) {
    return optionLabel;
  }

  return (
    <Tooltip targetClassName={styles.tooltip} placement="top" content="Option for matching context kinds">
      {optionLabel}
    </Tooltip>
  );
};

type SelectContextKindWrapperProps = Omit<SelectContextKindProps, 'flagKey'>;
/* eslint-disable import/no-default-export */
export default forwardRef<HTMLSelectElement, SelectContextKindWrapperProps>((props, ref) => {
  const params = {
    envKey: useParam('envKey', { optional: true }),
    flagKey: useParam('flagKey', { optional: true }),
  };

  const ContextKindsModal = <SelectContextKind innerRef={ref} {...props} flagKey={params.flagKey} />;

  return params.flagKey && params.envKey ? (
    <FlagContextErrorWrapper envKey={params.envKey} flagKey={params.flagKey} expandEvaluation>
      {ContextKindsModal}
    </FlagContextErrorWrapper>
  ) : (
    ContextKindsModal
  );
});
