import { ReactElement, useState } from 'react';
import { Icon, IconProps } from '@launchpad-ui/icons';
import type { PopoverPlacement } from '@launchpad-ui/popover';
import { Button, IconButton, Menu, MenuItem, Popover } from 'launchpad';

import { useProfileEntity } from 'reducers/profile';

import styles from './AddTargetingMenu.module.css';

export type AddTargetingMenuItem = {
  label: string;
  icon?: React.ReactNode;
  hidden?: boolean;
  disabled?: boolean;
  tooltip?: string;
  tooltipPlacement?: PopoverPlacement;
  onClick: () => void;
};

export type AddTargetingMenuProps = {
  addIcon?: Omit<ReactElement<IconProps>, 'size'>;
  addButtonClassName?: string;
  buttonKind?: 'default' | 'primary';
  menuItems: AddTargetingMenuItem[];
  label: string;
  isIconButton?: boolean;
  ariaLabel?: string;
};

export const AddTargetingMenu = ({
  addIcon,
  buttonKind,
  addButtonClassName,
  menuItems,
  label,
  isIconButton,
  ariaLabel,
}: AddTargetingMenuProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const availableMenuItems = menuItems.filter((menuItem) => !menuItem.hidden);
  const showMenuItems = availableMenuItems.length > 1;

  const profile = useProfileEntity();

  const handlePopoverClosed = () => {
    setShowMenu(false);
  };

  const handleOnClick = (onClick: () => void) => {
    setShowMenu(false);
    onClick();
  };

  const handleAddButtonClicked = () => {
    if (showMenuItems) {
      setShowMenu(!showMenu);
    }

    if (availableMenuItems.length === 1) {
      handleOnClick(availableMenuItems[0].onClick);
    }
  };

  const renderButton = () => {
    if (isIconButton) {
      return (
        <IconButton
          aria-label={ariaLabel || label}
          className={addButtonClassName}
          kind={buttonKind}
          icon={
            addIcon ? (
              addIcon
            ) : (
              <>
                <Icon name="add" size="small" /> Add {label}
              </>
            )
          }
          onClick={handleAddButtonClicked}
        />
      );
    }

    return (
      <Button
        kind={buttonKind}
        className={addButtonClassName}
        onClick={handleAddButtonClicked}
        aria-label={ariaLabel || label}
        data-test-id={`add-targeting-menu-${label}-button`}
        renderIconFirst
        icon={addIcon ? addIcon : <Icon name="add" size="small" />}
      >
        {!addIcon && <>Add {label}</>}
      </Button>
    );
  };

  return profile.isReader() ? null : (
    <Popover interactionKind="click" isOpen={showMenu} onClose={handlePopoverClosed} placement="bottom-start">
      {renderButton()}

      {showMenuItems && (
        <Menu menuItemClassName={styles.menuItem}>
          {availableMenuItems.map((menuItem, index) => {
            if (menuItem.hidden) {
              return null;
            }
            return (
              <MenuItem
                key={index}
                onClick={() => handleOnClick(menuItem.onClick)}
                onKeyDown={(event: React.KeyboardEvent<HTMLButtonElement>) => {
                  if (event.key === 'Enter') {
                    handleOnClick(menuItem.onClick);
                  }
                }}
                disabled={menuItem.disabled}
                tooltip={menuItem.tooltip}
                tooltipPlacement={menuItem.tooltipPlacement}
              >
                {menuItem?.icon} <span className={styles.menuItemLabel}>{menuItem.label}</span>
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </Popover>
  );
};
