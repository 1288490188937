import { useNavigate, useParams } from 'react-router-dom';
import { Time } from '@gonfalon/datetime';
import { DateFormat } from '@gonfalon/format';
import { toArchiveFlagFromSettings } from '@gonfalon/navigator';
import { Banner, Button } from 'launchpad';

import Restrict from 'components/Restrict';
import { AccessDecision } from 'utils/accessUtils';
import { Flag } from 'utils/flagUtils';

import { trackDeprecateEvent } from '../../utils/archiveUtils';

export type DeprecatedFlagBannerProps = {
  flag: Flag;
  checkAccess: (type?: string) => AccessDecision;
};

export function DeprecatedFlagBanner({ flag, checkAccess }: DeprecatedFlagBannerProps) {
  const { projKey, envKey, flagKey } = useParams();
  const deprecateFlagAccess = checkAccess('updateDeprecated');

  const navigateToArchivePanel = () => {
    trackDeprecateEvent('Deprecated Flag Banner Dismissed', { location: 'Flag Settings' });
    navigate(
      toArchiveFlagFromSettings({
        projectKey: projKey || '',
        environmentKey: envKey || '',
        flagKey: flagKey || '',
      }),
    );
  };

  const navigate = useNavigate();

  return (
    <Banner kind="info">
      <span className="u-color-text-ui-primary">
        {' '}
        <span>You deprecated this flag on</span>{' '}
        <Time dateFormat={DateFormat.MMM_D_YYYY} datetime={flag?.deprecatedDate} notooltip />.{' '}
        <span>Turn the flag off to serve the same variation to all contexts.</span>{' '}
        <Restrict
          isRestricted={!deprecateFlagAccess.isAllowed}
          tooltip={deprecateFlagAccess.getActionReason()}
          willDisable
        >
          <Button kind="link" onClick={navigateToArchivePanel}>
            Archive this flag.
          </Button>
        </Restrict>
      </span>
    </Banner>
  );
}
