import { MouseEvent, ReactNode, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useDebounce } from '@gonfalon/async';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { isSyncedSegmentRelayUIEnabled } from '@gonfalon/dogfood-flags';
import { uniqBy, xor } from '@gonfalon/es6-utils';
import { CustomSelect } from '@gonfalon/launchpad-experimental';
import { toCreateSegment, toHref, toSegments } from '@gonfalon/navigator';
import { useProjectKey } from '@gonfalon/router';
import { trackSegmentEvent } from '@gonfalon/segments';
import { pluralize } from '@gonfalon/strings';
import { List } from 'immutable';
import { Popover } from 'launchpad';

import { FullDate } from 'components/dateFormatting';
import { AlignValue, Cell, CellLayoutValue, Grid } from 'components/ui/grid';
import { IconDimension } from 'components/ui/icons/types';
import { useGoaltenderManifests } from 'hooks/useGoaltenderManifests';
import { useSegments } from 'hooks/useSegments';
import { useUsage } from 'hooks/useUsage';
import { trackFlagTargetingEvent } from 'utils/flagUtils';
import { Segment } from 'utils/segmentUtils';

import { SegmentPopover } from '../SegmentPopover';
import { SegmentPagePlacement, SyncedSegmentLogo } from '../syncedSegment/SyncedSegmentLogo';
import { SyncedSegmentProvider } from '../syncedSegment/SyncedSegmentsProvider';

import styles from './SelectSegmentV2.module.css';

export type SelectSegmentV2Props = {
  disabled?: boolean;
  id: string;
  segmentKeys: List<string>;
  onChange(selectedOption: string[]): void;
  onBlur?(): void;
};

type OptionProps = {
  label: string;
  segment: Segment;
  siteLink: string;
  value: string;
  metadata: ReactNode;
};

export const SelectSegmentV2 = ({ segmentKeys, disabled, id, onChange, onBlur }: SelectSegmentV2Props) => {
  const projKey = useProjectKey();
  const envKey = useSelectedEnvironmentKey();
  const [queryValue, setQueryValue] = useState('');

  const segment = useOutletContext<{ segment: Segment }>();
  const currentSegmentKey = segment?.segment.key ?? '';

  const { segments: selectedSegments } = useSegments({
    projKey,
    envKey,
    appliedParams: { filter: { keys: segmentKeys.toJS() } },
    enabled: segmentKeys.size > 0,
  });

  const { segments, isReady } = useSegments({
    projKey,
    envKey,
    appliedParams: {
      filter: {
        query: queryValue,
        excludedKeys: selectedSegments.map((s: Segment) => s.key).toArray(),
      },
      sort: '-lastModified',
      limit: 20,
    },
  });

  const { manifests } = useGoaltenderManifests();
  const { relayDetection } = useUsage();
  const debouncedSetQueryValue = useDebounce((v: string) => {
    setQueryValue(v);
  }, 500);
  const isRelayUIEnabled = isSyncedSegmentRelayUIEnabled();

  const manageSegmentsUrl = toHref(
    toSegments({
      projectKey: projKey,
      environmentKey: envKey,
    }),
  );
  const newSegmentUrl = toHref(
    toCreateSegment({
      projectKey: projKey,
      environmentKey: envKey,
    }),
  );
  const segmentKeysArr = segmentKeys.toArray();

  // top 5 most targeted segments
  const mostTargetedOptions = {
    label: 'Most targeted segments',
    options: segments
      .filter((s: Segment) => s._flagCount > 0)
      .filter((s: Segment) => s.key !== currentSegmentKey)
      .sortBy(
        (s: Segment) => s._flagCount,
        (a: number, b: number) => b - a,
      )
      .map((s: Segment) => ({
        value: s.key,
        label: `${s.name}${s.countAllTargetsAndRules() === 0 ? ' (0 targets)' : ''}`,
        siteLink: s.siteLink(),
        segment: s,
        metadata: (
          <div className={styles.metadata} data-test-id="targeted-segment-metadata">
            Targeted by {s._flagCount} {pluralize('flag', s._flagCount)}
          </div>
        ),
      }))
      .toArray()
      .slice(0, 5),
  };

  // top 5 sorted by default by lastModified asc
  const recentlyUpdatedOptions = {
    label: 'Recently updated segments',
    options: segments
      .filter((s: Segment) => s.key !== currentSegmentKey)
      .sortBy(
        (s: Segment) => s.lastModifiedDate || s.creationDate,
        (a: number, b: number) => b - a,
      )
      .map((s: Segment) => ({
        value: s.key,
        label: `${s.name}${s.countAllTargetsAndRules() === 0 ? ' (0 targets)' : ''}`,
        siteLink: s.siteLink(),
        segment: s,
        metadata: (
          <div className={styles.metadata} data-test-id="last-updated-segment-metadata">
            Last modified: <FullDate datetime={!!s.lastModifiedDate ? s.lastModifiedDate : s.creationDate} />
          </div>
        ),
      }))
      .toArray()
      .slice(0, 5),
  };

  // adds the rest of the segments results to the bottom of the list
  const restOfSegments = {
    label: 'Segments',
    options: segments
      .map((s: Segment) => ({
        value: s.key,
        label: `${s.name}${s.countAllTargetsAndRules() === 0 ? ' (0 targets)' : ''}`,
        siteLink: s.siteLink(),
        segment: s,
        metadata: <></>,
      }))
      .toArray(),
  };

  const selectedOptions = {
    label: 'Selected segments',
    options: selectedSegments
      .map((s: Segment) => ({
        value: s.key,
        label: `${s.name}${s.countAllTargetsAndRules() === 0 ? ' (0 targets)' : ''}`,
        siteLink: s.siteLink(),
        segment: s,
        metadata: <></>,
      }))
      .toArray(),
  };

  const groupedOptions = [mostTargetedOptions, recentlyUpdatedOptions, restOfSegments];
  // selected segments won't show in the list, but they must be present so they can be shown as chips in the input
  if (segmentKeys.size > 0) {
    groupedOptions.push(selectedOptions);
  }

  const allOptions = groupedOptions.reduce((allOpts: OptionProps[], opts) => allOpts.concat(opts.options), []);

  const handleSearchInputChange = (val: string) => {
    debouncedSetQueryValue(val || '');
  };

  const handleSelectInputChange = (valueSelected: OptionProps[]) => {
    const segmentKeysSelected = valueSelected ? valueSelected.map((v: OptionProps) => v.value) : [];
    onChange(segmentKeysSelected);
    // new segment that was selected
    const changedSegmentKey = xor(segmentKeysSelected, segmentKeysArr)[0];
    const changedSegment = segments.find((s: Segment) => s.key === changedSegmentKey);
    if (changedSegmentKey && changedSegment) {
      const addedRemoved = segmentKeysSelected.length > segmentKeysArr.length ? 'Added' : 'Removed';
      trackSegmentEvent(`Segment ${addedRemoved} to Flag Targeting`, { type: changedSegment.getType() });
    }
  };

  const renderOptionDisplayName = (option: OptionProps) => (
    <>
      {option.label}{' '}
      {option.segment._external && (
        <SyncedSegmentLogo
          integrationKey={option.segment._external}
          placement={SegmentPagePlacement.row}
          size={IconDimension.SMALL}
        />
      )}
    </>
  );

  const renderOption = (option: OptionProps, { context }: { context: string }) => {
    switch (context) {
      case 'menu':
        return (
          <Grid align={AlignValue.BASELINE}>
            <Cell>{renderOptionDisplayName(option)}</Cell>
            <Cell layout={CellLayoutValue.AUTO}>{option.metadata}</Cell>
          </Grid>
        );
      case 'value':
        return (
          <div
            role="presentation"
            onMouseDown={(event: MouseEvent) => {
              // We stop the mousedown event from propagating so that the menu does
              // not open when the user clicks on "View details". (ch81030)
              event.stopPropagation();
            }}
          >
            <Popover interactionKind="hover" placement="bottom">
              <span>{renderOptionDisplayName(option)}</span>
              <SegmentPopover
                segment={option.segment}
                isRelaySetup={relayDetection}
                isRelayUIEnabled={isRelayUIEnabled}
                showRelayAlert={false}
              />
            </Popover>
          </div>
        );
      default:
        return undefined;
    }
  };

  const customStyles = {
    control: { padding: '0.125rem 0' },
    valueContainer: { maxHeight: '10.625rem', overflow: 'auto', padding: '0.125rem 0.1875rem' },
    menu: { marginTop: '0' },
    menuList: { paddingTop: '0' },
    multiValue: {
      alignItems: 'center',
      background: 'var(--lp-color-bg-interactive-secondary-hover)',
      borderRadius: '0.5rem',
      height: '1.75rem',
      maxWidth: '32.5rem',
      marginLeft: '0.1875rem',
      paddingLeft: '0.0625rem',
      pointerEvents: 'all',
    },
    multiValueLabel: {},
    multiValueRemove: {
      borderRadius: '0 0.5rem 0.5rem 0',
      height: '100%',
      display: disabled ? 'none' : 'flex',
    },
    group: {
      '&:first-of-type': {
        paddingTop: '0',
        borderTop: 'none',
      },
      paddingTop: '0.375rem',
      borderTop: '1px solid var(--lp-color-border-ui-secondary)',
      paddingBottom: '0',
    },
    groupHeading: {
      color: 'var(--field-placeholder-color)',
      fontSize: '0.875rem',
      textTransform: 'uppercase',
      padding: '0.375rem 0.75rem',
      marginBottom: '0',
      fontWeight: 'var(--lp-font-weight-semibold)',
    },
  };

  return (
    <SyncedSegmentProvider goaltenderManifests={manifests}>
      <CustomSelect
        className={SelectSegmentV2ClassName}
        isMulti
        id={id}
        options={groupedOptions}
        onInputChange={handleSearchInputChange}
        value={uniqBy(
          allOptions.filter((option: OptionProps) => segmentKeysArr.includes(option.value)),
          (o) => o.value,
        )}
        placeholder="Select segments..."
        aria-label="Select segments"
        onChange={handleSelectInputChange}
        onBlur={onBlur}
        noOptionsMessage={() => (
          <span>
            No segments found —{' '}
            <a href={manageSegmentsUrl} target="_blank" rel="noreferrer noopener">
              Manage segments
            </a>{' '}
            or{' '}
            <a
              href={newSegmentUrl}
              onClick={() => trackFlagTargetingEvent('Create a new segment from Flag Targeting')}
              target="_blank"
              rel="noreferrer noopener"
            >
              Create a new segment
            </a>
          </span>
        )}
        formatOptionLabel={renderOption}
        disabled={disabled}
        styles={customStyles}
        controlShouldRenderValue
        isLoading={!isReady}
      />
    </SyncedSegmentProvider>
  );
};

// this class is used to query the DOM for all instances of this component in the workflow builder when applying a workflow template
export const SelectSegmentV2ClassName = 'SelectSegmentV2';
