import { useDeferredValue, useState } from 'react';
import { getProjectListPageSize } from '@gonfalon/dogfood-flags';
import { CustomSelect, OptionProps } from '@gonfalon/launchpad-experimental';
import type { Project } from '@gonfalon/projects';
import { useProjects } from '@gonfalon/rest-api';

export type SelectProjectProps = {
  onChange(project: Project): void;
  value: Project | undefined;
  id?: string;
  name?: string;
  required?: boolean;
  disableSelect?: boolean;
};

export function SelectProject({ onChange, value, id, name, required, disableSelect }: SelectProjectProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const deferredSearchTerm = useDeferredValue(searchTerm);
  const customStyles = {
    option: {
      fontSize: '0.8125rem',
      padding: '0.3125rem 0.3125rem 0.3125rem 0.625rem',
    },
    input: {
      fontSize: '0.8125rem',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    valueContainer: {
      lineHeight: '0.9375rem',
      fontSize: '0.8125rem',
    },
    control: {
      border: '1px solid var(--lp-color-border-field-base)',
      borderRadius: 'var(--lp-border-radius-regular)',
    },
  };

  const { data, isPending } = useProjects({
    params: { filter: { query: deferredSearchTerm }, limit: getProjectListPageSize() },
  });

  function onChangeValue(selection: OptionProps) {
    onChange(selection.project);
  }

  return (
    <CustomSelect
      disabled={disableSelect}
      id={id}
      ariaLabel={id ? undefined : 'Select project'}
      options={data && data.items.map((p) => ({ value: p.key, label: p.name, project: p }))}
      name={name}
      required={required}
      value={{ value: value?.key, label: value?.name, project: value }}
      onInputChange={(input) => setSearchTerm(input)}
      onChange={onChangeValue}
      isLoading={isPending && !data}
      styles={customStyles}
    />
  );
}
