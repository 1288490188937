import { Control, Controller, useFieldArray, UseFormReturn, useFormState, useWatch } from 'react-hook-form';
import {
  Alert,
  Button,
  FieldError,
  FieldGroup,
  IconButton,
  Input,
  ListBox,
  ListBoxItem,
  Popover,
  Select,
  SelectValue,
  Text,
  TextField,
} from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';

import { AccessDecision, allowDecision } from 'utils/accessUtils';
import { urlMatcherKindDisplayNames } from 'utils/goalUtils';
import { isAbsolute } from 'utils/urlUtils';

import { MetricEventUrlKind, MetricForm } from '../common';

import { RequiredLabel } from './RequiredLabel';

import styles from './MetricUrls.module.css';

const formHints: { [k in MetricEventUrlKind]: string } = {
  canonical: 'Use "simple match" to target a single URL that does not include query or hash parameters',
  exact: 'Use "exact match" to target a single URL that specifies a query or hash parameter',
  substring: 'Use "substring match" to target all URLs that contain a specific string of text',
  regex: 'Use "regular expression" to target all URLs that match a regular expression',
};

type Props = {
  access?: AccessDecision;
  isSaving?: boolean;
  context: UseFormReturn<MetricForm>;
};

export function MetricUrls({ access = allowDecision(), isSaving, context }: Props) {
  const { control, watch } = context;
  const { fields, append, remove } = useFieldArray({ control, name: 'urls' });
  const { errors } = useFormState<MetricForm>({ control, name: 'urls' });
  const watchArr = watch('urls', fields);

  return (
    <div className={styles.targetUrlContainer}>
      {fields.map(({ id }, index) => (
        <div key={id} className={styles.targetUrlRow}>
          <FieldGroup aria-label={`Target URL ${index + 1}`} name="urls">
            <div>
              <div className={styles.targetingRow}>
                <div className={styles.targetType}>
                  <Controller
                    control={control}
                    name={`urls.${index}.kind`}
                    render={({ field }) => (
                      <Select
                        onSelectionChange={field.onChange}
                        selectedKey={field.value}
                        isDisabled={isSaving || !access.isAllowed}
                        aria-invalid={errors.urls?.[index] ? 'true' : 'false'}
                      >
                        <RequiredLabel label="Target Type" htmlFor="targetType" className={styles.targetLabel} />
                        <Button>
                          <SelectValue />
                          <Icon name="chevron-down" size="small" />
                        </Button>
                        <Popover>
                          <ListBox>
                            {Object.entries(urlMatcherKindDisplayNames).map(([value, label]) => (
                              <ListBoxItem key={value} id={value}>
                                {label}
                              </ListBoxItem>
                            ))}
                          </ListBox>
                        </Popover>
                      </Select>
                    )}
                  />
                </div>
                <TextField className={styles.targetUrlInput} isInvalid={Boolean(errors.urls?.[index])}>
                  <RequiredLabel label="Target URL" className={styles.targetLabel} />

                  <div className={styles.removeTargetUrlContainer}>
                    <Input
                      key={id}
                      {...control.register(`urls.${index}.value`, {
                        validate: (value, formValues) => validate(value, formValues, index),
                        deps: ['kind'],
                      })}
                      value={watchArr && watchArr[index].value}
                      disabled={isSaving || !access.isAllowed}
                      placeholder="Enter URL"
                      className={styles.targetUrlInputBar}
                    />
                    {fields.length > 1 && (
                      <IconButton
                        aria-label={`Remove URL ${index + 1}`}
                        icon="minus-circle"
                        onPress={() => remove(index)}
                        className={styles.removeTargetUrlIcon}
                      />
                    )}
                  </div>
                  {errors.urls?.[index] && (
                    <FieldError>{errors.urls?.[index]?.value?.message || 'Required'}</FieldError>
                  )}
                </TextField>
              </div>
              <KindHint control={control} index={index} />
            </div>
          </FieldGroup>
        </div>
      ))}
      <span className={styles.addTargetUrl}>
        <Button
          aria-label="Add URL"
          onPress={() => append({ kind: 'canonical', value: '' })}
          className={styles.noBorderIcon}
          isDisabled={isSaving || !access.isAllowed}
        >
          <Icon name="add-circle" size="small" />
          <span className={styles.addTargetUrlText}>Add target URL</span>
        </Button>
      </span>
      <Alert isOpen={!access.isAllowed} className={styles.noAccessAlert}>
        <Text>{access.getModifyFieldReason()}</Text>
      </Alert>
    </div>
  );
}

function validate(value: string, formValues: MetricForm, index: number): boolean | string {
  const kind = formValues.urls?.[index].kind;
  //TODO: Check for duplicates and alert

  switch (kind) {
    // TODO the backend doesn't validate this, do we really need to?
    case 'regex':
    case 'substring':
      //substring/pattern is not empty
      return value.length > 0 || 'This field is required';
    case 'canonical':
    case 'exact':
      //isNotEmpty
      //isAbsoluteURL
      return (isAbsolute(value) && value.length > 0) || 'Must be an absolute URL';
    default:
      return !!value || 'Required';
  }
}

function KindHint({ control, index }: { control: Control<MetricForm>; index: number }) {
  const kind = useWatch({ control, name: `urls.${index}.kind` });
  return (
    <Text slot="subtitle" className={styles.kindHint}>
      {formHints[kind]}
    </Text>
  );
}
