import { ClipboardEventHandler, Component, FocusEvent } from 'react';
import { components, MultiValueProps } from 'react-select';
import { ClauseValue, coerceClauseValues } from '@gonfalon/clauses';
import { isPlainObject } from '@gonfalon/es6-utils';
import { CustomCreatable, CustomSelectAction, OptionProps } from '@gonfalon/launchpad-experimental';
import { coerceToType } from '@gonfalon/types';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List } from 'immutable';
import { Label } from 'launchpad';
import { from, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { v4 } from 'uuid';

import { fetchContextAttributeValues } from 'components/Contexts/common/contextAPI';
import { ContextAttributesValuesResponse, ContextKind } from 'components/Contexts/types';
import { makeSelectOptionsForContextAttributeValues } from 'components/Contexts/utils/contextTargetingUtils';
import { CONTEXT_KIND_ATTRIBUTE } from 'utils/constants';
import { DEBOUNCE_MS } from 'utils/inputUtils';
import { areAllStrings } from 'utils/typeUtils';

import { SelectAttributeValueType } from './SelectAttributeValueType';

const createNewOption = ({ label, type }: { label: ClauseValue; type?: 'number' | 'string' | 'boolean' | 'json' }) => ({
  label,
  value: label,
  type,
  isCreated: true,
});

// NOTE(ag): Some users were confused that values they typed
// into the field were not automatically added to the list of
// selected values selected if the field was blurred. So we
// handle the blur event and potentially trigger a change
// to add anything the user has typed in.
const onAttributeValueBlur = (onChange: (v: OptionProps) => void) => (event: FocusEvent<HTMLInputElement>) => {
  // CH1634(ag): one of the react-select pieces remains
  // focusable even when the component is disabled, and
  // blurring that piece will invoke this handler without
  // a value

  if (event.target.value === undefined) {
    return;
  }

  const value = event.target.value.trim();
  if (value.length > 0) {
    onChange(createNewOption({ label: value }));
  }
};

const isOptionObject = (option: OptionProps[] | OptionProps): option is OptionProps => isPlainObject(option);
const isContextsAwareResponse = (
  res: List<ClauseValue> | ContextAttributesValuesResponse,
  isTargetingByContextEnabled: boolean,
): res is ContextAttributesValuesResponse => isTargetingByContextEnabled;
// Values is the array of values that were passed into the field.
// This works in tandem with the custom onBlur handler to be able
// to add a new option.
const onAttributeValueChange =
  (onChange: (values: List<string>) => void, values: ClauseValue[], expectedType?: 'string' | 'number' | undefined) =>
  (vs: OptionProps[] | OptionProps) => {
    let newValues: Array<OptionProps | ClauseValue>;

    // Is this a single value created via blur?
    if (isOptionObject(vs)) {
      newValues = [...values, vs];
    } else if (vs) {
      newValues = vs;
    } else {
      newValues = [];
    }

    const coercedValues = List(
      coerceClauseValues(fromJS(newValues), {
        expectedType: areAllStrings(values) ? 'string' : expectedType,
        preserveTypes: true,
      }),
    );

    onChange(coercedValues);
  };

const onAttributeValueCreated = (onChange: (vs: OptionProps[] | OptionProps) => void) => (v: string) =>
  onChange(createNewOption({ label: v }));

export type SelectAttributeValuesProps = {
  attribute: string;
  values: ClauseValue[];
  disabled: boolean;
  onChange(values: List<string>): void;
  onPaste?: ClipboardEventHandler<HTMLDivElement>;
  autoload?: boolean;
  autocomplete?: boolean;
  expectedType?: 'string' | 'number' | undefined;
  isClearable?: boolean;
  value?: OptionProps;
  placeholder?: string;
  className?: string;
  ariaLabel?: string;
  contextKind: string;
  projectKey: string;
  environmentKey: string;
  projectContextKinds: ContextKind[];
};

type StateProps = {
  isPristine: boolean;
  isLoading: boolean;
  options: OptionProps[];
};

export class SelectAttributeValues extends Component<SelectAttributeValuesProps, StateProps> {
  // This subject can be fed strings which it will emit to any listeners.
  loadOptions$: Subject<string>;
  // This observable is a (collection over time of) a list of (react-select) options.
  options$: Observable<OptionProps[]>;
  getAttributeValues: (q: string) => Promise<List<ClauseValue> | ContextAttributesValuesResponse>;

  constructor(props: SelectAttributeValuesProps) {
    super(props);
    this.state = {
      isPristine: true,
      isLoading: false,
      options: [],
    };

    this.getAttributeValues = async (q: string) =>
      fetchContextAttributeValues(
        this.props.projectKey,
        this.props.environmentKey,
        this.props.contextKind,
        this.props.attribute,
        q,
      );

    this.loadOptions$ = new Subject<string>();
    this.options$ = this.loadOptions$.pipe(
      debounceTime(DEBOUNCE_MS),
      switchMap((q) =>
        from(this.getAttributeValues(q)).pipe(
          map((res) => {
            if (!isContextsAwareResponse(res, true)) {
              return res.map((value: ClauseValue) => ({ value, label: value.toString() })).toArray();
            }

            if (this.props.attribute === CONTEXT_KIND_ATTRIBUTE) {
              return this.props.projectContextKinds.map((kind: ContextKind) => ({
                value: kind.key,
                label: kind.name.toString(),
              }));
            }

            return makeSelectOptionsForContextAttributeValues(res.items, this.props.contextKind);
          }),
          catchError(() => of([])),
        ),
      ),
    );
  }

  componentDidMount() {
    this.options$.subscribe((options: OptionProps[]) => {
      this.setState({
        isLoading: false,
        options: this.props.expectedType === 'number' ? options.filter((o) => typeof o.value === 'number') : options,
      });
    });
  }

  componentDidUpdate(prevProps: SelectAttributeValuesProps) {
    if (prevProps.contextKind !== this.props.contextKind) {
      this.loadOptions('');
    }
  }

  render() {
    const { values, disabled, onChange, onPaste, expectedType, ...props } = this.props;
    const { isLoading, options } = this.state;
    const selectValueType = (label: string, value: string, isInMenu: boolean) => (
      <SelectAttributeValueType<ClauseValue>
        isInMenu={isInMenu}
        disabled={disabled}
        expectedType={expectedType}
        onTypeChange={this.handleTypeChange}
        label={label}
        value={value}
      />
    );
    const MultiValue = (multiValueProps: MultiValueProps<{ label: string; value: string }>) => (
      <components.MultiValue {...multiValueProps}>
        {selectValueType(multiValueProps.data.label, multiValueProps.data.value, false)}
      </components.MultiValue>
    );
    const fieldId = v4();

    return (
      <div onPaste={onPaste}>
        <Label htmlFor={fieldId}>Values</Label>
        <CustomCreatable
          id={fieldId}
          isMulti
          customComponents={{ MultiValue }}
          {...props}
          isDisabled={disabled}
          isValidNewOption={(input: string) => input.length > 0 && !values.find((value) => input === String(value))}
          noOptionsMessage={() => (isLoading ? 'Loading values' : 'No values found')}
          options={options}
          onFocus={this.handleFocus}
          onInputChange={this.handleInputChange}
          onBlur={onAttributeValueBlur(onAttributeValueChange(onChange, values, expectedType))}
          onChange={onAttributeValueChange(onChange, values, expectedType)}
          onCreateOption={onAttributeValueCreated(onAttributeValueChange(onChange, values, expectedType))}
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          formatOptionLabel={({ __isNew__, label, value }: { __isNew__: boolean; label: string; value: string }) =>
            __isNew__ ? `Add "${value}"` : selectValueType(label, value, true)
          }
        />
      </div>
    );
  }

  // GOTCHA: we are using handleFocus and isPristine here to load the initial
  // "all" options instead of onWindowOpen because of a bug in react select where
  // onWindowOpen gets invoked on every keystroke. See the official issue and our
  // pr discussion below:
  // https://github.com/JedWatson/react-select/issues/3335
  // https://github.com/launchdarkly/gonfalon/pull/9018#pullrequestreview-492963565
  handleFocus = () => {
    // load the "all" options once at the start of user interaction
    if (this.state.isPristine) {
      this.setState({ isPristine: false });
      this.loadOptions('');
    }
  };

  handleInputChange = (input: string, { action }: CustomSelectAction) => {
    const inputTrimmed = input.trim();

    if (inputTrimmed === '') {
      // reset to "all" options when closing the menu or the search input is an empty
      if (action === 'menu-close' || action === 'input-change') {
        this.loadOptions('');
      }
    } else {
      this.loadOptions(inputTrimmed);
    }
  };

  handleTypeChange = (value: ClauseValue, newType?: 'string' | 'number' | 'boolean') => {
    const newValue = coerceToType(value, newType);
    if (newValue !== value) {
      const { onChange, expectedType, values } = this.props;
      const index = values.indexOf(value);
      values[index] = newValue;
      onAttributeValueChange(
        onChange,
        [],
        expectedType,
      )(values.map((v) => createNewOption({ label: v, type: newType })));
    }
  };

  loadOptions(q: string) {
    this.setState(
      {
        isLoading: true,
        options: [],
      },
      () => {
        this.loadOptions$.next(q);
      },
    );
  }
}
