// eslint-disable-next-line no-restricted-imports
import { useSelector } from 'react-redux';

import { EnvironmentMarker } from 'components/EnvironmentMarker';
import { currentEnvironmentSelector, currentProjectSelector } from 'reducers/projects';

import './styles.css';

export const useRedux = () => {
  const currentEnvironmentState = useSelector(currentEnvironmentSelector).get('entity');
  const currentProjectName = useSelector(currentProjectSelector).getIn(['entity', 'name']);

  return {
    currentEnvironmentState,
    currentProjectName,
  };
};

const ProjectEnvironmentLabel = () => {
  const { currentEnvironmentState, currentProjectName } = useRedux();

  return (
    <div className="ProjectEnvironmentLabel">
      <div className="ProjectEnvironmentLabel-label">
        <div>Environment |</div>
        <div className="ProjectEnvironmentLabel-environmentMarker">
          <EnvironmentMarker env={currentEnvironmentState} />
        </div>
      </div>
      <div className="ProjectEnvironmentLabel-label">
        <div>Project | </div>
        <div className="ProjectEnvironmentLabel-projectLabelContent">{currentProjectName}</div>
      </div>
    </div>
  );
};

/* eslint-disable import/no-default-export */
export default ProjectEnvironmentLabel;
