// ensure that the value passed in is a number between 0 and 100
//  using strings because intended usecase is with inputs
const constrainPercentage = (input: string) => {
  const i = parseFloat(input);
  if (i >= 0 && i <= 100) {
    return `${i}`;
  }
  return i > 100 ? '100' : '0';
};
export { constrainPercentage };

export function fixFractionDigitsWithoutRounding(num: number, fractionDigits: number) {
  return Math.floor(num * 10 ** fractionDigits) / 10 ** fractionDigits;
}
