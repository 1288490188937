import { type FeatureFlagStatus } from '@gonfalon/flags';
import { useIsInNewIA } from '@gonfalon/ia-migration';
import { toFlagTargeting } from '@gonfalon/navigator';
import {
  ReleaseTrackerPhaseStatus,
  trackPhaseReactivated,
  trackPhaseReactivatedAttempted,
} from '@gonfalon/release-pipelines';
import { useFlag, useUpdateFlagRelease } from '@gonfalon/rest-api';
import { useFlagKey, useProjectKey } from '@gonfalon/router';
import { capitalize } from '@gonfalon/strings';
import { SnackbarQueue, ToastQueue } from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';
import { format } from 'date-fns';
import { Alert, Button, Chip } from 'launchpad';

import { ReleasePhaseLabel } from 'components/releases/constants';
import {
  ReleasesFlagEnvironmentSummary,
  ReleasesFlagEnvironmentSummaryProps,
} from 'components/releases/legacy/common/ReleasesFlagEnvironmentSummary/ReleasesFlagEnvironmentSummary';
import { useCheckAccess } from 'utils/accessUtils';

import { formatReleasePhaseActivationDate } from '../../common/releaseUtils';
import { ReleaseDuration } from '../common/ReleaseDuration';

import styles from './LegacyReleaseTrackerPhasePopover.module.css';

export type LegacyReleaseTrackerPopoverFlagEnvironmentSummary = {
  environment: ReleasesFlagEnvironmentSummaryProps['environment'];
  isTargeting: boolean;
  flagStatus: FeatureFlagStatus['name'];
  flagVariations: Array<{
    value: string;
    name?: string;
    color: string;
  }>;
};

export type ReleaseTrackerPhasePopoverProps = {
  status: Omit<ReleaseTrackerPhaseStatus, 'released'>;
  isError?: boolean;
  activationDate?: number;
  completionDate?: number;
  // trying to remove this detail which is only need to making the mutation request
  index?: number;
  isLastPhase?: boolean;
  phaseName: string;
  phaseId: string;
  canReactivated?: boolean;
  isV2Pipeline?: boolean;
  flagEnvironmentSummaries: LegacyReleaseTrackerPopoverFlagEnvironmentSummary[];
  setIsOpen?: (isOpen: boolean) => void;
  setSaveModalOpen?: (isOpen: boolean) => void;
  markPhaseActive?: () => void;
  markPhaseComplete: () => void;
};

type ActivePhasePopoverProps = {
  isLastPhase?: boolean;
  isError?: boolean;
  summaries: LegacyReleaseTrackerPopoverFlagEnvironmentSummary[];
  activationDate?: number;
  isV2Pipeline: boolean;
  phaseName: string;
  setSaveModalOpen?: (isOpen: boolean) => void;
  setIsOpen?: (isOpen: boolean) => void;
  markPhaseComplete: () => void;
};

function PhaseLoadErrorAlert({ phaseName }: { phaseName: string }) {
  return (
    <Alert kind="error" isInline>
      Failed to load flag data for {phaseName} phase. Please try again later
    </Alert>
  );
}

function ActivePhasePopover({
  summaries,
  activationDate,
  isLastPhase,
  phaseName,
  isError,
  isV2Pipeline,
  setIsOpen,
  setSaveModalOpen,
  markPhaseComplete,
}: ActivePhasePopoverProps) {
  const isFlagCompleteForPhase = summaries.every((summary) => summary.flagStatus === 'launched');
  const hasCompleteAccess = useCheckAccess()('updateReleasePhaseCompleted').isAllowed;
  const isInNewIA = useIsInNewIA();

  const handleOpenModal = () => {
    setIsOpen?.(false);
    setSaveModalOpen?.(true);
  };

  const projectKey = useProjectKey();
  const flagKey = useFlagKey();
  const { data: flag } = useFlag({ projectKey, flagKey });
  const canComplete = hasCompleteAccess && !flag?.archived;

  return (
    <div className={styles.phase} data-test-id={getPhasePopoverTestId(phaseName)}>
      <p className={styles.popoverHeaderActive}>
        <span className={styles.activeLabel}>Active</span>
        {!!activationDate && (
          <span className="u-color-text-ui-primary"> {formatReleasePhaseActivationDate(activationDate)}</span>
        )}
      </p>
      {isFlagCompleteForPhase && (
        <Chip className={styles.readyToComplete}>{capitalize(ReleasePhaseLabel.PHASE_READY_TO_COMPLETE_LABEL)}</Chip>
      )}
      {isError ? (
        <PhaseLoadErrorAlert phaseName={phaseName} />
      ) : (
        <FlagEnvironmentSummaries flagKey={flagKey} projectKey={projectKey} summaries={summaries} />
      )}
      {!isError &&
        !isV2Pipeline &&
        canComplete &&
        // The new IA implementation hoists ownership of the mutations to a common ancestor (ReleaseTrackerCard).
        // This is a temporary branch to accomodate the new IA owning the mutation higher up, without
        // affecting this component under the existing IA. We can unify the implementations once
        // we're all happy with this.
        (isInNewIA ? (
          <Button kind="primaryFlair" onClick={markPhaseComplete} className={styles.gap}>
            {capitalize(
              isLastPhase
                ? ReleasePhaseLabel.LAST_PHASE_READY_TO_COMPLETE_CTA
                : ReleasePhaseLabel.PHASE_READY_TO_COMPLETE_CTA,
            )}
          </Button>
        ) : isLastPhase ? (
          <Button kind="primaryFlair" onClick={handleOpenModal} className={styles.gap}>
            {capitalize(ReleasePhaseLabel.LAST_PHASE_READY_TO_COMPLETE_CTA)}
          </Button>
        ) : (
          <Button kind="primaryFlair" onClick={markPhaseComplete} className={styles.gap}>
            {capitalize(ReleasePhaseLabel.PHASE_READY_TO_COMPLETE_CTA)}
          </Button>
        ))}
    </div>
  );
}

type FlagEnvironmentSummariesProps = {
  summaries: LegacyReleaseTrackerPopoverFlagEnvironmentSummary[];
  projectKey: string;
  flagKey: string;
};

function FlagEnvironmentSummaries({ summaries, projectKey, flagKey }: FlagEnvironmentSummariesProps) {
  return (
    <>
      {summaries.map(({ environment, isTargeting, flagVariations: variations, flagStatus }, i) => (
        <ReleasesFlagEnvironmentSummary
          key={`flag-environment-summary-${i}`}
          environment={{
            ...environment,
            url: toFlagTargeting({ projectKey, flagKey, environmentKey: environment.key }),
          }}
          isTargeting={isTargeting}
          variations={variations}
          flagStatus={flagStatus}
        />
      ))}
    </>
  );
}

type CompletedPhaseProps = {
  // trying to remove this detail which is only need to making the mutation request
  index?: number;
  isError?: boolean;
  summaries: LegacyReleaseTrackerPopoverFlagEnvironmentSummary[];
  phaseName: string;
  activationDate?: number;
  completionDate: number;
  canReactivated?: boolean;
  isV2Pipeline: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  markPhaseActive?: () => void;
};

function CompletedPhase({
  index,
  isError,
  summaries,
  phaseName,
  canReactivated,
  isV2Pipeline,
  activationDate,
  completionDate,
  setIsOpen,
  markPhaseActive,
}: CompletedPhaseProps) {
  const projectKey = useProjectKey();
  const flagKey = useFlagKey();
  const { data: flag } = useFlag({ projectKey, flagKey });
  const hasReactivateAccess = useCheckAccess()('updateReleasePhaseCompleted').isAllowed;
  const canReactivate = canReactivated && hasReactivateAccess && !flag?.archived;

  const isInNewIA = useIsInNewIA();

  const { mutate: reactivatePhase } = useUpdateFlagRelease({
    onSuccess: () => {
      ToastQueue.success(`${phaseName} phase marked active`);
      trackPhaseReactivated();
    },
    onError: () => {
      SnackbarQueue.error({ description: 'Reactivating phase unsuccessful. Try again later.' });
    },
  });

  const reactivateCompletedPhase = () => {
    // We'll want to refactor this eventually -- this component doesn't need to know the
    // mutation details, but in the old IA, it does. This branch is temporary and
    // accomodates the new IA owning the mutation higher up.
    if (isInNewIA && markPhaseActive !== undefined) {
      markPhaseActive();
    } else {
      reactivatePhase({
        projectKey,
        flagKey,
        input: {
          patch: [
            {
              op: 'replace',
              path: `/phases/${index}/complete`,
              value: false,
            },
          ],
        },
      });
      setIsOpen?.(false);
      trackPhaseReactivatedAttempted();
    }
  };

  return (
    <div className={styles.phase} data-test-id={getPhasePopoverTestId(phaseName)}>
      <ReleaseDuration startDate={activationDate} completionDate={completionDate} />
      {isError ? (
        <PhaseLoadErrorAlert phaseName={phaseName} />
      ) : (
        <FlagEnvironmentSummaries projectKey={projectKey} flagKey={flagKey} summaries={summaries} />
      )}
      {!isError && !isV2Pipeline && canReactivate && (
        <Button
          data-test-id="CompletedPhaseReactivate-Button"
          renderIconFirst
          onClick={reactivateCompletedPhase}
          icon={<Icon name="arrow-undo" size="small" />}
          className={styles.gap}
        >
          {capitalize(ReleasePhaseLabel.PHASE_COMPLETED_CTA)}
        </Button>
      )}
    </div>
  );
}

export const getPhasePopoverTestId = (phaseName: string) => `release-tracker-phase-popover-${phaseName}` as const;

export function LegacyReleasedPopover({ completionDate }: { completionDate?: number }) {
  return (
    <div className={styles.phase} data-test-id="released-popover">
      <p className={styles.popoverHeaderActive}>
        {completionDate ? (
          <>
            <span className={styles.activeLabel}>Released</span>
            <span className="u-color-text-ui-primary"> on {format(new Date(completionDate), 'MM/dd/yyyy')}</span>
          </>
        ) : (
          <span>Complete all phases to release this flag</span>
        )}
      </p>
    </div>
  );
}

export function LegacyReleaseTrackerPhasePopover({
  status,
  isError,
  completionDate,
  flagEnvironmentSummaries,
  isLastPhase,
  index,
  phaseName,
  activationDate,
  canReactivated,
  isV2Pipeline,
  setIsOpen,
  setSaveModalOpen,
  markPhaseActive,
  markPhaseComplete,
}: ReleaseTrackerPhasePopoverProps) {
  const projectKey = useProjectKey();
  const flagKey = useFlagKey();

  if (status === 'complete' && completionDate !== undefined) {
    return (
      <CompletedPhase
        isError={isError}
        canReactivated={canReactivated}
        isV2Pipeline={!!isV2Pipeline}
        phaseName={phaseName}
        index={index}
        setIsOpen={setIsOpen}
        summaries={flagEnvironmentSummaries}
        activationDate={activationDate}
        completionDate={completionDate}
        markPhaseActive={markPhaseActive}
      />
    );
  }

  if (status === 'active') {
    return (
      <ActivePhasePopover
        isError={isError}
        isV2Pipeline={!!isV2Pipeline}
        setIsOpen={setIsOpen}
        summaries={flagEnvironmentSummaries}
        isLastPhase={isLastPhase}
        setSaveModalOpen={setSaveModalOpen}
        markPhaseComplete={markPhaseComplete}
        activationDate={activationDate}
        phaseName={phaseName}
      />
    );
  }

  // Inactive status
  return (
    <div className={styles.phase} data-test-id={getPhasePopoverTestId(phaseName)}>
      {isError ? (
        <PhaseLoadErrorAlert phaseName={phaseName} />
      ) : (
        <FlagEnvironmentSummaries projectKey={projectKey} flagKey={flagKey} summaries={flagEnvironmentSummaries} />
      )}
    </div>
  );
}
