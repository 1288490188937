import { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';
import { SegmentSemanticInstruction } from '@gonfalon/openapi';
import { type PendingChangesState, pendingChangesReducer, SegmentPendingChangesActionsType } from '@gonfalon/segments';
import invariant from 'tiny-invariant';

const SegmentPendingChangesStateContext = createContext<PendingChangesState>({
  instructions: [] as SegmentSemanticInstruction[],
});

const SegmentPendingChangesDispatchContext = createContext<Dispatch<SegmentPendingChangesActionsType> | undefined>(
  undefined,
);

export function useSegmentPendingChangesState() {
  const state = useContext(SegmentPendingChangesStateContext);
  invariant(state, 'Cannot call "useSegmentPendingChangesState" outside of "SegmentPendingChangesProvider"');
  return state;
}

export function useSegmentPendingChangesDispatch() {
  const dispatch = useContext(SegmentPendingChangesDispatchContext);
  invariant(dispatch, 'Cannot call "useSegmentPendingChangesDispatch" outside of "SegmentPendingChangesProvider"');
  return dispatch;
}

export function SegmentPendingChangesProvider({ children }: { children: ReactNode }) {
  const [pendingChangesState, pendingChangesDispatch] = useReducer(pendingChangesReducer, {
    instructions: [] as SegmentSemanticInstruction[],
  });

  return (
    <SegmentPendingChangesStateContext.Provider value={pendingChangesState}>
      <SegmentPendingChangesDispatchContext.Provider value={pendingChangesDispatch}>
        {children}
      </SegmentPendingChangesDispatchContext.Provider>
    </SegmentPendingChangesStateContext.Provider>
  );
}
