import { createTrackerForCategory } from '@gonfalon/analytics';

import { SegmentTrackEventInfo } from './types';

export const trackSegmentEvent = (event: string, attributes?: SegmentTrackEventInfo) =>
  createTrackerForCategory('Segment')(event, attributes);

export const trackAddTargetsToSegment = (contextKind: string) =>
  trackSegmentEvent('Segment Targets Added', { contextKind });
export const trackCreateSegmentExitModalClicked = () => trackSegmentEvent('Create Segment Exit Modal Clicked');
export const trackCreateSegmentModalSegmentFormSubmitted = (isBigSegment = false) =>
  trackSegmentEvent('Create Segment Modal Segment Form Submitted', { isBigSegment });
export const trackCreateSegmentModalStepCompleted = () => trackSegmentEvent('Create Segment Modal Step Completed');
export const trackCreateSegmentModalViewed = () => trackSegmentEvent('Create Segment Modal Viewed');
export const trackSegmentFilterTagViewed = () => trackSegmentEvent('Segment Filter Viewed', { filterType: 'tag' });
export const trackSegmentOverviewFlagClicked = () => trackSegmentEvent('Segment Overview Flag Clicked');
export const trackSyncedSegmentIntegrationClicked = (key: string) =>
  trackSegmentEvent(`Segment Synced ${key} Promote Clicked`);
export const trackCreateApprovalRequestForSegment = () => trackSegmentEvent('Approval request created');
