import { relayDetectionSelector } from 'reducers/usage';

import { useSelector } from './useSelector';

export const useUsage = () => {
  const relayDetection = useSelector(relayDetectionSelector);

  return {
    relayDetection,
  };
};
