import { experimentResultsUseTrafficField } from '@gonfalon/dogfood-flags';
import { maxBy, minBy } from '@gonfalon/es6-utils';
import { Iteration } from '@gonfalon/experiments';
import { Metric } from '@gonfalon/metrics';

import { ExperimentResults, TreatmentResult } from 'components/experimentation/common/types';

import { AttributeValue } from '../api/experiment';

type HasUnits = {
  units: number;
};

/**
 * @param treatmentResults
 * @returns boolean - whether the treatment results set has recorded any data or not
 */
export function resultsArePending(treatmentResults: HasUnits[]): boolean {
  return treatmentResults.every((result) => result.units === 0);
}

export function hideIfPending(value: string | number, isPending: boolean): string {
  if (isPending) {
    return '-';
  }
  return String(value);
}

export function toPercent(num: number, truncation: number) {
  return `${(num * 100).toFixed(truncation)}%`;
}

// deprecated, use @gonfalon/experiments#formatRange instead
export function formatRangeDisplay(lower: number, upper: number, asPercent = false) {
  if (asPercent) {
    return `[${toInfinityLabel(lower, true)}, ${toInfinityLabel(upper, true)}]`;
  }

  return `[${toInfinityLabel(lower, false)}, ${toInfinityLabel(upper, false)}]`;
}

// The API will turn Infinity into min/max numbers, if we see min/max numbers, render an "Infinity" label
function toInfinityLabel(num: number, isPercentage: boolean): string {
  switch (true) {
    // API logic that truncates infinity could in practice produce values just short of MAX_VALUE but still extremely large.
    case num > Number.MAX_VALUE * 0.9:
      return 'Infinity';
    case num < -Number.MAX_VALUE * 0.9:
      return '-Infinity';
    case isPercentage:
      return toPercent(num, 2);
    default:
      return num.toFixed(2);
  }
}

// set of treatment names that have no traffic
export type NoDataSet = Set<string>;

export function newNoDataSetFromTreatments(treatments: TreatmentResult[]) {
  const s = new Set<string>();
  treatments.forEach((t) => (t.units === 0 ? s.add(t.treatmentName) : null));
  return s;
}

//   The experiment results endpoint returns a `treatmentResults` field, which is being deprecated
// in order to support data slicing, which (when enabled) will instead return a `results` field,
// which is of type `{ attributeName: string, attributeValue: string, treatmentResults: TreatmentResult[] }[]`,
// having a single item if no data slicing was requested, or one item per slice if it was.
//   If an attribute name+value is provided will search the results for that slice (otherwise it flattens them).
//   This can be cleaned up some when we remove this flag.
export function getTreatmentResults(
  experimentResults: ExperimentResults | undefined,
  attributeName?: string,
  attributeValue?: AttributeValue,
) {
  if (experimentResults === undefined) {
    return [];
  }

  if (!attributeName || !attributeValue) {
    // return flattened treatment results for all slices
    return experimentResults.results.reduce<TreatmentResult[]>(
      (acc, { treatmentResults }) => [...acc, ...treatmentResults],
      [],
    );
  }

  return (
    experimentResults.results.find(
      (result) => result.attribute === attributeName && result.attributeValue === attributeValue,
    )?.treatmentResults ?? []
  );
}

// returns the treatment results in the order that the treatments are in
// and filters out any treatments with no allocation
export function sortAndFilterTreatmentResults(
  treatments: Iteration['treatments'],
  treatmentResults: TreatmentResult[] | null | undefined,
): TreatmentResult[] | undefined {
  return treatmentResults
    ? treatments
        ?.filter(({ allocationPercent }) => allocationPercent !== '0')
        .reduce<TreatmentResult[]>((acc, { _id }) => {
          const treatmentResult = treatmentResults.find(({ treatmentId }) => _id === treatmentId);
          if (treatmentResult !== undefined) {
            acc.push(treatmentResult);
          }
          return acc;
        }, [])
    : undefined;
}

export function treatmentTraffic(treatment: TreatmentResult) {
  // `traffic` is the total number of units that have been exposed to a treatment
  // `units` is the total number of units that were used in analysis
  // when defaulting missing events, these counts will be the same
  // when ignoring missing events, `units` will be the number of conversions
  return experimentResultsUseTrafficField() && treatment.traffic !== undefined ? treatment.traffic : treatment.units;
}

// using partial params for easier testing
export type LeadingEffectSizeParams = {
  successCriteria: Metric['successCriteria'];
  treatments: Iteration['treatments'];
  winningTreatmentId: string | undefined;
  treatmentResults: Array<{
    treatmentId: string;
    treatmentName: string;
    relativeDifferences: Array<{
      fromTreatmentId: string;
      estimate?: number;
    }>;
  }>;
};

export function leadingEffectSize({
  successCriteria,
  treatments,
  winningTreatmentId,
  treatmentResults,
}: LeadingEffectSizeParams) {
  const controlId = treatments?.find((t) => t.baseline)?._id;
  if (!controlId) {
    throw Error('No control treatment found');
  }
  const higherThanBaseline = successCriteria === 'HigherThanBaseline';

  // Create a list of all the treatments and their estimate effect size to the control
  // [{ estimate: 0.1, treatmentName: 'Treatment 1', isBetterThanControl: true }]
  const results = treatmentResults
    .filter((result) => result.treatmentId !== controlId)
    .map((result) => {
      const relDiff = result.relativeDifferences.find((rd) => rd.fromTreatmentId === controlId);
      const effectSize = relDiff?.estimate ?? 0;
      return {
        effectSize,
        treatmentName: result.treatmentName,
        treatmentId: result.treatmentId,
        isBetterThanControl: higherThanBaseline ? effectSize > 0 : effectSize < 0,
      };
    });

  const zeroValue = { effectSize: 0, isBetterThanControl: false, treatmentId: undefined, treatmentName: undefined };

  // If a winning treatment is specified, return the effect size of that treatment even if it's not the "leading" treatment. This was the treatment that was shipped.
  if (winningTreatmentId) {
    const winning = results.find((result) => result.treatmentId === winningTreatmentId);
    return winning ?? zeroValue;
  }

  // Filter out any treatments that have no effect size.
  const movedResults = results.filter((result) => result.effectSize !== 0);

  const leading = higherThanBaseline
    ? maxBy(movedResults, (result) => result.effectSize)
    : minBy(movedResults, (result) => result.effectSize);

  return leading ?? zeroValue;
}

export function getMaxRelDiffVarianceReduction(
  treatmentResults: TreatmentResult[],
  baseline: string,
): number | undefined {
  return treatmentResults
    .reduce<TreatmentResult['relativeDifferences']>((acc, result) => [...acc, ...result.relativeDifferences], [])
    .filter((r) => r.fromTreatmentId === baseline && r.varianceReduction !== undefined)
    .reduce<number | undefined>((acc, r) => Math.max(acc ?? 0, r.varianceReduction ?? 0), undefined);
}
