// transformed by jscodeshift
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParam } from '@gonfalon/router';
import { ProgressBar } from '@launchpad-ui/components';
import { List } from 'immutable';

import { fetchSegments as getSegments } from 'actions/segments';
import { OptionProps as SelectedSegment, SelectSegment } from 'components/segments/SelectSegment';
import withProjectAndEnvironment from 'components/withProjectAndEnvironment';
import { GlobalState } from 'reducers';
import { segmentListRequestSelector, segmentListSelector } from 'reducers/segments';
import { Environment } from 'utils/environmentUtils';
import { Project } from 'utils/projectUtils';
import { ready } from 'utils/reduxUtils';
import { Segment } from 'utils/segmentUtils';

export type SelectSegmentContainerProps = {
  fetchSegments(projKey: string, envKey: string): void;
  isReady: boolean;
  project: Project;
  environment: Environment;
  segmentKeys: List<string>;
  onChange(selectedOption: SelectedSegment | SelectedSegment[] | null | string[]): void;
  onBlur?(): void;
  segments: List<Segment>;
  disabled?: boolean;
  id: string;
};

export function SelectSegmentContainer({
  isReady,
  project,
  environment,
  fetchSegments,
  segmentKeys,
  segments,
  disabled,
  onChange,
  onBlur,
  id,
}: SelectSegmentContainerProps) {
  const segmentKey = useParam('segmentKey', {
    optional: true,
  });
  const getManageSegmentUrl = () => `/${project.key}/${environment.key}/segments`;

  useEffect(() => {
    if (!isReady) {
      fetchSegments(project.key, environment.key);
    }
  }, []);

  if (!isReady) {
    return <ProgressBar aria-label="Loading…" isIndeterminate />;
  }
  return (
    <SelectSegment
      manageSegmentUrl={getManageSegmentUrl()}
      segmentKeys={segmentKeys}
      filterSegmentKey={segmentKey}
      segments={segments}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      id={id}
    />
  );
}

const mapStateToProps = (state: GlobalState) => {
  const req = segmentListRequestSelector(state);
  const list = segmentListSelector(state);

  return {
    isReady: ready(req),
    segments: list,
  };
};

const mapDispatchToProps = {
  fetchSegments: getSegments,
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(SelectSegmentContainer);

/* eslint-disable import/no-default-export */
export default withProjectAndEnvironment(Connected);
