import { createContext, useContext, useMemo } from 'react';
import nullthrows from 'nullthrows';
import invariant from 'tiny-invariant';

import { Flag } from 'utils/flagUtils';

interface NotMigrationFlag {
  isMigrationFlag: false;
  stageCount: undefined;
  contextKind?: undefined;
}

interface TwoStageMigrationContextValue {
  isMigrationFlag: true;
  stageCount: 2;
  contextKind?: undefined;
}

interface FourStageMigrationContextValue {
  isMigrationFlag: true;
  stageCount: 4;
  contextKind?: undefined;
}

interface SixStageMigrationContextValue {
  isMigrationFlag: true;
  stageCount: 6;
  contextKind: string;
}

type MigrationContextValue =
  | NotMigrationFlag
  | TwoStageMigrationContextValue
  | FourStageMigrationContextValue
  | SixStageMigrationContextValue;

const MigrationContext = createContext<MigrationContextValue | undefined>(undefined);

export const MigrationContextProvider = ({ flag, children }: { flag: Flag; children: React.ReactNode }) => {
  const isMigrationFlag = flag.isMigrationFlag();
  const stageCount = flag.getStageCount();
  const contextKind = flag.getMigrationContextKind();

  const value = useMemo(() => {
    if (!isMigrationFlag) {
      return { isMigrationFlag: false } as const;
    }

    if (stageCount === 2) {
      return { isMigrationFlag: true, stageCount: 2 } as const;
    }

    if (stageCount === 4) {
      return { isMigrationFlag: true, stageCount: 4 } as const;
    }

    invariant(typeof contextKind === 'string', 'Expected contextKind must be a string');

    return { isMigrationFlag: true, stageCount: 6, contextKind } as const;
  }, [isMigrationFlag, stageCount, contextKind]);

  return <MigrationContext.Provider value={value}>{children}</MigrationContext.Provider>;
};

export function useMigrationContext({ optional }: { optional?: boolean } = {}) {
  const context = useContext(MigrationContext);

  if (optional && !context) {
    return { isMigrationFlag: false } as const;
  }

  return nullthrows(context, 'useMigrationContext() may only be used inside a MigrationContext');
}
