import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { isSyncedSegmentRelayUIEnabled } from '@gonfalon/dogfood-flags';
import { useProjectKey } from '@gonfalon/router';
import { Alert } from 'launchpad';

import { SegmentInfoText } from 'components/segments/SegmentRelayProxyAlert';
import { useSegments } from 'hooks/useSegments';
import { useUsage } from 'hooks/useUsage';
import { Clause } from 'utils/clauseUtils';
import { Segment } from 'utils/segmentUtils';

export type ExternalSegmentClauseAlertProps = {
  clause: Clause;
};

export const ExternalSegmentClauseAlert = ({ clause }: ExternalSegmentClauseAlertProps) => {
  const projKey = useProjectKey();
  const envKey = useSelectedEnvironmentKey();
  const isRelayUIEnabled = isSyncedSegmentRelayUIEnabled();
  const { relayDetection } = useUsage();

  const segmentKeys = (clause.values.toArray() as string[]).sort((a, b) => a.localeCompare(b));

  const { segments, isReady } = useSegments({
    projKey,
    envKey,
    appliedParams: { filter: { keys: segmentKeys } },
    enabled: segmentKeys.length > 0,
  });

  const externalSegments = segments.filter((segment: Segment) => !!segment._external);

  if (isReady && externalSegments.size > 0) {
    return (
      <div className="u-mv-l">
        <Alert isInline className="u-pa-0" kind={relayDetection ? 'info' : 'warning'} size="medium">
          <SegmentInfoText isRelayUIEnabled={isRelayUIEnabled} isRelaySetup={relayDetection} />
        </Alert>
      </div>
    );
  }

  return null;
};
