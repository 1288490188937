import { useLocation } from 'react-router-dom';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { matchWorkflow, toFlagApproval, toHref } from '@gonfalon/navigator';
import { useFlagKey, useProjectKey } from '@gonfalon/router';
import { Button } from 'launchpad';

import { ApprovalServiceKind } from 'utils/environmentUtils';
import { trackApprovalRequestEvent } from 'utils/tracking/approvalRequest';

type ViewApprovalRequestButtonProps = {
  className?: string;
  workflowId: string;
};

/* eslint-disable import/no-default-export */
export default function ViewApprovalRequestButton(props: ViewApprovalRequestButtonProps) {
  const projKey = useProjectKey();
  const envKey = useSelectedEnvironmentKey();
  const flagKey = useFlagKey();
  const location = useLocation();
  const isWorkflowReadOnlyView = matchWorkflow(location);

  function onClickViewApprovalRequest() {
    trackApprovalRequestEvent('Custom Workflow Approval Request View Button Clicked', {
      approvalSystem: ApprovalServiceKind.LaunchDarklyApprovalServiceKind,
    });

    const approvalRequestId = props.workflowId;
    if (projKey && envKey && flagKey) {
      window.open(
        toHref(
          toFlagApproval(
            { projectKey: projKey, flagKey, environmentKey: envKey, approvalRequestId },
            {
              search: { source: isWorkflowReadOnlyView ? 'custom-workflow' : 'active-workflow-summary' },
            },
          ),
        ),
        '_blank',
      );
    }
  }
  return (
    <Button className={props.className} kind="primary" onClick={onClickViewApprovalRequest}>
      View approval request
    </Button>
  );
}
