import {
  type AddClausesToRuleInstruction,
  type AddExpiringTargetInstruction,
  type AddRuleInstruction,
  type AddValuesToClauseInstruction,
  type Clause,
  type RemoveClausesFromRuleInstruction,
  type RemoveExpiringTargetInstruction,
  type RemoveRuleInstruction,
  type RemoveValuesFromClauseInstruction,
  type ReorderRulesInstruction,
  type Rule,
  type Segment,
  type SegmentSemanticInstruction,
  type UpdateClauseInstruction,
  type UpdateExpiringTargetInstruction,
  type UpdateRuleDescriptionInstruction,
} from '@gonfalon/openapi';

import { makeUpdatedInstructions } from './makeUpdatedInstructions';
import { makeUpdatedInstructionsForNewRule } from './makeUpdatedInstructionsWithUpdatedRule';

export type PendingChangesState = {
  instructions: SegmentSemanticInstruction[];
};

export const actions = {
  addClausesToRule: (ruleId: string, clauses: Clause[]) => ({ type: 'ADD_CLAUSES_TO_RULE', ruleId, clauses }) as const,
  addExpiringTarget: (contextKind: string, value: string, removalDate: number) =>
    ({ type: 'ADD_EXPIRING_TARGET', contextKind, value, removalDate }) as const,
  addRule: (rule: Rule) => ({ type: 'ADD_RULE', rule }) as const,
  addValuesToClause: (ruleId: string, clauseId: string, values: unknown[]) =>
    ({ type: 'ADD_VALUES_TO_CLAUSE', ruleId, clauseId, values }) as const,
  changeRuleDescription: (ruleId: string, description: string, isNewRule: boolean) =>
    ({ type: 'CHANGE_RULE_DESCRIPTION', ruleId, description, isNewRule }) as const,
  changeRuleRollout: (ruleId: string, weight: number, isNewRule: boolean) =>
    ({ type: 'CHANGE_RULE_ROLLOUT', ruleId, weight, isNewRule }) as const,
  changeRuleRolloutContextKind: (ruleId: string, contextKind: string, isNewRule: boolean) =>
    ({ type: 'CHANGE_RULE_ROLLOUT_CONTEXT_KIND', ruleId, contextKind, isNewRule }) as const,
  editClause: (ruleId: string, clause: Clause, isNewRule: boolean) =>
    ({ type: 'EDIT_CLAUSE', ruleId, clause, isNewRule }) as const,
  removeClausesFromRule: (ruleId: string, clauseIds: string[]) =>
    ({ type: 'REMOVE_CLAUSES_FROM_RULE', ruleId, clauseIds }) as const,
  removeExpiringTarget: (contextKind: string, value: string) =>
    ({ type: 'REMOVE_EXPIRING_TARGET', contextKind, value }) as const,
  removeRule: (ruleId: string) => ({ type: 'REMOVE_RULE', ruleId }) as const,
  removeValuesFromClause: (ruleId: string, clauseId: string, values: unknown[]) =>
    ({ type: 'REMOVE_VALUES_FROM_CLAUSE', ruleId, clauseId, values }) as const,
  reorderRules: (ruleIds: string[]) => ({ type: 'REORDER_RULES', ruleIds }) as const,
  resetAllInstructions: () => ({ type: 'RESET_ALL_INSTRUCTIONS' }) as const,
  resetRuleInstructions: (ruleId: string) => ({ type: 'RESET_RULE_INSTRUCTIONS', ruleId }) as const,
  save: (currentSegment: Segment) => ({ type: 'SAVE', currentSegment }) as const,
  undoExpiringTarget: (contextKind: string, value: string) =>
    ({ type: 'UNDO_EXPIRING_TARGET', contextKind, value }) as const,
  updateClause: (ruleId: string, clauseId: string, clause: Clause) =>
    ({ type: 'UPDATE_CLAUSE', ruleId, clauseId, clause }) as const,
  updateExcludedTargets: (
    targets: string[],
    contextKind: string,
    kind: 'removeExcludedTargets' | 'addExcludedTargets',
  ) => ({ type: 'UPDATE_EXCLUDED_TARGETS', targets, contextKind, kind }) as const,
  updateExcludedUsers: (targets: string[], kind: 'removeExcludedUsers' | 'addExcludedUsers') =>
    ({ type: 'UPDATE_EXCLUDED_USERS', targets, kind }) as const,
  updateExpiringTarget: (contextKind: string, value: string, removalDate: number) =>
    ({ type: 'UPDATE_EXPIRING_TARGET', contextKind, value, removalDate }) as const,
  updateIncludedTargets: (
    targets: string[],
    contextKind: string,
    kind: 'removeIncludedTargets' | 'addIncludedTargets',
  ) => ({ type: 'UPDATE_INCLUDED_TARGETS', targets, contextKind, kind }) as const,
  updateIncludedUsers: (targets: string[], kind: 'removeIncludedUsers' | 'addIncludedUsers') =>
    ({ type: 'UPDATE_INCLUDED_USERS', targets, kind }) as const,
};

export type SegmentPendingChangesActionsType =
  | {
      type: 'ADD_CLAUSES_TO_RULE';
      ruleId: string;
      clauses: Clause[];
    }
  | {
      type: 'ADD_EXPIRING_TARGET';
      contextKind: string;
      value: string;
      removalDate: number;
    }
  | { type: 'ADD_RULE'; rule: Rule }
  | {
      type: 'ADD_VALUES_TO_CLAUSE';
      ruleId: string;
      clauseId: string;
      values: unknown[];
    }
  | {
      type: 'CHANGE_RULE_DESCRIPTION';
      ruleId: string;
      description: string;
      isNewRule: boolean;
    }
  | {
      type: 'CHANGE_RULE_ROLLOUT';
      ruleId: string;
      weight: number;
      isNewRule: boolean;
    }
  | {
      type: 'CHANGE_RULE_ROLLOUT_CONTEXT_KIND';
      ruleId: string;
      contextKind: string;
      isNewRule: boolean;
    }
  | { type: 'EDIT_CLAUSE'; ruleId: string; clause: Clause; isNewRule: boolean }
  | {
      type: 'REMOVE_CLAUSES_FROM_RULE';
      ruleId: string;
      clauseIds: string[];
    }
  | {
      type: 'REMOVE_EXPIRING_TARGET';
      contextKind: string;
      value: string;
    }
  | {
      type: 'REMOVE_VALUES_FROM_CLAUSE';
      ruleId: string;
      clauseId: string;
      values: unknown[];
    }
  | { type: 'REMOVE_RULE'; ruleId: string }
  | {
      type: 'REORDER_RULES';
      ruleIds: string[];
    }
  | {
      type: 'RESET_ALL_INSTRUCTIONS';
    }
  | {
      type: 'RESET_RULE_INSTRUCTIONS';
      ruleId: string;
    }
  | { type: 'SAVE'; currentSegment: Segment }
  | {
      type: 'UNDO_EXPIRING_TARGET';
      contextKind: string;
      value: string;
    }
  | {
      type: 'UPDATE_CLAUSE';
      ruleId: string;
      clauseId: string;
      clause: Clause;
    }
  | {
      type: 'UPDATE_EXCLUDED_TARGETS';
      targets: string[];
      contextKind: string;
      kind: 'removeExcludedTargets' | 'addExcludedTargets';
    }
  | {
      type: 'UPDATE_EXCLUDED_USERS';
      targets: string[];
      kind: 'removeExcludedUsers' | 'addExcludedUsers';
    }
  | {
      type: 'UPDATE_EXPIRING_TARGET';
      contextKind: string;
      value: string;
      removalDate: number;
    }
  | {
      type: 'UPDATE_INCLUDED_TARGETS';
      targets: string[];
      contextKind: string;
      kind: 'removeIncludedTargets' | 'addIncludedTargets';
    }
  | {
      type: 'UPDATE_INCLUDED_USERS';
      targets: string[];
      kind: 'removeIncludedUsers' | 'addIncludedUsers';
    };

export function pendingChangesReducer(
  state: PendingChangesState,
  action: ReturnType<(typeof actions)[keyof typeof actions]>,
) {
  switch (action.type) {
    case 'ADD_CLAUSES_TO_RULE':
      const addClausesToRuleInstruction: AddClausesToRuleInstruction = {
        kind: 'addClauses',
        ruleId: action.ruleId,
        clauses: action.clauses,
      };
      return {
        ...state,
        instructions: makeUpdatedInstructions('addClauses', state.instructions, addClausesToRuleInstruction),
      };
    case 'ADD_EXPIRING_TARGET':
      let addExpiringTargetInstruction = state.instructions.find(
        (instruction) =>
          instruction.kind === 'addExpiringTarget' &&
          instruction.contextKind === action.contextKind &&
          instruction.value === action.value,
      );

      if (addExpiringTargetInstruction !== undefined) {
        (addExpiringTargetInstruction as AddExpiringTargetInstruction).removalDate = action.removalDate;
      } else {
        addExpiringTargetInstruction = {
          kind: 'addExpiringTarget',
          contextKind: action.contextKind,
          value: action.value,
          removalDate: action.removalDate,
        };
      }

      return {
        ...state,
        instructions: makeUpdatedInstructions('addExpiringTarget', state.instructions, addExpiringTargetInstruction),
      };
    case 'ADD_RULE':
      const addRuleInstruction = {
        kind: 'addRule',
        ...action.rule,
      };
      return { ...state, instructions: state.instructions.concat(addRuleInstruction as AddRuleInstruction) };
    case 'ADD_VALUES_TO_CLAUSE':
      const addValuesToClauseInstruction: AddValuesToClauseInstruction = {
        kind: 'addValuesToClause',
        ruleId: action.ruleId,
        clauseId: action.clauseId,
        values: action.values,
      };
      return {
        ...state,
        instructions: makeUpdatedInstructions('addValuesToClause', state.instructions, addValuesToClauseInstruction),
      };
    case 'CHANGE_RULE_DESCRIPTION':
      if (action.isNewRule) {
        // check if there is a new rule added
        return {
          ...state,
          instructions: makeUpdatedInstructionsForNewRule(state.instructions, action.ruleId, {
            description: action.description,
          }),
        };
      }
      const updateRuleDescriptionInstruction: UpdateRuleDescriptionInstruction = {
        kind: 'updateRuleDescription',
        description: action.description,
        ruleId: action.ruleId,
      };
      return {
        ...state,
        instructions: makeUpdatedInstructions(
          'updateRuleDescription',
          state.instructions,
          updateRuleDescriptionInstruction,
        ),
      };
    case 'CHANGE_RULE_ROLLOUT':
      if (action.isNewRule) {
        // check if there is a new rule added
        return {
          ...state,
          instructions: makeUpdatedInstructionsForNewRule(state.instructions, action.ruleId, {
            weight: action.weight,
          }),
        };
      }
      // TODO: else add a new edit instruction
      return { ...state };
    case 'CHANGE_RULE_ROLLOUT_CONTEXT_KIND':
      if (action.isNewRule) {
        // check if there is a new rule added
        return {
          ...state,
          instructions: makeUpdatedInstructionsForNewRule(state.instructions, action.ruleId, {
            contextKind: action.contextKind,
          }),
        };
      }
      // TODO: else add a new edit instruction
      return { ...state };
    case 'EDIT_CLAUSE':
      if (action.isNewRule) {
        return {
          ...state,
          instructions: makeUpdatedInstructionsForNewRule(state.instructions, action.ruleId, {
            clauseToUpdate: action.clause,
          }),
        };
      }
      // TODO: else add a new edit instruction
      return { ...state };
    case 'REMOVE_CLAUSES_FROM_RULE':
      const removeClausesFromRuleInstruction: RemoveClausesFromRuleInstruction = {
        kind: 'removeClauses',
        ruleId: action.ruleId,
        clauseIds: action.clauseIds,
      };
      return {
        ...state,
        instructions: makeUpdatedInstructions('removeClauses', state.instructions, removeClausesFromRuleInstruction),
      };
    case 'REMOVE_EXPIRING_TARGET':
      const removeExpiringTargetInstruction = {
        kind: 'removeExpiringTarget',
        contextKind: action.contextKind,
        value: action.value,
      };
      return {
        ...state,
        instructions: makeUpdatedInstructions(
          'removeExpiringTarget',
          state.instructions,
          removeExpiringTargetInstruction as RemoveExpiringTargetInstruction,
        ),
      };
    case 'REMOVE_RULE':
      const existingRuleToAddIdx = state.instructions.findIndex(
        (instruction) => instruction.kind === 'addRule' && instruction._key === action.ruleId,
      );
      if (existingRuleToAddIdx !== -1) {
        return { ...state, instructions: state.instructions.toSpliced(existingRuleToAddIdx, 1) };
      }
      const removeRuleInstruction = {
        kind: 'removeRule',
        ruleId: action.ruleId,
      } as RemoveRuleInstruction;
      return { ...state, instructions: state.instructions.concat(removeRuleInstruction) };
    case 'REMOVE_VALUES_FROM_CLAUSE':
      const removeValuesFromClauseInstruction: RemoveValuesFromClauseInstruction = {
        kind: 'removeValuesFromClause',
        ruleId: action.ruleId,
        clauseId: action.clauseId,
        values: action.values,
      };
      return {
        ...state,
        instructions: makeUpdatedInstructions(
          'removeValuesFromClause',
          state.instructions,
          removeValuesFromClauseInstruction,
        ),
      };
    case 'REORDER_RULES':
      const reorderRulesInstruction = {
        kind: 'reorderRules',
        ruleIds: action.ruleIds,
      } as ReorderRulesInstruction;
      return {
        ...state,
        instructions: makeUpdatedInstructions('reorderRules', state.instructions, reorderRulesInstruction),
      };
    case 'RESET_ALL_INSTRUCTIONS':
      return { instructions: [] as SegmentSemanticInstruction[] };
    case 'RESET_RULE_INSTRUCTIONS':
      const instructionsToKeep = resetRuleInstructions(state, action);
      return { ...state, instructions: instructionsToKeep };
    case 'SAVE':
      return {
        ...state,
        originalSegment: action.currentSegment,
        instructions: [] as SegmentSemanticInstruction[],
      };
    case 'UPDATE_CLAUSE':
      const updateClauseInstruction: UpdateClauseInstruction = {
        kind: 'updateClause',
        ruleId: action.ruleId,
        clauseId: action.clauseId,
        clause: action.clause,
      };
      return {
        ...state,
        instructions: makeUpdatedInstructions('updateClause', state.instructions, updateClauseInstruction),
      };
    case 'UNDO_EXPIRING_TARGET':
      const instructionToUndoIdx = state.instructions.findIndex(
        (instruction) =>
          ['addExpiringTarget', 'updateExpiringTarget', 'removeExpiringTarget'].includes(instruction.kind) &&
          'contextKind' in instruction &&
          instruction.contextKind === action.contextKind &&
          'value' in instruction &&
          instruction.value === action.value,
      );
      if (instructionToUndoIdx !== -1) {
        const instructionsAfterRemoval = state.instructions;
        instructionsAfterRemoval.splice(instructionToUndoIdx, 1);
        return { ...state, instructions: instructionsAfterRemoval };
      }
      return { ...state };
    case 'UPDATE_EXCLUDED_TARGETS':
      const excludedTargetsInstruction = {
        kind: action.kind,
        values: action.targets,
        contextKind: action.contextKind,
      };

      return {
        ...state,
        instructions: makeUpdatedInstructions(action.kind, state.instructions, excludedTargetsInstruction),
      };
    case 'UPDATE_EXCLUDED_USERS':
      const excludedUsersInstruction = {
        kind: action.kind,
        values: action.targets,
      };

      return {
        ...state,
        instructions: makeUpdatedInstructions(action.kind, state.instructions, excludedUsersInstruction),
      };
    case 'UPDATE_EXPIRING_TARGET':
      let updateExpiringTargetInstruction = state.instructions.find(
        (instruction) =>
          instruction.kind === 'updateExpiringTarget' &&
          instruction.contextKind === action.contextKind &&
          instruction.value === action.value,
      );

      if (updateExpiringTargetInstruction !== undefined) {
        (updateExpiringTargetInstruction as UpdateExpiringTargetInstruction).removalDate = action.removalDate;
      } else {
        updateExpiringTargetInstruction = {
          kind: 'updateExpiringTarget',
          contextKind: action.contextKind,
          value: action.value,
          removalDate: action.removalDate,
        };
      }

      // check if a corresponding remove expiring target instruction exists
      const existingRemoveExpiringTargetIdx = state.instructions.findIndex(
        (instruction) =>
          instruction.kind === 'removeExpiringTarget' &&
          instruction.contextKind === action.contextKind &&
          instruction.value === action.value,
      );

      const updatedInstructionsForUpdateExpiringTarget = state.instructions;
      if (existingRemoveExpiringTargetIdx !== -1) {
        updatedInstructionsForUpdateExpiringTarget.splice(existingRemoveExpiringTargetIdx, 1);
      }

      return {
        ...state,
        instructions: makeUpdatedInstructions(
          'updateExpiringTarget',
          updatedInstructionsForUpdateExpiringTarget,
          updateExpiringTargetInstruction as UpdateExpiringTargetInstruction,
        ),
      };
    case 'UPDATE_INCLUDED_TARGETS':
      const includedTargetsInstruction = {
        kind: action.kind,
        values: action.targets,
        contextKind: action.contextKind,
      };

      return {
        ...state,
        instructions: makeUpdatedInstructions(action.kind, state.instructions, includedTargetsInstruction),
      };
    case 'UPDATE_INCLUDED_USERS':
      const includedUsersInstruction = {
        kind: action.kind,
        values: action.targets,
      };

      return {
        ...state,
        instructions: makeUpdatedInstructions(action.kind, state.instructions, includedUsersInstruction),
      };
    default:
      return state;
  }
}

const resetRuleInstructions = (
  state: PendingChangesState,
  action: ReturnType<typeof actions.resetRuleInstructions>,
) => {
  const addRuleInstructionIndex = state.instructions.findIndex((i) => i.kind === 'addRule' && action.ruleId === i._key);

  if (addRuleInstructionIndex !== -1) {
    return state.instructions.toSpliced(addRuleInstructionIndex, 1);
  }

  return state.instructions.filter((instruction) => {
    if ('ruleId' in instruction) {
      return action.ruleId !== instruction.ruleId;
    } else {
      return true;
    }
  });
};
