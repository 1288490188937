/* eslint-disable no-nested-ternary */
import { Alert } from 'launchpad';

import { ClauseValidation } from 'utils/validation/clauses';
import { TargetingRuleValidation } from 'utils/validation/flags';
import {
  FallthroughValidation,
  PrerequisiteValidation,
  RolloutValidation,
  WeightedVariationValidation,
} from 'utils/validation/targeting';

type TargetingValidationAlertProps = {
  validation:
    | ClauseValidation
    | WeightedVariationValidation
    | RolloutValidation
    | FallthroughValidation
    | PrerequisiteValidation
    | TargetingRuleValidation;
};

const TargetingValidationAlert = ({ validation }: TargetingValidationAlertProps) => (
  <Alert isInline wide kind={validation.isIncomplete() ? 'warning' : 'error'}>
    {validation.getMessage()}
  </Alert>
);

/* eslint-disable import/no-default-export */
export default TargetingValidationAlert;
