import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { enableBigSegmentContexts, syncedSegmentRelayVersion } from '@gonfalon/dogfood-flags';
import { noop } from '@gonfalon/es6-utils';
import { toSegmentTargeting } from '@gonfalon/navigator';
import { trackSegmentEvent } from '@gonfalon/segments';
import { Set as immutableSet } from 'immutable';
import {
  AbsoluteModalFooter,
  Alert,
  Button,
  Chip,
  Label,
  Radio,
  RadioGroup,
  RequiredAsterisk,
  TextField,
} from 'launchpad';
import { useCreateSegmentMutation } from 'queries/segmentQueries';

import { useFormActions } from 'actions/forms';
import { editSegment } from 'actions/segments';
import SelectContextKind from 'components/contextTargeting/SelectContextKind';
import DocumentationLink from 'components/DocumentationLink';
import SelectTags from 'components/SelectTags';
import { TagKind } from 'components/tagFilter/types';
import { SubmitButton } from 'components/ui/buttons';
import { AutosizeTextArea, FieldError, Form, FormError, FormGroup, FormHint } from 'components/ui/forms';
import { segmentCreationFormSelector } from 'reducers/segments';
import NavigationPrompt from 'routers/NavigationPrompt';
import { Segment } from 'utils/segmentUtils';
import { getDocumentationUrl } from 'utils/urlUtils';

import styles from './styles/CreateSegmentModalForm.module.css';

const bigSegmentsDocLink = getDocumentationUrl('sdk/features/big-segments');
const syncedSegmentsDocLink = getDocumentationUrl('home/flags/synced-segments');

export function BigSegmentRadioGroup({
  value,
  onChange,
}: {
  value: string;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
}) {
  const boundedSegmentFormHint =
    'Best suited for most common use cases. Use dynamic targeting rules to define a set of users of unlimited size. Manually enter specific users to be included or excluded.';
  const bigSegmentFormHint = `Select a very large set of specific users by uploading your own CSV file containing user keys. Does not support dynamic targeting rules. If using a server-side SDK, a Relay Proxy v${syncedSegmentRelayVersion()} or higher is required. `;
  return (
    <RadioGroup name="unbounded" value={value} onChange={onChange} legend="Size">
      <div className={styles.radioContainer}>
        <div className={styles.radioLabelContainer}>
          <Radio id="big-segment-false" value="false" className={styles.radio} labelClassName={styles.radioLabel}>
            Standard
          </Radio>
        </div>
        <FormHint className={styles.radioFormHint}>{boundedSegmentFormHint}</FormHint>
      </div>

      <div className={styles.radioContainer}>
        <div className={styles.radioLabelContainer}>
          <Radio id="big-segment-true" value="true" className={styles.radio} labelClassName={styles.radioLabel}>
            Big
          </Radio>
        </div>
        <FormHint className={styles.radioFormHint}>
          {bigSegmentFormHint}
          <DocumentationLink href={bigSegmentsDocLink} component="CreateSegmentForm" text="Learn more" />
        </FormHint>
      </div>
    </RadioGroup>
  );
}

export type CreateSegmentModalFormProps = {
  projKey: string;
  envKey: string;
  isBigSegmentsEnabled: boolean;
  isNativeBigSegmentUIEnabled: boolean;
  onSubmit?(segment: Segment): void;
  onCancel?(): void;
  onClickSubmit?(description?: boolean, tags?: boolean): void;
};

export const useRedux = () => {
  const formState = useSelector(segmentCreationFormSelector);
  const isFormDirtyAndUnsaved = formState.isFormDirtyAndUnsaved();
  const modified = formState.modified;

  const dispatch = useDispatch();
  const { onBlur, onDestroyForm } = useFormActions('createSegmentForm');
  const onChange = (field: string, value: string | immutableSet<string> | boolean) =>
    dispatch(editSegment(field, value));
  const { mutate: onSubmitDefault } = useCreateSegmentMutation();
  const { pathname } = useLocation();
  const showSyncedSegmentUpsell = pathname.endsWith('segments/new');

  return {
    formState,
    isFormDirtyAndUnsaved,
    modified,
    onBlur,
    onDestroyForm,
    onChange,
    onSubmitDefault,
    showSyncedSegmentUpsell,
  };
};

const CreateSegmentModalForm = ({
  projKey,
  envKey,
  isBigSegmentsEnabled,
  isNativeBigSegmentUIEnabled,
  onCancel,
  onSubmit,
  onClickSubmit = noop,
}: CreateSegmentModalFormProps) => {
  const {
    formState,
    isFormDirtyAndUnsaved,
    modified,
    onBlur,
    onDestroyForm,
    onChange,
    onSubmitDefault,
    showSyncedSegmentUpsell,
  } = useRedux();
  const [hasTag, setHasTag] = useState(false);
  const [hasDescription, setHasDescription] = useState(false);

  useEffect(
    () => () => {
      onDestroyForm();
    },
    [],
  );

  const canCreateBigSegment = isBigSegmentsEnabled && isNativeBigSegmentUIEnabled;

  const handleRadioSelection = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = event;
    onChange(name, value === 'true');
  };

  const handleSubmit = (e: FormEvent<EventTarget>) => {
    e.preventDefault();

    if (onSubmit) {
      onSubmit(modified);
    } else {
      onSubmitDefault({ projectKey: projKey, environmentKey: envKey, input: modified.toJS() });
    }
  };

  const isBigSegment = !!modified.get('unbounded');
  const pathToNewlyCreatedSegment = new Set([
    toSegmentTargeting({ projectKey: projKey, environmentKey: envKey, segmentKey: modified.key }).pathname,
  ]);

  return (
    <>
      <NavigationPrompt shouldBlock={isFormDirtyAndUnsaved} allowList={pathToNewlyCreatedSegment} />
      {showSyncedSegmentUpsell && (
        <Alert isInline className={styles.syncedSegmentAlert}>
          You can now sync from Amplitude{' '}
          <DocumentationLink
            href={syncedSegmentsDocLink}
            component="CreateSegmentForm"
            text="Learn more"
            onTrackEvent={() => trackSegmentEvent('Segment Synced Amplitude Promote Clicked')}
          />
        </Alert>
      )}
      <Form onSubmit={handleSubmit}>
        <FormError formState={formState} />
        <FormGroup name="name" formState={formState} className={styles.firstFormGroup}>
          <Label htmlFor="name">
            Name <RequiredAsterisk />
          </Label>
          <TextField
            required
            id="name"
            name="name"
            autoComplete="off"
            placeholder="Eg. Beta users"
            value={modified.name}
            onBlur={() => onBlur('name')}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange('name', e.target.value)}
          />
          <FieldError formState={formState} name="name" />
        </FormGroup>
        <FormGroup name="key" formState={formState}>
          <Label htmlFor="key">
            Key <RequiredAsterisk />
          </Label>
          <TextField
            required
            id="key"
            name="key"
            autoComplete="off"
            value={modified.key}
            onBlur={() => onBlur('key')}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange('key', e.target.value)}
          />
          <FieldError formState={formState} name="key" />
          <FormHint>
            LaunchDarkly uses the key to give you friendly URLs. Keys must only contain letters, numbers, <code>.</code>
            , <code>_</code> or <code>-</code>. <br />
            You cannot use <code>new</code> as a key.
          </FormHint>
        </FormGroup>
        <FormGroup name="description" formState={formState}>
          <Label htmlFor="description" optional>
            Description
          </Label>
          <AutosizeTextArea
            maxRows={4}
            id="description"
            name="description"
            rows={1}
            value={modified.description}
            onBlur={() => onBlur('description')}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              onChange('description', e.target.value);
              setHasDescription(!!e.target.value);
            }}
          />
          <FieldError formState={formState} name="description" />
        </FormGroup>
        <FormGroup name="tags" formState={formState}>
          <Label htmlFor="tags" optional>
            Tags
          </Label>
          <SelectTags
            name="tags"
            kind={TagKind.SEGMENT}
            value={modified.tags.toArray()}
            placeholder="Add tags"
            ariaLabel="Add tags"
            onBlur={() => onBlur('tags')}
            onChange={(tags) => {
              onChange('tags', immutableSet(tags));
              setHasTag(tags.length > 0);
            }}
          />
          <FieldError formState={formState} name="tags" />
        </FormGroup>
        {canCreateBigSegment && (
          <FormGroup name="unbounded" formState={formState} className={styles.radioFormGroup}>
            <div>
              <div className="u-fs-sm">
                Size
                <RequiredAsterisk />
                <span className="u-ml-m">
                  <Chip size="small" kind="new">
                    New
                  </Chip>
                </span>
              </div>
              <BigSegmentRadioGroup value={isBigSegment.toString()} onChange={handleRadioSelection} />
            </div>
          </FormGroup>
        )}
        {enableBigSegmentContexts() && isBigSegment && (
          <FormGroup name="unboundedContextKind" formState={formState}>
            <Label htmlFor="unboundedContextKind">Context kind</Label>
            <SelectContextKind
              name="unboundedContextKind"
              value={modified.unboundedContextKind || 'user'}
              onChange={(kind: string) => {
                onChange('unboundedContextKind', kind);
              }}
              menuPosition="fixed"
            />
            <FieldError formState={formState} name="unboundedContextKind" />
            <FormHint>
              Provide the context kind to be used for this big segment. Big segments can only contain members of a
              single context kind.
            </FormHint>
          </FormGroup>
        )}

        <AbsoluteModalFooter
          primaryButton={
            <SubmitButton
              formState={formState}
              loadingText="Saving segment"
              onClick={() => onClickSubmit(hasDescription, hasTag)}
            >
              Save segment
            </SubmitButton>
          }
          secondaryButton={onCancel && <Button onClick={onCancel}>Cancel</Button>}
        />
      </Form>
    </>
  );
};

/* eslint-disable import/no-default-export */
export default CreateSegmentModalForm;
