import { ChangeEvent } from 'react';
import cx from 'clsx';
import { TextField } from 'launchpad';

import { constrainPercentage, fixFractionDigitsWithoutRounding } from 'utils/numberUtils';

import 'components/rollouts/RolloutInput.css';

type RolloutInputProps = {
  id?: string;
  name?: string;
  className?: string;
  disabled?: boolean;
  value?: number;
  suffix?: string;
  onChange(rolloutValue: number): void;
  onBlur?(): void;
  testId?: string;
  ariaLabel?: string;
};

const RolloutInput = ({
  id,
  name,
  value,
  disabled = false,
  className,
  suffix,
  onChange,
  testId,
  onBlur,
  ariaLabel = name,
}: RolloutInputProps) => {
  const handleFocus = (e: ChangeEvent<HTMLInputElement>) => {
    //this highlights the input upon select so updating is faster
    e.target.select();
  };

  const handleChangeRollout = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseFloat(event.target.value);
    const constrainedValue = parseFloat(constrainPercentage(inputValue.toString()));
    const fixedValue = fixFractionDigitsWithoutRounding(constrainedValue, 3);
    onChange(fixedValue * 1000);
  };
  return (
    <TextField
      id={id}
      name={name}
      aria-label={ariaLabel}
      type="number"
      inputMode="numeric"
      value={(value || 0) / 1000}
      disabled={disabled}
      min={0}
      max={100}
      step={0.001}
      className={cx('RolloutInput', 'RolloutInput--wide', className)}
      onChange={handleChangeRollout}
      suffix={suffix}
      onFocus={handleFocus}
      data-test-id={testId}
      onBlur={onBlur}
    />
  );
};

/* eslint-disable import/no-default-export */
export default RolloutInput;
