import { AriaOnChangeProps } from 'react-select';
import { isAppSupportedOp } from '@gonfalon/clauses';
import { OptionProps } from '@gonfalon/launchpad-experimental';
import { useApplicationVersionCount } from '@gonfalon/rest-api';
import { pluralize } from '@gonfalon/strings';
import { ProgressBar } from '@launchpad-ui/components';
import { List } from 'immutable';
import { Chip } from 'launchpad';
import { isNil } from 'lodash';

import { Clause } from 'utils/clauseUtils';

import SelectApplication from './SelectApplication';

type SelectApplicationClauseProps = {
  onChange(clause: Clause): void;
  clause: Clause;
  canModify: boolean;
};

export const SelectApplicationClause = ({ onChange, clause, canModify }: SelectApplicationClauseProps) => {
  const applicationKey = clause.values.first();
  const supported = !clause.negate;
  const enabled = !isNil(applicationKey) && isAppSupportedOp(clause.op);
  const { data, isPending, error } = useApplicationVersionCount(
    {
      applicationKey: applicationKey as string,
      params: { supported },
    },
    {
      enabled,
    },
  );

  const infoText = getApplicationVersionSupportCountString(supported, data?.totalCount);

  return (
    <>
      <SelectApplication
        hideLabelForSelectedOption
        ariaLabel="Application ID"
        placeholder="Enter an application ID"
        value={clause.values.first() as string}
        onChange={(selectedOption: OptionProps & { _id: string }) => {
          onChange(
            !isNil(selectedOption) ? clause.set('values', List.of(selectedOption.value)) : clause.remove('values'),
          );
        }}
        formatOptionLabel={(option: OptionProps) => (
          <>
            {option?.label && <>{option?.label} </>}
            <Chip>{option?.value}</Chip>{' '}
          </>
        )}
        disabled={!canModify}
        customAriaLiveMessageOnChange={(ariaProps: AriaOnChangeProps<OptionProps, false>) => {
          if (isPending) {
            // avoids screen reader reading the label twice
            return '';
          }
          if (!isPending && !error) {
            return `${ariaProps.label} has ${infoText}`;
          }
          return ariaProps.label;
        }}
      />

      {enabled && isPending && <ProgressBar aria-label="Loading…" isIndeterminate />}
      {!isPending && !error && <>{infoText}</>}
    </>
  );
};

const getApplicationVersionSupportCountString = (supported: boolean, totalCount?: number) =>
  `${totalCount} ${supported ? 'supported' : 'unsupported'} ${pluralize('version', totalCount)}`;
