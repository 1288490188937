import { Segment } from 'utils/segmentUtils';

import SegmentRelayProxyAlert from './SegmentRelayProxyAlert';

import styles from './styles/SegmentPopover.module.css';

type SegmentPopoverProps = {
  segment: Segment;
  isRelaySetup?: boolean;
  isRelayUIEnabled?: boolean;
  showRelayAlert?: boolean;
};

export const SegmentPopover = ({
  segment,
  isRelaySetup = false,
  isRelayUIEnabled = false,
  // remove this when we decide to show this by default
  showRelayAlert = true,
}: SegmentPopoverProps) => (
  <div className="u-fs-sm">
    <div className="u-tc u-pa-s u-bb u-b--ui-primary">
      <a href={segment.siteLink()} target="_blank" rel="noreferrer">
        View details
      </a>
    </div>
    <div className="u-mh-40 u-o-auto u-pv-s u-ph-m">
      {segment._external ? (
        <>
          <p className={styles.description}>
            {segment.description ? segment.description : <i className="u-subtle">No description</i>}
          </p>
          {showRelayAlert && (
            <SegmentRelayProxyAlert
              isRelaySetup={isRelaySetup}
              isRelayUIEnabled={isRelayUIEnabled}
              size="small"
              isInline
            />
          )}
        </>
      ) : (
        <p>{segment.description ? segment.description : <i className="u-subtle">No description</i>}</p>
      )}
    </div>
  </div>
);
