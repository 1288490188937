import { CSSProperties, Dispatch, useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useVirtual } from 'react-virtual';
import { SegmentPendingChangesActionsType } from '@gonfalon/segments';
import { Icon } from '@launchpad-ui/icons';
import { List } from 'immutable';
import { CopyToClipboard, IconButton } from 'launchpad';

import { loadContextTarget as loadContextTargetAction } from 'actions/contexts';
import { useIsScrolling } from 'hooks/useIsScrolling';
import { AccessDecision } from 'utils/accessUtils';

import ContextTarget from './ContextTarget';

import './ContextTargetGrid.css';

export const SCROLL_DIRECTION_FORWARD = 1;
export const SCROLL_DIRECTION_HORIZONTAL = 'horizontal';

export type ContextTargetGridProps = {
  accessCheck?: AccessDecision;
  envKey: string;
  onDeleteTarget(target: string): void;
  onLoadContextTarget?(contextKey: string): void;
  onSetOpenContextPopoverKey(value: string): void;
  openContextPopoverKey?: string;
  projKey: string;
  segmentKey?: string;
  targets: List<string>;
  updateExpiringTargetsAccess?: AccessDecision;
  variationId?: string;
  contextKind: string;
  columnCount?: number;
  segmentPendingChangesDispatch?: Dispatch<SegmentPendingChangesActionsType>;
};

export type ContextTargetGridStyle = CSSProperties & {
  '--column-count': number;
};

function ContextTargetGrid({
  targets,
  segmentKey,
  variationId,
  onLoadContextTarget,
  onDeleteTarget,
  updateExpiringTargetsAccess,
  accessCheck,
  openContextPopoverKey,
  onSetOpenContextPopoverKey,
  contextKind,
  columnCount = 4,
  segmentPendingChangesDispatch,
}: ContextTargetGridProps) {
  const parentRef = useRef<HTMLDivElement>(null);
  const { isScrolling } = useIsScrolling({ ref: parentRef });
  const [showCopyIcon, setShowCopyIcon] = useState(false);

  const rowHeight = 42;
  const rowCount = Math.ceil(targets.size / columnCount);
  const rowVirtualizer = useVirtual({
    size: rowCount,
    parentRef,
    estimateSize: useCallback(() => rowHeight, []),
  });

  return (
    <div
      className="ContextTargetGrid-wrapper"
      data-test-id="individual-target-grid"
      role="grid"
      aria-label="Individual Targets"
      tabIndex={0}
      onMouseEnter={() => setShowCopyIcon(true)}
      onMouseLeave={() => setShowCopyIcon(false)}
    >
      <div
        className="ContextTargetGrid"
        style={{ '--column-count': columnCount } as ContextTargetGridStyle}
        ref={parentRef}
      >
        <div
          className="ContextTargetGrid-container"
          style={{
            height: `${rowVirtualizer.totalSize}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {rowVirtualizer.virtualItems.map((virtualRow) => {
            const theseTargets = targets.slice(
              virtualRow.index * columnCount,
              virtualRow.index * columnCount + columnCount,
            );

            return (
              <div
                key={virtualRow.index}
                className="ContextTargetGrid-row"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                {theseTargets.map((target) => (
                  <div className="ContextTargetGrid-cell" key={`context-target-${target}`}>
                    <ContextTarget
                      contextKey={target}
                      variationId={variationId}
                      onDeleteTarget={onDeleteTarget}
                      isScrolling={isScrolling}
                      updateExpiringTargetsAccess={updateExpiringTargetsAccess}
                      onSetOpenContextPopoverKey={onSetOpenContextPopoverKey}
                      accessCheck={accessCheck}
                      openContextPopoverKey={openContextPopoverKey}
                      contextKind={contextKind}
                      onLoadTarget={onLoadContextTarget}
                      segmentKey={segmentKey}
                      segmentPendingChangesDispatch={segmentPendingChangesDispatch}
                    />
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
      <div className="ContextTargetGrid-copy">
        {showCopyIcon && (
          <CopyToClipboard
            tooltip={`Copy ${contextKind} keys to clipboard`}
            text={targets?.join(', ')}
            data-test-id={`CopyValues-${contextKind}`}
            asChild
          >
            <IconButton kind="default" aria-label={`Copy ${contextKind} keys`} icon={<Icon name="copy-content" />} />
          </CopyToClipboard>
        )}
      </div>
    </div>
  );
}

type OwnProps = ContextTargetGridProps & {
  id: string;
};

/* eslint-disable import/no-default-export */
export default connect(null, (dispatch, ownProps: OwnProps) => ({
  onLoadContextTarget: (contextKey: string) =>
    dispatch(
      loadContextTargetAction({
        viewId: ownProps.id,
        projKey: ownProps.projKey,
        envKey: ownProps.envKey,
        target: { kind: ownProps.contextKind, key: contextKey },
      }),
    ),
}))(ContextTargetGrid);
