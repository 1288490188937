import React from 'react';
import { UseFormReturn, UseFormSetValue, useWatch } from 'react-hook-form';
import { enableMetricEventActivity } from '@gonfalon/dogfood-flags';
import { FieldGroup, Input, Radio, RadioGroup, TextField } from '@launchpad-ui/components';

import HelpTooltip from 'components/HelpTooltip';

import { MetricForm } from '../common';
import { CardSelectGroup } from '../components/CardSelectGroup';
import { RequiredLabel } from '../components/RequiredLabel';

import { EventActivitySummary } from './EventActivitySummary';
import { measureTypeCardSelectOptions, unitAggregationCardSelectOptions } from './formUtils';
import { MetricDefinition } from './MetricDefinition';

import styles from './GuidedMetricCreationModal.module.css';

interface CustomMetricKindDetailsProps {
  context: UseFormReturn<MetricForm>;
}

export const CustomMetricKindDetails: React.FC<CustomMetricKindDetailsProps> = ({ context }) => {
  const {
    control,
    register,
    formState: { errors },
    setValue,
  } = context;

  const eventDefault: 'enabled' | 'disabled' = useWatch({ control, name: 'eventDefault', exact: true }).disabled
    ? 'disabled'
    : 'enabled';
  const unitAggregationType = useWatch({ control, name: 'unitAggregationType', exact: true });
  const unitOfMeasure = useWatch({ control, name: 'unit', exact: true });
  const measureType = useWatch({ control, name: 'measureType', exact: true });
  const hideEventDefault = useWatch({ control, name: 'analysisType', exact: true }) === 'percentile';

  const handleMeasureOptionSelect = (value: string) => {
    if (!['count', 'occurrence', 'value'].includes(value)) {
      return;
    }
    if (value === 'count') {
      setValue('measureType', 'count');
      setValue('eventDefault', { disabled: false, value: 0 }, { shouldDirty: true });
      setValue('unit', undefined);
      setValue('analysisType', 'mean');
      setValue('percentileValue', undefined);
      setValue('unitAggregationType', 'sum', { shouldDirty: true });
      setValue('isNumeric', false, { shouldDirty: true });
    } else if (value === 'occurrence') {
      setValue('measureType', 'occurrence');
      setValue('eventDefault', { disabled: false, value: 0 }, { shouldDirty: true });
      setValue('unit', undefined);
      setValue('analysisType', 'mean');
      setValue('percentileValue', undefined);
      setValue('unitAggregationType', 'average', { shouldDirty: true });
      setValue('analysisType', 'mean', { shouldDirty: true });
      setValue('isNumeric', false, { shouldDirty: true });
    } else {
      setValue('unitAggregationType', undefined, { shouldDirty: true });
      setValue('measureType', 'value');
      setValue('isNumeric', true, { shouldDirty: true });
    }
  };

  function handleUnitAggregationTypeSelect(value: string) {
    if (value === 'average' || value === 'sum') {
      setValue('unitAggregationType', value, { shouldDirty: true });
    }
  }

  return (
    <>
      <FieldGroup errorMessage={errors.eventKey?.message}>
        <TextField className={styles.eventKeyInput}>
          <span className={styles.tooltipContainer}>
            <RequiredLabel label="Event key" htmlFor="eventKey" />
            <HelpTooltip placement="right">
              Add this event key to your codebase to let your SDK track actions taken in your app as events
            </HelpTooltip>
          </span>

          <Input
            {...register('eventKey', { required: 'Event key is required' })}
            value={useWatch({ control, name: 'eventKey', exact: true })}
            placeholder="Enter new or existing event key"
          />
          {enableMetricEventActivity() && <EventActivitySummary />}
        </TextField>
      </FieldGroup>
      <CardSelectGroup
        context={context}
        name="measureType"
        label="What do you want to measure?"
        validationErrorMessage="Selection is required"
        options={measureTypeCardSelectOptions}
        ariaLabel="What do you want to measure?"
        onSelect={handleMeasureOptionSelect}
        errorMessage={errors?.measureType?.message}
        defaultValue={measureType}
      />
      {measureType === 'value' && (
        <>
          <CardSelectGroup
            context={context}
            name="unitAggregationType"
            label="Choose how multiple event values are aggregated per unit"
            validationErrorMessage="Event value aggregation is required"
            options={unitAggregationCardSelectOptions}
            ariaLabel="Choose how multiple event values are aggregated per unit"
            onSelect={handleUnitAggregationTypeSelect}
            errorMessage={errors.unitAggregationType?.message}
            defaultValue={unitAggregationType}
          />
          <div className={styles.formElementsGroup}>
            {unitAggregationType && <MetricDefinition context={context} />}
            {!hideEventDefault && (
              <FieldGroup errorMessage={errors.eventDefault?.message} className={styles.labelInputWrapper}>
                <RequiredLabel label="Units without events" htmlFor="eventDefault" />
                <EventDefaultPicker currentEventDefault={eventDefault} setValue={setValue} />
              </FieldGroup>
            )}
            <FieldGroup errorMessage={errors.unit?.message}>
              <TextField>
                <RequiredLabel label="Unit of measure" htmlFor="unitOfMeasure" />
                <Input
                  {...register('unit', { required: 'Unit of measure is required' })}
                  value={unitOfMeasure}
                  placeholder="ex: $, ms"
                  id="unitOfMeasure"
                  className={styles.unitOfMeasureInput}
                />
              </TextField>
            </FieldGroup>
          </div>
        </>
      )}
      {measureType && measureType !== 'value' && <MetricDefinition context={context} />}
    </>
  );
};

// NOTE: the enabled | disabled below refers to whether we're supporting a default or not,
// not whether this form field is able to be edited.
interface EventDefaultPickerProps {
  currentEventDefault: 'enabled' | 'disabled';
  setValue: UseFormSetValue<MetricForm>;
}

const EventDefaultPicker: React.FC<EventDefaultPickerProps> = (props: EventDefaultPickerProps) => {
  function onEventDefaultChange(value: string) {
    if (value === 'disabled') {
      props.setValue('eventDefault', { disabled: true }, { shouldDirty: true });
    } else {
      props.setValue('eventDefault', { disabled: false, value: 0 }, { shouldDirty: true });
    }
  }
  return (
    <RadioGroup
      onChange={onEventDefaultChange}
      value={props.currentEventDefault}
      id="eventDefault"
      aria-label="Choose a default behavior for events"
    >
      <Radio value="enabled">Include units that did not send any events and set their value to 0</Radio>
      <Radio value="disabled">Exclude units that did not send any events from the analysis</Radio>
    </RadioGroup>
  );
};
