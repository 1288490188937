import { Fragment } from 'react';
import { ClauseValue } from '@gonfalon/clauses';
import { useProjectContext, useSelectedEnvironmentKey } from '@gonfalon/context';
import { Time } from '@gonfalon/datetime';
import { DateFormat } from '@gonfalon/format';
import { Segment, SegmentSemanticInstruction } from '@gonfalon/openapi';
import { capitalize } from '@gonfalon/strings';
import { List } from 'immutable';
import nullthrows from 'nullthrows';

import { ClauseInstructionDescription } from 'components/InstructionList/ClauseInstructionDescription';
import { ClauseValueInstructionEntry } from 'components/InstructionList/ClauseValueInstructionEntry';
import { CollapsibleInstructionListItem } from 'components/InstructionList/CollapsibleInstructionListItem';
import { InstructionListItem } from 'components/InstructionList/InstructionListItem';
import { InstructionListChangeKind, InstructionListSubCategory } from 'components/InstructionList/instructionListUtils';
import { NestedInstructionListItem } from 'components/InstructionList/NestedInstructionListItem';
import { RuleDescriptionKind } from 'components/InstructionList/RuleInstructionDescription';
import { createClause } from 'utils/clauseUtils';
import { ClauseInstructionKind } from 'utils/instructions/clauses/types';

import { useOriginalSegment } from '../hooks/useOriginalSegment';

import { SegmentRuleInstructionDescription } from './SegmentRuleInstructionDescription';

import styles from './SegmentInstructionsList.module.css';

export type SegmentInstructionsListProps = {
  segment: Segment;
  instructions: SegmentSemanticInstruction[];
};

const INSTRUCTION_CATEGORY = {
  INCLUDED: 'Included targets',
  EXCLUDED: 'Excluded targets',
  RULES: 'Rules',
};

export function SegmentInstructionsList({ instructions, segment }: SegmentInstructionsListProps) {
  const { project } = useProjectContext();
  const projectKey = project.key;
  const environmentKey = useSelectedEnvironmentKey();

  const originalSegment = useOriginalSegment({ segmentKey: segment.key });
  const instructionElementsByCategory: { [category: string]: JSX.Element[] } = {
    [INSTRUCTION_CATEGORY.INCLUDED]: [],
    [INSTRUCTION_CATEGORY.EXCLUDED]: [],
    [INSTRUCTION_CATEGORY.RULES]: [],
  };

  const instructionsByRuleId: { [ruleId: string]: JSX.Element[] } = {};

  const updateInstructionsForRuleId = (ruleId: string, instructionListItem: JSX.Element) => {
    instructionsByRuleId[ruleId]
      ? instructionsByRuleId[ruleId].push(instructionListItem)
      : (instructionsByRuleId[ruleId] = [instructionListItem]);
  };

  const addInstructionToCategory = (category: string, instructionListItem: JSX.Element) => {
    instructionElementsByCategory[category].push(instructionListItem);
  };

  const makeListItemForTargetingContext = ({
    instructionKind,
    changeKind,
    contextKind,
    instructionValues,
    index,
  }: {
    instructionKind: SegmentSemanticInstruction['kind'];
    changeKind: InstructionListChangeKind;
    contextKind: string;
    instructionValues: string[];
    index: number;
  }) => (
    <InstructionListItem key={`${instructionKind}-${index}`} changeKind={changeKind}>
      <span key={`${instructionValues.toString()}-${index}`}>
        {capitalize(changeKind)}{' '}
        {instructionValues.map((value, valueIdx) => (
          <Fragment key={valueIdx}>
            <code>{value}</code>
            {valueIdx !== instructionValues.length - 1 ? ', ' : ''}
          </Fragment>
        ))}{' '}
        for context kind <code>{contextKind}</code>
      </span>
    </InstructionListItem>
  );

  function getExpiringTargetCategory(contextKind: string, value: string) {
    if (contextKind === 'user') {
      if (segment.excluded && segment.excluded.includes(value)) {
        return INSTRUCTION_CATEGORY.EXCLUDED;
      }
    } else if (
      Boolean(segment.excludedContexts?.find((context) => context.contextKind === contextKind)?.values?.includes(value))
    ) {
      return INSTRUCTION_CATEGORY.EXCLUDED;
    }

    return INSTRUCTION_CATEGORY.INCLUDED;
  }

  function getClauseForRule(ruleId: string, clauseId: string) {
    const seg = originalSegment ?? segment;
    const existingRule = nullthrows(seg.rules.find((r) => r._id === ruleId));
    const existingClause = nullthrows(existingRule.clauses.find((c) => c._id === clauseId));

    return createClause({ ...existingClause, values: existingClause.values as ClauseValue[] });
  }

  function getNewRuleOrder(ruleIds: string[]) {
    const originalOrder = originalSegment?.rules ?? segment.rules;

    const newOrder: typeof originalOrder = [];

    for (const id of ruleIds) {
      for (const rule of originalOrder) {
        if (rule._id === id) {
          newOrder.push(rule);
          break;
        }
      }
    }

    return newOrder;
  }

  instructions.forEach((instruction, idx) => {
    switch (instruction.kind) {
      case 'addIncludedUsers':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.INCLUDED,
          makeListItemForTargetingContext({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.ADD,
            contextKind: 'user',
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'addIncludedTargets':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.INCLUDED,
          makeListItemForTargetingContext({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.ADD,
            contextKind: instruction.contextKind,
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'removeIncludedTargets':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.INCLUDED,
          makeListItemForTargetingContext({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.REMOVE,
            contextKind: instruction.contextKind,
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'removeIncludedUsers':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.INCLUDED,
          makeListItemForTargetingContext({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.REMOVE,
            contextKind: 'user',
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'addExcludedUsers':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.EXCLUDED,
          makeListItemForTargetingContext({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.ADD,
            contextKind: 'user',
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'addExcludedTargets':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.EXCLUDED,
          makeListItemForTargetingContext({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.ADD,
            contextKind: instruction.contextKind,
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'removeExcludedUsers':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.EXCLUDED,
          makeListItemForTargetingContext({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.REMOVE,
            contextKind: 'user',
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'removeExcludedTargets':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.EXCLUDED,
          makeListItemForTargetingContext({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.REMOVE,
            contextKind: instruction.contextKind,
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'addExpiringTarget':
        addInstructionToCategory(
          getExpiringTargetCategory(instruction.contextKind, instruction.value),
          <InstructionListItem key={`${instruction.kind}-${idx}`} changeKind={InstructionListChangeKind.ADD}>
            Schedule removal of context kind <code>{instruction.contextKind}</code> <code>{instruction.value}</code> for{' '}
            <Time
              className={styles.time}
              datetime={instruction.removalDate}
              dateFormat={DateFormat.MM_DD_YYYY_H_MM_A_Z}
              notooltip
            />
          </InstructionListItem>,
        );
        return;
      case 'removeExpiringTarget':
        addInstructionToCategory(
          getExpiringTargetCategory(instruction.contextKind, instruction.value),
          <InstructionListItem key={`${instruction.kind}-${idx}`} changeKind={InstructionListChangeKind.REMOVE}>
            Delete scheduled removal of context kind <code>{instruction.contextKind}</code>{' '}
            <code>{instruction.value}</code>
          </InstructionListItem>,
        );
        return;
      case 'updateExpiringTarget':
        addInstructionToCategory(
          getExpiringTargetCategory(instruction.contextKind, instruction.value),
          <InstructionListItem key={`${instruction.kind}-${idx}`} changeKind={InstructionListChangeKind.CHANGE}>
            Update scheduled removal of context kind <code>{instruction.contextKind}</code>{' '}
            <code>{instruction.value}</code> to{' '}
            <Time
              className={styles.time}
              datetime={instruction.removalDate}
              dateFormat={DateFormat.MM_DD_YYYY_H_MM_A_Z}
              notooltip
            />
          </InstructionListItem>,
        );
        return;
      case 'addRule':
        const { kind, ...rule } = instruction;
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.RULES,
          <InstructionListItem key={`${instruction.kind}-${idx}`} changeKind={InstructionListChangeKind.ADD}>
            Add rule <SegmentRuleInstructionDescription rule={rule} />
          </InstructionListItem>,
        );
        return;
      case 'removeRule':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.RULES,
          <InstructionListItem key={`${instruction.kind}-${idx}`} changeKind={InstructionListChangeKind.REMOVE}>
            Remove rule{' '}
            <SegmentRuleInstructionDescription
              rule={originalSegment ? originalSegment.rules.find((r) => r._id === instruction.ruleId) : undefined}
            />
          </InstructionListItem>,
        );
        return;
      case 'updateRuleDescription':
        updateInstructionsForRuleId(
          instruction.ruleId,
          <InstructionListItem key={`${instruction.kind}-${idx}`} changeKind={InstructionListChangeKind.CHANGE}>
            Update rule description to {instruction.description}
          </InstructionListItem>,
        );
        return;
      case 'addValuesToClause':
        updateInstructionsForRuleId(
          instruction.ruleId,
          <InstructionListItem key={`${instruction.kind}-${idx}`} changeKind={InstructionListChangeKind.ADD}>
            <ClauseValueInstructionEntry
              projKey={projectKey}
              envKey={environmentKey}
              clause={getClauseForRule(instruction.ruleId, instruction.clauseId)}
              values={List(instruction.values as ClauseValue[])}
              kind={ClauseInstructionKind.ADD_VALUES_TO_CLAUSE}
            />
          </InstructionListItem>,
        );
        return;
      case 'removeValuesFromClause':
        updateInstructionsForRuleId(
          instruction.ruleId,
          <InstructionListItem key={`${instruction.kind}-${idx}`} changeKind={InstructionListChangeKind.REMOVE}>
            <ClauseValueInstructionEntry
              projKey={projectKey}
              envKey={environmentKey}
              clause={getClauseForRule(instruction.ruleId, instruction.clauseId)}
              values={List(instruction.values as ClauseValue[])}
              kind={ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE}
            />
          </InstructionListItem>,
        );
        return;
      case 'addClauses':
        instruction.clauses.forEach((clause, clauseIdx) => {
          updateInstructionsForRuleId(
            instruction.ruleId,
            <InstructionListItem
              key={`${instruction.kind}-${idx}-clause-${clauseIdx}`}
              changeKind={InstructionListChangeKind.ADD}
            >
              Add clause{' '}
              <ClauseInstructionDescription
                projKey={projectKey}
                envKey={environmentKey}
                clause={createClause({ ...clause, values: clause.values as ClauseValue[] })}
                kind={RuleDescriptionKind.CLAUSE_SUMMARY}
              />
            </InstructionListItem>,
          );
        });
        return;
      case 'removeClauses':
        instruction.clauseIds.forEach((clauseId, clauseIdx) => {
          updateInstructionsForRuleId(
            instruction.ruleId,
            <InstructionListItem
              key={`${instruction.kind}-${idx}-clause-${clauseIdx}`}
              changeKind={InstructionListChangeKind.REMOVE}
            >
              Remove clause{' '}
              <ClauseInstructionDescription
                projKey={projectKey}
                envKey={environmentKey}
                clause={getClauseForRule(instruction.ruleId, clauseId)}
                kind={RuleDescriptionKind.CLAUSE_SUMMARY}
              />
            </InstructionListItem>,
          );
        });
        return;
      case 'reorderRules':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.RULES,
          <NestedInstructionListItem
            subCategoryHeader={InstructionListSubCategory.REORDER_RULES}
            isOrderedList
            changeKind={InstructionListChangeKind.REORDER}
            key={`${instruction.kind}-${idx}`}
          >
            {getNewRuleOrder(instruction.ruleIds).map((r, index) => (
              <InstructionListItem
                key={`${instruction.kind}-${idx}-${index}`}
                changeKind={InstructionListChangeKind.DECIMAL}
              >
                <SegmentRuleInstructionDescription rule={r} />
              </InstructionListItem>
            ))}
          </NestedInstructionListItem>,
        );
        return;
      case 'updateClause':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.RULES,
          <InstructionListItem key={`${instruction.kind}-${idx}`} changeKind={InstructionListChangeKind.CHANGE}>
            Update clause{' '}
            <ClauseInstructionDescription
              projKey={projectKey}
              envKey={environmentKey}
              clause={createClause({ ...instruction.clause, values: instruction.clause.values as ClauseValue[] })}
              kind={RuleDescriptionKind.CLAUSE_SUMMARY}
            />
          </InstructionListItem>,
        );
        return;
      default:
    }
  });

  Object.keys(instructionsByRuleId).forEach((ruleId) => {
    if (instructionsByRuleId[ruleId].length === 0) {
      return;
    }
    const ruleIdx = originalSegment ? originalSegment.rules.findIndex((rule) => rule._id === ruleId) : -1;
    const ruleNumber = ruleIdx + 1;
    const rule = originalSegment ? originalSegment.rules[ruleIdx] : undefined;
    const ruleDescription = rule ? rule.description || `Rule ${ruleNumber}` : 'rule not found';

    const insElem = (
      <NestedInstructionListItem
        subCategoryHeader="Edit rule"
        subCategoryDetails={ruleDescription}
        changeKind={InstructionListChangeKind.CHANGE}
        key={ruleId}
      >
        {instructionsByRuleId[ruleId].map((ins) => ins)}
      </NestedInstructionListItem>
    );

    addInstructionToCategory(INSTRUCTION_CATEGORY.RULES, insElem);
  });

  const categoriesWithInstructions = Object.keys(instructionElementsByCategory).filter(
    (category) => instructionElementsByCategory[category].length,
  );
  return (
    <ul className={styles.container}>
      {categoriesWithInstructions.map((category, idx) => (
        <CollapsibleInstructionListItem
          key={idx}
          initialOpen={idx === 0}
          categoryHeader={category}
          categoryInsElems={instructionElementsByCategory[category]}
        />
      ))}
    </ul>
  );
}
