import { pluralize } from '@gonfalon/strings';
import { Map } from 'immutable';
import { Tooltip } from 'launchpad';

import styles from 'stylesheets/components/Variation.module.css';
import { Flag, Variation } from 'utils/flagUtils';

import { VariationIconStack } from './VariationIconStack/VariationIconStack';
import { EmptyVariationDiamond } from './EmptyVariationDiamond';

const MAX_DISPLAY_VARIATION_ICON = 5;

type ActiveFlagVariationsDiamondsProps = {
  environmentKey: string;
  environmentName?: string;
  flag: Flag;
  listOfVariationNames: string;
  showPrereqsWarning?: boolean;
  useFlagConfiguration?: boolean;
  variations?: Map<number, Variation>;
};

const ActiveFlagVariationsDiamonds = ({
  environmentKey,
  environmentName,
  flag,
  listOfVariationNames,
  showPrereqsWarning,
  useFlagConfiguration,
  variations,
}: ActiveFlagVariationsDiamondsProps) => {
  const count = variations ? variations.count() : 0;
  const hasPrerequisites = flag.hasPrerequisites(environmentKey, useFlagConfiguration);

  const tooltipIntroText = useFlagConfiguration ? 'On save, this flag will serve' : 'This flag is serving';
  const variationCountText = pluralize('variation', count, true);

  let tooltipContent;
  if (showPrereqsWarning && hasPrerequisites && flag.isOn(environmentKey)) {
    tooltipContent = useFlagConfiguration
      ? 'On save, variations served will depend on prerequisites'
      : 'Multiple variations';
  } else {
    tooltipContent =
      count === 0
        ? 'No off variation is set. Users will get the default defined in your codebase.'
        : `${tooltipIntroText} ${variationCountText}: ${listOfVariationNames}`;
  }

  const environmentDisplay = environmentName ?? flag.environments.get(environmentKey)?._environmentName;
  const ariaLabel = count
    ? `Variations served for ${environmentDisplay}`
    : `No off variation for ${environmentDisplay}`;

  const Diamonds = () => {
    if (showPrereqsWarning && hasPrerequisites && flag.isOn(environmentKey)) {
      return <EmptyVariationDiamond />;
    }
    if (variations && count) {
      return <VariationIconStack maxIconsToDisplay={MAX_DISPLAY_VARIATION_ICON} variations={variations} />;
    }
    return <EmptyVariationDiamond />;
  };

  return (
    <Tooltip targetClassName={styles.descriptionPopover} placement="bottom" content={tooltipContent}>
      <span role="button" tabIndex={0} aria-label={ariaLabel}>
        <Diamonds />
      </span>
    </Tooltip>
  );
};

export { ActiveFlagVariationsDiamonds };
