import { Map } from 'immutable';
import { Tooltip } from 'launchpad';

import styles from 'stylesheets/components/Variation.module.css';
import { Flag, Variation } from 'utils/flagUtils';

const MAX_DISPLAY_VARIATION_NAME = 2;
const MAX_DISPLAY_VARIATION_LENGTH = 64;

type ActiveFlagVariationsLabelProps = {
  environmentKey: string;
  flag: Flag;
  listOfVariationNames: string;
  showDetailedSummaryText?: boolean;
  showOffVariationText?: boolean;
  showPrereqsWarning?: boolean;
  useFlagConfiguration?: boolean;
  variations?: Map<number, Variation>;
};

const ActiveFlagVariationsLabel = ({
  environmentKey,
  flag,
  listOfVariationNames,
  showDetailedSummaryText,
  showOffVariationText,
  showPrereqsWarning,
  useFlagConfiguration,
  variations,
}: ActiveFlagVariationsLabelProps) => {
  const count = variations ? variations.count() : 0;
  const VariationText = () => {
    const detailedSummaryText = useFlagConfiguration ? 'On save, will serve' : 'Currently serving';
    let text;
    const hasPrerequisites = flag.hasPrerequisites(environmentKey, useFlagConfiguration);
    if (showPrereqsWarning && hasPrerequisites && flag.isOn(environmentKey)) {
      text = useFlagConfiguration ? 'On save, variations served will depend on prerequisites' : 'Multiple variations';
    } else if (count === 0) {
      text = showDetailedSummaryText ? `${detailedSummaryText} default defined in code` : '';
    } else {
      const introText = showDetailedSummaryText ? `${detailedSummaryText} ` : '';
      // If there are more than N variations, just show the count
      text =
        count > MAX_DISPLAY_VARIATION_NAME ? `${introText}${count} variations` : `${introText}${listOfVariationNames}`;
    }
    return (
      <>
        {text.length > MAX_DISPLAY_VARIATION_LENGTH ? (
          <Tooltip className={styles.tooltip} targetClassName={styles.popoverTarget} content={text}>
            <span className={styles.variationNames}>{text}</span>
          </Tooltip>
        ) : (
          <span className={styles.variationNames}>{text}</span>
        )}
      </>
    );
  };

  const OffVariationText = () => {
    if (!variations || variations.isEmpty()) {
      return <span className={styles.descriptionDetails}>(No off variation set)</span>;
    }
    if (!showOffVariationText || flag.isOn(environmentKey)) {
      return <></>;
    }
    return (
      <>
        &nbsp;
        <span className={styles.descriptionDetails}>- off variation</span>
      </>
    );
  };

  return (
    <div className={styles.variationNameContainer}>
      <VariationText />
      <OffVariationText />
    </div>
  );
};

export { ActiveFlagVariationsLabel };
