import { useState } from 'react';
import { isEmpty } from '@gonfalon/es6-utils';
import { Alert } from 'launchpad';

import ExperimentationAlert from 'components/FlagExperiments/ExperimentationAlert';
import { MultipleApprovalRequestsCreatedAlert } from 'components/PendingChanges/MultipleApprovalRequestsCreatedAlert';

type PendingChangesConfirmationModalAlertsProps = {
  showExperimentsAlert: boolean;
  showMultipleApprovalRequestsCreatedAlert: boolean;
  showFlagTargetingOffAlert: boolean;
  showDeprecatedAlert: boolean;
  showMigrationGuardrailsAlert?: boolean;
  ExperimentsAlertComponent?: React.ReactNode;
  MigrationsGuardrailsSafetyIssuesComponent?: React.ReactNode;
};

export const NUM_APPROVAL_REQUESTS_CREATED = 2;
export const TEXT_APPROVAL_REQUESTS_CREATED =
  'Approval will be requested separately for flag targeting updates and updates to the scheduled removal of individual targets.';

/* eslint-disable import/no-default-export */
export default function PendingChangesConfirmationModalAlerts({
  showExperimentsAlert = false,
  showMultipleApprovalRequestsCreatedAlert = false,
  showFlagTargetingOffAlert = false,
  showDeprecatedAlert = false,
  showMigrationGuardrailsAlert = false,
  ExperimentsAlertComponent,
  MigrationsGuardrailsSafetyIssuesComponent,
}: PendingChangesConfirmationModalAlertsProps) {
  const [hideTargetingOff, setHideTargetingOff] = useState(false);
  return (
    <section className="PendingChangesConfirmationModalAlerts">
      <ul className="PendingChangesConfirmationModalAlerts-List">
        {showDeprecatedAlert && (
          <li>
            <section>
              <Alert kind="info" header="You are making changes to a deprecated flag">
                Deprecated flags are still evaluated.
              </Alert>
            </section>
          </li>
        )}
        {showExperimentsAlert && (
          <li>{!isEmpty(ExperimentsAlertComponent) ? ExperimentsAlertComponent : <ExperimentationAlert />}</li>
        )}
        {showMultipleApprovalRequestsCreatedAlert && (
          <li>
            <MultipleApprovalRequestsCreatedAlert
              numApprovalRequests={NUM_APPROVAL_REQUESTS_CREATED}
              bodyText={TEXT_APPROVAL_REQUESTS_CREATED}
            />
          </li>
        )}
        {showFlagTargetingOffAlert && !hideTargetingOff && (
          <li>
            <section>
              <Alert kind="success" size="small" dismissible onDismiss={() => setHideTargetingOff(true)}>
                Your flag changes will be saved, but the flag is currently off.
              </Alert>
            </section>
          </li>
        )}
        {showMigrationGuardrailsAlert &&
          !isEmpty(MigrationsGuardrailsSafetyIssuesComponent) &&
          MigrationsGuardrailsSafetyIssuesComponent}
      </ul>
    </section>
  );
}
