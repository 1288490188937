import { ReactNode } from 'react';

import styles from './KindButtonGroup.module.css';

export function KindButtonGroup({ children, title }: { children: ReactNode; title: string }) {
  return (
    <>
      <h2 className="u-mt-xl">{title}</h2>
      <fieldset className={styles.kindButtonGroup}>{children}</fieldset>
    </>
  );
}
