import { ClipboardEvent, ClipboardEventHandler, Dispatch, useState } from 'react';
import { isBulkUserManagementEnabled } from '@gonfalon/dogfood-flags';
import { SegmentPendingChangesActionsType } from '@gonfalon/segments';
import { List, Set } from 'immutable';
import { Label } from 'launchpad';
import { isNil } from 'lodash';

import { ContextKind } from 'components/Contexts/types';
import ContextTargetGrid from 'components/contextTargeting/ContextTargetGrid';
import SelectContextContainer from 'components/contextTargeting/SelectContextContainer';
import { AccessDecision } from 'utils/accessUtils';
import { SegmentVariationTypes } from 'utils/expiringContextTargetsUtils';
import { getTargetByContextAndVariation, Target } from 'utils/flagUtils';
import { isBulkInput, readPastedInput } from 'utils/inputUtils';
import { getSegmentTargetByContext, SegmentTarget } from 'utils/segmentUtils';

import './ContextTargeting.css';

export type ManageContextTargetingProps = {
  updateTargetAccessCheck: AccessDecision;
  variationName?: string;
  projKey: string;
  envKey: string;
  updateExpiringTargetsAccess?: AccessDecision;
  segmentKey?: string;
  segmentVariation?: SegmentVariationTypes;
  allTargets: List<Target> | List<SegmentTarget>;
  contextKind: ContextKind;
  gridId: string;
  selectId: string;
  variationId?: string;
  variationIndex: number;
  onChange(targets: List<string>, contextKind?: string): void;
  alreadyTargetedKeys: Set<string>;
  openBulkEditModal(input?: string, contextKind?: string): void;
  contextTargetGridColumnCount?: number;
  segmentPendingChangesDispatch?: Dispatch<SegmentPendingChangesActionsType>;
};

export const ManageContextTargeting = ({
  contextKind,
  allTargets,
  alreadyTargetedKeys,
  onChange,
  projKey,
  envKey,
  segmentKey,
  variationId,
  gridId,
  selectId,
  variationIndex,
  updateTargetAccessCheck,
  updateExpiringTargetsAccess,
  segmentVariation,
  openBulkEditModal,
  contextTargetGridColumnCount,
  segmentPendingChangesDispatch,
}: ManageContextTargetingProps) => {
  const [openContextPopoverKey, setOpenContextPopoverKey] = useState('');
  const isSegmentTargeting = !isNil(segmentVariation);
  const contextKey = contextKind.key;
  let targetsToDisplay: List<string> = List();

  if (!isSegmentTargeting) {
    targetsToDisplay = getTargetByContextAndVariation(allTargets as List<Target>, contextKey, variationIndex);
  }
  if (isSegmentTargeting) {
    targetsToDisplay = getSegmentTargetByContext(allTargets as List<SegmentTarget>, contextKey);
  }

  const handleSelectTargetChange = (value?: string) => {
    value && onChange(targetsToDisplay.unshift(value), contextKey);
  };

  const handleDeleteTarget = (target: string) => {
    const index = targetsToDisplay.indexOf(target);
    if (index > -1) {
      onChange(targetsToDisplay.delete(index), contextKey);
    }
  };

  const handlePaste: ClipboardEventHandler<HTMLDivElement> = (event: ClipboardEvent<HTMLDivElement>) => {
    const input = readPastedInput(event);

    if (isBulkInput(input) && isBulkUserManagementEnabled()) {
      // This prevents a subsequent change event.
      event.preventDefault();
      openBulkEditModal(input, contextKind.key);
    }
  };

  return (
    <div>
      <div className="ManageContextTargeting">
        <div data-test-id="select-targets" className="u-flex u-flex-row">
          <div className="ContextTargeting-selectAndGrid">
            <Label>
              <h4 className="u-pb-s">{contextKind.name}</h4>
            </Label>
            <SelectContextContainer
              id={selectId}
              className="ContextTargeting-selectContext"
              alreadyTargetedKeys={alreadyTargetedKeys}
              hasSelectedTargets={!targetsToDisplay.isEmpty()}
              onOpenContextPopover={(input?: string) => input && setOpenContextPopoverKey(input)}
              envKey={envKey}
              projKey={projKey}
              onChange={(option: { value?: string } | null) => {
                option && handleSelectTargetChange(option.value);
              }}
              hasExpiringContextTargetAccess={updateExpiringTargetsAccess?.isAllowed}
              onPaste={handlePaste}
              contextKind={contextKey}
              isDisabled={!updateTargetAccessCheck.isAllowed}
              disabledReason={updateTargetAccessCheck.getActionReason()}
            />

            {!targetsToDisplay.isEmpty() ? (
              <ContextTargetGrid
                variationId={variationId}
                segmentKey={segmentKey}
                id={gridId}
                targets={targetsToDisplay}
                accessCheck={updateTargetAccessCheck}
                updateExpiringTargetsAccess={updateExpiringTargetsAccess}
                envKey={envKey}
                projKey={projKey}
                onDeleteTarget={(target: string) => handleDeleteTarget(target)}
                onSetOpenContextPopoverKey={setOpenContextPopoverKey}
                openContextPopoverKey={openContextPopoverKey}
                contextKind={contextKey}
                columnCount={contextTargetGridColumnCount}
                segmentPendingChangesDispatch={segmentPendingChangesDispatch}
              />
            ) : (
              <div className="ManageContextTargeting-cellPlaceHolder" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
