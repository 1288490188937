import UpsellFeatureImage from 'components/upsells/UpsellFeatureImage';

const UpsellImageBulkFeatures = () => (
  <UpsellFeatureImage
    altText="A screenshot showing bulk user management features in action."
    fileName="bulk-features"
    height="215"
    width="552"
  />
);

/* eslint-disable import/no-default-export */
export default UpsellImageBulkFeatures;
