import { ProjectContext } from '@gonfalon/context';
import { UIContextsView } from '@gonfalon/context-kinds';
import { UIFlagListView } from '@gonfalon/flag-filters';
import { AppRoute } from '@gonfalon/router';
import { UISegmentsListView } from '@gonfalon/segments';

interface ShortcutBase {
  name: string;
  icon: string;
  key: string;
  visibility: ShortcutVisibility;
  context: ProjectContext;
}
interface FlagListShortcut extends ShortcutBase {
  type: 'flags';
  filters?: UIFlagListView;
}
interface ContextListShortcut extends ShortcutBase {
  type: 'contexts';
  filters?: UIContextsView;
}
interface FlagShortcut extends ShortcutBase {
  type: 'flag';
  entityKey: string;
  filters: undefined;
}
interface SegmentsShortcut extends ShortcutBase {
  type: 'segments';
  filters?: UISegmentsListView;
}

export type Shortcut = FlagListShortcut | ContextListShortcut | FlagShortcut | SegmentsShortcut;

export type AvailableShortcuts = keyof typeof availableShortcuts;

export const availableShortcuts = {
  '/projects/:projectKey/flags': 'flags',
  // This is a legacy route that renders as a descendant of the flag list route.
  // It is unclear how long it will continue to live here. Putting it here
  // ensures the shortcut trigger doesn't disappear when navigating to it: other wise
  // that causes a weird layout shift in the background (above the flag list)
  '/projects/:projectKey/flags/:flagKey/environment-overview': 'flag',
  '/projects/:projectKey/contexts': 'contexts',
  '/projects/:projectKey/flags/:flagKey/targeting': 'flag',
  '/projects/:projectKey/segments': 'segments',
} satisfies {
  [K in AppRoute]?: Shortcut['type'];
};

export type ShortcutVisibility = 'me';
