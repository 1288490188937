import UpsellFeatureImage from 'components/upsells/UpsellFeatureImage';

const UpsellImageConvertToSegment = () => (
  <UpsellFeatureImage
    altText="A screenshot showing the process of converting a list of users to a segment."
    fileName="convert-segment"
    height="170"
    width="552"
  />
);

/* eslint-disable import/no-default-export */
export default UpsellImageConvertToSegment;
