import { getFlagVariationDisplayValue } from '@gonfalon/flags';
import cx from 'clsx';
import { Map } from 'immutable';

import { ActiveFlagVariationsDiamonds } from 'components/ActiveFlagVariationsDiamonds';
import { ActiveFlagVariationsLabel } from 'components/ActiveFlagVariationsLabel';
import styles from 'stylesheets/components/Variation.module.css';
import { Flag, getActiveVariations, Variation } from 'utils/flagUtils';

export type ActiveFlagVariationsProps = {
  className?: string;
  environmentKey: string;
  environmentName?: string;
  flag: Flag;
  segmentKey?: string;
  showDetailedSummaryText?: boolean;
  showOffVariationText?: boolean;
  showPrereqsWarning?: boolean;
  useFlagConfiguration?: boolean;
};

/* eslint-disable import/no-default-export */
export default function ActiveFlagVariations({
  className,
  environmentKey,
  environmentName,
  flag,
  segmentKey,
  showDetailedSummaryText = false,
  showOffVariationText = true,
  showPrereqsWarning = true,
  useFlagConfiguration = false,
}: ActiveFlagVariationsProps) {
  // Using active variations from _summary shows only saved data. Using active variations
  // from the flag config means it'll update as a user edits the flag's configuration.
  const activeVariations: Map<number, Variation> | undefined = getActiveVariations({
    environmentKey,
    flag,
    segmentKey,
    useFlagConfiguration,
  });

  const listOfVariationNames =
    activeVariations
      ?.map((v: Variation) => getFlagVariationDisplayValue(v.toJS(), { displayValueForEmptyStrings: true }))
      .join(', ') || '';

  return (
    <div className={cx([styles.variations], className)}>
      <ActiveFlagVariationsDiamonds
        environmentKey={environmentKey}
        environmentName={environmentName}
        flag={flag}
        listOfVariationNames={listOfVariationNames}
        showPrereqsWarning={showPrereqsWarning}
        useFlagConfiguration={useFlagConfiguration}
        variations={activeVariations}
      />
      <ActiveFlagVariationsLabel
        environmentKey={environmentKey}
        flag={flag}
        listOfVariationNames={listOfVariationNames}
        showDetailedSummaryText={showDetailedSummaryText}
        showOffVariationText={showOffVariationText}
        showPrereqsWarning={showPrereqsWarning}
        useFlagConfiguration={useFlagConfiguration}
        variations={activeVariations}
      />
    </div>
  );
}
