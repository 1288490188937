// TODO: For now, this only supports user context kinds. SC-171088 for supporting other context kinds

import { Component, createRef, ReactNode, RefObject } from 'react';
import { connect } from 'react-redux';
import { Item } from 'react-stately';
import { segmentTargetBatchLimit } from '@gonfalon/dogfood-flags';
import { isEmpty } from '@gonfalon/es6-utils';
import { formatCount, pluralize } from '@gonfalon/format';
import { ProgressBar } from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';
import { OrderedSet, Set } from 'immutable';
import {
  Alert,
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TabList,
  TextArea,
  Tooltip,
} from 'launchpad';

import { clearContextsResolutionResults, resolveContexts } from 'actions/contexts';
import { ContextKind, ContextTargetKindKey, ResolveContextsGroups } from 'components/Contexts/types';
import {
  hasKindKeyInList,
  intersectKindKeysList,
  makeKindKeyStringFromKindKey,
  subtractKindKeysList,
} from 'components/Contexts/utils/contextTargetingUtils';
import { Cell, Grid, GridLayoutValue } from 'components/ui/grid';
import { GlobalState } from 'reducers';
import {
  contextResolutionSelector,
  ContextResolutionStateType,
  contextViewStatusByIdSelector,
  groupedContextResolutionSelector,
} from 'reducers/contexts';
import { USER_CONTEXT_KIND } from 'utils/constants';
import { tokenizeInput } from 'utils/inputUtils';
import { RequestProps } from 'utils/requestUtils';
import { ResourceKind } from 'utils/saveButtonUtils';

import { ContextTargetListContainer } from './ContextTargetList';

import styles from './BulkContextTargeting.module.css';

export enum modeTypes {
  REPLACE = 'replace',
  ADD = 'add',
  REMOVE = 'remove',
}

function formatTargetCount(n: number, long: boolean) {
  return `${formatCount(n, long)} ${pluralize(n, 'target', 'targets')}`;
}

function formatButton(mode: modeTypes, count: number, withCount: boolean) {
  const map = {
    [modeTypes.ADD]: withCount ? `Add ${formatTargetCount(count, true)}` : 'Add targets',
    [modeTypes.REMOVE]: withCount ? `Remove ${formatTargetCount(count, true)}` : 'Remove targets',
    [modeTypes.REPLACE]: withCount ? `Replace with ${formatTargetCount(count, true)}` : 'Replace with targets',
  };

  return map[mode];
}

type InvidualTargetListFetchStatusContainerProps = {
  id: string;
  envKey: string;
  projKey: string;
};

const IndividualTargetListFetchStatus = ({ isFetching }: RequestProps) =>
  isFetching ? <ProgressBar aria-label="Loading…" isIndeterminate /> : null;

const IndividualTargetListFetchStatusContainer = connect(
  (state: GlobalState, ownProps: InvidualTargetListFetchStatusContainerProps) =>
    contextViewStatusByIdSelector(state, {
      viewId: ownProps.id,
      envKey: ownProps.envKey,
      projKey: ownProps.projKey,
    }).toObject(),
)(IndividualTargetListFetchStatus);

const TabEmptyState = ({ children, withBlankState }: { children: ReactNode; withBlankState: boolean }) => (
  <>
    <Alert kind="info" className={styles.emptyState} size="small">
      {withBlankState ? 'Enter target keys on the left to search.' : children}
    </Alert>
    <Alert kind="info" className={styles.emptyState} size="small">
      {withBlankState
        ? 'Paste up to 1,500 keys at a time. Click "Add targets", then reopen this modal to paste more.'
        : children}
    </Alert>
  </>
);

const TabPanelStatus = ({ children }: { children: ReactNode }) => (
  <p className="u-mt-xs u-ml-xs u-medium u-flex u-flex-middle u-flex-center">{children}</p>
);

const ContextKindsSelect = ({
  contextKinds,
  value,
  onChange,
}: {
  contextKinds: ContextKind[];
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}) => (
  <select value={value} onChange={onChange} data-test-id="select-contextKind" className={styles.selectContextKind}>
    {contextKinds.map((contextKind) => (
      <option value={contextKind.key} key={contextKind.key}>
        {contextKind.name || contextKind.key}
      </option>
    ))}
  </select>
);

type BulkContextTargetingProps = BulkContextTargetingContainerProps &
  ContextResolutionStateType & {
    onResolveTargets?: (values: OrderedSet<ContextTargetKindKey>) => void;
    onClearResults?: () => void;
    resultGroups?: ResolveContextsGroups;
  };

export type BulkContextTargetingStateProps = {
  activeTab: string;
  activeMode: modeTypes;
  inputTargetKeys: OrderedSet<string>;
  ignoredTargets: Set<ContextTargetKindKey>;
  contextKind: string;
};

class BulkContextTargeting extends Component<BulkContextTargetingProps, BulkContextTargetingStateProps> {
  _textarea: RefObject<HTMLTextAreaElement>;

  constructor(props: BulkContextTargetingProps) {
    super(props);
    this.state = {
      activeTab: 'all',
      activeMode: modeTypes.ADD,
      inputTargetKeys: OrderedSet(),
      ignoredTargets: Set() as Set<ContextTargetKindKey>,
      contextKind: props.inputContextKind || USER_CONTEXT_KIND,
    };
    this._textarea = createRef<HTMLTextAreaElement>();
  }

  componentDidMount() {
    if (this._textarea.current) {
      this._textarea.current.focus();
    }

    const { input } = this.props;

    if (input && !isEmpty(input)) {
      this.updateForInput(input);
    }
  }

  componentWillUnmount() {
    this.props.onClearResults?.();
  }

  render() {
    const { resourceKind, title, input, onCancel, currentTargets, targetCount } = this.props;
    const { isFetching, lastFetched, resultGroups, error, envKey, projKey, contextKinds } = this.props;
    const { inputTargetKeys, ignoredTargets, activeMode, activeTab } = this.state;
    let irrelevantTargets: Set<ContextTargetKindKey> = Set();
    const currentTargetsForContextKind = currentTargets.filter((target) => target.kind === this.state.contextKind);
    const currentCount = currentTargetsForContextKind.size;

    let allTargets: OrderedSet<ContextTargetKindKey> = OrderedSet();
    let foundTargets: OrderedSet<ContextTargetKindKey> = OrderedSet();
    let notFoundTargets: OrderedSet<ContextTargetKindKey> = OrderedSet();
    let explainIrrelevance;

    if (activeMode === modeTypes.ADD) {
      allTargets = resultGroups?.all ?? OrderedSet();
      foundTargets = resultGroups?.found ?? OrderedSet();
      notFoundTargets = resultGroups?.notFound ?? OrderedSet();
      irrelevantTargets = currentTargetsForContextKind.isEmpty()
        ? OrderedSet()
        : intersectKindKeysList(allTargets, currentTargetsForContextKind);
      explainIrrelevance = 'Already targeted';
    } else if (activeMode === modeTypes.REPLACE) {
      allTargets = resultGroups?.all ?? OrderedSet();
      foundTargets = resultGroups?.found ?? OrderedSet();
      notFoundTargets = resultGroups?.notFound ?? OrderedSet();
    } else if (activeMode === modeTypes.REMOVE) {
      allTargets = resultGroups?.all ?? OrderedSet();
      foundTargets = resultGroups?.found ?? OrderedSet();
      notFoundTargets = resultGroups?.notFound ?? OrderedSet();
      irrelevantTargets = currentTargetsForContextKind.isEmpty()
        ? allTargets
        : subtractKindKeysList(allTargets, currentTargetsForContextKind);
      explainIrrelevance = 'Not targeted';
    }

    const allCount = allTargets.size;
    const foundCount = foundTargets.size;
    const notFoundCount = notFoundTargets.size;
    const selectedCount = allCount - ignoredTargets.size - irrelevantTargets.size;
    const limit = segmentTargetBatchLimit();

    let exceedsBatchLimit = false;
    if (resourceKind === ResourceKind.SEGMENT && activeMode !== modeTypes.REMOVE && limit > -1) {
      const newlyAddedTargets = currentTargetsForContextKind.isEmpty()
        ? allTargets
        : subtractKindKeysList(allTargets, currentTargetsForContextKind);
      exceedsBatchLimit = newlyAddedTargets.size + ignoredTargets.size > limit;
    }

    const bulkContextTargetingHeaderDescription = `Add or edit targets for this segment. This segment currently has ${targetCount}/15,000 targets.`;
    return (
      <Modal onCancel={onCancel}>
        <ModalHeader title={title} className={styles.modalHeader} description={bulkContextTargetingHeaderDescription} />
        <ModalBody>
          {error && (
            <Alert kind="error" className={styles.error}>
              An unexpected error occurred.
            </Alert>
          )}
          {exceedsBatchLimit && (
            <Alert kind="error" className={styles.error}>
              {`Number of new targets must not exceed batch limit of ${limit}`}
            </Alert>
          )}

          <Grid layout={GridLayoutValue.ONE_OF_TWO} className={styles.content}>
            <Cell className={styles.selectArea}>
              <p className={styles.selectOptionFieldContainer}>
                <select
                  className={styles.selectContextKind}
                  value={activeMode}
                  onChange={this.handleModeChange}
                  data-test-id="select-mode"
                >
                  <option value={modeTypes.ADD}>Add</option>
                  <option value={modeTypes.REMOVE}>Remove</option>
                  <option value={modeTypes.REPLACE}>Replace</option>
                </select>
                {activeMode === modeTypes.REPLACE ? ' with ' : ' '}these targets for
                <span>
                  <ContextKindsSelect
                    value={this.state.contextKind}
                    contextKinds={contextKinds}
                    onChange={this.handleContextKindChange}
                  />
                </span>
              </p>
              <Label htmlFor="targeting-input">Paste target keys</Label>
              <TextArea
                id="targeting-input"
                ref={this._textarea}
                className={cx(styles.targetingInput, 'fs-exclude')}
                rows={3}
                placeholder="Paste your comma-separated or newline-separated target keys here…"
                defaultValue={input}
                onChange={this.handleInputChange}
              />
              <p aria-live="polite" className="u-fs-sm u-tr u-mt-s">
                {formatTargetCount(inputTargetKeys.size, true)}
              </p>
            </Cell>

            {isFetching && (
              <Cell className={styles.fetchingText}>
                <div>
                  <ProgressBar aria-label="Loading…" isIndeterminate className={styles.progress} />
                  <p aria-live="polite">Looking up individual targets</p>
                </div>
              </Cell>
            )}

            {!isFetching && (
              <Cell>
                <TabList activeTab={activeTab} onChange={this.handleTabChange}>
                  <Item
                    key="all"
                    title={
                      <Tooltip placement="top" content="All targets">
                        <div>{`All (${formatCount(allCount)})`}</div>
                      </Tooltip>
                    }
                  >
                    <>
                      {allCount > 0 ? (
                        <ContextTargetListContainer
                          id="all-list"
                          targets={allTargets.toList()}
                          resolved
                          ignoredTargets={ignoredTargets}
                          irrelevantTargets={irrelevantTargets}
                          explainIrrelevance={explainIrrelevance}
                          onToggle={this.handleToggle}
                          projKey={projKey}
                          envKey={envKey}
                        />
                      ) : (
                        <TabEmptyState withBlankState={inputTargetKeys.isEmpty()}>
                          There are no targets for this operation.
                        </TabEmptyState>
                      )}

                      <TabPanelStatus>
                        <IndividualTargetListFetchStatusContainer id="all-list" envKey={envKey} projKey={projKey} />
                      </TabPanelStatus>
                    </>
                  </Item>
                  <Item
                    key="found"
                    title={
                      <Tooltip placement="top" content="Targets with one matching record">
                        <div data-test-id="found-targets">
                          <Icon name="check" size="small" /> ({formatCount(foundCount)})
                        </div>
                      </Tooltip>
                    }
                  >
                    <>
                      {foundCount > 0 ? (
                        <ContextTargetListContainer
                          id="found-list"
                          targets={foundTargets.toList()}
                          resolved
                          ignoredTargets={ignoredTargets}
                          irrelevantTargets={irrelevantTargets}
                          explainIrrelevance={explainIrrelevance}
                          onToggle={this.handleToggle}
                          projKey={projKey}
                          envKey={envKey}
                        />
                      ) : (
                        <TabEmptyState withBlankState={inputTargetKeys.isEmpty()}>
                          We didn’t find any matching targets.
                        </TabEmptyState>
                      )}

                      <TabPanelStatus>
                        <IndividualTargetListFetchStatusContainer id="found-list" envKey={envKey} projKey={projKey} />
                      </TabPanelStatus>
                    </>
                  </Item>
                  <Item
                    key="notfound"
                    title={
                      <Tooltip placement="top" content="Targets with no matching records">
                        <div data-test-id="not-found-targets">
                          <Icon name="help" size="small" /> ({formatCount(notFoundCount)})
                        </div>
                      </Tooltip>
                    }
                  >
                    <>
                      {notFoundCount > 0 ? (
                        <ContextTargetListContainer
                          id="notfound-list"
                          targets={notFoundTargets.toList()}
                          resolved
                          ignoredTargets={ignoredTargets}
                          irrelevantTargets={irrelevantTargets}
                          explainIrrelevance={explainIrrelevance}
                          onToggle={this.handleToggle}
                          projKey={projKey}
                          envKey={envKey}
                        />
                      ) : (
                        <TabEmptyState withBlankState={inputTargetKeys.isEmpty()}>
                          All targets were found.
                        </TabEmptyState>
                      )}

                      <TabPanelStatus>
                        <IndividualTargetListFetchStatusContainer
                          id="notfound-list"
                          envKey={envKey}
                          projKey={projKey}
                        />
                      </TabPanelStatus>
                    </>
                  </Item>
                  <Item
                    key="current"
                    title={
                      <Tooltip placement="top" content="Currently targeted">
                        <div>Current ({formatCount(currentCount)})</div>
                      </Tooltip>
                    }
                  >
                    <>
                      {currentCount > 0 ? (
                        <ContextTargetListContainer
                          id="current-list"
                          targets={currentTargetsForContextKind.toList()}
                          projKey={projKey}
                          envKey={envKey}
                        />
                      ) : (
                        <TabEmptyState withBlankState={false}>There are no targets.</TabEmptyState>
                      )}

                      <TabPanelStatus>
                        <IndividualTargetListFetchStatusContainer id="current-list" envKey={envKey} projKey={projKey} />
                      </TabPanelStatus>
                    </>
                  </Item>
                </TabList>
              </Cell>
            )}
          </Grid>
        </ModalBody>
        <ModalFooter
          primaryButton={
            <Button kind="primary" disabled={isFetching || selectedCount === 0} onClick={this.handleSave}>
              {formatButton(activeMode, selectedCount, !isFetching && !!lastFetched)}
            </Button>
          }
          secondaryButton={<Button onClick={onCancel}>Cancel</Button>}
          className={`${styles.modalFooter} u-flex u-flex-between`}
        />
      </Modal>
    );
  }

  handleModeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      activeMode: event.target.value as modeTypes,
    });
  };

  handleContextKindChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newContextKind = event.target.value;
    const targetKindKeys = this.state.inputTargetKeys.map((key) => ({
      kind: newContextKind,
      key,
    }));

    this.setState(
      {
        contextKind: event.target.value,
      },
      () => this.props.onResolveTargets?.(targetKindKeys),
    );
  };

  handleTabChange = (tabId: string) => {
    this.setState({
      activeTab: tabId,
    });
  };

  handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.updateForInput(event.target.value);
  };

  handleToggle = (target: ContextTargetKindKey) => {
    this.setState((prevState) => {
      const prevIgnoredTargets = prevState.ignoredTargets;
      return {
        ignoredTargets: hasKindKeyInList(prevIgnoredTargets, target)
          ? prevIgnoredTargets.filter((t) => makeKindKeyStringFromKindKey(t) !== makeKindKeyStringFromKindKey(target))
          : prevIgnoredTargets.add(target),
      };
    });
  };

  handleSave = () => {
    const { currentTargets, resultGroups } = this.props;
    const { activeMode, ignoredTargets } = this.state;
    const all = resultGroups?.all ?? (OrderedSet() as OrderedSet<ContextTargetKindKey>);
    const selected = all.filter((target: ContextTargetKindKey) => !hasKindKeyInList(ignoredTargets, target));
    const currentTargetsForContextKind = currentTargets.filter((target) => target.kind === this.state.contextKind);
    const toTargets = {
      add: selected.concat(currentTargetsForContextKind).map((target) => target.key),
      remove: currentTargetsForContextKind
        .filter((target) => !hasKindKeyInList(selected, target))
        .map((target) => target.key),
      replace: selected.map((target) => target.key),
    };
    this.props.onValue?.(toTargets[activeMode], this.state.contextKind);
  };

  updateForInput(input: string) {
    const values = tokenizeInput(input);
    const targetKindKeys = values.map((key) => ({
      kind: this.state.contextKind,
      key,
    }));
    this.setState({ inputTargetKeys: values }, () => this.props.onResolveTargets?.(targetKindKeys));
  }
}

export type BulkContextTargetingContainerProps = {
  resourceKind: ResourceKind;
  title: string | React.ReactNode;
  currentTargets: OrderedSet<ContextTargetKindKey>;
  targetCount: number;
  input?: string;
  inputContextKind?: string;
  onValue?: (values: OrderedSet<string>, contextKind: string) => void;
  envKey: string;
  projKey: string;
  onCancel?: () => void;
  contextKinds: ContextKind[];
};

const BulkContextTargetingContainer = connect(
  (state: GlobalState) => ({
    ...contextResolutionSelector(state),
    resultGroups: groupedContextResolutionSelector(state),
  }),
  (dispatch, ownProps: BulkContextTargetingContainerProps) => ({
    onResolveTargets: (targets: OrderedSet<ContextTargetKindKey>) =>
      dispatch(resolveContexts(targets, ownProps.projKey, ownProps.envKey, '-ts', true /* searchEmails */)),
    onClearResults: () => dispatch(clearContextsResolutionResults(ownProps.projKey, ownProps.envKey)),
  }),
)(BulkContextTargeting);

/* eslint-disable import/no-default-export */
export default BulkContextTargetingContainer;
