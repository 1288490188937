import { useMemo } from 'react';
import { useMatches } from 'react-router-dom';
import { z } from 'zod';

const filtersSchema = z.object({
  Filters: z.function().returns(z.any()),
});

/**
 * Render the filters for the current route.
 *
 * A route defines its filters via its `handle`.
 */
export function Filters() {
  const matches = useMatches();

  const RouteFilters = useMemo(() => {
    // Extract view modes from the deepest route first, since
    // view modes may be siblings in the route tree, and we
    // want to ensure active links are highlighted correctly.
    const reversedMatches = [...matches].reverse();

    for (const match of reversedMatches) {
      const parse = filtersSchema.safeParse(match.handle);
      if (parse.success) {
        return parse.data.Filters;
      }
    }
    return undefined;
  }, [matches]);

  if (RouteFilters === undefined) {
    return null;
  }

  return (
    <div>
      <RouteFilters />
    </div>
  );
}
