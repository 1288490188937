import { components, OptionProps } from 'react-select';
import { Icon } from '@launchpad-ui/icons';

import styles from './SelectContextOption.module.css';

/* eslint-disable import/no-default-export */
export default function SelectContextOption({
  cx,
  data,
  innerProps,
  innerRef,
  ...props
}: OptionProps<{
  value: string;
  label?: string;
}>) {
  if (data.label?.includes('Find') || data.label?.includes('Add')) {
    return (
      <div {...innerProps} ref={innerRef} className={styles.Container}>
        <span className={styles.Label}>
          <Icon name="add-circle" className={styles.Icon} aria-label={props.label} size="small" />
          {props.label}
        </span>
        <span className={styles.Button}>{props.children}</span>
      </div>
    );
  }

  return <components.Option cx={cx} data={data} innerProps={innerProps} innerRef={innerRef} {...props} />;
}
