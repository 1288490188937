import { useId } from 'react';
import { pluralize } from '@gonfalon/strings';
import { Icon } from '@launchpad-ui/icons';
import classnames from 'clsx';
import { Checkbox } from 'launchpad';

import { ContextKind } from 'components/Contexts/types';
import Restrict from 'components/Restrict';

import styles from './ContextKindsModalListItem.module.css';

export type ContextKindsModalListItemProps = {
  isChecked: boolean;
  isOnlyKindChecked: boolean;
  contextKind: ContextKind;
  targetCount: number;
  onUpdateSelectedContexts(selectedContext: string): void;
};

export function ContextKindsModalListItem({
  isChecked,
  isOnlyKindChecked,
  contextKind,
  targetCount,
  onUpdateSelectedContexts,
}: ContextKindsModalListItemProps) {
  const hasTargets = targetCount > 0;
  const key = useId();
  return (
    <li
      className={classnames(styles.listItem, {
        [styles.listItemChecked]: isChecked,
      })}
    >
      <Restrict
        isRestricted={isOnlyKindChecked}
        tooltipOptions={{ placement: 'bottom', targetClassName: styles.restrictedListItem }}
        tooltip="You must have at least one context kind selected"
        willDisable
      >
        <Checkbox onChange={() => onUpdateSelectedContexts(contextKind.key)} checked={isChecked}>
          {hasTargets && (
            <Icon name="warning" className={styles.warning} size="small" aria-labelledby={`warning-${key}`}>
              <title id={`warning-${key}`}>Warning</title>
            </Icon>
          )}
          {contextKind.name}
          {hasTargets && <span className="u-ml-s">{`(${targetCount} ${pluralize('target', targetCount)})`}</span>}
        </Checkbox>
      </Restrict>
    </li>
  );
}
