import { RefObject, useEffect, useRef, useState } from 'react';
import { useFocusWithin } from 'react-aria';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';
import { Button, ButtonGroup, Popover, Tooltip } from 'launchpad';

import { useWorkflowBuilderViewOptions } from 'components/CustomWorkflows/WorkflowBuilder/WorkflowBuilderState/utils';
import Restrict from 'components/Restrict';
import UserPopoverContent from 'components/UserPopoverContent';
import { usePendingSemanticPatchInstructions } from 'reducers/flagManager';
import { AccessDecision, denyDecision } from 'utils/accessUtils';
import { trackExpiringContextTargetsEvent } from 'utils/expiringContextTargetsUtils';
import { getUpdateTargetsInstructionKindForContext } from 'utils/instructions/targets/helpers';
import { TargetsInstructionKind } from 'utils/instructions/targets/types';
import { User } from 'utils/userUtils';

import './ContextTargetGrid';

import ScheduleRemovalButton from './ExpiringContextTargets/ScheduleRemovalButton';
import ScheduleRemovalModal from './ExpiringContextTargets/ScheduleRemovalModal';

import styles from './ContextTargetContent.module.css';

export type ContextTargetContentProps = {
  accessCheck?: AccessDecision;
  deleteAction?: React.ReactElement;
  info: React.ReactElement;
  isOpen?: boolean;
  isUnknown?: boolean;
  onChangeTargetingExpiration: ({
    originalExpirationDate,
    updatedExpirationDate,
  }: {
    originalExpirationDate?: number | null;
    updatedExpirationDate?: number | null;
  }) => void;
  onDelete?(): void;
  onPopoverInteraction?(): void;
  openDatePickerKey?: string;
  originalExpirationDate?: number | null;
  scheduleHover?(): void;
  setRef?: RefObject<HTMLDivElement>;
  updateExpiringTargetsAccess?: AccessDecision;
  updatedExpirationDate?: number | null;
  updatedExpirationVariationId?: string | null;
  user?: User;
  contextKey: string;
  variationId?: string;
  contextKind: string;
  showScheduleRemoval?: boolean;
};

export function ContextTargetContent({
  accessCheck,
  deleteAction,
  info,
  isOpen,
  isUnknown,
  onChangeTargetingExpiration,
  onDelete,
  onPopoverInteraction,
  openDatePickerKey,
  originalExpirationDate,
  setRef,
  updateExpiringTargetsAccess = denyDecision(),
  updatedExpirationDate,
  updatedExpirationVariationId,
  user,
  contextKey: targetKey,
  variationId,
  contextKind,
  showScheduleRemoval,
}: ContextTargetContentProps) {
  const pendingSemanticPatchInstructions = usePendingSemanticPatchInstructions();
  const [showScheduleRemovalModal, setShowScheduleRemovalModal] = useState(showScheduleRemoval);
  const [showScheduleRemovalButton, setShowScheduleRemovalButton] = useState(false);
  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: (isFocusWithin) => setShowScheduleRemovalButton(isFocusWithin),
  });
  const { hideExpiringTargets } = useWorkflowBuilderViewOptions();
  const scheduleRemovalButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setShowScheduleRemovalModal(showScheduleRemoval);
  }, [showScheduleRemoval]);

  const targetUpdateInstruction =
    targetKey && variationId
      ? getUpdateTargetsInstructionKindForContext({
          targetKey,
          contextKind,
          variationId,
          pendingSemanticPatchInstructions,
        })
      : undefined;
  const targetClasses = cx('ContextTarget', 'IndividualTarget', {
    'ContextTarget--added': targetUpdateInstruction === TargetsInstructionKind.ADD_TARGETS,
    'ContextTarget--removed': targetUpdateInstruction === TargetsInstructionKind.REMOVE_TARGETS,
  });

  const targetingInfoClasses = cx('ContextTarget-userTargetingInfo', 'fs-exclude', 'u-c-pointer', {
    'ContextTarget--unknown': isUnknown,
    'ContextTargetGrid--hover': !isUnknown,
  });

  const canDelete = accessCheck ? accessCheck.isAllowed : false;
  const hasExpirationOnDifferentVariation =
    !!updatedExpirationVariationId && variationId !== updatedExpirationVariationId;
  const hasExpiration = !!updatedExpirationVariationId && variationId === updatedExpirationVariationId;
  const readOnly = !updateExpiringTargetsAccess?.isAllowed;
  const restrictReason = hasExpirationOnDifferentVariation
    ? 'User is already scheduled for removal on a different variation. You must remove them before continuing.'
    : updateExpiringTargetsAccess.getActionReason();

  const renderInfoWithLeadingOrTrailingWhiteSpace = () => {
    const value = info.props.children;
    const hasLeadingWhiteSpace = /^\s/.test(value as string);
    const hasTrailingWhiteSpace = /\s$/.test(value as string);
    const whiteSpaceIndicator = (position: 'leading' | 'trailing') => (
      <Tooltip placement="bottom" content={`This value contains ${position} whitespace`}>
        <span className={styles.whitespace} />
      </Tooltip>
    );
    return (
      <>
        {hasLeadingWhiteSpace && whiteSpaceIndicator('leading')}
        <span>{info}</span>
        {hasTrailingWhiteSpace && whiteSpaceIndicator('trailing')}
      </>
    );
  };

  const renderContextTargetingInfo = () => (
    <div className={targetingInfoClasses} ref={setRef}>
      <div className="u-flex u-o-hidden">
        <div className="ContextTarget-info">{renderInfoWithLeadingOrTrailingWhiteSpace()}</div>
      </div>
    </div>
  );

  return (
    <>
      <div
        role="menubar"
        tabIndex={0}
        className={targetClasses}
        onMouseEnter={() => setShowScheduleRemovalButton(true)}
        onMouseLeave={() => setShowScheduleRemovalButton(false)}
        {...focusWithinProps}
      >
        <Popover
          interactionKind="click"
          placement="bottom"
          key={targetKey}
          allowBoundaryElementOverflow
          disablePlacementFlip
          onInteraction={onPopoverInteraction}
          isOpen={isOpen}
          targetClassName="ContextTarget-popoverTarget"
          popoverClassName={styles.Popover}
          onClick={() => {
            trackExpiringContextTargetsEvent('Individual Context Target Content Clicked');
          }}
        >
          {renderContextTargetingInfo()}
          <UserPopoverContent
            userKey={targetKey}
            openDatePickerKey={openDatePickerKey}
            variationId={variationId}
            updatedExpirationVariationId={updatedExpirationVariationId}
            user={user}
            linkToPage
            updateExpiringTargetsAccess={updateExpiringTargetsAccess}
            originalExpirationDate={originalExpirationDate}
            updatedExpirationDate={updatedExpirationDate}
            onChangeUserTargetingExpiration={onChangeTargetingExpiration}
            onHandlePopoverInteraction={onPopoverInteraction}
            onClickRemovalDate={() => setShowScheduleRemovalModal(true)}
            enableExpiringUserTargets={!hideExpiringTargets}
          />
        </Popover>
        {!hideExpiringTargets ? (
          <ButtonGroup spacing="compact" className={styles.ButtonGroup}>
            {(hasExpiration || showScheduleRemovalButton) && (
              <Restrict
                isRestricted={readOnly || hasExpirationOnDifferentVariation}
                willDisable
                tooltip={restrictReason}
              >
                <ScheduleRemovalButton
                  hasRemovalScheduled={!!updatedExpirationDate}
                  onClick={() => setShowScheduleRemovalModal(true)}
                  buttonRef={scheduleRemovalButtonRef}
                />
              </Restrict>
            )}
            {canDelete && (
              <Button aria-label={`Remove target ${info.props.children}`} onClick={onDelete}>
                <Icon name="cancel" size="medium" />
              </Button>
            )}
          </ButtonGroup>
        ) : (
          canDelete && deleteAction
        )}
      </div>
      {showScheduleRemovalModal && (
        <ScheduleRemovalModal
          context={user}
          contextKind={contextKind}
          contextKey={targetKey}
          originalExpirationDate={originalExpirationDate}
          updatedExpirationDate={updatedExpirationDate}
          onSave={(onSaveObject) => {
            scheduleRemovalButtonRef.current?.focus();
            onChangeTargetingExpiration(onSaveObject);
          }}
          onCancel={() => {
            if (showScheduleRemovalButton) {
              scheduleRemovalButtonRef.current?.focus();
            }
            setShowScheduleRemovalModal(false);
          }}
          onKeyDownRemoveButton={(event) => {
            if (event.key === 'Enter' || event.key === 'Spacebar') {
              setShowScheduleRemovalButton(true);
            }
          }}
        />
      )}
    </>
  );
}
