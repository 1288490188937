import { useEffect } from 'react';
import { flushSync } from 'react-dom';
// eslint-disable-next-line no-restricted-imports
import { useDispatch } from 'react-redux';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { useFlagKey, useProjectKey } from '@gonfalon/router';
import { trackSegmentEvent } from '@gonfalon/segments';
import { List } from 'immutable';
import { Modal, ModalBody, ModalHeader } from 'launchpad';

import { convertRulesToSegment, convertTargetsToSegment } from 'actions/segments';
import ConvertToSegmentSummary from 'components/convertToSegment/ConvertToSegmentSummary';
import ProjectEnvironmentLabel from 'components/projectEnvironmentLabel/ProjectEnvironmentLabel';
import CreateSegmentForm from 'components/segments/CreateSegmentModalForm';
import { Rule, Target, Variation } from 'utils/flagUtils';
import { Segment } from 'utils/segmentUtils';

type WithRule = { isIndividualTargeting?: false; rule: Rule; onCancel(): void };
type WithIndividualTargeting = {
  isIndividualTargeting: true;
  allTargets: List<Target>;
  variation: Variation;
  variationLabel: string | JSX.Element;
  handleClearTargets(): void;
  onCancel(): void;
};

type ConvertToSegmentModalProps = WithRule | WithIndividualTargeting;

export const useRedux = () => {
  const projKey = useProjectKey();
  const envKey = useSelectedEnvironmentKey();
  const flagKey = useFlagKey();
  const dispatch = useDispatch();

  const onSubmitRule = (onCancel: VoidFunction, segment: Segment, rule: Rule) => {
    dispatch(convertRulesToSegment(projKey, envKey, flagKey, segment, rule, onCancel));
  };

  const onSubmitTargeting = (
    onCancel: VoidFunction,
    segment: Segment,
    variation: Variation,
    allTargets: List<Target>,
    handleClearTargets?: () => void,
  ) => {
    flushSync(() =>
      dispatch(convertTargetsToSegment(projKey, envKey, flagKey, segment, variation, onCancel, allTargets)),
    );

    handleClearTargets?.();
  };

  return { onSubmitTargeting, onSubmitRule };
};

const ConvertToSegmentModal = (props: ConvertToSegmentModalProps) => {
  const projKey = useProjectKey();
  const envKey = useSelectedEnvironmentKey();
  const { onSubmitTargeting, onSubmitRule } = useRedux();

  const segmentEventTracker = (description?: boolean, tags?: boolean) =>
    trackSegmentEvent('Convert Rule to Segment Viewed', {
      location: 'feature flag',
      type: props.isIndividualTargeting ? 'individual' : 'rule',
      description, // this will be undefined if not specified, which is what we want
      tags, // so is this
    });

  useEffect(() => {
    segmentEventTracker();
  }, []); // intentional empty array as second param here to ensure this effect executes only once on mount

  return (
    <Modal onCancel={props.onCancel}>
      <ModalHeader
        title={
          props.isIndividualTargeting ? 'Convert individual targets to segment' : 'Convert targeting rule to segment'
        }
        description={
          <>
            {props.isIndividualTargeting
              ? 'Turn this rule into a segment you can use on other flags.'
              : "Add this segment to the flag's targeting as a new rule."}{' '}
            Creating a segment does not change this flag's targeting.
          </>
        }
        hasRequiredField
      />
      <ModalBody>
        <ProjectEnvironmentLabel />
        {props.isIndividualTargeting && (
          <ConvertToSegmentSummary allTargets={props.allTargets} variationLabel={props.variationLabel} />
        )}
        <CreateSegmentForm
          projKey={projKey}
          envKey={envKey}
          isBigSegmentsEnabled={false}
          isNativeBigSegmentUIEnabled={false}
          onSubmit={(segment: Segment) => {
            if (props.isIndividualTargeting) {
              onSubmitTargeting(props.onCancel, segment, props.variation, props.allTargets, props.handleClearTargets);
            } else {
              onSubmitRule(props.onCancel, segment, props.rule);
            }
          }}
          onCancel={props.onCancel}
          onClickSubmit={segmentEventTracker}
        />
      </ModalBody>
    </Modal>
  );
};

/* eslint-disable import/no-default-export */
export default ConvertToSegmentModal;
