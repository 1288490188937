import React, { useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { getQueryClient } from '@gonfalon/react-query-client';
import { getMetricQuery } from '@gonfalon/rest-api';
import { useProjectKey } from '@gonfalon/router';
import { hookFormKeyInvalid } from '@gonfalon/strings';
import { FieldGroup, Input, TextField } from '@launchpad-ui/components';

import HelpTooltip from 'components/HelpTooltip';

import { MAX_METRIC_KEY_LENGTH, MetricForm } from '../common';
import { RequiredLabel } from '../components/RequiredLabel';

import { toValidMetricKey } from './formUtils';

import styles from './GuidedMetricCreationModal.module.css';

const keyFieldHint = (
  <>
    We use the key to give you friendly URLs. Keys should be at most 256 characters and must only contain letters,
    numbers, <code>.</code>, <code>_</code> or <code>-</code>.
  </>
);

interface MetricKeyNameFieldsProps {
  context: UseFormReturn<MetricForm>;
  isEdit?: boolean;
}

export const MetricKeyNameFields: React.FC<MetricKeyNameFieldsProps> = ({ context, isEdit = false }) => {
  const [autoGenKey, setAutoGenKey] = useState(true);
  const projectKey = useProjectKey();

  const {
    control,
    register,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    trigger,
  } = context;

  const handleMetricKeyPrefill = (name: string) => {
    if (autoGenKey && !isEdit) {
      clearErrors('key');
      setValue('key', toValidMetricKey(name), { shouldDirty: true });
    }
  };

  const handleInput = () => {
    setAutoGenKey(false);
  };

  const metricKey = useWatch({ control, name: 'key', exact: true });
  const metricName = useWatch({ control, name: 'name', exact: true });

  const checkMetricKeyExists = async (value: string) => {
    if (isEdit || value === '') {
      return true;
    } else {
      try {
        await getQueryClient().fetchQuery(getMetricQuery({ projectKey, metricKey: value }));
        // It's necessary to set an error manually below in the case of the prefill from name
        // in addition to just returning the error message, since just returning it won't cause
        // the error to be shown unless the field has been touched.
        setError('key', { message: 'Metric key already exists' });
        return 'Metric key already exists';
      } catch (e) {
        clearErrors('key');
        return true;
      }
    }
  };

  return (
    <div className={styles.dualInputContainer}>
      <FieldGroup errorMessage={errors.name?.message}>
        <TextField className={styles.metricKeyInput} onChange={handleMetricKeyPrefill}>
          <RequiredLabel label="Metric name" htmlFor="name" />
          <Input
            value={metricName}
            {...register('name', { required: 'Metric name is required' })}
            placeholder="ex: Button average click rate"
            onBlur={async (e) => {
              await trigger('name');
              if (autoGenKey) {
                await checkMetricKeyExists(e.target.value);
              }
            }}
          />
        </TextField>
      </FieldGroup>
      <FieldGroup errorMessage={errors.key?.message}>
        <TextField className={styles.metricKeyInput} isDisabled={isEdit}>
          <span className={styles.tooltipContainer}>
            <RequiredLabel label="Metric key" htmlFor="key" />
            <HelpTooltip placement="right">{keyFieldHint}</HelpTooltip>
          </span>
          <Input
            className={styles.metricKeyPlaceholderText}
            variant="minimal"
            id="key"
            {...register('key', {
              required: 'Metric key is required',
              pattern: hookFormKeyInvalid,
              maxLength: {
                value: MAX_METRIC_KEY_LENGTH,
                message: `Key must be fewer than ${MAX_METRIC_KEY_LENGTH} characters`,
              },
              validate: {
                unique: checkMetricKeyExists,
              },
            })}
            onChange={(e) => {
              clearErrors('key');
              setValue('key', e.target.value, { shouldDirty: true });
            }}
            onInput={handleInput}
            value={metricKey}
            placeholder="Metric key will be auto generated here"
          />
        </TextField>
      </FieldGroup>
    </div>
  );
};
