import {
  DependentExperiment,
  Experiment as RestApiExperiment,
  Iteration as RestApiIteration,
  LayerSnapshot,
  Treatment as RestApiTreatment,
} from '@gonfalon/experiments';
import { FeatureFlag } from '@gonfalon/flags';
import type { DependentMetric, Metric as RestAPIMetric } from '@gonfalon/metrics';
import { schemas } from '@gonfalon/openapi';

import type { MetricEventUrl } from 'components/metrics/common';
import { Clause } from 'utils/clauseUtilsImmer';
import { FlagStatusKind } from 'utils/flagStatusUtils';

export type Link = {
  href: string;
  type: string;
};

type AccessReason = {
  actions: string[];
  effect: 'allow' | 'deny';
  resources: string[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  role_name: string;
};

type VariationSummary = {
  rules: number;
  nullRules: number;
  targets: number;
  isFallthrough?: boolean;
  isOff?: boolean;
  rollout?: number;
  bucketBy?: string;
};

type WeightedVariation = {
  variation: number;
  weight: number;
  readonly _untracked?: boolean;
};

type ExperimentRollout = {
  canReshuffle: boolean;
  defaultVariation: number;
};

type Rollout = {
  variations: WeightedVariation[];
  bucketBy?: string;
  experimentAllocation?: ExperimentRollout;
  seed?: number;
};

type Prerequisite = {
  readonly _key?: string;
  key: string;
  variation: number;
  variationId?: string;
};

export type { Clause };

export type Rule = {
  readonly _id?: string;
  readonly _key?: string;
  clauses: Clause[];
  trackEvents: boolean;
  description?: string;
  variation?: number;
  rollout?: Rollout;
};

type Target = {
  variation: number;
  values: string[];
};

export type VariationData = {
  name: string;
  value: string | number | boolean | Record<string, string | number | boolean>;
  varIdx: number;
  totalVariations: number;
};

export type Access = {
  action: string;
  reason: AccessReason;
};

export type MetricEventSummary = {
  events: MetricEvent[];
  treatmentResults: TreatmentResult[];
};

export type MetricEvent = {
  treatment: string;
  timestamp: number;
  value: number;
};

export type Metric = {
  readonly _creationDate: number;
  readonly _id: string;
  readonly _links: {
    self: Link;
    parent?: Link;
  };
  readonly _maintainer?: {
    readonly _id: string;
    readonly _links: {
      self: Link;
    };
    email: string;
    firstName: string;
    lastName: string;
    role: string;
  };
  readonly _site: Link | null;
  readonly _attachedFlagCount: number;
  readonly _attachedFeatures?: string[];
  readonly _access?: {
    denied: Access[];
  };
  readonly _version?: number;
  description?: string;
  eventKey?: string;
  isActive?: boolean;
  isNumeric?: boolean;
  successCriteria?: SuccessCriteria;
  unit?: string;
  key: string;
  kind: string;
  lastModified?: {
    date: string;
  };
  maintainerId?: string;
  name: string;
  tags: string[] | null;
  urls?: MetricEventUrl[];
  selector?: string;
  randomizationUnits?: string[];
};

export type MetricWithExperiments = Metric & { experiments?: DependentExperiment[] };

export type ExperimentQueryDate = Date | number | undefined;

export const NOT_STARTED = 'not_started';
export const RUNNING = 'running';
export const STOPPED = 'stopped';

export const HOLDOUT_CREATED = 'created';
export const HOLDOUT_ENABLED = 'enabled';
export const HOLDOUT_ANALYZING = 'running';
export const HOLDOUT_ENDED = 'ended';

export const DEFAULT_CONTEXT_KIND = 'user';

export type ExperimentStatus = typeof NOT_STARTED | typeof RUNNING | typeof STOPPED | undefined;

export type StepName =
  | 'experimentInfo'
  | 'experimentFactors'
  | 'selectMetrics'
  | 'selectVariations'
  | 'selectAllocation'
  | 'statisticalAnalysis';

export type Experiment = {
  metricKey: string;
  readonly _metric: Metric;
  environments: string[];
  experimentSummaryResults?: ExperimentSummaryResultsType;
  readonly _environmentSettings: {
    [key: string]:
      | {
          startDate?: number;
          stopDate?: number;
          enabledPeriods: Array<{
            startDate: number;
            stopDate?: number;
          }>;
        }
      | undefined;
  };
};

export type FlagV2 = {
  targetingRule: string;
  targetingRuleDescription?: string;
  targetingRuleClauses?: Clause[];
  flagConfigVersion: number;
  readonly _links: {
    self: Link;
  };
  notInExperimentVariationId?: string;
};

export type Treatment = RestApiTreatment & { _color?: string };

export type Iteration = {
  readonly _id: string;
  readonly createdAt: number;
  hypothesis: string;
  status: ExperimentStatus;
  startedAt?: number;
  endedAt?: number;
  winningReason?: string;
  winningTreatmentId?: string;
  randomizationUnit?: string;
  canReshuffleTraffic: boolean;
  flags: Record<string, FlagV2>;
  treatments: Treatment[];
  attributes?: string[];
  primarySingleMetric?: DependentMetric;
  primaryFunnel?: DependentMetricGroup;
  metrics?: DependentMetricOrGroup[];
  layerSnapshot?: LayerSnapshot;
};

export type AnalysisConfig = {
  bayesianThreshold?: string;
  significanceThreshold?: string;
  testDirection?: string;
};

export type DependentMetricOrFunnel = {
  key: string;
  name: string;
  isGroup?: boolean;
  kind?: 'funnel' | 'standard';
  metrics?: FunnelMetric[];
  readonly _links: { self: Link; parent?: Link };
};

export type DependentMetricGroup = DependentMetricOrFunnel & {
  isGroup: true;
  metrics: FunnelMetric[];
  // TODO [SC218875] we don't expect this to be populated yet
  description?: string;
};

// Single metric, standard metric group or funnel metric group
// TODO replace with rep from generated types when available
export type MetricOrGroup = {
  key: string;
  name: string;
  isGroup: boolean;
  kind?: unknown;
  // only for groups
  metrics?: unknown;
  // only for single metrics
  isNumeric?: unknown;
} & (
  | ({
      isGroup: false;
      metrics?: never;
    } & RestAPIMetric)
  | {
      isGroup: true;
      metrics: DependentMetric[];
      kind: 'funnel' | 'standard';
      isNumeric?: never;
    }
);

// TODO move export to package
export type DependentMetricOrGroup = schemas['DependentMetricOrMetricGroupRep'];

export type ExperimentV2 = {
  readonly _id: string;
  key: string;
  name: string;
  description: string;
  readonly _maintainerId: string;
  readonly _creationDate: number;
  readonly _links: {
    parent: Link;
    self: Link;
  };
  environmentKey: string;
  archivedDate?: number;
  currentIteration: Iteration;
  previousIterations?: Iteration[];
  draftIteration?: Iteration;
  holdoutId?: string;
  methodology?: string;
  analysisConfig?: AnalysisConfig;
};

export type NormalDistributionParams = {
  mu: number;
  sigma: number;
};

export type NormalDistribution = {
  kind: 'normal';
  parameters: NormalDistributionParams;
};

export type BetaDistributionParams = {
  alpha: number;
  beta: number;
};

export type BetaDistribution = {
  kind: 'beta';
  parameters: BetaDistributionParams;
};

export type Distribution = NormalDistribution | BetaDistribution;

export type BayesianProperties = {
  dataWeight: number;
  priorMean: number;
};

export type TreatmentResult = {
  treatmentId: string;
  treatmentName: string;
  dataMean?: number; //controlled by this flag: https://app.ld.catamorphic.com/default/case-dev/features/add-data-mean-to-experiment-results-rep/targeting
  dataStdDev?: number;
  mean: number;
  credibleInterval: {
    upper: number;
    lower: number;
  };
  pBest: number;
  relativeDifferences: Array<{
    estimate?: number;
    upper: number;
    lower: number;
    fromTreatmentId: string;
    varianceReduction?: number;
  }>;
  units: number;
  traffic?: number;
  distribution?: Distribution;
  varianceReduction?: number;
  eventValuesSum?: number;
  correlation?: number;
  standardDeviationRatio?: number;
  covariateImbalance?: number;
  model?: string;
  bayesianNormal?: BayesianProperties;
  bayesianBeta?: BayesianProperties;
};

export type ExperimentResults = {
  readonly _links: {
    self: Link;
  };
  results: ExperimentResultsSlice[];
  treatmentResults: TreatmentResult[] | null;
  probabilityOfMismatch?: number;
  metricSeen: {
    ever: boolean;
    timestamp: number;
  };
};

export type ExperimentResultsSlice = {
  attribute: string;
  attributeValue: string;
  treatmentResults: TreatmentResult[];
};

export type TreatmentPercentileResults = {
  treatmentId: string;
  treatmentName: string;
  percentileInterval: {
    upper: number;
    lower: number;
  };
  significance: {
    significant: boolean;
    regression: boolean;
    fromTreatmentId: string;
  };
  units: number;
};

export type ExperimentPercentileResults = {
  percentile: number;
  confidence: number;
  treatmentResults: TreatmentPercentileResults[];
  probabilityOfMismatch?: number;
};

export type Environment = {
  readonly _access: {
    denied: Access[];
  };
  readonly _environmentName: string;
  readonly _site: Link;
  readonly _summary: {
    prerequisites: number;
    variations: {
      [key: number]: VariationSummary;
    };
  };
  readonly _debugEventsUntilDate?: number;
  archived: boolean;
  fallthrough: {
    variation?: number;
    rollout?: Rollout;
  };
  lastModified: number;
  offVariation?: number;
  on: boolean;
  prerequisites: Prerequisite[] | null;
  rules: Rule[];
  salt: string;
  sel: string;
  targets: Target[] | null;
  trackEvents: boolean;
  trackEventsFallthrough: boolean;
  version: number;
};

export type Flag = {
  readonly _links: {
    parent: Link;
    self: Link;
  };
  readonly _maintainer: {
    readonly _id: string;
    readonly _links: {
      self: Link;
    };
    email: string;
    firstName: string;
    lastName: string;
    role: string;
  };
  readonly _version: number;
  archived: boolean;
  clientSideAvailability: {
    usingEnvironmentId: boolean;
    usingMobileKey: boolean;
  };
  creationDate: number;
  customProperties: {
    [key: string]:
      | {
          name: string;
          key?: string;
          value: string[];
          new?: boolean;
        }
      | undefined;
  };
  defaults?: {
    offVariation: number;
    onVariation: number;
  };
  description: string;
  environments: {
    [key: string]: Environment;
  };
  experiments: {
    baselineIdx: number;
    items: Experiment[];
  };
  goalIds: string[];
  includeInSnippet: boolean;
  key: string;
  kind: string;
  maintainerId: string;
  name: string;
  tags: string[] | null;
  temporary: boolean;
  variationJsonSchema: null;
  variations: Variation[];
};

export type Variation = {
  name?: string;
  readonly _id: string;
  value: string | number | boolean | Record<string, string | number | boolean>;
  description?: string;
};

export type VariationWeights = Record<string, number>;

export type ExperimentationData = {
  flagName: string;
  experiments: Flag['experiments'];
  variations: Flag['variations'];
};

export type WeightedExperimentRollout = ExperimentRollout & {
  variationWeights: VariationWeights;
  defaultVariationId?: string;
};

export type ExperimentSummaryResultsType = {
  metadata?: ExperimentSummaryMetadata[];
  metricSeen?: ExperimentSummaryMetricSeen;
  totals: ExperimentSummaryTotal[];
  stats: ExperimentSummaryStats;
};

export type ExperimentSummaryMetadata = {
  key: string | number;
};

export type ExperimentSummaryMetricSeen = {
  ever: boolean;
  timestamp: number;
};

export type ExperimentSummaryTotal = {
  cumulativeValue: number | null;
  cumulativeCount: number | null;
  cumulativeImpressionCount?: number;
  cumulativeConversionRate: number | null;
  cumulativeConfidenceInterval: { lower: number; upper: number } | null;
  pValue: number | null;
  improvement: number | null;
  minSampleSize?: number;
};

export type ExperimentSummaryStats = {
  pValue?: number;
  chi2?: number;
  winningVariationIdx?: number;
  minSampleSizeMet?: boolean;
};

export enum VariationResultChangeType {
  IMPROVEMENT = 'improvement',
  SETBACK = 'setback',
  NO_CHANGE = 'no change',
}

export enum MetricKind {
  CLICK = 'click',
  PAGEVIEW = 'pageview',
  CUSTOM = 'custom',
  NULL = 'null',
}

export enum SuccessCriteria {
  LOWER_THAN_BASELINE = 'LowerThanBaseline',
  HIGHER_THAN_BASELINE = 'HigherThanBaseline',
}

export enum NumericWinner {
  IS_NUMERIC_SUCCESS = 'isNumericSuccess',
  IS_NUMERIC_SETBACK = 'isNumericSetback',
  IS_NUMERIC_NO_CHANGE = 'isNumericNoChange',
}

export type FlagStatus = {
  name: FlagStatusKind;
  lastRequested: string | null;
  default?: boolean;
};

export type MemberPermissionGrants = {
  actionSet: string;
  actions: string[];
  resource: string;
};

export type MemberTeams = {
  customRoleKeys: string[];
  key: string;
  name: string;
};

type User = {
  readonly _id: string;
  readonly _pendingInvite: boolean;
  readonly _verified: boolean;
  creationDate: number | null;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  isBeta: boolean;
  customRoles: string[];
  excludedDashboards: string[];
  mfa: 'enabled' | 'disabled' | 'reset';
};

export type Profile = User & {
  readonly _links: {
    canonical: Link;
    self: Link;
  };
  permissionGrants: MemberPermissionGrants[];
  teams?: MemberTeams[];
};

export type Member = User & {
  readonly _links: {
    parent: Link;
    self: Link;
    sendMfaEnableRequest: Link;
  };
  readonly _access: {
    denied: Access[];
    allowed: Access[];
  };
  readonly _lastSeen: number;
};

export type ExperimentExpandOptions =
  | 'previousIterations'
  | 'treatments'
  | 'draftIteration'
  | 'secondaryMetrics'
  | 'metrics'
  | 'analysisConfig';

export type StandardRandomizationUnit =
  | 'organization'
  | 'time'
  | 'userTime'
  | 'guest'
  | 'guestTime'
  | 'request'
  | 'user';

export type RandomizationUnit = {
  randomizationUnit: string;
  standardRandomizationUnit: StandardRandomizationUnit;
  _hidden: boolean | null;
  _displayName?: string;
  default?: boolean;
};

export type ExperimentationSettings = {
  _projectId: string;
  _projectKey: string;
  randomizationUnits: RandomizationUnit[];
};

export type CreateRandomizationUnitPayload = {
  randomizationUnit: string;
  standardRandomizationUnit?: StandardRandomizationUnit;
  default?: boolean;
};

export type AggregationType = 'average' | 'sum';

// TODO replace these with types from openAPI when available
export type MetricGroup = Readonly<{
  _id: string;
  key: string;
  name: string;
  kind: 'funnel' | 'standard';
  description: string;
  _links: {
    parent: Link;
    self: Link;
  };
  tags: string[];
  _creationDate: number;
  _lastModified: number;
  maintainer: {
    key: string;
    kind: string;
    _member: schemas['MemberSummary'];
  };
  metrics: FunnelMetric[];
  _version: number;
  experiments?: DependentExperiment[];
  experimentCount?: number;
}>;

export type FunnelMetric = {
  key: string;
  name: string;
  kind: 'click' | 'pageview' | 'custom';
  _links: {
    parent: Link;
    self: Link;
  };
  nameInGroup: string;
  isNumeric?: boolean;
  randomizationUnits?: string[];
  unitAggregationType?: 'sum' | 'average';
  _versionId?: string;
};

export type AllMetricGroups = Readonly<{
  _links: {
    parent: Link;
    self: Link;
    first?: Link;
    last?: Link;
    next?: Link;
    prev?: Link;
  };
  items: MetricGroup[];
}>;

// Note this is not a part of the experiments API, it is used to
// determine what UX to deliver to the user.
export type ExperimentType = 'feature' | 'funnel';

// maps openAPI generated types to deprecated types, to make migrating in stages easier
export function toDeprecatedExperiment(experiment: RestApiExperiment): ExperimentV2 {
  const { currentIteration } = experiment;
  if (!currentIteration) {
    throw new Error('Experiment has no current iteration');
  }
  return {
    ...experiment,
    _id: experiment._id ?? '',
    description: experiment.description ?? '',
    currentIteration: toDeprecatedIteration(currentIteration),
    draftIteration: experiment.draftIteration ? toDeprecatedIteration(experiment.draftIteration) : undefined,
    previousIterations: experiment.previousIterations?.map(toDeprecatedIteration),
    _links: {
      parent: {
        href: experiment._links.parent?.href ?? '',
        type: experiment._links.parent?.type ?? '',
      },
      self: {
        href: experiment._links.self?.href ?? '',
        type: experiment._links.self?.type ?? '',
      },
    },
  };
}
export function toDeprecatedIteration(iteration: RestApiIteration): Iteration {
  return {
    ...iteration,
    _id: iteration._id ?? '',
    status: iteration.status === 'running' ? 'running' : iteration.status === 'stopped' ? 'stopped' : 'not_started',
    canReshuffleTraffic: iteration.canReshuffleTraffic ?? false,
    flags: Object.entries(iteration.flags ?? {}).reduce(
      (acc, [flagKey, flag]) =>
        flag
          ? {
              ...acc,
              [flagKey]: flag,
            }
          : acc,
      {},
    ),
    treatments: iteration.treatments ?? [],
    primaryFunnel: iteration.primaryFunnel
      ? {
          ...iteration.primaryFunnel,
          isGroup: true,
          metrics:
            iteration.primaryFunnel.metrics?.map((metric) => ({
              ...metric,
              nameInGroup: metric.nameInGroup ?? '',
              _links: {
                parent: {
                  href: metric._links.parent?.href ?? '',
                  type: metric._links.parent?.type ?? '',
                },
                self: {
                  href: metric._links.self?.href ?? '',
                  type: metric._links.self?.type ?? '',
                },
              },
            })) ?? [],
          _links: {
            parent: {
              href: iteration.primaryFunnel._links.parent?.href ?? '',
              type: iteration.primaryFunnel._links.parent?.type ?? '',
            },
            self: {
              href: iteration.primaryFunnel._links.self?.href ?? '',
              type: iteration.primaryFunnel._links.self?.type ?? '',
            },
          },
        }
      : undefined,
  };
}
export function toDeprecatedFlag(flag: FeatureFlag): Flag {
  return {
    ...flag,
    description: flag.description ?? '',
    variationJsonSchema: null,
    _links: {
      parent: {
        href: flag._links.parent?.href ?? '',
        type: flag._links.parent?.type ?? '',
      },
      self: {
        href: flag._links.self?.href ?? '',
        type: flag._links.self?.type ?? '',
      },
    },
    _maintainer: {
      _id: flag._maintainer?._id ?? '',
      _links: {
        self: {
          href: flag._maintainer?._links.self?.href ?? '',
          type: flag._maintainer?._links.self?.type ?? '',
        },
      },
      email: flag._maintainer?.email ?? '',
      firstName: flag._maintainer?.firstName ?? '',
      lastName: flag._maintainer?.lastName ?? '',
      role: flag._maintainer?.role ?? '',
    },
    clientSideAvailability: {
      usingEnvironmentId: !!flag.clientSideAvailability?.usingEnvironmentId,
      usingMobileKey: !!flag.clientSideAvailability?.usingMobileKey,
    },
    environments: Object.entries(flag.environments).reduce(
      (acc, [envKey, env]) =>
        env
          ? {
              ...acc,
              [envKey]: env,
            }
          : acc,
      {},
    ),
    // @ts-expect-error nothing consuming this is likely to use legacy experiments anyway
    experiments: flag.experiments,
  };
}
