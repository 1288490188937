import cx from 'clsx';
import { Button } from 'launchpad';

import styles from './AddAndScheduleRemovalButton.module.css';

type AddAndScheduleRemovalButtonProps = {
  className?: string;
  onClick(): void;
};

/* eslint-disable import/no-default-export */
export default function AddAndScheduleRemovalButton(props: AddAndScheduleRemovalButtonProps) {
  return (
    <Button onClick={props.onClick} size="small" className={cx(styles.Button, props.className)}>
      Add and schedule removal
    </Button>
  );
}
