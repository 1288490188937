import { useLocation } from 'react-router-dom';
import { syncedSegmentRelayVersion } from '@gonfalon/dogfood-flags';
import { NewTabLink } from '@gonfalon/launchpad-experimental';
import { trackSegmentEvent } from '@gonfalon/segments';
import type { AlertProps } from 'launchpad';
import { Alert } from 'launchpad';

import DocumentationLink from 'components/DocumentationLink';
import { getTrackingLocation } from 'utils/analyticsUtils';
import { getDocumentationUrl } from 'utils/urlUtils';

type SegmentRelayProxyAlertProps = {
  size?: AlertProps['size'];
  isInline?: boolean;
  isRelaySetup?: boolean;
  isRelayUIEnabled?: boolean;
  isBigSegmentStoreSetup?: boolean;
};

type SyncedSegmentInfoTextProps = {
  isRelaySetup?: boolean;
  isRelayUIEnabled?: boolean;
  isBigSegmentStoreSetup?: boolean;
};

const segmentsDocLink = getDocumentationUrl('sdk/relay-proxy/sdk-config#configuring-the-relay-proxy-for-segments');

// Displayed as a text hint when the menu option appears for selection in flag rule targeting
export const SegmentInfoText = ({
  isRelaySetup = false,
  isRelayUIEnabled = false,
  isBigSegmentStoreSetup = false,
}: SyncedSegmentInfoTextProps) => {
  const location = useLocation();
  const trackRelayDocsClicked = () => {
    trackSegmentEvent('Segment Relay Proxy Documentation Clicked ', {
      location: getTrackingLocation(location.pathname),
      isRelaySetup,
    });
  };

  if (isRelayUIEnabled || (!isRelaySetup && !isBigSegmentStoreSetup)) {
    return (
      <>
        <h3>Additional configurations may be required</h3>
        <span>
          To use this Segment with a server-side SDK, you must either:
          <ul>
            <li className="u-ml-l"> Configure a Big Segment integration, such as Redis or DynamoDB, or</li>
            <li className="u-ml-l">
              {' '}
              Use the Relay Proxy v{syncedSegmentRelayVersion()} or above with persistent storage{' '}
            </li>
          </ul>
        </span>
        for this environment.{' '}
        <NewTabLink href={segmentsDocLink} onClick={() => trackRelayDocsClicked()} text="Learn more" />
      </>
    );
  } else {
    return (
      // Is this still factually accurate?
      <>
        Big segments are not supported on server-side SDKs or the LaunchDarkly Relay Proxy. To learn more,{' '}
        <DocumentationLink href={segmentsDocLink} component="SegmentRelayProxyAlert" />
      </>
    );
  }
};

const SegmentRelayProxyAlert = ({
  size = 'medium',
  isInline = false,
  isRelaySetup = false,
  isRelayUIEnabled = false,
  isBigSegmentStoreSetup = false,
}: SegmentRelayProxyAlertProps) => (
  <Alert
    kind={isRelaySetup || isBigSegmentStoreSetup ? 'info' : 'warning'}
    size={size}
    isInline={isInline}
    data-test-id={`segment-alert-${isRelaySetup || isBigSegmentStoreSetup ? 'info' : 'warning'}`}
  >
    <SegmentInfoText
      isRelaySetup={isRelaySetup}
      isRelayUIEnabled={isRelayUIEnabled}
      isBigSegmentStoreSetup={isBigSegmentStoreSetup}
    />
  </Alert>
);

/* eslint-disable import/no-default-export */
export default SegmentRelayProxyAlert;
