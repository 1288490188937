import { List } from 'immutable';

import { DateTimePickerWithPopover } from 'components/ui/dates';
import { Clause } from 'utils/clauseUtils';

type DatePickerClauseProps = {
  clause: Clause;
  canModify: boolean;
  onChange(clause: Clause): void;
  id: string;
};

export function DatePickerClause({ clause, canModify, onChange, id }: DatePickerClauseProps) {
  const valueDisabled = !canModify || !clause.attribute;
  const handleDateValueChange = (value: string | Date) => {
    onChange(clause.set('values', value ? List.of(value.valueOf()) : List.of()));
  };
  const parsedDate = new Date(clause.values.first() as number);
  return (
    <DateTimePickerWithPopover
      value={parsedDate}
      disabled={valueDisabled}
      disablePast={false}
      id={id}
      onChange={(val: number) => {
        const date = new Date(val);
        handleDateValueChange(date);
      }}
      useLongDate={false}
    />
  );
}
