import { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useMatch, useParams } from 'react-router-dom';
import { userId } from '@gonfalon/constants';
import { Metric } from '@gonfalon/metrics';
import { ProgressBar } from '@launchpad-ui/components';
import { Button, Form, ModalBody, ModalFooter, ModalHeader } from 'launchpad';

import DocumentationLink from 'components/DocumentationLink';
import { SuccessCriteria } from 'components/experimentation/common/types';
import { ModalWithCancelPrompt } from 'components/ModalWithCancelPrompt';
import { trackExperimentEvent } from 'utils/analyticsUtils';
import { getDocumentationUrl } from 'utils/urlUtils';

import { MetricEventUrl, NewMetricForm, toMetricEventUrl } from '../common';
import { MetricRandomizationUnits } from '../components/MetricRandomizationUnits';
import { useCreateMetric } from '../hooks/useCreateMetric';

import { AnalysisSection } from './AnalysisSection';
import { EventInformationSection } from './EventInformationSection';
import { MetricPropertiesSection } from './MetricPropertiesSection';

import styles from './CreateMetricModal.module.css';

// need to include every field or `isDirty` may be a false positive
const defaultFormValues: NewMetricForm = {
  kind: 'custom',
  isNumeric: false,
  urls: [{ kind: 'canonical', value: '' }],
  randomizationUnits: [],
  maintainerId: '',
  name: '',
  key: '',
  description: '',
  eventKey: '',
  tags: undefined,
  successCriteria: SuccessCriteria.HIGHER_THAN_BASELINE,
  unitAggregationType: 'average',
  analysisType: 'mean',
  percentileValue: undefined,
  eventDefault: { disabled: false, value: 0 },
};

export function LegacyCreateMetricModal({
  onCancel,
  onSuccess,
  defaultValues,
}: {
  onCancel: () => void;
  onSuccess?: (metric: Metric) => void;
  defaultValues?: Partial<NewMetricForm>;
}) {
  const [showPrompt, setShowPrompt] = useState(false);
  const { mutate: createMetric, isPending: isSaving } = useCreateMetric();
  const form = useForm<NewMetricForm>({
    defaultValues: {
      ...defaultFormValues,
      maintainerId: userId(),
      ...defaultValues,
    },
  });

  const { dirtyFields, isDirty } = form.formState;

  const params = useParams();
  const matchBuilder = useMatch({ path: '/:projKey/:envKey/experiments/create' });
  const matchMetrics = useMatch({ path: '/:projKey/metrics' });

  const getLocation = () => {
    if (params.experimentKey) {
      return 'design';
    }
    if (matchBuilder) {
      return 'builder';
    }
    if (matchMetrics) {
      return 'metricsList';
    }
    return 'unknown';
  };

  // set default eventKey to name
  const name = useWatch({ control: form.control, name: 'name' });
  useEffect(() => {
    if (dirtyFields.eventKey || defaultValues?.eventKey) {
      return;
    }
    form.resetField('eventKey', { defaultValue: name });
  }, [name]);

  const onValid = (metric: NewMetricForm) => {
    createMetric(toMetricPost(metric), {
      onSuccess: (m) => {
        if (onSuccess) {
          return onSuccess(m);
        }
        onCancel();
      },
    });
    trackExperimentEvent('Metric Created', {
      location: getLocation(),
    });
  };

  const onCancelWithPrompt = () => {
    if (isDirty) {
      setShowPrompt(true);
    } else {
      onCancel();
    }
  };

  return (
    <ModalWithCancelPrompt
      onCancel={onCancelWithPrompt}
      className={styles.modal}
      shouldBlock={isDirty && !isSaving}
      showPrompt={showPrompt}
      onCancelPrompt={() => setShowPrompt(false)}
      onConfirmPrompt={onCancel}
    >
      <ModalHeader
        title="Create metric"
        className={styles.header}
        description={<ModalDescription />}
        hasRequiredField
      />
      <ModalBody>
        <FormProvider {...form}>
          <Form className={styles.createMetricForm}>
            <MetricPropertiesSection />
            <EventInformationSection />
            <AnalysisSection className={styles.analysisSection} />
            <MetricRandomizationUnits selectProps={{ menuPlacement: 'top' }} />
          </Form>
        </FormProvider>
      </ModalBody>
      <ModalFooter
        primaryButton={
          <Button
            kind="primary"
            onClick={async () => form.handleSubmit(onValid)()}
            disabled={!isDirty || isSaving}
            data-test-id="metric-create-button"
          >
            Create metric{isSaving && <ProgressBar aria-label="Loading…" isIndeterminate size="small" />}
          </Button>
        }
        secondaryButton={<Button onClick={onCancelWithPrompt}>Cancel</Button>}
      />
    </ModalWithCancelPrompt>
  );
}

type MetricPost = Omit<NewMetricForm, 'tags' | 'randomizationUnits' | 'urls'> & {
  tags?: string[];
  randomizationUnits?: string[];
  urls?: MetricEventUrl[];
};

function toMetricPost(form: NewMetricForm): MetricPost {
  const { kind, urls, randomizationUnits, tags, ...rest } = form;
  const metric = {
    ...rest,
    isActive: true,
    kind,
    randomizationUnits: randomizationUnits?.map(({ value }) => value),
    tags: tags?.map(({ value }) => value),
    urls: kind === 'click' || kind === 'pageview' ? urls?.map(toMetricEventUrl) : undefined,
  };

  if (kind === 'custom') {
    metric.isNumeric = !!metric.isNumeric;
  }

  return metric;
}

function ModalDescription() {
  return (
    <>
      Metrics measure audience behaviors affected by flags in experiments.{' '}
      <DocumentationLink
        href={getDocumentationUrl('home/observability/metrics')}
        component="CreateMetricModal"
        text="Learn more"
      />
      .
    </>
  );
}
