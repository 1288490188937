import { FetchStatus, QueryObserverResult, QueryStatus } from '@tanstack/react-query';

import {
  ExperimentPatchBody,
  FlagsPayload,
  MetricsPayload,
  START_ITERATION as API_START_ITERATION,
  STOP_ITERATION as API_STOP_ITERATION,
  TreatmentsPayload,
  UPDATE_DESCRIPTION as API_UPDATE_DESCRIPTION,
  UPDATE_METHODOLOGY,
  UPDATE_NAME as API_UPDATE_NAME,
} from '../../api/api.types';
import { ExperimentV2, Flag } from '../../types';

export type UpdateField =
  | typeof START_ITERATION
  | typeof STOP_ITERATION
  | typeof UPDATE_NAME
  | typeof UPDATE_DESCRIPTION
  | typeof UPDATE_HYPOTHESIS
  | typeof UPDATE_METRICS
  | typeof UPDATE_ATTRIBUTES
  | typeof UPDATE_TREATMENTS
  | typeof UPDATE_TRAFFIC_RESHUFFLE
  | typeof UPDATE_RULE_ID
  | typeof UPDATE_RANDOMIZATION_UNIT
  | typeof UPDATE_EDIT_REASON
  | typeof UPDATE_METHODOLOGY;

// Rexport types to keep our imports clean
export const START_ITERATION = API_START_ITERATION;
export const STOP_ITERATION = API_STOP_ITERATION;
export const UPDATE_NAME = API_UPDATE_NAME;
export const UPDATE_DESCRIPTION = API_UPDATE_DESCRIPTION;

export const UPDATE_METRICS = 'updateMetrics';
export type UpdateMetrics = { metrics: MetricsPayload; primarySingleMetricKey?: string; primaryFunnelKey?: string };

export const UPDATE_ATTRIBUTES = 'updateAttributes';
export type UpdateAttributes = { attributes: string[] };

export const UPDATE_TREATMENTS = 'updateTreatments';
export type UpdateTreatments = { treatments: TreatmentsPayload };

export const UPDATE_TRAFFIC_RESHUFFLE = 'updateTrafficReshuffle';
export type UpdateTrafficReshuffle = { canReshuffleTraffic: boolean };

export const UPDATE_RANDOMIZATION_UNIT = 'updateRandomizationUnit';
export type UpdateRandomizationUnit = { randomizationUnit: string };

export const UPDATE_RULE_ID = 'updateRuleId';
export type UpdateRuleId = { flags: FlagsPayload };

export const UPDATE_HYPOTHESIS = 'updateHypothesis';
export type UpdateHypothesis = { hypothesis: string };

export const UPDATE_EDIT_REASON = 'updateEditReason';
export type UpdateEditReason = { winningReason: string };

export type ExperimentUpdateBody =
  | ExperimentPatchBody
  | UpdateMetrics
  | UpdateAttributes
  | UpdateTreatments
  | UpdateTrafficReshuffle
  | UpdateRuleId
  | UpdateHypothesis
  | UpdateRandomizationUnit
  | UpdateEditReason;

export type Context = {
  experiment?: ExperimentV2;
  flag?: Flag;
  status: QueryStatus;
  fetchStatus: FetchStatus;
  error?: unknown;
  updateIterationStatus: (treatmentId?: string, reason?: string) => void;
  updateField: (key: UpdateField, payload: unknown) => void;
  resetUpdateState: () => void;
  saveUpdatedChanges: () => Promise<QueryObserverResult<ExperimentV2> | void>;
  archive: () => void;
  restore: () => void;
  refetchExperiment: () => void;
};
