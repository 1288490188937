export type Step = 'Get started' | 'Create flag' | 'Install SDK' | 'Enable feature';

export type SkipSetupEventInfo = {
  step?: Step;
  type?: 'button' | 'link';
  sdk?: string;
  on?: boolean;
  userEmail?: string;
  component?: string;
  count?: number;
  role?: string;
  location?: string;
};

export type ReasonToUseLaunchDarkly = 'personal-project' | 'work-project' | 'joining-existing-team';

export type FormFields = {
  reason?: ReasonToUseLaunchDarkly;
};

export type ReasonToUseLaunchDarklyItem = {
  value: ReasonToUseLaunchDarkly;
  label: string;
};

export const reasons: ReasonToUseLaunchDarklyItem[] = [
  {
    value: 'personal-project',
    label: 'A personal project',
  },
  {
    value: 'work-project',
    label: 'A project for work',
  },
  {
    value: 'joining-existing-team',
    label: 'Joining an existing LaunchDarkly team',
  },
];
