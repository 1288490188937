import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';

import { MetricForm } from '../common';
import { CardSelectGroup } from '../components/CardSelectGroup';
import { MetricUrls } from '../components/MetricUrls';

import { pageviewMeasureTypeSelectOptions } from './formUtils';
import { MetricDefinition } from './MetricDefinition';

interface PageviewMetricDetailsProps {
  context: UseFormReturn<MetricForm>;
}

export const PageviewMetricDetails: React.FC<PageviewMetricDetailsProps> = ({ context }) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = context;

  const handleMeasureOptionSelect = (value: string) => {
    if (!['count', 'occurrence'].includes(value)) {
      return;
    }
    if (value === 'count') {
      setValue('measureType', 'count');
      setValue('unitAggregationType', 'sum', { shouldDirty: true });
    } else if (value === 'occurrence') {
      setValue('measureType', 'occurrence');
      setValue('eventDefault', { disabled: false, value: 0 }, { shouldDirty: true });
      setValue('unitAggregationType', 'average', { shouldDirty: true });
      setValue('analysisType', 'mean', { shouldDirty: true });
      setValue('percentileValue', undefined);
    }
  };

  const measureType = useWatch({ control, name: 'measureType', exact: true });
  return (
    <>
      <MetricUrls context={context} />
      <CardSelectGroup
        context={context}
        name="measureType"
        label="What do you want to measure?"
        validationErrorMessage="Selection is required"
        options={pageviewMeasureTypeSelectOptions}
        ariaLabel="What do you want to measure?"
        onSelect={handleMeasureOptionSelect}
        errorMessage={errors?.measureType?.message}
        defaultValue={measureType}
      />
      {measureType && <MetricDefinition context={context} />}
    </>
  );
};
