import { List, Map } from 'immutable';

import { Clause } from 'components/Clause';
import { Clause as ClauseRecord } from 'utils/clauseUtils';
import { ClauseValidation } from 'utils/validation/clauses';

interface ConditionBuilderProps {
  clauses: List<ClauseRecord | null>;
  min: number;
  canModify: boolean;
  validation?: Map<string, ClauseValidation>;
  onAddClause: (c?: ClauseRecord) => void;
  onDeleteClause: (c: ClauseRecord) => void;
  className?: string;
  onEditClause: (c: ClauseRecord) => void;
  showAddButton?: boolean;
  isInPortal?: boolean;
  isFlagTargeting: boolean;
  isMobileRule?: boolean;
  ruleKind?: string;
  hasMobileFlagConfig?: boolean;
  shouldFetchContextKinds?: boolean;
}

/* eslint-disable import/no-default-export */
export default function ConditionBuilder({
  clauses,
  min,
  className,
  canModify,
  validation,
  onAddClause,
  onDeleteClause,
  onEditClause,
  showAddButton,
  isInPortal,
  isFlagTargeting,
  isMobileRule,
  ruleKind,
  hasMobileFlagConfig,
  shouldFetchContextKinds = false,
}: ConditionBuilderProps) {
  return (
    <div className={className}>
      {clauses
        .map((c, i) => {
          if (!c) {
            return null;
          }

          return (
            <Clause
              className="u-mb-m"
              key={c._key}
              prefix={i === 0 ? 'if' : 'and'}
              clause={c}
              validation={validation?.getIn(['items', c._key])}
              canModify={canModify}
              canDelete={clauses.size > min}
              onChange={onEditClause}
              onDelete={() => onDeleteClause(c)}
              onAdd={i === clauses.size - 1 ? onAddClause : undefined}
              showAddButton={showAddButton}
              clauseIndex={i}
              isInPortal={isInPortal}
              isMobileRule={isMobileRule}
              ruleKind={ruleKind}
              isFlagTargeting={isFlagTargeting}
              hasMobileFlagConfig={hasMobileFlagConfig}
              allClauses={clauses}
              shouldFetchContextKinds={shouldFetchContextKinds}
            />
          );
        })
        .toArray()}
    </div>
  );
}
