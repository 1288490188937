import { Controller, UseFormReturn } from 'react-hook-form';
import { FieldGroup } from '@launchpad-ui/components';

import { CardSelect } from 'components/CardSelect';
import { CardSelectValue } from 'components/CardSelect/CardSelect';

import { MetricForm } from '../common';

import { RequiredLabel } from './RequiredLabel';

interface CardSelectGroupProps {
  name: keyof MetricForm;
  label: string;
  validationErrorMessage: string;
  options: CardSelectValue[];
  ariaLabel: string;
  context: UseFormReturn<MetricForm>;
  onSelect: (value: string) => void;
  errorMessage?: string;
  defaultValue?: string;
}

export const CardSelectGroup: React.FC<CardSelectGroupProps> = ({
  name,
  label,
  validationErrorMessage,
  options,
  ariaLabel,
  context,
  onSelect,
  errorMessage,
  defaultValue,
}) => (
  <Controller
    control={context.control}
    name={name}
    rules={{
      required: validationErrorMessage,
      onChange: (e) => {
        onSelect(e.target.value);
        context.clearErrors(name);
      },
    }}
    render={({ field: { onChange } }) => (
      <FieldGroup errorMessage={errorMessage}>
        <RequiredLabel label={label} htmlFor={name} />
        <CardSelect
          items={options}
          ariaLabel={ariaLabel}
          disallowEmptySelection
          onSelect={onChange}
          defaultValue={defaultValue}
        />
      </FieldGroup>
    )}
  />
);
