import { Dispatch, useEffect, useRef, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useDispatch, useSelector } from 'react-redux';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { isDynamicDefaultTargetingContextKindEnabled } from '@gonfalon/dogfood-flags';
import { formatNumber, pluralize } from '@gonfalon/format';
import { useParam, useProjectKey } from '@gonfalon/router';
import { SegmentPendingChangesActionsType } from '@gonfalon/segments';
import { capitalize } from '@gonfalon/strings';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';
import { List, OrderedSet, Set } from 'immutable';
import { Button } from 'launchpad';

import {
  fetchExpiringContextTargetsForFlag as fetchExpiringContextTargetsForFlagAction,
  fetchExpiringContextTargetsForSegment as fetchExpiringContextTargetsForSegmentAction,
} from 'actions/expiringContextTargets';
import { ContextKind } from 'components/Contexts/types';
import { getDefaultTargetingContextKind } from 'components/Contexts/utils/contextKindUtils';
import { convertTargetsToKindKeyList } from 'components/Contexts/utils/contextTargetingUtils';
import ConvertToSegmentModal from 'components/convertToSegment/ConvertToSegmentModal';
import { useEffectOnClose } from 'hooks/useEffectOnClose';
import {
  expiringContextTargetsForFlagRequestSelector,
  expiringContextTargetsForSegmentListRequestSelector,
} from 'reducers/expiringContextTargets';
import { useProfileEntity } from 'reducers/profile';
import { AccessDecision, denyDecision } from 'utils/accessUtils';
import { downloadCsv } from 'utils/csvUtils';
import { SegmentVariationTypes } from 'utils/expiringContextTargetsUtils';
import { getKeysForContextKindsWithTargets, getTargetListForVariation, Target, Variation } from 'utils/flagUtils';
import { ready } from 'utils/reduxUtils';
import { ResourceKind } from 'utils/saveButtonUtils';
import { SegmentTarget } from 'utils/segmentUtils';

import BulkContextTargeting from './BulkContextTargeting';
import ContextTargetingActionMenuContainer from './ContextTargetingActionMenuContainer';
import ManageContextKindsModal from './ManageContextKindsModal';
import { ManageContextTargeting } from './ManageContextTargeting';

import './ContextTargeting.css';

export type ContextTargetingProps = {
  id: string;
  clearIndex?: number;
  allOriginalTargets?: List<Target> | List<SegmentTarget>;
  targetCount: number;
  label: string | JSX.Element;
  bulkDialogTitle: string | JSX.Element;
  updateTargetAccessCheck: AccessDecision;
  createSegmentAccessCheck?: AccessDecision;
  showConvertToSegment?: boolean;
  onChange(targets: List<string>, contextKind?: string): void;
  variationName?: string;
  projKey: string;
  envKey: string;
  flagKey?: string;
  variationId?: string;
  variation?: Variation;
  updateExpiringTargetsAccess?: AccessDecision;
  segmentKey?: string;
  onClearAllTargets?: (variationIndex: number, contextsWithTargets: string[]) => void;
  segmentVariation?: SegmentVariationTypes;
  onClearAllSegmentTargets?: (contextsWithTargets: string[]) => void;
  allTargets: List<Target> | List<SegmentTarget>;
  /**
   * In some scenarios, we render multiple ContextTargeting components (i.e., on segments: one for included, and one for excluded).
   * We don't want to allow the same context to be targeted in both components, so we pass in the cumulative targets which
   * is targets from both component instances.
   */
  cumulativeTargets?: List<Target> | List<SegmentTarget>;
  contextKinds: ContextKind[];
  isContextKindsReady?: boolean;
  contextTargetGridColumnCount?: number;
  hideManageContextKinds?: boolean;
  hideLabel?: boolean;
  hideTargetCount?: boolean;
  actionMenuVisibility?: 'hide' | 'only';
  segmentPendingChangesDispatch?: Dispatch<SegmentPendingChangesActionsType>;
};

export const useRedux = () => {
  const projKey = useProjectKey();
  const envKey = useSelectedEnvironmentKey();
  const flagKey = useParam('flagKey', { optional: true });
  const expiringTargetsForFlagRequest = useSelector(expiringContextTargetsForFlagRequestSelector);
  const expiringTargetsForSegmentListRequest = useSelector(expiringContextTargetsForSegmentListRequestSelector);

  const segmentKey = useParam('segmentKey', {
    optional: true,
  });

  let requests, fetchFeatureWorkflows;
  const dispatch = useDispatch();

  if (segmentKey) {
    requests = expiringTargetsForSegmentListRequest;
    fetchFeatureWorkflows = () =>
      dispatch(fetchExpiringContextTargetsForSegmentAction({ segmentKey, projKey, envKey }));
  } else {
    requests = expiringTargetsForFlagRequest;
    fetchFeatureWorkflows = () => dispatch(fetchExpiringContextTargetsForFlagAction({ flagKey, projKey, envKey }));
  }

  const isReady = ready(requests);
  return {
    isReady,
    fetchFeatureWorkflows,
  };
};

function ContextTargeting({
  id,
  clearIndex = -1,
  allOriginalTargets,
  targetCount,
  label,
  bulkDialogTitle,
  updateTargetAccessCheck,
  createSegmentAccessCheck = denyDecision(),
  showConvertToSegment = false,
  onChange,
  variationName = '',
  projKey,
  envKey,
  variationId,
  flagKey,
  segmentKey,
  updateExpiringTargetsAccess,
  onClearAllTargets,
  segmentVariation,
  onClearAllSegmentTargets,
  allTargets,
  cumulativeTargets = List(),
  contextKinds,
  isContextKindsReady,
  variation,
  contextTargetGridColumnCount,
  hideManageContextKinds,
  hideLabel,
  hideTargetCount,
  actionMenuVisibility,
  segmentPendingChangesDispatch,
}: ContextTargetingProps) {
  const [isBulkEditModalOpen, setIsBulkEditModalOpen] = useState(false);
  const [isConvertToSegmentModalOpen, setIsConvertToSegmentModalOpen] = useState(false);
  const [isManageContextKindsModalOpen, setIsManageContextKindsModalOpen] = useState(false);
  const [pastedInput, setPastedInput] = useState('');
  const [pastedContextKind, setPastedContextKind] = useState('');
  const [selectedContexts, setSelectedContexts] = useState([] as string[]);
  const isSegmentTargeting = segmentVariation !== undefined;
  let allTargetsForVariation = allTargets;
  if (!isSegmentTargeting) {
    allTargetsForVariation = (allTargets as List<Target>).filter((target) => target.variation === clearIndex);
  }
  const { isReady, fetchFeatureWorkflows } = useRedux();
  useEffect(() => {
    if (!isReady) {
      fetchFeatureWorkflows();
    }
  }, []);

  useEffect(() => {
    allOriginalTargets?.toJS().forEach((t: Target) => {
      if (t?.contextKind && !contextKinds?.some((ck: ContextKind) => ck.key === t.contextKind)) {
        contextKinds?.push({
          description: t.contextKind,
          hidden: false,
          key: t.contextKind,
          name: t.contextKind,
        });
      }
    });
  }, [allOriginalTargets, contextKinds]);

  const gridId = `${id}-grid`;
  const openBulkEditModal = (input: string = '', contextKind: string = '') => {
    setIsBulkEditModalOpen(true);
    setPastedInput(input);
    setPastedContextKind(contextKind);
  };

  const handleBulkEditValueChange = (data: OrderedSet<string>, contextKind?: string) => {
    setIsBulkEditModalOpen(false);
    setPastedInput('');
    setPastedContextKind('');
    onChange(data.toList(), contextKind);
  };

  const handleBulkEditCancel = () => {
    setIsBulkEditModalOpen(false);
    setPastedInput('');
    setPastedContextKind('');
  };

  const alreadyTargetedKeysByContext = getAlreadyTargetedKeysByContext(
    List.of(...allTargets, ...cumulativeTargets) as List<Target> | List<SegmentTarget>,
  );

  const handleShowManageContextKindsModal = () => {
    setIsManageContextKindsModalOpen(!isManageContextKindsModalOpen);
  };

  // visible context kinds list management

  const sortedContextKinds = contextKinds.sort((a, b) => a.name.localeCompare(b.name));
  const contextsWithTargets = getKeysForContextKindsWithTargets(contextKinds, allTargets, clearIndex);
  const contextsSelectedInModal = sortedContextKinds.filter((ck) => selectedContexts.includes(ck.key));
  // we add the user context to the initial list of visible context kinds if it's not already in the contextsWithTargets array
  const userContext = contextKinds.filter((ck) => ck.key === 'user');
  const defaultContextKind = isDynamicDefaultTargetingContextKindEnabled()
    ? [getDefaultTargetingContextKind(contextKinds)]
    : userContext;
  const defaultVisibleContextKinds = contextsWithTargets.length > 0 ? contextsWithTargets : defaultContextKind;
  // handles the case where context targets were cleared in modal and then changes were discarded
  const deselectedContextsWithTargets = contextsWithTargets.filter(
    (context) => !contextsSelectedInModal.includes(context),
  );
  const defaultOrUpdatedContextKinds = contextsSelectedInModal.length
    ? contextsSelectedInModal.concat(deselectedContextsWithTargets)
    : defaultVisibleContextKinds;
  const visibleContextKinds = defaultOrUpdatedContextKinds ? defaultOrUpdatedContextKinds : sortedContextKinds;

  const profile = useProfileEntity();

  const handleUpdateVisibleContextKinds = (selected: string[]) => {
    if (selectedContexts.length) {
      setSelectedContexts(selectedContexts.filter((ck) => selected.includes(ck)));
    }
    setSelectedContexts(selected);
  };

  const handleClearTargetsForAllContextKinds = (contextsToClear?: string[]) => {
    const contextsWithTargetsKeys = contextsToClear
      ? contextsToClear
      : contextsWithTargets.map((contextKind) => contextKind.key);
    if (isSegmentTargeting && onClearAllSegmentTargets) {
      return onClearAllSegmentTargets(contextsWithTargetsKeys);
    }
    onClearAllTargets && onClearAllTargets(clearIndex, contextsWithTargetsKeys);
  };

  const handleBulkEditClick = () => {
    openBulkEditModal();
  };

  const handleExportAsCsv = () => {
    const fileName = `${projKey}-${envKey}-${flagKey ? flagKey : segmentKey}-${variationName}-contextKeys`;
    const targetRows: string[] = [];
    allTargetsForVariation.toJS().forEach((target) => {
      target.values.forEach((targetKey: string) => targetRows.push(`${targetKey}, ${target.contextKind}`));
    });
    const rows = ['Target keys, Context kind', ...targetRows];
    downloadCsv(fileName, rows);
  };

  const handleConvertToSegmentClick = () => {
    setIsConvertToSegmentModalOpen(true);
  };

  const renderAllContextSelectionAndGrid = () => {
    if (!isContextKindsReady || !contextKinds) {
      return null;
    }

    return (
      <div>
        {visibleContextKinds.map((ck: ContextKind) => {
          const selectId = `${id}--contextKind-${ck.key}--options`;
          return (
            <ManageContextTargeting
              key={`${ck.key}-${variationId}`}
              contextKind={ck}
              allTargets={allTargets}
              gridId={gridId}
              selectId={selectId}
              envKey={envKey}
              projKey={projKey}
              segmentKey={segmentKey}
              onChange={onChange}
              variationId={variationId}
              alreadyTargetedKeys={alreadyTargetedKeysByContext.get(ck.key) ?? Set<string>()}
              variationIndex={clearIndex}
              updateTargetAccessCheck={updateTargetAccessCheck}
              updateExpiringTargetsAccess={updateExpiringTargetsAccess}
              openBulkEditModal={openBulkEditModal}
              segmentVariation={segmentVariation}
              contextTargetGridColumnCount={contextTargetGridColumnCount}
              segmentPendingChangesDispatch={segmentPendingChangesDispatch}
            />
          );
        })}
        {profile.isReader()
          ? null
          : !hideManageContextKinds && (
              <Button
                className="ContextTargeting-manageContextKindsBtn"
                kind="link"
                onClick={handleShowManageContextKindsModal}
              >
                {visibleContextKinds.length === contextKinds.length ? (
                  <>
                    <Icon name="edit" size="small" />{' '}
                    <span className="ContextTargeting-manageContextKindsBtn--text">Edit context</span>
                  </>
                ) : (
                  <>
                    <Icon name="add" size="small" />{' '}
                    <span className="ContextTargeting-manageContextKindsBtn--text">Add context</span>
                  </>
                )}
              </Button>
            )}
      </div>
    );
  };
  const actionMenuTriggerRef = useRef<HTMLButtonElement>();
  const isActionMenuModalOpen = isBulkEditModalOpen || isConvertToSegmentModalOpen;
  useEffectOnClose(() => actionMenuTriggerRef.current?.focus(), isActionMenuModalOpen);

  return (
    <div
      key={id}
      data-test-id="target-individual-targets"
      className={cx('ContextTargeting', 'IndividualTargeting', {
        'ContextTargeting--disabled': !updateTargetAccessCheck.isAllowed,
      })}
    >
      <fieldset>
        <div className="ContextTargeting-label">
          {!hideLabel && <legend>{typeof label === 'string' ? capitalize(label) : label}</legend>}
          <span className="ContextTargeting-actions">
            {actionMenuVisibility !== 'hide' && (
              <>
                {!hideTargetCount && (
                  <span className="ContextTargeting-action">
                    <span className="ContextTargeting-count">
                      {formatNumber(targetCount)} {pluralize(targetCount, 'target', 'targets')}
                    </span>
                  </span>
                )}
                <ContextTargetingActionMenuContainer
                  clearIndex={clearIndex}
                  handleBulkEditClick={handleBulkEditClick}
                  handleExportAsCSVClick={handleExportAsCsv}
                  onChange={onChange}
                  onClearAllTargets={handleClearTargetsForAllContextKinds}
                  segmentKey={segmentKey}
                  segmentVariation={segmentVariation}
                  handleConvertToSegmentClick={handleConvertToSegmentClick}
                  showConvertToSegment={showConvertToSegment}
                  updateTargetAccessCheck={updateTargetAccessCheck}
                  createSegmentAccessCheck={createSegmentAccessCheck}
                  variationName={variationName}
                  isEmpty={!targetCount}
                  menuTriggerRef={actionMenuTriggerRef}
                />
              </>
            )}
            {isBulkEditModalOpen && (
              <BulkContextTargeting
                resourceKind={segmentKey ? ResourceKind.SEGMENT : ResourceKind.FLAG}
                title={bulkDialogTitle}
                projKey={projKey}
                envKey={envKey}
                input={pastedInput}
                inputContextKind={pastedContextKind}
                currentTargets={convertTargetsToKindKeyList(allTargetsForVariation).toOrderedSet()}
                onValue={handleBulkEditValueChange}
                onCancel={handleBulkEditCancel}
                contextKinds={sortedContextKinds}
                targetCount={targetCount}
              />
            )}

            {isConvertToSegmentModalOpen && (
              <ConvertToSegmentModal
                handleClearTargets={handleClearTargetsForAllContextKinds}
                allTargets={allTargetsForVariation as List<Target>}
                variation={
                  /* eslint-disable @typescript-eslint/no-non-null-assertion */
                  variation! /* eslint-enable @typescript-eslint/no-non-null-assertion */
                }
                variationLabel={label}
                isIndividualTargeting
                onCancel={() => setIsConvertToSegmentModalOpen(false)}
              />
            )}
            {isManageContextKindsModalOpen && isContextKindsReady && (
              <ManageContextKindsModal
                onClearTargets={(contextsWithClearedTargets: string[]) => {
                  handleClearTargetsForAllContextKinds(contextsWithClearedTargets);
                }}
                onCancel={handleShowManageContextKindsModal}
                onUpdateVisibleContextKinds={handleUpdateVisibleContextKinds}
                allContextKinds={contextKinds}
                visibleContextKinds={visibleContextKinds.map((ck) => ck.key)}
                contextsWithTargets={contextsWithTargets.map((ck) => ck.key)}
                targetsForVariation={getTargetListForVariation(allTargets, clearIndex)}
                label={label}
                projKey={projKey}
                envKey={envKey}
                flagKey={flagKey}
              />
            )}
          </span>
        </div>
        {actionMenuVisibility !== 'only' && <>{renderAllContextSelectionAndGrid()}</>}
      </fieldset>
    </div>
  );
}

export const getAlreadyTargetedKeysByContext = (
  targets?: List<Target> | List<SegmentTarget>,
): Map<string, Set<string>> => {
  const alreadyTargetedKeysByContext = new Map<string, Set<string>>();
  if (targets) {
    targets.forEach((info: Target | SegmentTarget) => {
      const contextKind = info.getContextKind();

      let targetedKeysForContext = alreadyTargetedKeysByContext.get(contextKind) ?? Set<string>();

      // IDE complainining about targetedKeysForContext being potentially undefined when it's declared above
      info.values.forEach((value: string) => (targetedKeysForContext = targetedKeysForContext?.add(value)));

      alreadyTargetedKeysByContext.set(contextKind, targetedKeysForContext);
    });
  }

  return alreadyTargetedKeysByContext;
};

/* eslint-disable import/no-default-export */
export default ContextTargeting;
