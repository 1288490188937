import React, { useState } from 'react';
import { type Segment as SegmentType } from '@gonfalon/openapi';
import cx from 'clsx';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, RequiredAsterisk, TextField } from 'launchpad';

import { Comment } from 'components/Comment';
import CurrentEnvironment from 'components/CurrentEnvironment';
import { Segment } from 'utils/segmentUtils';

import { useSegmentPendingChangesState } from '../pendingChanges/SegmentPendingChangesProvider';

import { SegmentInstructionsList } from './SegmentInstructionsList';

import styles from './SegmentPendingChangesModal.module.css';

export type SegmentPendingChangesModalProps = {
  segment: Segment;
  isConfirmationRequired?: boolean;
  isCommentRequired?: boolean;
  onCancel(): void;
  onSave(comment?: string): void;
  validation?: { placeholder?: string; onValidate: (input: string) => boolean; label: string };
};

export function SegmentPendingChangesModal({
  segment,
  isConfirmationRequired = false,
  isCommentRequired = false,
  onCancel,
  onSave,
  validation,
}: SegmentPendingChangesModalProps) {
  const [comment, setComment] = useState('');
  const [isCommentValid, setIsCommentValid] = useState(true);
  const [isConfirmationValid, setIsConfirmationValid] = useState(false);
  const pendingChangesState = useSegmentPendingChangesState();

  function onBlurComment() {
    if (isCommentRequired) {
      setIsCommentValid(!!comment.trim());
    }
  }

  function onChangeConfirmation(e: React.ChangeEvent<HTMLInputElement>) {
    if (isConfirmationRequired && validation) {
      setIsConfirmationValid(validation.onValidate(e.target.value || ''));
    }
  }

  function onSubmit() {
    onSave(comment);
  }

  function shouldDisableSubmit() {
    if (isConfirmationRequired) {
      return !isConfirmationValid;
    }

    if (isCommentRequired) {
      return !isCommentValid || !Boolean(comment.trim().length);
    }

    return false;
  }
  return (
    <Modal size="small" onCancel={onCancel}>
      <ModalHeader title={isConfirmationRequired ? 'Confirmation required' : 'Leave a comment'} />
      <ModalBody>
        <section>
          <p>
            This will make changes to the <strong>{segment.name}</strong> segment in the{' '}
            <CurrentEnvironment className={styles.currentEnv} /> environment.
          </p>
          {pendingChangesState.instructions.length > 0 && (
            <>
              <h3 className={styles.changesHeader}>Changes</h3>
              <SegmentInstructionsList
                instructions={pendingChangesState.instructions}
                segment={segment.toJS() as SegmentType}
              />
            </>
          )}

          <Comment
            className={cx({ [styles.errorBorder]: !isCommentValid })}
            optional={!isCommentRequired}
            comment={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder={
              isCommentRequired ? 'Comments are required for changes in this environment' : 'Leave a comment'
            }
            onBlur={onBlurComment}
            showAsterix={isCommentRequired}
          />
        </section>
        {validation && (
          <div className={styles.confirmation}>
            <label htmlFor="segment-pending-changes-validation">
              Confirm
              <RequiredAsterisk />
            </label>
            <p className={styles.label}>{validation.label}</p>
            <TextField
              id="segment-pending-changes-validation"
              placeholder={validation.placeholder}
              onChange={onChangeConfirmation}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter
        secondaryButton={<Button onClick={onCancel}>Cancel</Button>}
        primaryButton={
          <Button kind="primary" onClick={onSubmit} disabled={shouldDisableSubmit()}>
            {isConfirmationRequired ? 'Confirm' : 'Save changes'}
          </Button>
        }
      />
    </Modal>
  );
}
