// transformed by jscodeshift
import { MouseEvent } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useSelector } from 'react-redux';
import { isSyncedSegmentRelayUIEnabled } from '@gonfalon/dogfood-flags';
import { xor } from '@gonfalon/es6-utils';
import { CustomSelect } from '@gonfalon/launchpad-experimental';
import { trackSegmentEvent } from '@gonfalon/segments';
import { List } from 'immutable';
import { Alert, Popover } from 'launchpad';

import { SegmentPopover } from 'components/segments/SegmentPopover';
import { FormHint } from 'components/ui/forms';
import { segmentEntitiesSelector } from 'reducers/segments';
import { relayDetectionSelector } from 'reducers/usage';
import { Segment } from 'utils/segmentUtils';

import { SegmentInfoText } from './SegmentRelayProxyAlert';

type SelectSegmentProps = {
  manageSegmentUrl: string;
  segmentKeys: List<string>;
  onChange(selectedOption: string[]): void;
  onBlur?(): void;
  segments: List<Segment>;
  disabled?: boolean;
  filterSegmentKey?: string;
  id: string;
};

export type OptionProps = {
  label: string;
  segment: Segment;
  siteLink: string;
  value: string;
};

const useRedux = () => {
  const relayDetectionState = useSelector(relayDetectionSelector);
  const segmentEntities = useSelector(segmentEntitiesSelector); // We add this to access all segment entities
  const isRelayUIEnabled = isSyncedSegmentRelayUIEnabled();
  return { relayDetectionState, isRelayUIEnabled, segmentEntities };
};

export const SelectSegment = ({
  segmentKeys,
  segments,
  manageSegmentUrl,
  disabled = false,
  filterSegmentKey,
  onChange,
  onBlur,
  id,
}: SelectSegmentProps) => {
  const { relayDetectionState, isRelayUIEnabled, segmentEntities } = useRedux();
  const segmentKeysArray = segmentKeys.toArray();

  const options = segments
    .map((seg: Segment) => ({
      value: seg.key,
      label: seg.name,
      siteLink: seg.siteLink(),
      segment: seg,
    }))
    .filter((op) => op.value !== filterSegmentKey)
    .toArray();

  const renderOption = (option: OptionProps, { context }: { context: string }) => {
    switch (context) {
      case 'menu': // When the option appears in the menu
        return option.segment._external ? (
          <>
            <Alert isInline className="u-pa-0" kind={relayDetectionState ? 'info' : 'warning'} size="medium">
              {option.segment.name}
            </Alert>
            <FormHint>
              <SegmentInfoText isRelaySetup={relayDetectionState} isRelayUIEnabled={isRelayUIEnabled} />
            </FormHint>
          </>
        ) : (
          option.segment.name
        );
      case 'value': // When the option appears as a selected value
        return (
          <div
            role="presentation"
            onMouseDown={(event: MouseEvent) => {
              // We stop the mousedown event from propagating so that the menu does
              // not open when the user clicks on "View details". (ch81030)
              event.stopPropagation();
            }}
          >
            <Popover interactionKind="hover" placement="bottom">
              <span>
                {option.segment._external ? (
                  <Alert isInline className="u-pa-0" kind={relayDetectionState ? 'info' : 'warning'} size="small">
                    {option.segment.name}
                  </Alert>
                ) : (
                  option.segment.name
                )}
              </span>
              <SegmentPopover
                segment={option.segment}
                isRelaySetup={relayDetectionState}
                isRelayUIEnabled={isRelayUIEnabled}
              />
            </Popover>
          </div>
        );
      default:
        return undefined;
    }
  };
  return (
    <CustomSelect
      className={SelectSegmentClassName}
      isMulti
      id={id}
      blurInputOnSelect
      placeholder="Select segments…"
      aria-label="Select segments"
      value={options.filter((option: OptionProps) => segmentKeysArray.includes(option.value))}
      options={options}
      onChange={(valueSelected: OptionProps[]) => {
        const segmentKeysSelected = valueSelected ? valueSelected.map((v: OptionProps) => v.value) : [];
        onChange(segmentKeysSelected);
        // new segment that was selected
        const changedSegmentKey = xor(segmentKeysSelected, segmentKeysArray)[0];
        if (changedSegmentKey) {
          // then we fish out the Segment from the redux store
          const changedSegment = segmentEntities.find((s: Segment) => s.key === changedSegmentKey);
          const addedRemoved = segmentKeysSelected.length > segmentKeysArray.length ? 'Added' : 'Removed';
          trackSegmentEvent(`Segment ${addedRemoved} to Flag Targeting`, { type: changedSegment.getType() });
        }
      }}
      onBlur={onBlur}
      noOptionsMessage={() => (
        <span>
          No segments found —{' '}
          <a href={manageSegmentUrl} target="_blank" rel="noreferrer noopener">
            Manage segments
          </a>
        </span>
      )}
      formatOptionLabel={renderOption}
      autoSort
      disabled={disabled}
      styles={{ multiValue: { pointerEvents: 'all' }, multiValueRemove: { display: disabled ? 'none' : 'flex' } }}
    />
  );
};

// this class is used to query the DOM for all instances of this component in the workflow builder when applying a workflow template
export const SelectSegmentClassName = 'SelectSegment';
