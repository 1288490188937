import { ChangeEvent, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { enabledMetricAnalysisConfigFields } from '@gonfalon/dogfood-flags';
import { CustomCreatable, OptionProps } from '@gonfalon/launchpad-experimental';
import { Alert, Chip, FormField, FormHint, Radio, RadioGroup } from 'launchpad';

import { NewMetricForm } from '../common';

import styles from './CreateMetricModal.module.css';

export function AnalysisSection({ className }: { className?: string }) {
  const enabledFields = enabledMetricAnalysisConfigFields();
  if (!enabledFields.length) {
    return null;
  }
  return (
    <div className={className}>
      <h2>Measurement definition</h2>

      <fieldset className={styles.analysisConfigFieldset}>
        {enabledFields.includes('unitAggregationType') && <AggregationTypeField />}
        {enabledFields.includes('analysisType') && <AnalysisTypeField />}
        {enabledFields.includes('eventDefault') && <DefaultMissingEventsField />}
      </fieldset>
    </div>
  );
}

const DEFAULT_ANALYSIS_TYPE_OPTIONS = [
  { label: 'Mean', value: 'mean' },
  { label: 'p50', value: 50 },
  { label: 'p75', value: 75 },
  { label: 'p90', value: 90 },
  { label: 'p95', value: 95 },
  { label: 'p99', value: 99 },
];

function AnalysisTypeField() {
  const { control, setValue } = useFormContext<NewMetricForm>();
  const analysisType = useWatch({ control, name: 'analysisType' });
  const percentileValue = useWatch({ control, name: 'percentileValue' });
  const isNumeric = useWatch({ control, name: 'isNumeric' });
  const aggType = useWatch({ control, name: 'unitAggregationType' });
  const eventDefaultValue = useWatch({ control, name: 'eventDefault' }).value;
  // conversion + unit averaged should only allow mean analysis
  const meanOnly = !isNumeric && aggType === 'average';

  const handleAnalysisTypeChange = (selection: OptionProps) => {
    if (selection.value === 'mean') {
      setValue('analysisType', 'mean');
      setValue('eventDefault', {
        disabled: false,
        ...(eventDefaultValue !== undefined && { value: eventDefaultValue }),
      });
      setValue('percentileValue', undefined, { shouldDirty: true });
    } else {
      setValue('analysisType', 'percentile');
      setValue('eventDefault', { disabled: true });
      setValue('percentileValue', selection.value, { shouldDirty: true });
    }
  };

  const getSelectValue = () => ({
    value: analysisType === 'mean' ? 'mean' : percentileValue,
    label: analysisType === 'mean' ? 'Mean' : `p${percentileValue}`,
  });

  return (
    <FormField
      isRequired={false}
      name="analysis-type-selector"
      htmlFor="analysis-type-selector"
      label="Analysis method"
      hint="What method do you want to use to aggregate across units?"
    >
      <>
        <CustomCreatable
          name="Analysis method"
          disabled={meanOnly}
          menuPlacement="top"
          value={getSelectValue()}
          onChange={handleAnalysisTypeChange}
          options={DEFAULT_ANALYSIS_TYPE_OPTIONS}
          formatCreateLabel={(v: string) => v}
          isValidNewOption={(v: string) => {
            // allow entering in custom percentile values if p1-p99
            if (!v.startsWith('p')) {
              return false;
            }
            const n = Number(v.replace('p', ''));
            return Number.isInteger(n) && n >= 1 && n < 100;
          }}
          onCreateOption={(v: string) => {
            const n = Number(v.replace('p', ''));
            handleAnalysisTypeChange({ label: `p${n}`, value: n });
          }}
        />
        {meanOnly && <FormHint>The aggregation method you chose only supports Mean</FormHint>}
        {getSelectValue().value !== 'mean' && (
          <Alert className={styles.betaWarning} isInline kind="info" size="small">
            Percentiles for experiments is in{' '}
            <Chip kind="beta" size="tiny">
              Beta
            </Chip>
          </Alert>
        )}
      </>
    </FormField>
  );
}

function AggregationTypeField() {
  const { control, setValue } = useFormContext<NewMetricForm>();
  const aggType = useWatch({ control, name: 'unitAggregationType' });
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('unitAggregationType', event.target.value as 'average' | 'sum', { shouldDirty: true });
  };
  return (
    <FormField
      label="Unit aggregation method"
      isRequired={false}
      name="aggregation-selector"
      htmlFor="aggregation-selector"
      hint="How do you want to handle units that have received more than one event?"
      className={styles.aggregationField}
    >
      <RadioGroup name="aggregation-selector" value={aggType} onChange={handleChange}>
        <div className={styles.aggregationRadio}>
          <Radio id="unit-aggregation-average" name="aggregation-selector" value="average" />
          <label htmlFor="unit-aggregation-average">Average individual unit values</label>
        </div>
        <div className={styles.aggregationRadio}>
          <Radio id="unit-aggregation-sum" name="aggregation-selector" value="sum" />
          <label htmlFor="unit-aggregation-sum">Sum individual unit values</label>
        </div>
      </RadioGroup>
    </FormField>
  );
}

function DefaultMissingEventsField() {
  const { control, setValue } = useFormContext<NewMetricForm>();
  const eventDefault = useWatch({ control, name: 'eventDefault' });
  const isNumeric = !!useWatch({ control, name: 'isNumeric' });
  const isPercentile = useWatch({ control, name: 'analysisType' }) === 'percentile';
  const eventDefaultValue = useWatch({ control, name: 'eventDefault' }).value;

  useEffect(() => {
    if (!isNumeric) {
      // ensure we always enable event default for conversion metrics
      setValue('eventDefault', {
        disabled: false,
        ...(eventDefaultValue !== undefined && { value: eventDefaultValue }),
      });
    }
  }, [isNumeric]);

  if (!isNumeric || isPercentile) {
    return null;
  }

  return (
    <FormField
      isRequired={false}
      name="default-missing"
      htmlFor="default-missing"
      label="Units without events"
      hint="How do you want to handle units that have received no events?"
      className={styles.aggregationField}
    >
      <RadioGroup
        name="default-missing"
        value={eventDefault.disabled ? 'disabled' : 'defaulted'}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setValue(
            'eventDefault',
            {
              disabled: e.target.value === 'disabled',
              ...(e.target.value !== 'disabled' && eventDefaultValue !== undefined && { value: eventDefaultValue }),
            },
            { shouldDirty: true },
          )
        }
      >
        <div className={styles.aggregationRadio}>
          <Radio id="default-missing-defaulted" name="default-missing" value="defaulted" />
          <label htmlFor="default-missing-defaulted">Set the value for units with no events to zero</label>
        </div>
        <div className={styles.aggregationRadio}>
          <Radio id="default-missing-disabled" name="default-missing" value="disabled" />
          <label htmlFor="default-missing-disabled">Do not include units with no events in analysis</label>
        </div>
      </RadioGroup>
    </FormField>
  );
}
