// TODO: For now, this only supports user context kinds. SC-171088 for supporting other context kinds

import { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useVirtual } from 'react-virtual';
import { noop } from '@gonfalon/es6-utils';
import cx from 'clsx';
import { List, Set } from 'immutable';
import { Tooltip } from 'launchpad';
import { createStructuredSelector } from 'reselect';

import { fetchContextKindsIfNeeded as fetchContextKindsAction } from 'actions/contextKinds';
import { loadContextTarget as loadContextTargetAction } from 'actions/contexts';
import { ContextItem, ContextKind, ContextTargetKindKey } from 'components/Contexts/types';
import {
  createUserFromContextItem,
  getContextKindDisplayName,
  hasKindKeyInList,
  makeKindKeyStringFromKindKey,
} from 'components/Contexts/utils/contextTargetingUtils';
import UserAvatar from 'components/UserAvatar';
import UserAvatarWithPopover from 'components/UserAvatarWithPopover';
import { useIsScrolling } from 'hooks/useIsScrolling';
import { GlobalDispatch, GlobalState } from 'reducers';
import { unhiddenContextKindsSelector } from 'reducers/contextKinds';
import { contextByKindKeySelector, contextRequestByKindKeySelector, ContextsSelectorProps } from 'reducers/contexts';
import { safeInvoke } from 'utils/functionUtils';
import { Request } from 'utils/requestUtils';

import 'stylesheets/components/UserTargetList.css';

const rowHeight = 31;

export type ContextTargetProps = {
  isScrolling: boolean;
  target: ContextTargetKindKey;
  ignored?: boolean;
  irrelevant?: boolean;
  explainIrrelevance?: string;
  resolved?: boolean;
  request?: Request;
  contextItem?: ContextItem;
  projKey: string;
  envKey: string;
  contextKinds: ContextKind[];
  getTargetClassName?(target: ContextTargetKindKey): void;
  onToggle?(): void;
  onLoadTarget?(): void;
};

function ContextTarget(props: ContextTargetProps) {
  const {
    target,
    contextItem,
    request,
    ignored,
    irrelevant,
    explainIrrelevance,
    projKey,
    envKey,
    getTargetClassName,
    onToggle,
    contextKinds,
    isScrolling,
    resolved,
    onLoadTarget,
  } = props;

  useEffect(() => {
    if (resolved) {
      return;
    }

    if (!isScrolling && !request?.lastFetched && !request?.isFetching && onLoadTarget) {
      onLoadTarget();
    }
  }, [resolved, isScrolling, request, onLoadTarget]);

  // TODO: update child components to accept context instead of user sc-170119
  const user = contextItem ? createUserFromContextItem(contextItem, projKey, envKey) : undefined;
  const targetContextKindName = getContextKindDisplayName(contextKinds, target.kind);
  const isUnknown = request?.lastFetched && !user;
  const rootClasses = cx(
    'UserTarget',
    'fs-exclude',
    {
      'UserTarget--unknown': isUnknown,
    },
    safeInvoke(getTargetClassName, target) || '',
  );

  const infoClasses = cx('UserTarget-info', {
    'u-very-subtle': irrelevant,
  });

  const iconClasses = cx('UserTarget-icon', {
    'u-a-50': ignored || irrelevant,
  });

  let input;

  if (irrelevant) {
    input = (
      <Tooltip placement="top" content={explainIrrelevance}>
        <input id={`${target.kind} ${target.key}`} type="checkbox" disabled={irrelevant} onChange={noop} />
      </Tooltip>
    );
  } else {
    input = (
      <input
        id={`${target.kind} ${target.key}`}
        type="checkbox"
        disabled={irrelevant}
        checked={!ignored && !irrelevant}
        onChange={noop}
      />
    );
  }

  return (
    <label
      htmlFor={`${target.kind} ${target.key}`}
      role="presentation"
      className={rootClasses}
      onClick={!irrelevant ? onToggle : undefined}
      data-test-id="context-targeting-list-item"
    >
      {onToggle && <div className="UserTarget-select">{input}</div>}
      <div className={iconClasses}>
        {user ? <UserAvatarWithPopover user={user} placement="bottom" linkToPage /> : <UserAvatar />}
      </div>
      <div className={infoClasses}>
        <span>
          <code className="UserTarget-context-kind">{targetContextKindName}</code>{' '}
          <span>{user ? user.attributes.getDisplayName() : target.key}</span>
        </span>
      </div>
    </label>
  );
}

const mapStateToProps = createStructuredSelector<
  GlobalState,
  ContextsSelectorProps & { kindKey: string },
  { request: Request; contextItem?: ContextItem }
>({
  request: contextRequestByKindKeySelector,
  contextItem: contextByKindKeySelector,
});

const ConnectedContextTarget = connect(mapStateToProps)(ContextTarget);

export type ContextTargetListProps = {
  adjustToItemCount?: boolean;
  targets: List<ContextTargetKindKey>;
  resolved?: boolean;
  ignoredTargets?: Set<ContextTargetKindKey>;
  irrelevantTargets?: Set<ContextTargetKindKey>;
  explainIrrelevance?: string;
  getTargetClassName?(target: ContextTargetKindKey): void;
  projKey: string;
  envKey: string;
  contextKinds: ContextKind[];
  onToggle?(target: ContextTargetKindKey): void;
  onLoadTarget?(target: ContextTargetKindKey): void;
  fetchContextKinds(): void;
};

export function ContextTargetList({
  targets,
  ignoredTargets,
  irrelevantTargets,
  resolved,
  explainIrrelevance,
  adjustToItemCount,
  getTargetClassName,
  projKey,
  envKey,
  contextKinds,
  onLoadTarget,
  onToggle,
  fetchContextKinds,
}: ContextTargetListProps) {
  const parentRef = useRef<HTMLDivElement>(null);

  const { isScrolling } = useIsScrolling({ ref: parentRef });

  const rowVirtualizer = useVirtual({
    size: targets.size,
    parentRef,
    estimateSize: useCallback(() => rowHeight, []),
  });

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    fetchContextKinds();
  }, []);

  useEffect(() => {
    setCounter((counter + 1) % 2);
  }, [targets, ignoredTargets, irrelevantTargets]);

  return (
    <div
      className="UserTargetList fs-exclude"
      style={adjustToItemCount ? { height: `${targets.size * rowHeight}px` } : {}}
      ref={parentRef}
      // we want to be able to focus this element so keyboard users can scroll easily
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
    >
      <div
        className="UserTargetGrid-container"
        style={{
          height: `${rowVirtualizer.totalSize}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {rowVirtualizer.virtualItems.map((virtualRow) => {
          /* eslint-disable @typescript-eslint/no-non-null-assertion */
          const target = targets.get(virtualRow.index)!; /* eslint-enable @typescript-eslint/no-non-null-assertion */

          return (
            <div
              key={`${target.kind}-${target.key}`}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`,
              }}
              className="UserTargetList-row"
            >
              <ConnectedContextTarget
                target={target}
                resolved={resolved}
                isScrolling={isScrolling}
                ignored={ignoredTargets && hasKindKeyInList(ignoredTargets, target)}
                irrelevant={irrelevantTargets && hasKindKeyInList(irrelevantTargets, target)}
                explainIrrelevance={explainIrrelevance}
                projKey={projKey}
                envKey={envKey}
                getTargetClassName={getTargetClassName}
                contextKinds={contextKinds}
                onToggle={onToggle ? () => onToggle(target) : undefined}
                onLoadTarget={() => onLoadTarget?.(target)}
                kindKey={makeKindKeyStringFromKindKey(target)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

const mapContainerStateToProps = createStructuredSelector<GlobalState, { contextKinds: ContextKind[] }>({
  contextKinds: unhiddenContextKindsSelector,
});

const mapDispatchToProps = (
  dispatch: GlobalDispatch,
  ownProps: Omit<ContextTargetListProps, 'contextKinds' | 'fetchContextKinds'> & { id: string },
) => ({
  onLoadTarget: (target: ContextTargetKindKey) =>
    dispatch(
      loadContextTargetAction({
        viewId: ownProps.id,
        target,
        envKey: ownProps.envKey,
        projKey: ownProps.projKey,
      }),
    ),
  fetchContextKinds: async () => dispatch(fetchContextKindsAction(ownProps.projKey)),
});

export const ContextTargetListContainer = connect(mapContainerStateToProps, mapDispatchToProps)(ContextTargetList);
