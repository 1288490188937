import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToEvaluationReason = () => {
  const { hash } = useLocation();

  useLayoutEffect(() => {
    if (!hash) {
      return;
    }

    const labelEl = document.querySelector(`[data-anchor='${hash}']`);

    if (hash === '#targetingoff') {
      labelEl?.classList.add('Module-highlighted-targeting-off');
    } else {
      labelEl?.classList.add('Module-highlighted');
    }

    labelEl?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
  }, [hash]);
};
