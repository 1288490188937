import { List } from 'immutable';

import { ContextKind } from 'components/Contexts/types';
import { Target } from 'utils/flagUtils';
import { SegmentTarget } from 'utils/segmentUtils';

import { ContextKindsModalListItem } from './ContextKindsModalListItem';

export type ContextKindsModalListProps = {
  allContextKinds: ContextKind[];
  visibleContextKinds: string[];
  targetsForVariation: List<Target> | List<SegmentTarget>;
  selectedContexts: string[];
  onUpdateSelectedContexts(selectedContext: string): void;
};

const isChecked = (contextKey: string, selectedContexts: string[]) => selectedContexts.includes(contextKey);
const isOnlyKindChecked = (contextKey: string, selectedContexts: string[]) =>
  isChecked(contextKey, selectedContexts) && selectedContexts.length === 1;

export function ContextKindsModalList({
  allContextKinds,
  selectedContexts,
  targetsForVariation,
  onUpdateSelectedContexts,
}: ContextKindsModalListProps) {
  if (allContextKinds.length === 0) {
    return <p className="u-tc u-pv-m">No contexts match your query.</p>;
  }
  return (
    <ul>
      {allContextKinds.map((ck) => (
        <ContextKindsModalListItem
          key={ck.key}
          contextKind={ck}
          isChecked={isChecked(ck.key, selectedContexts)}
          isOnlyKindChecked={isOnlyKindChecked(ck.key, selectedContexts)}
          targetCount={targetsForVariation.find((target) => target.contextKind === ck.key)?.values.size || 0}
          onUpdateSelectedContexts={onUpdateSelectedContexts}
        />
      ))}
    </ul>
  );
}
