import { CardSelectValue } from 'components/CardSelect/CardSelect';
import MetricKind, { GuidedMetricKindDisplays } from 'utils/MetricKind';

export const unitAggregationCardSelectOptions: CardSelectValue[] = [
  {
    value: 'sum',
    name: 'Sum',
    description: 'Total value per unit',
    subtitle: 'ex: If a customer makes 3 purchases, add up their total ',
  },
  {
    value: 'average',
    name: 'Average',
    description: 'Average value per unit',
    subtitle: 'ex: If a customer makes 3 purchases, add up their purchases and divide by 3 to calculate their average',
  },
];

export const measureTypeCardSelectOptions: CardSelectValue[] = [
  {
    value: 'count',
    name: 'Count',
    description: 'Number of times an event occurred',
    subtitle: 'ex: Number of purchases made',
    tags: [GuidedMetricKindDisplays[MetricKind.COUNT].displayName],
  },
  {
    value: 'occurrence',
    name: 'Occurrence',
    description: 'Whether an event is seen at least once',
    subtitle: 'ex: Errors',
    tags: [GuidedMetricKindDisplays[MetricKind.BINARY].displayName],
  },
  {
    value: 'value',
    name: 'Value / Size',
    description: 'Magnitude of an event that has an associated number',
    subtitle: 'ex: Latency or purchase value',
    tags: [GuidedMetricKindDisplays[MetricKind.NUMERIC].displayName],
  },
];

export const clickMeasureTypeSelectOptions: CardSelectValue[] = [
  {
    value: 'count',
    name: 'Count',
    description: 'Number of times a target was clicked',
  },
  {
    value: 'occurrence',
    name: 'Occurrence',
    description: 'Whether a target was clicked',
  },
];

export const pageviewMeasureTypeSelectOptions: CardSelectValue[] = [
  {
    value: 'count',
    name: 'Count',
    description: 'Number of times a page was viewed',
  },
  {
    value: 'occurrence',
    name: 'Occurrence',
    description: 'Whether a page was viewed',
  },
];

export type RandomizationUnitOption = {
  label: string;
  value: string;
  default?: boolean;
};

export function toValidMetricKey(name: string): string {
  const dashed = name.toLowerCase().replace(/\s+/g, '-');
  let withoutInvalidCharacters = dashed.replace(/[^a-zA-Z0-9._-]/g, '');

  // Removing all non-matching characters from the front
  while (withoutInvalidCharacters.length > 0 && !/^[a-zA-Z0-9]/.test(withoutInvalidCharacters)) {
    withoutInvalidCharacters = withoutInvalidCharacters.substring(1);
  }

  return withoutInvalidCharacters;
}
