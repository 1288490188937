import { ClipboardEventHandler } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useDispatch, useSelector } from 'react-redux';
import { OptionProps } from '@gonfalon/launchpad-experimental';
import { Set } from 'immutable';

import { clearContextOptions, loadContextOptions } from 'actions/contexts';
import SelectContext from 'components/contextTargeting/SelectContext';
import { useWorkflowBuilderViewOptions } from 'components/CustomWorkflows/WorkflowBuilder/WorkflowBuilderState/utils';
import { GlobalState } from 'reducers';
import { contextOptionsByIdSelectorWithoutProps } from 'reducers/contexts';

type SelectContextContainerProps = {
  id: string;
  envKey: string;
  projKey: string;
  alreadyTargetedKeys: Set<string>;
  hasSelectedTargets: boolean;
  onChange(option: { value?: string } | OptionProps[] | null): void;
  onPaste: ClipboardEventHandler<HTMLDivElement>;
  contextKind: string;
  onBlur?(): void;
  onOpenContextPopover: (value?: string) => void;
  hasExpiringContextTargetAccess?: boolean;
  formatOptionLabel?: (option: OptionProps) => JSX.Element | undefined;
  getOptionLabel?: (option: OptionProps) => string | undefined;
  value?: Array<{ value: string; key: number }>;
  isMulti?: boolean;
  className?: string;
  enableExpiringContextTargets?: boolean;
  isSearch?: boolean;
  prefix?: string;
  isDisabled: boolean;
  disabledReason?: string;
};

const SelectContextContainer = (props: SelectContextContainerProps) => {
  const dispatch = useDispatch();
  const { hideExpiringTargets } = useWorkflowBuilderViewOptions();

  const onInputChange = (value: string) => {
    dispatch(loadContextOptions(props.id, value, props.projKey, props.envKey, props.contextKind));
  };

  const onClose = () => {
    dispatch(clearContextOptions(props.id, props.contextKind));
  };

  const contextDataFromGlobalState = useSelector((state: GlobalState) =>
    contextOptionsByIdSelectorWithoutProps(state, props.projKey, props.envKey, props.id),
  );

  return (
    <SelectContext
      isLoading={contextDataFromGlobalState.isLoading}
      options={contextDataFromGlobalState.options}
      onClose={onClose}
      onInputChange={onInputChange}
      searchCharacterMin={2}
      enableExpiringContextTargets={!hideExpiringTargets}
      {...props}
    />
  );
};

/* eslint-disable import/no-default-export */
export default SelectContextContainer;
